import { Component, OnInit, Input } from '@angular/core';
import { AppEndpoints } from '../../app.endpoints';
import { AppFunctions } from '../../app.functions';
import { AlertService } from '../../components/alert_service/alert.service';

@Component({
	selector: 'usage_summary_chart',
	templateUrl: './usage_summary_chart.component.html',
	styleUrls: [
		'../../../vendor/styles/circle-progressbar.scss',
		'../../../vendor/libs/spinkit/spinkit.scss'
	]
})

/**
  * @name UsageSummaryChart
  * @description Genera un gráfico del consumo del cliente basado en el endpoint _get_dashboard_data_.
  */
export class UsageSummaryChart implements OnInit {
	@Input() data:any;
	@Input() loading: any;
	@Input() valid:any;

	constructor() {}

	ngOnInit() {}

	getClass(){
		return `c100 p${Math.trunc(this.getPercent())}`;
	}

	getPercent(){
		return parseFloat(this.data.porcentaje.toFixed(2));
	}
}