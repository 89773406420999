import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpResponse,
    HttpErrorResponse 
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from "rxjs/operators";
import { AppEndpoints } from './app.endpoints';
import { AlertService } from './components/alert_service/alert.service';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(public endpoint: AppEndpoints, private alertService: AlertService){

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var idToken = "";
        if(localStorage.getItem("sms_marketing_impact_mobile_session")){
            idToken = JSON.parse(localStorage.getItem("sms_marketing_impact_mobile_session")).token;
        }
        if(idToken){
            const cloned = req.clone({ 
                headers: req.headers.set("Authorization",
                "Bearer " + idToken)
            });
            return next.handle(cloned).pipe(
                catchError((error: HttpErrorResponse) => {
                    if(error.status==403){
                        this.endpoint.logout();
                    }else{
                        return throwError(error);
                    }
                })
            );
        }else{
            return next.handle(req).pipe(
                catchError((error: HttpErrorResponse) => {
                    if(error.status==403){
                        this.endpoint.logout();
                        return [];
                    }else{
                        return throwError(error);
                    }
                })
            );;
        }
    }
}