import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { AppService } from '../../../app.service';
import { AppEndpoints } from '../../../app.endpoints';
import { LayoutService } from '../../../layout/layout.service';
import { ModalDirective } from 'ngx-bootstrap';
import { default as swal } from 'sweetalert2';
import { FormRendererComponent } from '../../../components/form_renderer/form_renderer.component';
import { AlertService } from '../../../components/alert_service/alert.service';
import { ExcelService } from '../../../components/excel_service/excel.service';
import { conformToMask } from 'angular2-text-mask/dist/angular2TextMask';
import { ServiceDatatableNoCountComponent } from '../../../components/service_datatable_nocount/service-datatable-nocount.component';
import * as FileSaver from 'file-saver';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
	selector: 'outbox_smpp_24h',
	templateUrl: './outbox_smpp_24h.component.html',
	styleUrls: [
		'../../../../vendor/libs/spinkit/spinkit.scss'
	]
})
export class OutboxSmpp24HComponent implements OnInit {
	@ViewChild('datatable_ref') datatable_ref: ServiceDatatableNoCountComponent;
	@ViewChild('search_modal') search_modal: ModalDirective;
	@ViewChild('view_sms_modal') view_sms_modal: ModalDirective;
	@ViewChild("view_sms_ref") view_sms_ref;
	public locale = 'es-HN';
	public datatable: any;
	public enable:boolean;
	public load = {
		current_offset: null,
		sort_ascendent: null,
		sort_order: null,
		view_length: null,
		fecha_in: null,
		fecha_fin: null,
		search_word: null
	};
	public filters = {
		current_offset: 1,
		view_length: 10,
		sort_order: "",
		sort_ascendent: false,
		search_word:""
	};
	public search_data = {
		dates:[],
		fecha_in: '',
		fecha_fin: ''
	}

	constructor(private appService: AppService, public endpoint: AppEndpoints, private layoutService: LayoutService, private alertService: AlertService, private excelService: ExcelService, private localeService: BsLocaleService){
		this.appService.pageTitle = 'Bandeja de Salida SMPP';
		this.localeService.use(this.locale);
		this.enable = true;
		this.datatable = {
			title: "Listado de Mensajes SMPP",
			show_search: true,
			search_placeholder: "- Buscar -",
			icon: "exit",
			object_description: "mensajes enviados",
			empty_text: "No se encontraron mensajes SMPP",
			header_class: "custom-th-dark",
			columns: [
				{
					column: "sender",
					wrap_column: false,
					header: "Remitente",
					wrap_header: true,
					type: "text",
					class: "custom-small-table-cell"
				},
				{
					column: "receiver",
					wrap_column: false,
					header: "Número",
					wrap_header: true,
					type: "text",
					class: "custom-small-table-cell"
				},
				{
					column: "fecha",
					wrap_column: true,
					header: "Fecha",
					wrap_header: true,
					type: "text",
					class: "custom-small-table-cell"
				},
				{
					column: "msgdata",
					wrap_column: false,
					header: "Texto",
					wrap_header: true,
					type: "text",
					class: "custom-small-table-cell"
				}
			],
			events: [
				{
                    name:"Reenviar SMS",
                    style:"color:#2F5BB4",
                    hover_style:"cursor:pointer; color:#2F5BB4; background-color:#D6E4FF !important;",
                    icon:"share"
                }
			],
			navigation_offsets: [5, 10, 15, 20, 25, 50],
			show_search_field: true,
			table_icon: "caret-right"
		}
	}

	ngOnInit(){
		this.datatable_ref.set_show_length(10);
	}

	ngOnDestroy() {
		setTimeout(() => this.layoutService.off('resize.app-home'));
	}


	//########################################################################
	//DATATABLE ##############################################################

		get_results_offset_change(data){
			this.filters = {
				current_offset: data.current_offset,
				view_length: data.view_length,
				sort_order: data.sort_order,
				sort_ascendent: data.sort_ascendent,
				search_word: data.search_word
			}
			var response;
			this.load = {
				current_offset: this.filters.current_offset,
				sort_ascendent: this.filters.sort_ascendent,
				sort_order: this.filters.sort_order,
				view_length: this.filters.view_length,
				fecha_in: this.search_data.fecha_in,
				fecha_fin: this.search_data.fecha_fin,
				search_word: this.filters.search_word
			}
			this.endpoint.get_sms_sent_smpp_24h(this.load).subscribe(
				data => response = data,
				err => {
					this.datatable_ref.set_loading(false);
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
				},
				() => {
					try {
						if(response.enable){
							this.enable = true;
							for (var i = 0; i < response.list.length; i++) {
								if (response.list[i].fecha) {
									response.list[i].fecha = new Date(response.list[i].fecha).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
								}
							}
							this.datatable_ref.set_results_offset_change(response.list);
						}else{
							this.enable = false;
							this.datatable_ref.set_results_filter_change([]);
						}
					} catch (error) {
						this.datatable_ref.set_loading(false);
						this.alertService.alert_aplication_error("Error Interno del Aplicativo");
					}

				}
			);
		}

		get_results_filter_change(data){
			this.filters = {
				current_offset: data.current_offset,
				view_length: data.view_length,
				sort_order: data.sort_order,
				sort_ascendent: data.sort_ascendent,
				search_word: data.search_word
			}
			var response;
			this.load = {
				current_offset: this.filters.current_offset,
				sort_ascendent: this.filters.sort_ascendent,
				sort_order: this.filters.sort_order,
				view_length: this.filters.view_length,
				fecha_in: this.search_data.fecha_in,
				fecha_fin: this.search_data.fecha_fin,
				search_word: this.filters.search_word
			}
			this.endpoint.get_sms_sent_smpp_24h(this.load).subscribe(
				data => response = data,
				err => {
					this.datatable_ref.set_loading(false);
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
				},
				() => {
					try {
						if(response.enable){
							this.enable = true;
							for (var i = 0; i < response.list.length; i++) {
								if (response.list[i].fecha) {
									response.list[i].fecha = new Date(response.list[i].fecha).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
								}
								if(response.list[i].msgdata){
									var txt = document.createElement("textarea");
								    txt.innerHTML = response.list[i].msgdata;
								    response.list[i].msgdata =  txt.value;
								}
							}
							this.datatable_ref.set_results_offset_change(response.list);
						}else{
							this.enable = false;
							this.datatable_ref.set_results_filter_change([]);
						}
					} catch (error) {
						this.datatable_ref.set_loading(false);
						this.alertService.alert_aplication_error("Error Interno del Aplicativo");
					}
				}
			);
		}

		get_results_update_list(data){
			this.filters = {
				current_offset: data.current_offset,
				view_length: data.view_length,
				sort_order: data.sort_order,
				sort_ascendent: data.sort_ascendent,
				search_word: this.filters.search_word
			}
			var response;
			this.load = {
				current_offset: this.filters.current_offset,
				sort_ascendent: this.filters.sort_ascendent,
				sort_order: this.filters.sort_order,
				view_length: this.filters.view_length,
				fecha_in: this.search_data.fecha_in,
				fecha_fin: this.search_data.fecha_fin,
				search_word: data.search_word
			}
			this.endpoint.get_sms_sent_smpp_24h(this.load).subscribe(
				data => response = data,
				err => {
					this.datatable_ref.set_loading(false);
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
				},
				() => {
					try {
						if(response.enable){
							this.enable = true;
							for (var i = 0; i < response.list.length; i++) {
								if (response.list[i].fecha) {
									response.list[i].fecha = new Date(response.list[i].fecha).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
								}
							}
							this.datatable_ref.set_results_update_list(response.list);
						}else{
							this.enable = false;
							this.datatable_ref.set_results_filter_change([]);
						}
					} catch (error) {
						this.datatable_ref.set_loading(false);
						this.alertService.alert_aplication_error("Error Interno del Aplicativo");
					}
				}
			);
		}

		get_results_refresh(){
			this.datatable_ref.set_loading(true);
			var response;
			this.load = {
				current_offset: this.filters.current_offset,
				sort_ascendent: this.filters.sort_ascendent,
				sort_order: this.filters.sort_order,
				view_length: this.filters.view_length,
				fecha_in: this.search_data.fecha_in,
				fecha_fin: this.search_data.fecha_fin,
				search_word: this.filters.search_word
			}
			this.endpoint.get_sms_sent_smpp_24h(this.load).subscribe(
				data => response = data,
				err => {
					this.datatable_ref.set_loading(false);
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
				},
				() => {
					try {
						if(response.enable){
							this.enable = true;
							for (var i = 0; i < response.list.length; i++) {
								if (response.list[i].fecha) {
									response.list[i].fecha = new Date(response.list[i].fecha).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
								}
							}
							this.datatable_ref.set_results_update_list(response.list);
						}else{
							this.enable = false;
							this.datatable_ref.set_results_filter_change([]);
						}
					} catch (error) {
						this.datatable_ref.set_loading(false);
						this.alertService.alert_aplication_error("Error Interno del Aplicativo");
					}
				}
			);	
		}

		datatable_events(event){
			if(event.event == "Reenviar SMS"){
            	this.alertService.option_alert("Reenviar SMS","¿Está seguro que desea reenviar el SMS seleccionado?","Sí, Enviar").then((result) => {
            		if(result.value){
            			this.resend_sms_smpp(event.data.sql_id);
            		}
                }).catch(()=>{return false;}); 
            }
		}

		resend_sms_smpp(id){
			this.datatable_ref.set_loading(true);
			var response;
			var load = {
				id:id
			}
			this.endpoint.resend_sms_smpp(load).subscribe(
				data => response = data,
				err => {
					this.datatable_ref.set_loading(false);
					if(err.status && err.error){
	                    this.alertService.alert_message(err.status ,err.error);
	                }else{
	                    this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
	                }
				},
				()=> {
					this.datatable_ref.set_loading(false);
					this.alertService.alert_success(response.title, response.message);
	            }
			);
		}

		search(){
			if(this.search_data.dates && this.search_data.dates.length == 2 && this.search_data.dates[0] instanceof Date && this.search_data.dates[1] instanceof Date){
				if(this.valid_date()){
					this.search_data.fecha_in = (this.search_data.dates[0].getDate()<10 ? "0" + this.search_data.dates[0].getDate() : "" + this.search_data.dates[0].getDate()) + "/" + ((this.search_data.dates[0].getMonth() + 1)<10 ? "0" + (this.search_data.dates[0].getMonth() + 1) : "" + (this.search_data.dates[0].getMonth() + 1)) + "/"+this.search_data.dates[0].getFullYear();
					this.search_data.fecha_fin = (this.search_data.dates[1].getDate()<10 ? "0" + this.search_data.dates[1].getDate() : "" + this.search_data.dates[1].getDate()) + "/" + ((this.search_data.dates[1].getMonth() + 1)<10 ? "0" + (this.search_data.dates[1].getMonth() + 1) : "" + (this.search_data.dates[1].getMonth() + 1)) + "/"+this.search_data.dates[1].getFullYear();
					this.search_modal.hide();
					this.datatable_ref.emit_get_results_filter_change();
				}
			}else{
				this.search_data.fecha_in = "";
				this.search_data.fecha_fin = "";
				this.search_modal.hide();
				this.datatable_ref.emit_get_results_filter_change();
			}
		}

		valid_date(){
			if(this.search_data.dates && this.search_data.dates.length == 2 && this.search_data.dates[0] instanceof Date && this.search_data.dates[1] instanceof Date){
				var diff = this.search_data.dates[1].getTime() - this.search_data.dates[0].getTime();
				if(diff/(1000 * 3600 * 24) > 30){
					return false;
				}else{
					return true;
				}
			}else{
				return true;
			}
		}

		clean_search(){
			this.search_data = {
				dates:[],
				fecha_in: '',
				fecha_fin: ''
			}
			this.search_modal.hide();
			this.datatable_ref.clean_search();
			this.datatable_ref.emit_get_results_filter_change();
		}

	//DATATABLE ##############################################################
	//########################################################################

	//########################################################################
	//MISC ###################################################################

		format_status(status){
			return status == 'SENT' ? 'ENVIADO' : status == 'SENDING' ? 'ENVIANDO' : status == 'QUEUED' ? 'EN COLA' : status == 'PARSE_ERROR' ? 'ERROR' : '';
		}

	//MISC ###################################################################
	//########################################################################

}
