import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, OnInit  } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { AppService } from '../../../app.service';
import { AppEndpoints } from '../../../app.endpoints';
import { LayoutService } from '../../../layout/layout.service';
import { ModalDirective } from 'ngx-bootstrap';
import { default as swal } from 'sweetalert2';
import { FormRendererComponent } from '../../../components/form_renderer/form_renderer.component';
import { AlertService } from '../../../components/alert_service/alert.service';
import { ExcelService } from '../../../components/excel_service/excel.service';
import { ServiceDatatableComponent } from '../../../components/service_datatable/service-datatable.component';
import { CalendarEvent, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { Subject } from 'rxjs';

@Component({
    selector: 'calendario-sms',
    templateUrl: './calendario_sms.component.html',
    styleUrls:[
        '../../../../vendor/libs/spinkit/spinkit.scss',
        '../../../../vendor/libs/angular-calendar/angular-calendar.scss'
    ]
})
export class CalendarioSmsComponent implements OnInit{
    @ViewChild('edit_sms_modal') edit_sms_modal: ModalDirective;
    @ViewChild("edit_sms_ref") edit_sms_ref;
    @ViewChild('view_sms_modal') view_sms_modal: ModalDirective;
    @ViewChild("view_sms_ref") view_sms_ref;
    public loading:boolean;
    public view:number;
    public calendar_view:string;
    public current_date:Date;
    public locale = 'es-hn';
    public events: CalendarEvent[] = [];
    public selected_date_events:any[];
    public refresh: Subject<any> = new Subject();
    public months:any[];
    public search_submitted:boolean;
    public selected_date={
        anio:"",
        mes:"",
        dia:"",
        date:new Date()
    }

    public edit_sms_loading:boolean;

    @ViewChild('download_sms_csv_modal') download_sms_csv_modal: ModalDirective;
    public download_sms_loading:boolean;
    public download_sms_view:number;
    public download_sms_link:string;
    public download_sms_data = {
        id_calendarizado:"",
        name:""
    }

    constructor(private appService: AppService, public endpoint : AppEndpoints,  private layoutService: LayoutService, private alertService: AlertService, private excelService: ExcelService) {
        this.appService.pageTitle = 'Calendario de SMS';
        this.loading = false;
        this.view = 1;
        this.calendar_view = "month";
        this.current_date = new Date();
        this.months = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
        this.search_submitted = false;
        this.selected_date_events = [];
        this.edit_sms_loading = false;

        this.download_sms_loading = false;
        this.download_sms_view = 1;
        this.download_sms_link = "";
        this.download_sms_data = {
            id_calendarizado:"",
            name:""
        }
    }

    ngOnInit(){
        this.get_calendar_events();
    }

    ngAfterViewInit(){    
    
    }

    //########################################################################
    //SMS CALENDAR ###########################################################

        get_calendar_events(){
            var response;
            this.loading = true;
            var load = {
                anio:this.current_date.getFullYear() + "",
                mes:(this.current_date.getMonth()+1<10)?("0"+(this.current_date.getMonth()+1)):((this.current_date.getMonth()+1)+""),
                dia:""
            }
            this.endpoint.get_calendar_events(load).subscribe(
                data => response = data,
                err => {
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                    this.loading = false;
                },
                ()=> {
                    try{
                        this.events = [];
                        for(var i = 0;i<response.length;i++){
                            response[i].sms_hour_string = new Date(response[i].sms_fecha).toLocaleString('es-HN', {hour:"2-digit", minute:"2-digit", hour12: true});
                            var event = {
                                title:response[i].smsadmin_resellers_nombre,
                                start:new Date(response[i].sms_fecha),
                                allDay: false,
                            };
                            this.events.push(this.create_event(event, response[i]));
                        }
                        this.refresh.next();
                        this.loading = false;
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.loading = false;
                    }
                }
            );
        }

        create_event(event: CalendarEvent, sms) {
            Object.assign(event, {
                draggable: false,
                sms:sms,
                resizable: {
                    beforeStart: false,
                    afterEnd: false
                },
                actions: [{
                    label: '<i class="ion ion-md-create"></i>',
                    onClick: ({ event }) => alert(`Edit event "${event.title}"`)
                },{
                    label: '<i class="ion ion-md-close"></i>',
                    onClick: ({ event }) => this.events = this.events.filter(ev => ev !== event)
                }]
            });
            return event;
        }

        add_year(){
            this.current_date.setFullYear(this.current_date.getFullYear() + 1);
            this.refresh.next();
            this.date_changed(this.current_date);
        }

        substract_year(){
            this.current_date.setFullYear(this.current_date.getFullYear() - 1);
            this.refresh.next();
            this.date_changed(this.current_date);
        }

        add_month(){
            this.current_date.setMonth(this.current_date.getMonth() + 1);
            this.refresh.next();
            this.date_changed(this.current_date);
        }

        substract_month(){
            this.current_date.setMonth(this.current_date.getMonth() - 1);
            this.refresh.next();
            this.date_changed(this.current_date);
        }

        date_changed(date){
            var response;
            this.loading = true;
            var load = {
                anio:date.getFullYear(),
                mes:(date.getMonth()+1<10)?("0"+(date.getMonth()+1)):((date.getMonth()+1)+""),
                dia:""
            }
            this.endpoint.get_calendar_events(load).subscribe(
                data => response = data,
                err => {
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                    this.loading = false;
                },
                ()=> {
                    try{
                        this.events = [];
                        for(var i = 0;i<response.length;i++){
                            var event = {
                                title:response[i].smsadmin_resellers_nombre,
                                start:new Date(response[i].sms_fecha),
                                allDay: false,
                            };
                            this.events.push(this.create_event(event, response[i]));
                        }
                        this.refresh.next();
                        this.loading = false;
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.loading = false;
                    }
                }
            );
        }

        date_changed_day(){
            this.selected_date_events = [];
            var response;
            this.loading = true;
            var load = {
                anio:this.selected_date.anio,
                mes:this.selected_date.mes,
                dia:this.selected_date.dia
            }
            this.endpoint.get_calendar_events(load).subscribe(
                data => response = data,
                err => {
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                    this.loading = false;
                },
                ()=> {
                    try{
                        this.events = [];
                        this.selected_date_events = [];
                        for(var i = 0;i<response.length;i++){
                            response[i].sms_fecha = new Date(response[i].sms_fecha);
                            if(!isNaN(response[i].sms_grupos)){
                                response[i].sms_grupos = parseInt(response[i].sms_grupos);
                            }
                        }
                        this.selected_date_events = response;
                        this.loading = false;
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.loading = false;
                    }
                }
            );
        }

        today_clicked(){
            this.current_date = new Date();
            this.refresh.next();
            this.get_calendar_events();
        }

        date_clicked(data){
            this.view = 2;
            this.selected_date_events = [];
            var response;
            this.loading = true;
            this.selected_date={
                anio:this.current_date.getFullYear() + "",
                mes:(this.current_date.getMonth()+1<10)?("0"+(this.current_date.getMonth()+1)):((this.current_date.getMonth()+1)+""),
                dia:((data.day.date.getDate())<10)?("0"+(data.day.date.getDate())):((data.day.date.getDate())+""),
                date:new Date(this.current_date.getFullYear(),this.current_date.getMonth(),data.day.date.getDate())
            }
            var load = {
                anio:this.current_date.getFullYear() + "",
                mes:(this.current_date.getMonth()+1<10)?("0"+(this.current_date.getMonth()+1)):((this.current_date.getMonth()+1)+""),
                dia:((data.day.date.getDate())<10)?("0"+(data.day.date.getDate())):((data.day.date.getDate())+"")
            }
            this.endpoint.get_calendar_events(load).subscribe(
                data => response = data,
                err => {
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                    this.loading = false;
                },
                ()=> {
                    try{
                        this.events = [];
                        this.selected_date_events = [];
                        for(var i = 0;i<response.length;i++){
                            response[i].sms_hour_string = new Date(response[i].sms_fecha).toLocaleString('es-HN', {hour:"2-digit", minute:"2-digit", hour12: true});
                            response[i].sms_fecha = new Date(response[i].sms_fecha);
                            if(!isNaN(response[i].sms_grupos)){
                                response[i].sms_grupos = parseInt(response[i].sms_grupos);
                            }
                        }
                        this.selected_date_events = response;
                        this.loading = false;
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.loading = false;
                    }
                }
            );
        }

        return_calendar(){
            this.view = 1;
            var response;
            this.loading = true;
            var load = {
                anio:this.current_date.getFullYear() + "",
                mes:(this.current_date.getMonth()+1<10)?("0"+(this.current_date.getMonth()+1)):((this.current_date.getMonth()+1)+""),
                dia:""
            }
            this.endpoint.get_calendar_events(load).subscribe(
                data => response = data,
                err => {
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                    this.loading = false;
                },
                ()=> {
                    try{
                        this.events = [];
                        for(var i = 0;i<response.length;i++){
                            response[i].sms_hour_string = new Date(response[i].sms_fecha).toLocaleString('es-HN', {hour:"2-digit", minute:"2-digit", hour12: true});
                            var event = {
                                title:response[i].smsadmin_resellers_nombre,
                                start:new Date(response[i].sms_fecha),
                                allDay: false,
                            };
                            this.events.push(this.create_event(event, response[i]));
                        }
                        this.refresh.next();
                        this.loading = false;
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.loading = false;
                    }
                }
            );
        }

    //SMS CALENDAR ###########################################################
    //########################################################################

    //########################################################################
    //SMS ####################################################################

        open_edit_sms(sms){
            this.edit_sms_ref.reset_sms();
            this.edit_sms_ref.set_values_sms(sms);
            this.edit_sms_modal.show();
        }

        open_view_sms(sms){
            this.view_sms_ref.reset_sms();
            this.view_sms_ref.set_values_sms(sms);
            this.view_sms_modal.show();
        }

        open_delete_sms(id){
            this.alertService.option_alert("Eliminar SMS","¿Está seguro que desea eliminar el SMS seleccionado?","Sí, Eliminar").then((result) => {
                if(result.value){
                    this.loading = true;
                    var response;
                    var load = {
                        id:id
                    }
                    this.endpoint.delete_sms(load).subscribe(
                        data => response = data,
                        err => {
                            this.loading = false;
                            if(err.status && err.error){
                                this.alertService.alert_message(err.status ,err.error);
                            }else{
                                this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                            }
                        },
                        ()=> {
                            try{
                                this.alertService.alert_success(response.title, response.message);
                                this.date_changed_day();
                            }catch(error){
                                this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                                this.loading = false;
                            }
                        }
                    );
                }
            }).catch(()=>{return false;});
        }

        open_stop_sms(id, fecha){
            this.alertService.option_alert("Detener Envío","¿Está seguro que desea detener el envío seleccionado?<br><br><b>Hora de Envío:<br></b><b class='text-success'> "+fecha+"</b>","Sí, Detener").then((result) => {
                if(result.value){
                    this.loading = true;
                    var load = {
                        id:id
                    };
                    var response;
                    this.endpoint.stop_sms(load).subscribe(
                        data => response = data,
                        err => {
                            this.loading = false;
                            if(err.status && err.error){
                                this.alertService.alert_message(err.status ,err.error);
                            }else{
                                this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                            }
                        },
                        ()=> {
                            try{
                                this.date_changed_day();
                                this.alertService.alert_success(response.title, response.message);
                            }catch(error){
                                this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                                this.loading = false;
                            }
                        }
                    );
                }
            }).catch(()=>{return false;});
        }

        close_edit_sms(){
            this.edit_sms_modal.hide();
        }

        close_view_sms(){
            this.view_sms_modal.hide();
        }

        edit_sms_event(data){
            this.edit_sms_loading = true;
            var response;
            this.endpoint.edit_sms(data).subscribe(
                data => response = data,
                err => {
                    this.edit_sms_loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.edit_sms_modal.hide();
                        this.edit_sms_ref.reset_sms();
                        this.alertService.alert_success(response.title, response.message);
                        this.date_changed_day();
                        this.edit_sms_loading = false;
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.edit_sms_loading = false;
                    }
                }
            );
        }

    //SMS ####################################################################
    //########################################################################

    //########################################################################
    //DOWNLOAD SMS ###########################################################

        open_download_sms(data){
            this.download_sms_loading = false;
            this.download_sms_view = 1;
            this.download_sms_link = "";
            this.download_sms_data = {
                id_calendarizado:data.id,
                name:data.sms_fecha_string + " - " + data.smsadmin_resellers_nombre
            }
            this.download_sms_csv_modal.show();
        }

        download_sms(){
            this.download_sms_loading = true;
            var response;
            var load = {
                id_calendarizado:this.download_sms_data.id_calendarizado
            }
            this.endpoint.get_sms_detail_csv(load).subscribe(
                data => {
                    response = data;
                },
                err => {
                    if (err.status && err.error) {
                        this.alertService.alert_message(err.status, err.error);
                    } else {
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                    this.download_sms_loading = false;
                },
                () => {
                    let url = response.filename;
                    window.open(this.endpoint.get_endpoint() + "/download/" + url);
                    this.download_sms_link = this.endpoint.get_endpoint() + "/download/" + response.filename;
                    this.download_sms_loading = false;
                    this.download_sms_view = 2;
                }
            );
        }

    //DOWNLOAD SMS ###########################################################
    //########################################################################

    ngOnDestroy() {
        setTimeout(() => this.layoutService.off('resize.app-home'));
    }

}
