import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, OnInit, ElementRef, Renderer2, Output, EventEmitter, Input } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { AppEndpoints } from '../../app.endpoints';
import { ModalDirective } from 'ngx-bootstrap';
import { default as swal } from 'sweetalert2';
import { FormRendererComponent } from '../form_renderer/form_renderer.component';
import { AlertService } from '../alert_service/alert.service';
import { ExcelService } from '../excel_service/excel.service';
import { ServiceDatatableComponent } from '../service_datatable/service-datatable.component';
import { conformToMask } from 'angular2-text-mask/dist/angular2TextMask';

@Component({
    selector: 'send-sms',
    templateUrl: './send_sms.template.html',
    styleUrls:[
    '../../../vendor/libs/spinkit/spinkit.scss'
    ]
})

export class SendSmsComponent implements OnInit{
    @Output() send_sms_event: EventEmitter<any> = new EventEmitter<any>();
    @Input() disable_groups:boolean;
    @Input() selected_group:any;
    @Input() enable_clean_clients:any;
    @ViewChild('sms_form') sms_form: FormControlDirective;
    @ViewChild('sms_mensaje_original') sms_mensaje_original:ElementRef;
    @ViewChild('calendarized_form') calendarized_form: FormRendererComponent;
    public sms_send_type:number;
    public sms_clean_clients:boolean;
    public sms_loading:boolean;
    public sms_view:number;
    public sms_submitted:boolean;
    public sms_data = {
        sms_mensaje_original:"",
        nom_camp:"",
        tipo_campania:"",
        num_gest:"",
        cif:"",
        remitente:"",
        remitentegw:"",
        id_area:"",
        id_empresa:"",
        id_contacto:""
    }
    public search_group_word:string;

    public groups_loading:boolean;
    public groups_list:any[];
    public groups_selected:any[];

    @ViewChild('client_datatable_ref') client_datatable_ref: ServiceDatatableComponent;
    public clients_loading:boolean;
    public clients_list:any[];
    public clients_selected:any[];
    public client_datatable:any;

    @ViewChild('template_datatable_ref') template_datatable_ref: ServiceDatatableComponent;
    @ViewChild('template_modal') template_modal: ModalDirective;
    public template_loading:boolean;
    public template_list:any[];
    public template_selected:any[];
    public template_datatable:any;

    public plantillas_label_list:any[];
    public remitentes_list:any[];
    public areas_list:any[];
    public empresas_list:any[];
    public contactos_list:any[];
    public tipo_campania_list:any[];

    public calendarized_dates:any[];
    public calendarized_inputs = [
        {
            class:"row",
            columns:[
                {
                    class:"col-md-12",
                    inputs:[
                        {
                            type:"calendar",
                            extra:"popup",
                            name:"sms_dia",
                            label:"",
                            icon:"",
                            class:"form-control form-control-sm",
                            placeholder:"",
                            minlength:null,
                            maxlength:null,
                            pattern:null,
                            error_required:"Requerido",
                            error_pattern:null,
                            error_minlength:"",
                            list_data:{
                                value:"",
                                text:""
                            },
                            list:()=>{
                                return []
                            },
                            textmask:()=>{
                                return false;
                            },
                            required:()=>{
                                return true;
                            },
                            disabled:()=>{
                                return !this.calendarized_sms;
                            },
                            change:(event)=>{
                            },
                            input:()=>{
                            }
                        }
                    ]
                },
                {
                    class:"col-md-12",
                    inputs:[
                        {
                            type:"time",
                            extra:"",
                            name:"sms_hora",
                            label:"",
                            icon:"",
                            class:"form-control form-control-sm",
                            placeholder:"",
                            minlength:null,
                            maxlength:null,
                            pattern:null,
                            error_required:"Requerido",
                            error_pattern:null,
                            error_minlength:"",
                            list_data:{
                                value:"",
                                text:""
                            },
                            list:()=>{
                                return []
                            },
                            textmask:()=>{
                                return false;
                            },
                            required:()=>{
                                return true;
                            },
                            disabled:()=>{
                                return !this.calendarized_sms;
                            },
                            change:(event)=>{
                            },
                            input:()=>{
                            }
                        }
                    ]
                }
            ]
        },
    ]

    @ViewChild('sms_prueba_modal') sms_prueba_modal: ModalDirective;
    @ViewChild('test_sms_form') test_sms_form: FormRendererComponent;
    public test_sms_loading:boolean;
    public test_sms_submitted:boolean;
    public test_sms_view:number;
    public test_sms:string;
    public test_sms_mask = [/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/];

    @ViewChild('sms_vista_previa') sms_vista_previa: ModalDirective;
    public sms_preview_data = {
        text:"",
        url:"",
        remitente:"",
        hour:""
    }

    @ViewChild('sms_caracteres_modal') sms_caracteres_modal: ModalDirective;

    public enable_cif:boolean;
    public current_time:Date;
    public calendarized_sms:boolean;

    constructor(public endpoint : AppEndpoints, private alertService: AlertService, private renderer: Renderer2) {
        this.sms_loading = false;
        this.sms_submitted = false;
        this.sms_view = 1;
        this.sms_send_type = 2;
        this.sms_clean_clients = false;

        this.test_sms_loading = false;
        this.test_sms_submitted = false;
        this.test_sms = "";
        this.test_sms_view = 1;

        this.groups_loading = false;
        this.groups_list = [];
        this.groups_selected = [];
        this.search_group_word = "";

        this.clients_loading = false;
        this.clients_list = [];
        this.clients_selected = [];
        this.client_datatable = {
            title:"Clientes Disponibles",
            show_search:true,
            search_placeholder:"- Buscar -",
            object_description:"clientes",
            empty_text:"No se encontraron clientes",
            columns:[
                {   
                    column:"cli_nombre",
                    class:"col-8",
                    header:"Nombre Cliente",
                    type:"text"
                },
                {   
                    column:"cli_celular",
                    class:"col-4",
                    header:"Celular",
                    type:"text"
                }
            ],
            navigation_offsets:[5,10,15,20,25,50],
            show_search_field:true
        }

        this.template_loading = false;
        this.template_list = [];
        this.template_selected = [];
        this.template_datatable = {
            title:"",
            white_bg:true,
            show_search:true,
            search_placeholder:"- Buscar -",
            object_description:"plantillas",
            empty_text:"No se encontraron plantillas",
            columns:[
                {   
                    column:"categoria_nombre",
                    class:"w-25",
                    header:"Categoría",
                    type:"text"
                },
                {   
                    column:"templates_nombre",
                    class:"w-25",
                    header:"Plantilla",
                    type:"text"
                },
                {   
                    column:"sms_mensaje_original",
                    class:"w-50",
                    header:"Mensaje",
                    type:"text"
                }
            ],
            navigation_offsets:[5,10,15,20,25,50],
            show_search_field:true
        }


        this.plantillas_label_list = [];
        this.remitentes_list = [];
        this.areas_list = [];
        this.empresas_list = [];
        this.contactos_list = [];
        this.tipo_campania_list = [];

        this.calendarized_dates = [];

        this.enable_cif = false;
        this.current_time = null;
        this.calendarized_sms = false;
    }

    ngOnInit() {
        this.get_template_labels();
        this.get_shortcodes();
        this.get_subresellers();
        this.get_enable_cif();
        this.get_current_time();
    }

    AfterViewInit(){
    }

    //########################################################################
    //SMS ####################################################################

        send_sms(){
            if(this.disable_groups){
                this.groups_selected = [];
                this.groups_selected.push({
                    grp_id:this.selected_group.grp_id,
                    grp_nombre:this.selected_group.grp_nombre,
                    subgroups:[JSON.parse(JSON.stringify(this.selected_group))]
                })
            }
            if(((this.calendarized_sms && this.calendarized_dates.length>0) || !this.calendarized_sms) && this.sms_form.valid && this.sms_data.sms_mensaje_original.length > 0 && ((this.sms_send_type == 2 && this.groups_selected.length > 0) || (this.sms_send_type == 1 && this.clients_selected.length > 0))){
                var mensaje_original = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ');
                var mensaje_parsed = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ') + " ";
                for(var j = 0;j<this.plantillas_label_list.length;j++){
                    var alias_to_replace = new RegExp("\#" + this.plantillas_label_list[j].var_alias + " ","g");
                    var field_to_insert = "$" + this.plantillas_label_list[j].var_campo + " ";
                    mensaje_parsed = mensaje_parsed.replace(alias_to_replace,field_to_insert);
                }
                var bad_chars = this.validate_sms_caracters();
                if(bad_chars.length > 0){
                    var error_text = ["Caracteres: " + bad_chars.join(", ")];
                    this.alertService.alert_multiple_error("Error de Caracteres", "Se encontraron caracteres no permitidos dentro del mensaje", error_text);
                    return false;
                }else{
                    var dates_errors = [];
                    for(var r = 0;r<this.calendarized_dates.length;r++){
                        var time = new Date(this.calendarized_dates[r].sms_dia + " " + this.calendarized_dates[r].sms_hora);
                        time.setMinutes(time.getMinutes() - 1)
                        if(time <= this.current_time){
                            dates_errors.push("Hora de Calendarización: " +  this.calendarized_dates[r].string_date + " " + this.calendarized_dates[r].string_time);
                        }
                    }
                    if(dates_errors.length>0){
                        this.alertService.alert_multiple_error("Error de Fechas", "La fecha y hora calendarizada debe de ser como mínimo un minuto mayor a la fecha y hora actual", dates_errors);
                    }else{
                        var shortcode ="";
                        for(var m = 0;m<this.remitentes_list.length;m++){
                            if(this.remitentes_list[m].id == this.sms_data.remitente){
                                shortcode = this.remitentes_list[m].short_shortcode;
                            }
                        };
                        var clean = 0;
                        if(this.sms_clean_clients){
                            clean = 1;
                        }
                        var data = {
                            sms_mensaje_original:mensaje_original.trim(),
                            sms_mensaje_parseado:mensaje_parsed.trim(),
                            nom_camp:this.sms_data.nom_camp,
                            tipo_campania:this.sms_data.tipo_campania?this.sms_data.tipo_campania:0,
                            num_gest:this.sms_data.num_gest,
                            cif:this.sms_data.cif,
                            remitente:this.sms_data.remitente,
                            remitentegw:shortcode,
                            id_area:this.sms_data.id_area,
                            id_empresa:this.sms_data.id_empresa?this.sms_data.id_empresa:0,
                            id_contacto:this.sms_data.id_contacto?this.sms_data.id_contacto:0,
                            groups_selected:this.sms_send_type==2?this.groups_selected:[],
                            clients_selected:this.sms_send_type==1?this.clients_selected:[], 
                            calendarized_dates:this.calendarized_dates,
                            calendarized_sms:this.calendarized_sms,
                            sms_send_type:this.sms_send_type,
                            limpiar_miembros:clean
                        }
                        this.get_total_sms_count(data);
                    }
                }
            }else{
                this.alertService.alert_error("Error de Formulario", "Se encontraron errores en el formulario de envío de SMS");
                this.sms_submitted = true;
            }
        }

        get_total_sms_count(data){
            this.sms_loading  = true;
            var response;
            this.endpoint.get_total_sms_count(data).subscribe(
                data => response = data,
                err => {
                    this.sms_loading  = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        var night_date = false;
                        for(var i = 0;i<this.calendarized_dates.length;i++){
                             var time = new Date(this.calendarized_dates[i].sms_dia + " " + this.calendarized_dates[i].sms_hora);
                             var hour = time.getHours();
                             if(time.getMinutes()>0){
                                 hour++;
                             }
                             if(hour>21 || hour<7){
                                 night_date = true;
                             }
                        }
                        if(this.calendarized_dates.length==0 && !this.calendarized_sms){
                            var hour = this.current_time.getHours();
                            if(this.current_time.getMinutes()>0){
                                 hour++;
                            }
                            if(hour>21 || hour<7){
                                 night_date = true;
                             }
                        }
                        var message = "" +
                        ((night_date)?"<br><h6 class='text-danger'>Su calendarización contiene envíos nocturnos</h6>":"") + 
                        "¿Está seguro que desea enviar el SMS a los clientes seleccionados?<br><br><b class='text-primary'>Total SMS a Enviar<br></b>"+
                        "<h3 style='border-bottom: solid 1px #dcdcdc;padding-bottom: 20px;'><b>" + response.total_sms_count.toLocaleString('es-HN') + "</b></h3>" +
                        "<div class='text-center' style='float:left;width:33%'>" +
                        "<p style='font-size:12px !important;margin-bottom:0px !important;' class='text-muted'><b>SMS Largo 1</b></p> " +
                        "<p style='font-size:12px !important;margin-bottom:0px !important;margin-right:10px;'><b>" + response.total_sms_count_length1.toLocaleString('es-HN') + "</b></p>" +
                        "</div>" +
                        "<div class='text-center' style='float:left;width:33%'>" +
                        "<p style='font-size:12px !important;margin-bottom:0px !important;' class='text-muted'><b>SMS Largo 2</b></p> " +
                        "<p style='font-size:12px !important;margin-bottom:0px !important;margin-right:10px;'><b>" + response.total_sms_count_length2.toLocaleString('es-HN') + "</b></p>" +
                        "</div>" +
                        "<div class='text-center' style='float:left;width:33%'>" +
                        "<p style='font-size:12px !important;margin-bottom:0px !important;' class='text-muted'><b>SMS Largo 3+</b></p> " +
                        "<p style='font-size:12px !important;margin-bottom:0px !important;margin-right:10px;'><b>" + response.total_sms_count_length3.toLocaleString('es-HN') + "</b></p>" +
                        "</div>";
                        var delete_db = "";
                        if(data.limpiar_miembros==1){
                            delete_db = "<br><br><b class='text-danger'>ADVERTENCIA: </b><b>La opción de eliminar clientes está seleccionada, las membresías y clientes de TODA LA BASE DE DATOS se eliminarán después de realizar el envío</b>";
                        }

                        this.alertService.option_alert("Enviar SMS", message + delete_db,"Sí, Enviar").then((result) => {
                            if(result.value){
                                this.send_sms_event.emit(data);
                            }
                        }).catch(()=>{return false;});
                        this.sms_loading  = false;
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.sms_loading  = false;
                    }
                }
            );
        }

        get_total_clientes(){
            if(this.sms_send_type == 1){
                return this.count_clients();
            }else{
                return this.clients_selected.length.toLocaleString('en-us');
            }
        }

        set_loading(value:boolean){
            this.sms_loading = value;
        }

        clean_sms_text(){
            this.sms_data.sms_mensaje_original = "";
        }

        get_enable_cif(){
            var response;
            this.endpoint.get_enable_cif().subscribe(
                data => response = data,
                err => {
                    this.enable_cif = false;
                },
                ()=> {
                    try{
                        if(response == 1){
                            this.enable_cif = true;
                        }else{
                            this.enable_cif = false;
                        }
                    }catch(error){
                        this.enable_cif = false;
                    }
                }
            );
        }

        get_shortcodes(){
            var response;
            this.endpoint.get_shortcodes().subscribe(
                data => response = data,
                err => {
                    this.remitentes_list = [];
                },
                ()=> {
                    try{
                        this.remitentes_list = [];
                        this.remitentes_list = response;
                        if(this.remitentes_list.length > 0){
                            this.sms_data.remitente = this.remitentes_list[0].id;
                        }
                    }catch(error){
                        this.remitentes_list = [];
                    }
                }
            );
        }

        get_subresellers(){
            var response;
            this.endpoint.get_subresellers().subscribe(
                data => response = data,
                err => {
                    this.areas_list = [];
                },
                ()=> {
                    try{
                        this.areas_list = [];
                        this.areas_list = response;
                        if(this.areas_list.length > 0){
                            this.sms_data.id_area = this.areas_list[0].id_subreseller;
                            if(this.areas_list.length > 0){
                                this.get_companies();
                                this.get_company_types();
                                this.get_contacts();
                            }
                        }
                    }catch(error){
                        this.areas_list = [];
                    }
                }
            );
        }

        get_companies(){
            var response;
            this.endpoint.get_companies().subscribe(
                data => response = data,
                err => {
                    this.empresas_list = [];
                },
                ()=> {
                    try{
                        this.empresas_list = [];
                        this.empresas_list = response;
                        if(this.empresas_list.length > 0){
                            this.sms_data.id_empresa = this.empresas_list[0].id;
                        }
                    }catch(error){
                        this.empresas_list = [];
                    }
                }
            );
        }

        get_company_types(){
            var response;
            this.endpoint.get_company_types().subscribe(
                data => response = data,
                err => {
                    this.tipo_campania_list = [];
                },
                ()=> {
                    try{
                        this.tipo_campania_list = [];
                        this.tipo_campania_list = response;
                        if(this.tipo_campania_list.length > 0){
                            this.sms_data.tipo_campania = this.tipo_campania_list[0].id;
                        }
                    }catch(error){
                        this.tipo_campania_list = [];
                    }
                }
            );
        }

        get_contacts(){
            var response;
            this.endpoint.get_contacts().subscribe(
                data => response = data,
                err => {
                    this.contactos_list = [];
                },
                ()=> {
                    try{
                        this.contactos_list = [];
                        this.contactos_list = response;
                        if(this.contactos_list.length > 0){
                            this.sms_data.id_contacto = this.contactos_list[0].id;
                        }
                    }catch(error){
                        this.contactos_list = [];
                    }
                }
            );
        }

        get_current_time(){
            var response;
            this.endpoint.get_current_time().subscribe(
                data => response = data,
                err => {
                    this.current_time = null;
                },
                ()=> {
                    try{
                        this.current_time = new Date(response.date);
                        setInterval(()=>{ 
                            this.current_time.setSeconds(this.current_time.getSeconds() + 1);
                        }, 1000);
                    }catch(error){
                        this.current_time = null;
                    }
                }
            );
        }

        trigger_calendarized(){
            this.calendarized_form.clean_form();
            if(!this.calendarized_sms){
                this.calendarized_dates = [];
            }
        }

        clean_calendarized_inputs(){
            this.calendarized_form.clean_form();
        }

        add_calendarized_day(){
            if(this.calendarized_form.valid()){
                var date = {
                    sms_dia:this.calendarized_form.get_values().sms_dia.split("/").reverse().join("-"),
                    sms_hora:this.calendarized_form.get_values().sms_hora,
                    string_time:this.time_pretty_print(this.calendarized_form.get_values().sms_hora),
                    string_date:this.calendarized_form.get_values().sms_dia
                }
                var time = new Date(this.calendarized_form.get_values().sms_dia.split("/").reverse().join("-") + " " + this.calendarized_form.get_values().sms_hora);
                time.setMinutes(time.getMinutes() - 1)
                if(time <= this.current_time){
                    this.alertService.alert_error("Fecha Incorrecta", "La fecha y hora calendarizada debe de ser como mínimo un minuto mayor a la fecha y hora actual");
                }else{
                    this.calendarized_dates.push(date);
                    this.calendarized_form.set_value("sms_dia","");
                }
            }
        }

        remove_calendarized_day(index){
            this.calendarized_dates.splice(index,1);
        }

        time_pretty_print(time){
            time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) {
                time = time.slice (1);
                time[5] = +time[0] < 12 ? ' AM' : ' PM';
                time[0] = +time[0] % 12 || 12;
            }
            return time.join ('');
        }

        clean_spaces(){
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/á/g, 'a');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/é/g, 'e');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/í/g, 'i');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/ó/g, 'o');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/ú/g, 'u');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/Á/g, 'A');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/É/g, 'E');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/Í/g, 'I');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/Ó/g, 'O');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/Ú/g, 'U');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.trim();
            for(var i = this.sms_data.sms_mensaje_original.length-1;i>=0;i--){
                var is_valid = false;
                for(var j = 0;j<this.endpoint.get_sms_allowed_characters_full().length;j++){
                    if(this.sms_data.sms_mensaje_original[i]==this.endpoint.get_sms_allowed_characters_full()[j]){
                        is_valid = true;
                        break;
                    }
                }
                if(!is_valid){
                    this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.slice(0,i) + this.sms_data.sms_mensaje_original.slice(i+1);
                }
            }
        }

        open_allowed_characters(){
            this.sms_caracteres_modal.show();
        }

        insert_character(value){
            if(value == "SPACE"){
                this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original + " ";
            }else if(value == "ENTER"){
                this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original + "\n";
            }else{
                this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original + value;
            }
        }

        validate_sms_caracters(){
            var chars = [];
            for(var i = 0;i<this.sms_data.sms_mensaje_original.length;i++){
                var is_valid = false;
                for(var j = 0;j<this.endpoint.get_sms_allowed_characters_full().length;j++){
                    if(this.sms_data.sms_mensaje_original[i]==this.endpoint.get_sms_allowed_characters_full()[j]){
                        is_valid = true;
                        break;
                    }
                }
                if(!is_valid){
                    chars.push("<b style='margin-right:10px;'>" + this.sms_data.sms_mensaje_original[i] + "</b>");
                }
            }
            return chars;
        }

        validate_input(event){
            const key = (event.which) ? event.which : event.keyCode;
            return true;
        }

        remove_double_spaces(){
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ').trim();
        }

    //SMS ####################################################################
    //########################################################################

    //########################################################################
    //CLIENTS ################################################################

        set_clients_view(){
            if(this.sms_send_type!=1){
                this.sms_send_type = 1;
            }
        }

        client_datatable_events(event){
            var exists = false;
            for(var i = 0;i<this.clients_selected.length;i++){
                if(this.clients_selected[i].id==event.data.id){
                    exists = true;
                }
            }
            if(!exists){
                this.clients_selected.push(event.data);
            }
        }

        client_remove(index){
            this.clients_selected.splice(index, 1);
        }

        client_datatable_get_results_offset_change(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                search_word:data.search_word
            }
            this.endpoint.get_clients_short(load).subscribe(
                data => response = data,
                err => {
                    this.client_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.client_datatable_ref.set_results_offset_change(response.list);
                    }catch(error){
                        this.client_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                    
                }
            );
        }

        client_datatable_get_results_filter_change(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                search_word:data.search_word
            }
            this.endpoint.get_clients_short(load).subscribe(
                data => response = data,
                err => {
                    this.client_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.client_datatable_ref.set_results_filter_change(response.list, response.count);
                    }catch(error){
                        this.client_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

        client_datatable_get_results_update_list(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                search_word:data.search_word
            }
            this.endpoint.get_clients_short(load).subscribe(
                data => response = data,
                err => {
                    this.client_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.client_datatable_ref.set_results_update_list(response.list, response.count);
                    }catch(error){
                        this.client_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

    //CLIENTS ################################################################
    //########################################################################

    //########################################################################
    //GROUPS #################################################################

        set_groups_view(){
            if(this.sms_send_type!=2){
                this.sms_send_type = 2;  
            }
        }

        get_groups_for_sms(){
            this.sms_loading = true;
            var response;
            this.endpoint.get_groups_for_sms().subscribe(
                data => response = data,
                err => {
                    this.groups_list = [];
                    this.groups_selected = [];
                    this.sms_loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.groups_list = [];
                        this.groups_selected = [];
                        for(var i = 0;i<response.length;i++){
                            var exists = false;
                            for(var j = 0;j<this.groups_list.length;j++){
                                if(response[i].grp_id == this.groups_list[j].grp_id){
                                    exists = true;
                                    this.groups_list[j].subgroups.push(response[i]);
                                }
                            }
                            if(!exists){
                                this.groups_list.push({
                                    grp_id:response[i].grp_id,
                                    grp_nombre:response[i].grp_nombre,
                                    subgroups:[response[i]],
                                    extended:false
                                })
                            }
                        }
                        this.sms_loading = false;
                    }catch(error){
                        this.sms_loading = false;
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

        select_group(group){
            var exists = false;
            for(var j = 0;j<this.groups_selected.length;j++){
                if(group.grp_id == this.groups_selected[j].grp_id){
                    exists = true;
                    var sbgrp_exists = false;
                    for(var i = 0 ;i<this.groups_selected[j].subgroups.length;i++){
                        if(this.groups_selected[j].subgroups[i].id==group.id){
                            sbgrp_exists = true;
                        }
                    }
                    if(!sbgrp_exists){
                        this.groups_selected[j].subgroups.push(group);
                    }
                }
            }
            if(!exists){
                this.groups_selected.push({
                    grp_id:group.grp_id,
                    grp_nombre:group.grp_nombre,
                    subgroups:[group]
                })
            }
        }

        remove_group(grp_index,sgrp_index){
            this.groups_selected[grp_index].subgroups.splice(sgrp_index,1);
            if(this.groups_selected[grp_index].subgroups.length==0){
               this.groups_selected.splice(grp_index,1); 
            }
        }

        count_clients(){
            var count = 0;
            for(var j = 0;j<this.groups_selected.length;j++){
                for(var i = 0 ;i<this.groups_selected[j].subgroups.length;i++){
                    count = count + this.groups_selected[j].subgroups[i].total_clientes;
                }
            }
            return count.toLocaleString('en-us');
        }

        search_count_clients(){
            var count = 0;
            for(var j = 0;j<this.groups_list.length;j++){
                for(var i = 0 ;i<this.groups_list[j].subgroups.length;i++){
                    if(this.groups_list[j].subgroups[i].id==this.selected_group.id){
                        count = count + this.groups_list[j].subgroups[i].total_clientes;
                    }
                }
            }
            return count.toLocaleString('en-us');
        }

        remove_all_groups(){
            this.groups_selected = [];
        }

    //GROUPS #################################################################
    //########################################################################

    //########################################################################
    //TEMPLATES ##############################################################

        get_template_labels(){
            var response;
            this.sms_loading = true;
            this.endpoint.get_labels().subscribe(
                data => response = data,
                err => {
                    this.plantillas_label_list = [];
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                    this.sms_loading = false;
                },
                ()=> {
                    try{
                        this.plantillas_label_list = [];    
                        for(var i = 0;i<response.length;i++){
                            if(response[i].var_alias){
                                this.plantillas_label_list.push(response[i])
                            }
                        }
                        this.get_groups_for_sms();
                        this.client_datatable_ref.set_show_length(5);
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.sms_loading = false;
                    }
                }
            );
        }

        add_alias(alias){
            if((this.sms_data.sms_mensaje_original + " #" + alias + " ").length > 320){
                if((this.sms_data.sms_mensaje_original + " #" + alias).length == 320){
                    this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original + " #" + alias;
                    this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ')
                }
            }else if(this.sms_data.sms_mensaje_original.length == 0){
                this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original + "#" + alias + " ";
                this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ')
            }else{
                this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original + " #" + alias + " ";
                this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ')
            }
            this.sms_mensaje_original.nativeElement.focus();
        }

        open_templates(){
            this.template_modal.show();
            this.template_datatable_ref.set_show_length(5);
        }

        template_datatable_get_results_offset_change(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                search_word:data.search_word
            }
            this.endpoint.get_templates(load).subscribe(
                data => response = data,
                err => {
                    this.template_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.template_datatable_ref.set_results_offset_change(response.list);
                    }catch(error){
                        this.template_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                    
                }
            );
        }

        template_datatable_get_results_filter_change(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                search_word:data.search_word
            };
            this.endpoint.get_templates(load).subscribe(
                data => response = data,
                err => {
                    this.template_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.template_datatable_ref.set_results_filter_change(response.list, response.count);
                    }catch(error){
                        this.template_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

        template_datatable_get_results_update_list(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                search_word:data.search_word
            }
            this.endpoint.get_templates(load).subscribe(
                data => response = data,
                err => {
                    this.template_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.template_datatable_ref.set_results_update_list(response.list, response.count);
                    }catch(error){
                        this.template_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

        template_datatable_events(event){
            this.sms_data.sms_mensaje_original = event.data.sms_mensaje_original;
            this.template_modal.hide();
        }

    //TEMPLATES ##############################################################
    //########################################################################

    //########################################################################
    //TEST SMS ###############################################################

        open_test_sms(){
            if(this.disable_groups){
                this.groups_selected = [];
                this.groups_selected.push({
                    grp_id:this.selected_group.grp_id,
                    grp_nombre:this.selected_group.grp_nombre,
                    subgroups:[JSON.parse(JSON.stringify(this.selected_group))]
                })
            }
            if(((this.calendarized_sms && this.calendarized_dates.length>0) || !this.calendarized_sms) && this.sms_form.valid && this.sms_data.sms_mensaje_original.length > 0 && ((this.sms_send_type == 2 && this.groups_selected.length > 0))){
                if(localStorage.getItem("SMS_TEST_NUMBER_REF")){
                    this.test_sms = conformToMask(localStorage.getItem("SMS_TEST_NUMBER_REF"),this.test_sms_mask,{guide: false}).conformedValue;
                }else{
                    this.test_sms = "";
                }
                this.test_sms_view = 1;
                this.sms_prueba_modal.show();
                this.test_sms_loading = false;
                this.test_sms_submitted = false;
            }else{
                this.alertService.alert_error("Error de Formulario", "Se encontraron errores en el formulario de envío de SMS");
                this.sms_submitted = true;
            }
        }

        send_test_sms(){
            if(this.test_sms_form.valid){
                this.test_sms_submitted = false;
                localStorage.setItem("SMS_TEST_NUMBER_REF", this.test_sms);
                var mensaje_original = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ');
                var mensaje_parsed = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ') + " ";
                for(var j = 0;j<this.plantillas_label_list.length;j++){
                    var alias_to_replace = new RegExp("\#" + this.plantillas_label_list[j].var_alias + " ","g");
                    var field_to_insert = "$" + this.plantillas_label_list[j].var_campo + " ";
                    mensaje_parsed = mensaje_parsed.replace(alias_to_replace,field_to_insert);
                }
                var bad_chars = this.validate_sms_caracters();
                if(bad_chars.length > 0){
                    var error_text = ["Caracteres: " + bad_chars.join(", ")];
                    this.alertService.alert_multiple_error("Error de Caracteres", "Se encontraron caracteres no permitidos dentro del mensaje", error_text);
                    return false;
                }else{
                    var shortcode ="";
                    for(var m = 0;m<this.remitentes_list.length;m++){
                        if(this.remitentes_list[m].id == this.sms_data.remitente){
                            shortcode = this.remitentes_list[m].short_shortcode;
                        }
                    };
                    var data = {
                        sms_mensaje_original:mensaje_original.trim(),
                        sms_mensaje_parseado:mensaje_parsed.trim(),
                        nom_camp:this.sms_data.nom_camp,
                        tipo_campania:this.sms_data.tipo_campania?this.sms_data.tipo_campania:0,
                        num_gest:this.sms_data.num_gest,
                        cif:this.sms_data.cif,
                        remitente:this.sms_data.remitente,
                        remitentegw:shortcode,
                        id_area:this.sms_data.id_area,
                        id_empresa:this.sms_data.id_empresa?this.sms_data.id_empresa:0,
                        id_contacto:this.sms_data.id_contacto?this.sms_data.id_contacto:0,
                        groups_selected:this.groups_selected,
                        clients_selected:[], 
                        calendarized_dates:[],
                        calendarized_sms:false,
                        sms_send_type:1,
                        limpiar_miembros:0,
                        test_number:this.test_sms.replace(/-/g, '')
                    }
                    var response;
                    this.test_sms_loading = true;
                    this.endpoint.send_test_sms(data).subscribe(
                        data => response = data,
                        err => {
                            this.test_sms_loading = false;
                            if(err.status && err.error){
                                this.alertService.alert_message(err.status ,err.error);
                            }else{
                                this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                            }
                        },
                        ()=> {
                            try{
                                this.test_sms_view = 2;
                                this.test_sms_loading = false;
                            }catch(error){
                                this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                                this.test_sms_loading = false;
                            }
                        }
                    );
                }
            }else{
                this.test_sms_submitted = true;
            }
        }

        sms_preview(){
            var mensaje_original = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ');
            var mensaje_parsed = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ') + " ";
            for(var j = 0;j<this.plantillas_label_list.length;j++){
                var alias_to_replace = new RegExp("\#" + this.plantillas_label_list[j].var_alias + " ","g");
                var field_to_insert = "$" + this.plantillas_label_list[j].var_campo + " ";
                mensaje_parsed = mensaje_parsed.replace(alias_to_replace,field_to_insert);
            }
            var bad_chars = this.validate_sms_caracters();
            if(bad_chars.length > 0){
                var error_text = ["Caracteres: " + bad_chars.join(", ")];
                this.alertService.alert_multiple_error("Error de Caracteres", "Se encontraron caracteres no permitidos dentro del mensaje", error_text);
            }else{
                var shortcode ="";
                for(var m = 0;m<this.remitentes_list.length;m++){
                    if(this.remitentes_list[m].id == this.sms_data.remitente){
                        shortcode = this.remitentes_list[m].short_shortcode;
                    }
                };
                this.sms_preview_data.remitente = shortcode;
                this.sms_preview_data.text = mensaje_parsed;
                this.sms_preview_data.hour = (new Date()).toLocaleTimeString('es-hn',{ hour: '2-digit', minute: '2-digit', hour12: true });

                this.sms_vista_previa.show();
            }
        }

    //TEST SMS ###############################################################
    //########################################################################

}