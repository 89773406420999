import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { AppService } from '../../../app.service';
import { AppEndpoints } from '../../../app.endpoints';
import { LayoutService } from '../../../layout/layout.service';
import { ModalDirective } from 'ngx-bootstrap';
import { default as swal } from 'sweetalert2';
import { FormRendererComponent } from '../../../components/form_renderer/form_renderer.component';
import { AlertService } from '../../../components/alert_service/alert.service';
import { ExcelService } from '../../../components/excel_service/excel.service';
import { conformToMask } from 'angular2-text-mask/dist/angular2TextMask';
import { ServiceDatatableNoCountComponent } from '../../../components/service_datatable_nocount/service-datatable-nocount.component';
import * as FileSaver from 'file-saver';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
	selector: 'inbox',
	templateUrl: './inbox.component.html',
	styleUrls: [
		'./inbox.component.scss',
		'../../../../vendor/libs/spinkit/spinkit.scss'
	]
})
export class InboxComponent implements OnInit {
	@ViewChild('datatable_ref') datatable_ref: ServiceDatatableNoCountComponent;
	@ViewChild('search_modal') search_modal: ModalDirective;
	@ViewChild('view_sms_modal') view_sms_modal: ModalDirective;
	@ViewChild("view_sms_ref") view_sms_ref;
	public locale = 'es-HN';
	public datatable: any;
	public enable:boolean;
	public load = {
		current_offset: null,
		sort_ascendent: null,
		sort_order: null,
		view_length: null,
		fecha_in: null,
		fecha_fin: null,
		search_word: null
	};
	public filters = {
		current_offset: 1,
		view_length: 10,
		sort_order: "",
		sort_ascendent: false,
		search_word:""
	};
	public search_data = {
		dates:[],
		fecha_in: '',
		fecha_fin: ''
	}

	@ViewChild('download_csv_modal') download_csv_modal: ModalDirective;
	public download_loading:boolean;
	public download_view:number;
	public download_link:string;
	public download_data = {
		dates:[],
		fecha_in: '',
		fecha_fin: ''
	}

	@ViewChild('sms_inbox_modal') sms_inbox_modal: ModalDirective;
	public sms_inbox_loading:boolean;
	public sms_inbox_send:boolean;
	public sms_inbox_view:number;
	public sms_inbox_link:string;
	public sms_inbox_data = {
		msj_cellphone: "",
		msj_recibido: "",
		msj_text: "",
		short_shortcode: "",
	}
	public sms_inbox_messages = [];

	@ViewChild('inbox_sms_form') inbox_sms_form: FormRendererComponent;
	
	public sms_message = {
		text: ""
	};


	constructor(private appService: AppService, public endpoint: AppEndpoints, private layoutService: LayoutService, private alertService: AlertService, private excelService: ExcelService, private localeService: BsLocaleService){
		this.appService.pageTitle = 'SMS Recibidos';
		this.localeService.use(this.locale);
		this.enable = true;
		this.datatable = {
			title: "Listado de SMS Recibidos",
			show_search: true,
			search_placeholder: "- Buscar -",
			icon: "enter",
			object_description: "SMS",
			empty_text: "No se encontraron SMS recibidos",
			header_class: "custom-th-dark",
			columns: [
				{
					column: "client_name",
					wrap_column: false,
					header: "Nombre",
					wrap_header: true,
					type: "text",
					class: "custom-small-table-cell"
				},
				{
					column: "msj_cellphone",
					wrap_column: false,
					header: "Número",
					wrap_header: true,
					type: "text",
					class: "custom-small-table-cell"
				},
				{
					column: "short_shortcode",
					wrap_column: false,
					header: "Remitente Destino",
					wrap_header: true,
					type: "text",
					class: "custom-small-table-cell"
				},
				{
					column: "msj_text",
					wrap_column: false,
					header: "Texto",
					wrap_header: true,
					type: "text",
					class: "custom-small-table-cell"
				},
				{
					column: "msj_recibido",
					wrap_column: true,
					header: "Fecha",
					wrap_header: true,
					type: "text",
					class: "custom-small-table-cell"
				}
			],
			events: [
				{
                    name:"Responder SMS",
                    style:"color:#FFD950",
                    hover_style:"cursor:pointer; color:#e0ae38; background-color:#D6E4FF !important;",
                    icon:"reply"
                }
			],
			navigation_offsets: [5, 10, 15, 20, 25, 50],
			show_search_field: false,
			table_icon: "caret-right"
		}
	}

	ngOnInit(){
		this.datatable_ref.set_show_length(10);
	}

	ngOnDestroy() {
		setTimeout(() => this.layoutService.off('resize.app-home'));
	}


	//########################################################################
	//DATATABLE ##############################################################

		get_results_offset_change(data){
			this.filters = {
				current_offset: data.current_offset,
				view_length: data.view_length,
				sort_order: data.sort_order,
				sort_ascendent: data.sort_ascendent,
				search_word: data.search_word
			}
			var response;
			this.load = {
				current_offset: this.filters.current_offset,
				sort_ascendent: this.filters.sort_ascendent,
				sort_order: this.filters.sort_order,
				view_length: this.filters.view_length,
				fecha_in: this.search_data.fecha_in,
				fecha_fin: this.search_data.fecha_fin,
				search_word: this.filters.search_word
			}
			this.endpoint.get_sms_inbox(this.load).subscribe(
				data => response = data,
				err => {
					this.datatable_ref.set_loading(false);
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
				},
				() => {
					try {
						if(response.enable){
							this.enable = true;
							for (var i = 0; i < response.list.length; i++) {
								if (response.list[i].msj_recibido) {
									response.list[i].msj_recibido = new Date(response.list[i].msj_recibido).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
								}
							}
							this.datatable_ref.set_results_offset_change(response.list);
						}else{
							this.enable = false;
							this.datatable_ref.set_results_filter_change([]);
						}
					} catch (error) {
						this.datatable_ref.set_loading(false);
						this.alertService.alert_aplication_error("Error Interno del Aplicativo");
					}

				}
			);
		}

		get_results_filter_change(data){
			this.filters = {
				current_offset: data.current_offset,
				view_length: data.view_length,
				sort_order: data.sort_order,
				sort_ascendent: data.sort_ascendent,
				search_word: data.search_word
			}
			var response;
			this.load = {
				current_offset: this.filters.current_offset,
				sort_ascendent: this.filters.sort_ascendent,
				sort_order: this.filters.sort_order,
				view_length: this.filters.view_length,
				fecha_in: this.search_data.fecha_in,
				fecha_fin: this.search_data.fecha_fin,
				search_word: this.filters.search_word
			}
			this.endpoint.get_sms_inbox(this.load).subscribe(
				data => response = data,
				err => {
					this.datatable_ref.set_loading(false);
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
				},
				() => {
					try {
						if(response.enable){
							this.enable = true;
							for (var i = 0; i < response.list.length; i++) {
								if (response.list[i].msj_recibido) {
									response.list[i].msj_recibido = new Date(response.list[i].msj_recibido).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
								}
								if(response.list[i].msgdata){
									var txt = document.createElement("textarea");
								    txt.innerHTML = response.list[i].msgdata;
								    response.list[i].msgdata =  txt.value;
								}
							}
							this.datatable_ref.set_results_offset_change(response.list);
						}else{
							this.enable = false;
							this.datatable_ref.set_results_filter_change([]);
						}
					} catch (error) {
						this.datatable_ref.set_loading(false);
						this.alertService.alert_aplication_error("Error Interno del Aplicativo");
					}
				}
			);
		}

		get_results_update_list(data){
			this.filters = {
				current_offset: data.current_offset,
				view_length: data.view_length,
				sort_order: data.sort_order,
				sort_ascendent: data.sort_ascendent,
				search_word: this.filters.search_word
			}
			var response;
			this.load = {
				current_offset: this.filters.current_offset,
				sort_ascendent: this.filters.sort_ascendent,
				sort_order: this.filters.sort_order,
				view_length: this.filters.view_length,
				fecha_in: this.search_data.fecha_in,
				fecha_fin: this.search_data.fecha_fin,
				search_word: data.search_word
			}
			this.endpoint.get_sms_inbox(this.load).subscribe(
				data => response = data,
				err => {
					this.datatable_ref.set_loading(false);
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
				},
				() => {
					try {
						if(response.enable){
							this.enable = true;
							for (var i = 0; i < response.list.length; i++) {
								if (response.list[i].msj_recibido) {
									response.list[i].msj_recibido = new Date(response.list[i].msj_recibido).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
								}
							}
							this.datatable_ref.set_results_update_list(response.list);
						}else{
							this.enable = false;
							this.datatable_ref.set_results_filter_change([]);
						}
					} catch (error) {
						this.datatable_ref.set_loading(false);
						this.alertService.alert_aplication_error("Error Interno del Aplicativo");
					}
				}
			);
		}

		get_results_refresh(){
			this.datatable_ref.set_loading(true);
			var response;
			this.load = {
				current_offset: this.filters.current_offset,
				sort_ascendent: this.filters.sort_ascendent,
				sort_order: this.filters.sort_order,
				view_length: this.filters.view_length,
				fecha_in: this.search_data.fecha_in,
				fecha_fin: this.search_data.fecha_fin,
				search_word: this.filters.search_word
			}
			this.endpoint.get_sms_inbox(this.load).subscribe(
				data => response = data,
				err => {
					this.datatable_ref.set_loading(false);
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
				},
				() => {
					try {
						if(response.enable){
							this.enable = true;
							for (var i = 0; i < response.list.length; i++) {
								if (response.list[i].msj_recibido) {
									response.list[i].msj_recibido = new Date(response.list[i].msj_recibido).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
								}
							}
							this.datatable_ref.set_results_update_list(response.list);
						}else{
							this.enable = false;
							this.datatable_ref.set_results_filter_change([]);
						}
					} catch (error) {
						this.datatable_ref.set_loading(false);
						this.alertService.alert_aplication_error("Error Interno del Aplicativo");
					}
				}
			);	
		}

		datatable_events(event){
			if(event.event == "Responder SMS"){
            	this.open_inbox_sms(event.data);
            }
		}

		search(){
			if(this.search_data.dates && this.search_data.dates.length == 2 && this.search_data.dates[0] instanceof Date && this.search_data.dates[1] instanceof Date){
				if(this.valid_date()){
					this.search_data.fecha_in = (this.search_data.dates[0].getDate()<10 ? "0" + this.search_data.dates[0].getDate() : "" + this.search_data.dates[0].getDate()) + "/" + ((this.search_data.dates[0].getMonth() + 1)<10 ? "0" + (this.search_data.dates[0].getMonth() + 1) : "" + (this.search_data.dates[0].getMonth() + 1)) + "/"+this.search_data.dates[0].getFullYear();
					this.search_data.fecha_fin = (this.search_data.dates[1].getDate()<10 ? "0" + this.search_data.dates[1].getDate() : "" + this.search_data.dates[1].getDate()) + "/" + ((this.search_data.dates[1].getMonth() + 1)<10 ? "0" + (this.search_data.dates[1].getMonth() + 1) : "" + (this.search_data.dates[1].getMonth() + 1)) + "/"+this.search_data.dates[1].getFullYear();
					this.search_modal.hide();
					this.datatable_ref.emit_get_results_filter_change();
				}
			}else{
				this.search_data.fecha_in = "";
				this.search_data.fecha_fin = "";
				this.search_modal.hide();
				this.datatable_ref.emit_get_results_filter_change();
			}
		}

		valid_date(){
			if(this.search_data.dates && this.search_data.dates.length == 2 && this.search_data.dates[0] instanceof Date && this.search_data.dates[1] instanceof Date){
				var diff = this.search_data.dates[1].getTime() - this.search_data.dates[0].getTime();
				if(diff/(1000 * 3600 * 24) > 30){
					return false;
				}else{
					return true;
				}
			}else{
				return true;
			}
		}

		clean_search(){
			this.search_data = {
				dates:[],
				fecha_in: '',
				fecha_fin: ''
			}
			this.search_modal.hide();
			this.datatable_ref.clean_search();
			this.datatable_ref.emit_get_results_filter_change();
		}

	//DATATABLE ##############################################################
	//########################################################################

	//########################################################################
	//DOWNLOAD ###############################################################

		open_download_csv(){
			this.download_loading = false;
			this.download_view = 1;
			this.download_csv_modal.show();
		}

		valid_download_date(){
			if(this.download_data.dates && this.download_data.dates.length == 2 && this.download_data.dates[0] instanceof Date && this.download_data.dates[1] instanceof Date){
				var diff = this.download_data.dates[1].getTime() - this.download_data.dates[0].getTime();
				if(diff/(1000 * 3600 * 24) > 30){
					return false;
				}else{
					return true;
				}
			}else{
				return true;
			}
		}

		download_csv() {
			this.download_loading = true;
			if(this.download_data.dates && this.download_data.dates.length == 2 && this.download_data.dates[0] instanceof Date && this.download_data.dates[1] instanceof Date){
				if(this.valid_download_date()){
					this.download_data.fecha_in = (this.download_data.dates[0].getDate()<10 ? "0" + this.download_data.dates[0].getDate() : "" + this.download_data.dates[0].getDate()) + "/" + ((this.download_data.dates[0].getMonth() + 1)<10 ? "0" + (this.download_data.dates[0].getMonth() + 1) : "" + (this.download_data.dates[0].getMonth() + 1)) + "/"+this.download_data.dates[0].getFullYear();
					this.download_data.fecha_fin = (this.download_data.dates[1].getDate()<10 ? "0" + this.download_data.dates[1].getDate() : "" + this.download_data.dates[1].getDate()) + "/" + ((this.download_data.dates[1].getMonth() + 1)<10 ? "0" + (this.download_data.dates[1].getMonth() + 1) : "" + (this.download_data.dates[1].getMonth() + 1)) + "/"+this.download_data.dates[1].getFullYear();
				}
			}else{
				this.download_data.fecha_in = "";
				this.download_data.fecha_fin = "";
			}
			var load = {
				fecha_in:this.download_data.fecha_in,
				fecha_fin:this.download_data.fecha_fin
			}
			var response;
			this.endpoint.get_sms_inbox_csv(load).subscribe(
				data => {
					response = data;
				},
				err => {
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
					this.download_loading = false;
				},
				() => {
					let url = response.filename;
	        		window.open(this.endpoint.get_endpoint() + "/download/" + url);
	        		this.download_link = this.endpoint.get_endpoint() + "/download/" + response.filename;
	        		this.download_loading = false;
	        		this.download_view = 2;
				}
			);
		}

	//DOWNLOAD ###############################################################
	//########################################################################


	//########################################################################
	//SMS CONVERSATION #######################################################

	open_inbox_sms(data){
		this.sms_inbox_loading = false;
		this.sms_inbox_view = 1;
		this.sms_inbox_link = "";
		this.sms_inbox_data = {
			msj_cellphone: data.msj_cellphone,
			msj_recibido: data.msj_recibido,
			msj_text: data.msj_text,
			short_shortcode: data.short_shortcode,
		}

		var response;
		let load = {
			current_offset: this.filters.current_offset,
			sort_ascendent: this.filters.sort_ascendent,
			sort_order: this.filters.sort_order,
			view_length: this.filters.view_length,
			fecha_in: this.search_data.fecha_in,
			fecha_fin: this.search_data.fecha_fin,
			search_word: this.filters.search_word,
			msj_cellphone: data.msj_cellphone
		}
		this.sms_inbox_messages =[];

		this.endpoint.get_sms_inbox_single(load).subscribe(
			data => response = data,
			err => {
				this.datatable_ref.set_loading(false);
				if (err.status && err.error) {
					this.alertService.alert_message(err.status, err.error);
				} else {
					this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
				}
			},
			() => {
				try {
					if(response){
						//Obtener mensajes enviados de inbox
						var response_out;
						let load_out = {
							current_offset: this.filters.current_offset,
							sort_ascendent: this.filters.sort_ascendent,
							sort_order: this.filters.sort_order,
							view_length: this.filters.view_length,
							fecha_in: this.search_data.fecha_in,
							fecha_fin: this.search_data.fecha_fin,
							search_word: this.filters.search_word,
							msj_cellphone: data.msj_cellphone
						}

						this.endpoint.get_sms_outbox_single(load_out).subscribe(
							data => response_out = data,
							err => {
								this.datatable_ref.set_loading(false);
								if (err.status && err.error) {
									this.alertService.alert_message(err.status, err.error);
								} else {
									this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
								}
							},
							() => {
								try {
									if(response_out){
										for (var i = 0; i < response_out.list.length; i++) {
											this.sms_inbox_messages.push({
												msj_cellphone: response_out.list[i].msj_cellphone,
												msj_recibido: response_out.list[i].msj_recibido,
												msj_text: response_out.list[i].msj_text,
												short_shortcode: response_out.list[i].short_shortcode,
												status: response_out.list[i].estado,
												sender: false
											})
										}

										if (response){
											for (var i = 0; i < response.list.length; i++) {
												this.sms_inbox_messages.push({
													msj_cellphone: response.list[i].msj_cellphone,
													msj_recibido: response.list[i].msj_recibido,
													msj_text: response.list[i].msj_text,
													short_shortcode: response.list[i].short_shortcode,
													sender: true
												})
											}
										}
				
										this.sms_inbox_messages.sort((a, b)=>{
											return new Date(a.msj_recibido).getTime() - new Date(b.msj_recibido).getTime()
										});
				
										for (var i = 0; i < this.sms_inbox_messages.length; i++) {
											if (this.sms_inbox_messages[i].msj_recibido) {
												this.sms_inbox_messages[i].msj_recibido = new Date(this.sms_inbox_messages[i].msj_recibido).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
											}
										}
									}
								} catch (error) {
									console.log(error)
									this.datatable_ref.set_loading(false);
									this.alertService.alert_aplication_error("Error Interno del Aplicativo");
								}
							}
						);


						
					}else{
					}
				} catch (error) {
					this.datatable_ref.set_loading(false);
					this.alertService.alert_aplication_error("Error Interno del Aplicativo");
				}
			}
		);

		this.sms_inbox_modal.show();
	}

	refresh_messages(){
		var response;
		let load = {
			current_offset: this.filters.current_offset,
			sort_ascendent: this.filters.sort_ascendent,
			sort_order: this.filters.sort_order,
			view_length: this.filters.view_length,
			fecha_in: this.search_data.fecha_in,
			fecha_fin: this.search_data.fecha_fin,
			search_word: this.filters.search_word,
			msj_cellphone: this.sms_inbox_data.msj_cellphone
		}
		this.sms_inbox_messages =[];

		this.endpoint.get_sms_inbox_single(load).subscribe(
			data => response = data,
			err => {
				this.datatable_ref.set_loading(false);
				if (err.status && err.error) {
					this.alertService.alert_message(err.status, err.error);
				} else {
					this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
				}
			},
			() => {
				try {
					if(response){
						//Obtener mensajes enviados de inbox
						var response_out;
						let load_out = {
							current_offset: this.filters.current_offset,
							sort_ascendent: this.filters.sort_ascendent,
							sort_order: this.filters.sort_order,
							view_length: this.filters.view_length,
							fecha_in: this.search_data.fecha_in,
							fecha_fin: this.search_data.fecha_fin,
							search_word: this.filters.search_word,
							msj_cellphone: this.sms_inbox_data.msj_cellphone
						}

						this.endpoint.get_sms_outbox_single(load_out).subscribe(
							data => response_out = data,
							err => {
								this.datatable_ref.set_loading(false);
								if (err.status && err.error) {
									this.alertService.alert_message(err.status, err.error);
								} else {
									this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
								}
							},
							() => {
								try {
									if(response_out){
										for (var i = 0; i < response_out.list.length; i++) {
											this.sms_inbox_messages.push({
												msj_cellphone: response_out.list[i].msj_cellphone,
												msj_recibido: response_out.list[i].msj_recibido,
												msj_text: response_out.list[i].msj_text,
												short_shortcode: response_out.list[i].short_shortcode,
												status: response_out.list[i].estado,
												sender: false
											})
										}

										if (response){
											for (var i = 0; i < response.list.length; i++) {
												this.sms_inbox_messages.push({
													msj_cellphone: response.list[i].msj_cellphone,
													msj_recibido: response.list[i].msj_recibido,
													msj_text: response.list[i].msj_text,
													short_shortcode: response.list[i].short_shortcode,
													sender: true
												})
											}
										}
				
										this.sms_inbox_messages.sort((a, b)=>{
											return new Date(a.msj_recibido).getTime() - new Date(b.msj_recibido).getTime()
										});
				
										for (var i = 0; i < this.sms_inbox_messages.length; i++) {
											if (this.sms_inbox_messages[i].msj_recibido) {
												this.sms_inbox_messages[i].msj_recibido = new Date(this.sms_inbox_messages[i].msj_recibido).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
											}
										}
									}
								} catch (error) {
									console.log(error)
									this.datatable_ref.set_loading(false);
									this.alertService.alert_aplication_error("Error Interno del Aplicativo");
								}
							}
						);


						
					}else{
					}
				} catch (error) {
					this.datatable_ref.set_loading(false);
					this.alertService.alert_aplication_error("Error Interno del Aplicativo");
				}
			}
		);
	}

	send_message(data){
		//this.remove_double_spaces();
		//this.clean_spaces();
		this.sms_inbox_send = true;
		var response;
		let load_out = {
			sms_mensaje_original: this.sms_message.text,
			sms_mensaje_parseado: this.sms_message.text,

			nom_camp: "INBOX RESPONSE",
			tipo_campania: 0,
			//num_gest:this.sms_data.num_gest,
			//cif:this.sms_data.cif,
			remitente:0,
			remitentegw: data.short_shortcode,
			id_area: 0,
			id_empresa: 0,
			id_contacto: 0,
			groups_selected:[],
			clients_selected:[{
				id: -1,
				cli_nombre: "sin nombre",
				cli_celular: data.msj_cellphone,
			}], 
			//calendarized_dates:this.calendarized_dates,
			//calendarized_sms:this.calendarized_sms,
			sms_send_type:1,
			limpiar_miembros:0
		}
		

		this.endpoint.send_sms(load_out).subscribe(
			data => response = data,
			err => {
				this.datatable_ref.set_loading(false);
				if (err.status && err.error) {
					this.alertService.alert_message(err.status, err.error);
				} else {
					this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
				}
			},
			() => {
				try {
					this.sms_inbox_send = false;
					this.alertService.alert_success("Mensaje Enviado", "SMS enviado correctamente.")

					this.sms_message.text = "";

					this.sms_inbox_messages.push({
						msj_cellphone: load_out.clients_selected[0].cli_celular,
						msj_recibido: new Date().toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true }),
						msj_text: load_out.sms_mensaje_parseado,
						status: "Delivered",
						short_shortcode: data.short_shortcode,
						sender: false
					});

				} catch (error) {
					this.datatable_ref.set_loading(false);
					this.alertService.alert_aplication_error("Error Interno del Aplicativo");
				}
			}
		);
	}
	

	remove_double_spaces(){
		this.sms_message.text = this.sms_message.text.replace(/  +/g, ' ').trim();
	}

	messagebox_keypress(event){
		const key = (event.which) ? event.which : event.keyCode;
	}

	clean_spaces(){
		this.sms_message.text = this.sms_message.text.replace(/á/g, 'a');
		this.sms_message.text = this.sms_message.text.replace(/é/g, 'e');
		this.sms_message.text = this.sms_message.text.replace(/í/g, 'i');
		this.sms_message.text = this.sms_message.text.replace(/ó/g, 'o');
		this.sms_message.text = this.sms_message.text.replace(/ú/g, 'u');
		this.sms_message.text = this.sms_message.text.replace(/Á/g, 'A');
		this.sms_message.text = this.sms_message.text.replace(/É/g, 'E');
		this.sms_message.text = this.sms_message.text.replace(/Í/g, 'I');
		this.sms_message.text = this.sms_message.text.replace(/Ó/g, 'O');
		this.sms_message.text = this.sms_message.text.replace(/Ú/g, 'U');
		this.sms_message.text = this.sms_message.text.trim();

		for(var i = this.sms_message.text.length-1;i>=0;i--){
			var is_valid = false;
			for(var j = 0;j<this.endpoint.get_sms_allowed_characters_full().length;j++){
				if(this.sms_message.text[i]==this.endpoint.get_sms_allowed_characters_full()[j]){
					is_valid = true;
					break;
				}
			}
			if(!is_valid){
				this.sms_message.text = this.sms_message.text.slice(0,i) + this.sms_message.text.slice(i+1);
			}
		}
	}
	//SMS CONVERSATION #######################################################
	//########################################################################




	//########################################################################
	//MISC ###################################################################

		format_status(status){
			return status == 'SENT' ? 'ENVIADO' : status == 'SENDING' ? 'ENVIANDO' : status == 'QUEUED' ? 'EN COLA' : status == 'PARSE_ERROR' ? 'ERROR' : '';
		}

	//MISC ###################################################################
	//########################################################################

}
