import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule,ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from '@angular/common';
import { EditSmsComponent } from "./edit_sms.component";
import { ServiceDatatableSmModule } from '../service_datatable_sm/service_datatable_sm.module';
import { FormRendererModule } from '../form_renderer/form_renderer.module'; 
import { ModalModule }          from 'ngx-bootstrap';

@NgModule({
  declarations: [
    EditSmsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ServiceDatatableSmModule,
    FormRendererModule,
    ModalModule.forRoot(),
  ],
  exports: [
    EditSmsComponent
  ],
})

export class EditSmsModule {}
