import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, OnInit, ElementRef, Renderer2, Output, EventEmitter, Input } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { AppEndpoints } from '../../app.endpoints';
import { ModalDirective } from 'ngx-bootstrap';
import { default as swal } from 'sweetalert2';
import { FormRendererComponent } from '../form_renderer/form_renderer.component';
import { AlertService } from '../alert_service/alert.service';
import { ExcelService } from '../excel_service/excel.service';
import { ServiceDatatableComponent } from '../service_datatable/service-datatable.component';
import { conformToMask } from 'angular2-text-mask/dist/angular2TextMask';

@Component({
    selector: 'edit-sms',
    templateUrl: './edit_sms.template.html',
    styleUrls:[
    '../../../vendor/libs/spinkit/spinkit.scss'
    ]
})

export class EditSmsComponent implements OnInit{
    @Output() edit_sms_event: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('sms_form') sms_form: FormControlDirective;
    @ViewChild('sms_mensaje_original') sms_mensaje_original:ElementRef;
    @ViewChild('calendarized_form') calendarized_form: FormRendererComponent;
    public sms_loading:boolean;
    public sms_view:number;
    public sms_submitted:boolean;
    public sms_data = {
        id:"",
        sms_mensaje_original:"",
        nom_camp:"",
        num_gest:"",
        cif:"",
        remitente:"",
        remitentegw:"",
        sms_grupos:"",
        sms_cli_celular:"",
        sms_tipo:"",
        sms_grupo_nombre:"",
        smsadmin_resellers_nombre:"",
        empresa_nombre:"",
                contacto_nombre:"",
                tipo_campania_nombre:"",
        sms_status:""
    }

    public plantillas_label_list:any[];
    public areas_list:any[];
    public empresas_list:any[];
    public contactos_list:any[];
    public tipo_campania_list:any[];

    public calendarized_inputs = [
        {
            class:"row",
            columns:[
                {
                    class:"col-md-12",
                    inputs:[
                        {
                            type:"calendar",
                            extra:"popup",
                            name:"sms_dia",
                            label:"",
                            icon:"",
                            class:"form-control form-control-sm",
                            placeholder:"",
                            minlength:null,
                            maxlength:null,
                            pattern:null,
                            error_required:"Requerido",
                            error_pattern:null,
                            error_minlength:"",
                            list_data:{
                                value:"",
                                text:""
                            },
                            list:()=>{
                                return []
                            },
                            textmask:()=>{
                                return false;
                            },
                            required:()=>{
                                return true;
                            },
                            disabled:()=>{
                                return false;
                            },
                            change:(event)=>{
                            },
                            input:()=>{
                            }
                        }
                    ]
                },
                {
                    class:"col-md-12",
                    inputs:[
                        {
                            type:"time",
                            extra:"",
                            name:"sms_hora",
                            label:"",
                            icon:"",
                            class:"form-control form-control-sm",
                            placeholder:"",
                            minlength:null,
                            maxlength:null,
                            pattern:null,
                            error_required:"Requerido",
                            error_pattern:null,
                            error_minlength:"",
                            list_data:{
                                value:"",
                                text:""
                            },
                            list:()=>{
                                return []
                            },
                            textmask:()=>{
                                return false;
                            },
                            required:()=>{
                                return true;
                            },
                            disabled:()=>{
                                return false;
                            },
                            change:(event)=>{
                            },
                            input:()=>{
                            }
                        }
                    ]
                }
            ]
        },
    ]

    public enable_cif:boolean;
    public current_time:Date;

    constructor(public endpoint : AppEndpoints, private alertService: AlertService, private renderer: Renderer2) {
        this.sms_loading = false;
        this.sms_submitted = false;
        this.sms_view = 1;
        this.plantillas_label_list = [];
        this.areas_list = [];
        this.empresas_list = [];
        this.contactos_list = [];
        this.tipo_campania_list = [];
        this.enable_cif = false;
        this.current_time = null;
    }

    ngOnInit() {
        this.get_template_labels();
        this.get_subresellers();
        this.get_enable_cif();
        this.get_current_time();
    }

    AfterViewInit(){
    }

    //########################################################################
    //SMS ####################################################################

        edit_sms(){
            if(this.calendarized_form.valid() && this.sms_form.valid && this.sms_data.sms_mensaje_original.length > 0){
                var mensaje_original = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ');
                var mensaje_parsed = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ') + " ";
                for(var j = 0;j<this.plantillas_label_list.length;j++){
                    var alias_to_replace = new RegExp("\#" + this.plantillas_label_list[j].var_alias + " ","g");
                    var field_to_insert = "$" + this.plantillas_label_list[j].var_campo + " ";
                    mensaje_parsed = mensaje_parsed.replace(alias_to_replace,field_to_insert);
                }
                var bad_chars = this.validate_sms_caracters();
                if(bad_chars.length > 0){
                    var error_text = ["Caracteres: " + bad_chars.join(", ")];
                    this.alertService.alert_multiple_error("Error de Caracteres", "Se encontraron caracteres no permitidos dentro del mensaje", error_text);
                    return false;
                }else{
                    var dates_errors = [];
                    var time = new Date(this.calendarized_form.get_values().sms_dia.split("/").reverse().join("-") + " " + this.calendarized_form.get_values().sms_hora);
                    console.log(time);
                    time.setMinutes(time.getMinutes() - 1)
                    if(time <= this.current_time){
                        dates_errors.push("Hora de Calendarización: " +  this.calendarized_form.get_values().sms_dia.split("/").reverse().join("-") + " " + this.time_pretty_print(this.calendarized_form.get_values().sms_hora));
                    }
                    if(dates_errors.length>0){
                        this.alertService.alert_multiple_error("Error de Fechas", "La fecha y hora calendarizada debe de ser como mínimo un minuto mayor a la fecha y hora actual", dates_errors);
                    }else{
                        var data = {
                            id:this.sms_data.id,
                            sms_mensaje_original:mensaje_original.trim(),
                            sms_mensaje_parseado:mensaje_parsed.trim(),
                            nom_camp:this.sms_data.nom_camp,
                            num_gest:this.sms_data.num_gest,
                            cif:this.sms_data.cif,
                            remitente:this.sms_data.remitentegw,
                            sms_fecha:this.calendarized_form.get_values().sms_dia.split("/").reverse().join("-") + " " +this.calendarized_form.get_values().sms_hora + ":00"
                        }
                        this.sms_submitted = false;
                        this.alertService.option_alert("Editar SMS", "¿Está seguro que desea editar el SMS seleccionado?","Sí, Editar").then((result) => {
                            if(result.value){
                                this.edit_sms_event.emit(data);
                            }
                        }).catch(()=>{return false;});
                    }
                }
            }else{
                this.alertService.alert_error("Error de Formulario", "Se encontraron errores en el formulario de envío de SMS");
                this.sms_submitted = true;
            }
            
        }

        validate_sms_caracters(){
            var chars = [];
            for(var i = 0;i<this.sms_data.sms_mensaje_original.length;i++){
                var is_valid = false;
                for(var j = 0;j<this.endpoint.get_sms_allowed_characters_full().length;j++){
                    if(this.sms_data.sms_mensaje_original[i]==this.endpoint.get_sms_allowed_characters_full()[j]){
                        is_valid = true;
                        break;
                    }
                }
                if(!is_valid){
                    chars.push("<b style='margin-right:10px;'>" + this.sms_data.sms_mensaje_original[i] + "</b>");
                }
            }
            return chars;
        }

        reset_sms(){
            this.sms_data = {
                id:"",
                sms_mensaje_original:"",
                nom_camp:"",
                num_gest:"",
                cif:"",
                remitente:"",
                remitentegw:"",
                sms_grupos:"",
                sms_cli_celular:"",
                sms_tipo:"",
                sms_grupo_nombre:"",
                smsadmin_resellers_nombre:"",
                empresa_nombre:"",
                contacto_nombre:"",
                tipo_campania_nombre:"",
                sms_status:""
            }
            this.sms_loading = false;
            this.sms_submitted = false;
            this.sms_view = 1;
        }

        set_values_sms(data){
            var original_message = data.sms_mensaje_original.split(".");
            var remitente = "";
            if(original_message.length > 1){
                remitente = original_message.pop();
            }
            this.sms_data = {
                id:data.id,
                sms_mensaje_original:original_message.join("."),
                nom_camp:data.nom_camp,
                num_gest:data.num_gest,
                cif:data.cif,
                remitente:data.remitente,
                remitentegw:data.sms_tipo==2?remitente:data.remitentegw,
                sms_grupos:data.sms_grupos,
                sms_cli_celular:data.sms_cli_celular,
                sms_tipo:data.sms_tipo,
                sms_grupo_nombre:"",
                smsadmin_resellers_nombre:data.smsadmin_resellers_nombre,
                empresa_nombre:data.empresa_nombre,
                contacto_nombre:data.contacto_nombre,
                tipo_campania_nombre:data.tipo_campania_nombre,
                sms_status:data.sms_status
            }
            if(data.sms_tipo==2){
               this.get_group_name(data.sms_grupos); 
           }
            this.calendarized_form.set_values_with_date({
                sms_dia:data.sms_fecha_string.split(" ")[0].split("-").reverse().join("/"),
                sms_hora:data.sms_fecha_string.split(" ")[1]
            });
        }

        set_loading(value:boolean){
            this.sms_loading = value;
        }

        clean_sms_text(){
            this.sms_data.sms_mensaje_original = "";
        }

        get_enable_cif(){
            var response;
            this.endpoint.get_enable_cif().subscribe(
                data => response = data,
                err => {
                    this.enable_cif = false;
                },
                ()=> {
                    try{
                        if(response == 1){
                            this.enable_cif = true;
                        }else{
                            this.enable_cif = false;
                        }
                    }catch(error){
                        this.enable_cif = false;
                    }
                }
            );
        }

        get_group_name(id){
            var response;
            this.sms_data.sms_grupo_nombre = "cargando...";
            var load = {
                id:id
            }
            this.endpoint.get_group_name(load).subscribe(
                data => response = data,
                err => {
                    this.sms_data.sms_grupo_nombre = "";
                },
                ()=> {
                    try{
                        this.sms_data.sms_grupo_nombre = response.grp_nombre + " - " + response.sgp_nombre;
                    }catch(error){
                        this.sms_data.sms_grupo_nombre = "";
                    }
                }
            );
        }

        get_subresellers(){
            var response;
            this.endpoint.get_subresellers().subscribe(
                data => response = data,
                err => {
                    this.areas_list = [];
                },
                ()=> {
                    try{
                        this.areas_list = [];
                        this.areas_list = response;
                        if(this.areas_list.length > 0){
                            this.get_companies();
                            this.get_company_types();
                            this.get_contacts();
                        }
                    }catch(error){
                        this.areas_list = [];
                    }
                }
            );
        }

        get_companies(){
            var response;
            this.endpoint.get_companies().subscribe(
                data => response = data,
                err => {
                    this.empresas_list = [];
                },
                ()=> {
                    try{
                        this.empresas_list = [];
                        this.empresas_list = response;
                    }catch(error){
                        this.empresas_list = [];
                    }
                }
            );
        }

        get_company_types(){
            var response;
            this.endpoint.get_company_types().subscribe(
                data => response = data,
                err => {
                    this.tipo_campania_list = [];
                },
                ()=> {
                    try{
                        this.tipo_campania_list = [];
                        this.tipo_campania_list = response;
                    }catch(error){
                        this.tipo_campania_list = [];
                    }
                }
            );
        }

        get_contacts(){
            var response;
            this.endpoint.get_contacts().subscribe(
                data => response = data,
                err => {
                    this.contactos_list = [];
                },
                ()=> {
                    try{
                        this.contactos_list = [];
                        this.contactos_list = response;
                    }catch(error){
                        this.contactos_list = [];
                    }
                }
            );
        }

        get_current_time(){
            var response;
            this.endpoint.get_current_time().subscribe(
                data => response = data,
                err => {
                    this.current_time = null;
                },
                ()=> {
                    try{
                        this.current_time = new Date(response.date);
                        setInterval(()=>{ 
                            this.current_time.setSeconds(this.current_time.getSeconds() + 1);
                        }, 1000);
                    }catch(error){
                        this.current_time = null;
                    }
                }
            );
        }

        time_pretty_print(time){
            time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) {
                time = time.slice (1);
                time[5] = +time[0] < 12 ? ' AM' : ' PM';
                time[0] = +time[0] % 12 || 12;
            }
            return time.join ('');
        }

        validate_input(event){
            const key = (event.which) ? event.which : event.keyCode;
            /*if(
                key==225 ||
                key==233 ||
                key==237 ||
                key==243 ||
                key==250 ||
                key==253 ||
                key==36  ||
                key==95  ||
                key==92  ||
                key==180 ||
                key==64  ||
                key==94  ||
                key==126 ||
                key==91  ||
                key==93  ||
                key==123 ||
                key==125 ||
                key==39  ||
                key==124 ||
                key==222
            ){
                return false;
            }else{
                return true;
            }*/
            return true;
        }

        remove_double_spaces(){
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ').trim();
        }

        clean_spaces(){
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/á/g, 'a');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/é/g, 'e');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/í/g, 'i');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/ó/g, 'o');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/ú/g, 'u');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/Á/g, 'A');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/É/g, 'E');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/Í/g, 'I');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/Ó/g, 'O');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/Ú/g, 'U');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.trim();
            for(var i = this.sms_data.sms_mensaje_original.length-1;i>=0;i--){
                var is_valid = false;
                for(var j = 0;j<this.endpoint.get_sms_allowed_characters_full().length;j++){
                    if(this.sms_data.sms_mensaje_original[i]==this.endpoint.get_sms_allowed_characters_full()[j]){
                        is_valid = true;
                        break;
                    }
                }
                if(!is_valid){
                    this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.slice(0,i) + this.sms_data.sms_mensaje_original.slice(i+1);
                }
            }
        }

    //SMS ####################################################################
    //########################################################################

    //########################################################################
    //TEMPLATES ##############################################################

        get_template_labels(){
            var response;
            this.sms_loading = true;
            this.endpoint.get_labels().subscribe(
                data => response = data,
                err => {
                    this.plantillas_label_list = [];
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                    this.sms_loading = false;
                },
                ()=> {
                    try{
                        this.plantillas_label_list = [];
                        for(var i = 0;i<response.length;i++){
                            if(response[i].var_alias){
                                this.plantillas_label_list.push(response[i])
                            }
                        }
                        this.sms_loading = false;
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.sms_loading = false;
                    }
                }
            );
        }

        add_alias(alias){
            if((this.sms_data.sms_mensaje_original + " #" + alias + " ").length > 320){
                if((this.sms_data.sms_mensaje_original + " #" + alias).length == 320){
                    this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original + " #" + alias;
                    this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ')
                }
            }else if(this.sms_data.sms_mensaje_original.length == 0){
                this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original + "#" + alias + " ";
                this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ')
            }else{
                this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original + " #" + alias + " ";
                this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ')
            }
            this.sms_mensaje_original.nativeElement.focus();
        }

    //TEMPLATES ##############################################################
    //########################################################################

}