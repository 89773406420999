import { Component, OnInit, Input } from '@angular/core';
import { AppEndpoints } from '../../app.endpoints';
import { AppFunctions } from '../../app.functions';
import { AlertService } from '../../components/alert_service/alert.service';

@Component({
	selector: 'smpp_usage_summary_chart',
	templateUrl: './smpp_usage_summary_chart.component.html',
	styleUrls: [
		'../../../vendor/styles/circle-progressbar.scss',
		'../../../vendor/libs/spinkit/spinkit.scss'
	]
})

/**
  * @name SmppUsageSummaryChart
  * @description Genera un gráfico del consumo del cliente basado en el endpoint _get_dashboard_data_.
  */
export class SmppUsageSummaryChart implements OnInit {
	@Input() data:any;
	@Input() loading: any;
	@Input() valid:any;

	constructor() {}

	ngOnInit() {}
	
}