import { Injectable } from '@angular/core';

@Injectable()
export class AppFunctions {

	/**
	 * @name dateSegment
	 * Returns an Array<T> with the chosen segments of a date
	 * @param { Type } T Type of segments.
	 * @param { JSON } options Options.
	 * @param { Date } date	Custom date.
	 * @param { string } year Year format. ( 'numeric', '2-digit' )
	 * @param { string } month Month format. ( 'numeric', '2-digit' )
	 * @param { string } day Day format. ( 'numeric', '2-digit' )
	 * @param { string } hour Hour format. ( 'numeric', '2-digit' )
	 * @param { string } minute Minute format. ( 'numeric', '2-digit' )
	 * @param { string } second Second format. ( 'numeric', '2-digit' )
	 * @param { boolean } hour12 Hour lapse format. ( true, false )
	 * @param { number } year_plus Number of years to add to the current year.
	 * @param { number } month_plus Number of months to add to the current month.
	 * @param { number } day_plus Number of days to add to the current day.
	 * @param { number } hour_plus Number of hours to add to the current hours.
	 * @param { number } minute_plus Number of minutes to add to the current minutes.
	 * @param { number } second_plus Number of seconds to add to the current seconds.
	 * @param { number } year_new Custom year to set to replace the current year.
	 * @param { number } month_new Custom month to set to replace the current month.
	 * @param { number } day_new Custom day to set to replace the current day.
	 * @param { number } hour_new Custom hour to set to replace the current hour.
	 * @param { number } minute_new Custom minute to set to replace the current minute.
	 * @param { number } second_new Custom second to set to replace the current second.
	 * @param { string } zone Zone format.
	 */
	public dateSegment(options): Array<number> {
		try {
			var { date, year, month, day, hour, minute, second, hour12, year_plus, month_plus, day_plus, hour_plus, minute_plus, second_plus, year_new, month_new, day_new, hour_new, minute_new, second_new, zone } = options;
			var segments = new Array<number>(0);

			if (!zone) {
				zone = 'es-HN';
			}

			if (!date) {
				date = new Date();
			} else {
				date = new Date(date);
			}

			date = new Date(
				year_new || year_new == 0 ?
					year_plus || year_plus == 0 ? year_new + year_plus : year_new
					:
					year_plus || year_plus == 0 ? date.getFullYear() + year_plus : date.getFullYear(),
				month_new || month_new == 0 ?
					month_plus || month_plus == 0 ? month_new + month_plus : month_new
					:
					month_plus || month_plus == 0 ? date.getMonth() + month_plus : date.getMonth(),
				day_new || day_new == 0 ?
					day_plus || day_plus == 0 ? day_new + day_plus : day_new
					:
					day_plus || day_plus == 0 ? date.getDate() + day_plus : date.getDate(),
				hour_new || hour_new == 0 ?
					hour_plus || hour_plus == 0 ? hour_new + hour_plus : hour_new
					:
					hour_plus || hour_plus == 0 ? date.getHours() + hour_plus : date.getHours(),
				minute_new || minute_new == 0 ?
					minute_plus || minute_plus == 0 ? minute_new + minute_plus : minute_new
					:
					minute_plus || minute_plus == 0 ? date.getMinutes() + minute_plus : date.getMinutes(),
				second_new || second_new == 0 ?
					second_plus || second_plus == 0 ? second_new + second_plus : second_new
					:
					second_plus || second_plus == 0 ? date.getSeconds() + second_plus : date.getSeconds(),
			);

			if (year) {
				segments.push(Number(date.toLocaleDateString(zone, {
					year: year,
					...hour12 ? hour12 : {}
				})));
			}

			if (month) {
				segments.push(Number(date.toLocaleDateString(zone, { month: month })));
			}

			if (day) {
				segments.push(Number(date.toLocaleDateString(zone, { day: day })));
			}

			if (hour) {
				segments.push(Number(date.toLocaleDateString(zone, { hour: hour })));
			}

			if (minute) {
				segments.push(Number(date.toLocaleDateString(zone, { minute: minute })));
			}

			if (second) {
				segments.push(Number(date.toLocaleDateString(zone, { second: second })));
			}
			return segments;
		} catch (error) {
			return [];
		}
	}

	get_month_string(month){
		return ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"][parseInt(month + "")];
	}

	get_month_diminutive_string(month){
		return ["Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"][parseInt(month + "")];
	}
}
