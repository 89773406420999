import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
import { AppEndpoints } from '../app.endpoints';

@Component({
  selector: 'manual',
  templateUrl: './manual.component.html'
})
export class ManualComponent {
  	public menu_expanded = {
  		menu1:false,
  		menu2:false,
  		menu3:false,
  		menu4:false,
  		menu5:false,
  		menu6:false,
  		menu7:false,
  		menu8:false,
  		menu9:false,
  		menu10:false,
  		menu11:false,
  		menu12:false,
  		menu13:false,
  	}
  	constructor(private appService: AppService, public endpoint : AppEndpoints, private router: Router) {
    	this.appService.pageTitle = 'Manual';
  	}
}
