import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dashboard_card',
  templateUrl: './dashboard_card.component.html'
})

export class DashboardCard implements OnInit {
	@Input() reseller_tipo: any;
  	@Input() value:string;
  	@Input() valid:boolean;
  	@Input() loading: any;
  	@Input() icon_class:string;
  	@Input() title:string;

  constructor() {
  }

  ngOnInit() {}

}