import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, OnInit, ElementRef } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { AppService } from '../../../app.service';
import { AppEndpoints } from '../../../app.endpoints';
import { LayoutService } from '../../../layout/layout.service';
import { ModalDirective } from 'ngx-bootstrap';
import { default as swal } from 'sweetalert2';
import { FormRendererComponent } from '../../../components/form_renderer/form_renderer.component';
import { AlertService } from '../../../components/alert_service/alert.service';
import { ExcelService } from '../../../components/excel_service/excel.service';
import { conformToMask } from 'angular2-text-mask/dist/angular2TextMask';
import { ServiceDatatableComponent } from '../../../components/service_datatable/service-datatable.component';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { UploadDatatableComponent } from '../../../components/upload_datatable/upload_datatable.component';
import { FileSystemFileEntry } from 'ngx-file-drop';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { fontawesome_icons } from './icon-list';

@Component({
    selector: 'create_survey',
    templateUrl: './create_survey.component.html',
    styleUrls: [
        './create_survey.component.scss',
        '../../../../vendor/libs/spinkit/spinkit.scss'
    ]
})
export class CreateSurveyComponent implements OnInit {
    @ViewChild('general_form') general_form: FormControlDirective;
    @ViewChild('sms_mensaje_original') sms_mensaje_original:ElementRef;
    public locale = 'es-HN';
    public view = 1;
    public datatable_view = 1;
    public loading = false;
    public submitted = false;
    public remitentes_list:any[];
    public areas_list:any[];
    public empresas_list:any[];
    public contactos_list:any[];
    public survey_type:any[];
    
    @ViewChild('download_csv_modal') download_csv_modal: ModalDirective;
	public download_loading:boolean;
	public download_view:number;
	public download_link:string;
	public download_data = {
		id: null
	};

    @ViewChild('enabled_datatable_ref') enabled_datatable_ref: ServiceDatatableComponent;
    public enabled_datatable:any;
    public enabled_datatable_filters = {
        current_offset:1,
        view_length:10,
        sort_order:"",
        sort_ascendent:false
    };

    @ViewChild('disabled_datatable_ref') disabled_datatable_ref: ServiceDatatableComponent;
    public disabled_datatable:any;
    public disabled_datatable_filters = {
        current_offset:1,
        view_length:10,
        sort_order:"",
        sort_ascendent:false
    };

    @ViewChild('data_form') data_form: FormControlDirective;
    @ViewChild('data_modal') data_modal: ModalDirective;
    public label_list:any[];
    public data = {
        id:"",
        name:"",
        survey_code:"",
        remitente:"",
        id_area:"",
        id_empresa:"",
        id_contacto:"",
        remitentegw:"",
        area_name:"",
        empresa_name:"",
        contacto_name:"",
        sms_mensaje_original:"",
        sms_mensaje_parseado:"",
        test_url:""
    }
    public data_view = 1;
    public data_loading = false;
    public data_submitted = false;

    public icon_list = fontawesome_icons;

    public image_survey_actions = [
        {
            id:1,
            name:"Ir a Siguiente Sección"
        },
        {
            id:2,
            name:"Finalizar"
        },
        {
            id:3,
            name:"Finalizar y Abrir URL"
        },
        {
            id:4,
            name:"Sin acción"
        },
    ];

    public satisfaction_survey_actions = [
        {
            id:1,
            name:"Ir a Siguiente Sección"
        },
        {
            id:2,
            name:"Finalizar"
        },
        {
            id:3,
            name:"Finalizar y Abrir URL"
        },
    ];
    public form_survey_actions = [
        {
            id:1,
            name:"Ir a Siguiente Sección"
        },
        {
            id:2,
            name:"Finalizar"
        },
        {
            id:3,
            name:"Finalizar y Abrir URL"
        },
        {
            id:4,
            name:"Sin acción"
        },
    ];
    public text_survey_actions = [
        {
            id:1,
            name:"Ir a Siguiente Sección"
        },
        {
            id:2,
            name:"Finalizar"
        },
        {
            id:3,
            name:"Finalizar y Abrir URL"
		},
		{
            id:4,
            name:"Sin acción"
        },
    ];
    public survey_view = 1;
    public survey_data = {
        id:"",
        name:"",
        survey_code:"",
        remitentegw:"",
        area_name:"",
        empresa_name:"",
        contacto_name:"",
        sms_mensaje_original:"",
        sms_mensaje_parseado:"",
        test_url:"",
        sections:[
        ]
	}
	
	public regex_int_optional = /^(\d+)?$/g;
	public regex_decimal_optional = /^(\d+(\.\d+)?)?$/g;
	public regex_int = /^\d+$/g;
	public regex_decimal = /^\d+(\.\d+)?$/g;

    @ViewChild('calendarized_form') calendarized_form: FormRendererComponent;
    public calendarized_sms:boolean;
    public calendarized_dates:any[];
    public calendarized_inputs = [
        {
            class:"row",
            columns:[
                {
                    class:"col-md-12",
                    inputs:[
                        {
                            type:"calendar",
                            extra:"popup",
                            name:"sms_dia",
                            label:"",
                            icon:"",
                            class:"form-control form-control-sm",
                            placeholder:"",
                            minlength:null,
                            maxlength:null,
                            pattern:null,
                            error_required:"Requerido",
                            error_pattern:null,
                            error_minlength:"",
                            list_data:{
                                value:"",
                                text:""
                            },
                            list:()=>{
                                return []
                            },
                            textmask:()=>{
                                return false;
                            },
                            required:()=>{
                                return true;
                            },
                            disabled:()=>{
                                return !this.calendarized_sms;
                            },
                            change:(event)=>{
                            },
                            input:()=>{
                            }
                        }
                    ]
                },
                {
                    class:"col-md-12",
                    inputs:[
                        {
                            type:"time",
                            extra:"",
                            name:"sms_hora",
                            label:"",
                            icon:"",
                            class:"form-control form-control-sm",
                            placeholder:"",
                            minlength:null,
                            maxlength:null,
                            pattern:null,
                            error_required:"Requerido",
                            error_pattern:null,
                            error_minlength:"",
                            list_data:{
                                value:"",
                                text:""
                            },
                            list:()=>{
                                return []
                            },
                            textmask:()=>{
                                return false;
                            },
                            required:()=>{
                                return true;
                            },
                            disabled:()=>{
                                return !this.calendarized_sms;
                            },
                            change:(event)=>{
                            },
                            input:()=>{
                            }
                        }
                    ]
                }
            ]
        },
    ]
    public current_time:Date;

    public send_view = 1;
    public send_submitted = false;
    public send_data = {
        id:"",
        name:"",
        survey_code:"",
        remitente:"",
        id_area:"",
        id_empresa:"",
        id_contacto:"",
        remitentegw:"",
        area_name:"",
        empresa_name:"",
        contacto_name:"",
        sms_mensaje_original:"",
        sms_mensaje_parseado:"",
        test_url:""
    }

    @ViewChild('sms_vista_previa') sms_vista_previa: ModalDirective;
    public sms_preview_data = {
        text:"",
        url:"",
        remitente:"",
        hour:""
    }

    @ViewChild('test_sms_form') test_sms_form: FormRendererComponent;
    public test_sms_submitted:boolean;
    public test_sms_view:number;
    public test_sms:string;
    public test_sms_mask = [/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/];
    @ViewChild('upload_files_modal') upload_files_modal: ModalDirective;

    @ViewChild('current_file_datatable_ref') current_file_datatable_ref: UploadDatatableComponent;
    public current_file_datatable: any;
    public upload_files_list: any[];
    public upload_files_ok_list: any[];
    public upload_files_formatted_list: any[];
    public upload_files_modal_view: number;
    public upload_files_loading: boolean;

    public search_group_word:string;
    public groups_loading:boolean;
    public groups_list:any[];
    public groups_selected:any[];

    public data_load:any;

    constructor(private elementRef : ElementRef, private appService: AppService, public endpoint: AppEndpoints, private layoutService: LayoutService, private alertService: AlertService, private excelService: ExcelService, private localeService: BsLocaleService) {
        this.appService.pageTitle = 'Encuestas Personalizadas';
        this.localeService.use(this.locale);
        this.remitentes_list = [];
        this.areas_list = [];
        this.empresas_list = [];
        this.contactos_list = [];
        this.survey_type = [];
        this.label_list = [];
        this.enabled_datatable = {
            title:"",
            show_search:true,
            search_placeholder:"- Buscar -",
            icon:"gifts",
            object_description:"encuestas habilitadas",
            empty_text:"No se encontraron encuestas habilitadas",
            header_class: "",
            columns:[
                {   
                    column:"name",
                    wrap_column:false,
                    header:"Nombre de Encuesta",
                    wrap_header:true,
                    type:"text",
                    class: "custom-md-table-cell"
                },
                {   
                    column:"survey_code",
                    wrap_column:false,
                    header:"Código de Encuesta",
                    wrap_header:true,
                    type:"text",
                    class: "custom-md-table-cell"
                },
                {   
                    column:"creation_date",
                    wrap_column:false,
                    header:"Fecha de Creación",
                    wrap_header:true,
                    type:"text",
                    class: "custom-md-table-cell"
                }
            ],
            events:[
                {
                    name:"Descargar Excel",
                    style:"color:#2EB073",
                    hover_style:"cursor:pointer; color:#2EB073; background-color:#BBF3D9 !important;",
                    icon:"table"
                },
                {
                    name:"Deshabilitar",
                    style:"color:#FB5D5D",
                    hover_style:"cursor:pointer; color:#FB5D5D; background-color:#FEDCDC !important;",
                    icon:"stop-circle"
                },
                {
                    name:"Editar",
                    style:"color:#ffb300",
                    hover_style:"cursor:pointer; color:#ffb300; background-color:#FFF7C6 !important;",
                    icon:"edit"
                },
                {
                    name:"Diseñar Encuesta",
                    style:"color:#39B7CB",
                    hover_style:"cursor:pointer; color:#39B7CB; background-color:#BDF0FF !important;",
                    icon:"paint-brush"
                },
                {
                    name:"Enviar Encuesta",
                    style:"color:#2EB073",
                    hover_style:"cursor:pointer; color:#2EB073; background-color:#BBF3D9 !important;",
                    icon:"share-square"
                }
            ],
            navigation_offsets:[5,10,15,20,25,50],
            show_search_field:true,
            table_icon:"caret-right"
        }
        this.disabled_datatable = {
            title:"",
            show_search:true,
            search_placeholder:"- Buscar -",
            icon:"gifts",
            object_description:"encuestas deshabilitadas",
            empty_text:"No se encontraron encuestas deshabilitadas",
            header_class: "",
            columns:[
                {   
                    column:"name",
                    wrap_column:false,
                    header:"Nombre de Encuesta",
                    wrap_header:true,
                    type:"text",
                    class: "custom-md-table-cell"
                },
                {   
                    column:"survey_code",
                    wrap_column:false,
                    header:"Código de Encuesta",
                    wrap_header:true,
                    type:"text",
                    class: "custom-md-table-cell"
                },
                {   
                    column:"creation_date",
                    wrap_column:false,
                    header:"Fecha de Creación",
                    wrap_header:true,
                    type:"text",
                    class: "custom-md-table-cell"
                }
            ],
            events:[
                {
                    name:"Habilitar",
                    style: "color:#57A473",
                    hover_style:"cursor:pointer; color:#57A473; background-color:#C1FFD7 !important;",
                    icon:"play-circle"
                },
                {
                    name:"Editar",
                    style:"color:#ffb300",
                    hover_style:"cursor:pointer; color:#ffb300; background-color:#FFF7C6 !important;",
                    icon:"edit"
                },
                {
                    name:"Diseñar Encuesta",
                    style:"color:#39B7CB",
                    hover_style:"cursor:pointer; color:#39B7CB; background-color:#BDF0FF !important;",
                    icon:"paint-brush"
                }
            ],
            navigation_offsets:[5,10,15,20,25,50],
            show_search_field:true,
            table_icon:"caret-right"
        }
        this.current_time = null;
        this.calendarized_sms = false;
        this.calendarized_dates = [];

        this.test_sms_submitted = false;
        this.test_sms = "";
        this.test_sms_view = 1;

        this.groups_loading = false;
        this.groups_list = [];
        this.groups_selected = [];
        this.search_group_word = "";

        this.current_file_datatable = {
            object_description: "registros",
            header_options: ['cli_identidad', 'cli_nombre', 'cli_sexo', 'cli_fecha_nac', 'cli_celular', 'cli_telefono', 'cli_email', 'cli_direccion', 'cli_tarjeta', 'cli_campo1', 'cli_campo2', 'cli_campo3', 'cli_campo4', 'cli_campo5', 'cli_campo6', 'cli_campo7', 'cli_tarjeta', 'cli_mensaje', 'cli_cif', 'cli_cm'],
            empty_text: "No se encontraron registros en el listado",
            events: [
                {
                    name: "Eliminar Registro",
                    style: "color:#FB5D5D",
                    hover_style: "cursor:pointer; color:#FB5D5D; background-color:#FEDCDC !important;",
                    icon: "trash-alt"
                }
            ],
            navigation_offsets: [5, 10, 15, 20, 25, 50],
            navigation_starting_offset_index: 1,
            table_icon: "caret-right"
        }

        this.upload_files_formatted_list = [];
    }

    ngOnInit() {
        this.get_shortcodes();
        this.get_subresellers();
        this.get_survey_types();
        this.get_current_time();
        this.enabled_datatable_ref.set_show_length(10);
    }

    //########################################################################
    //SURVEYS ################################################################

        go_to_enabled(){
            this.datatable_view = 1;
            this.enabled_datatable_ref.set_show_length(10);
        }

        go_to_disabled(){
            this.datatable_view = 2;
            this.disabled_datatable_ref.set_show_length(10);
        }

        datatable_events(event){
            if(event.event=="Diseñar Encuesta"){
               this.open_design_survey(event.data);
            }else if(event.event=="Editar"){
               this.open_update_data(event.data);
            }else if(event.event=="Deshabilitar"){
               this.open_disable_data(event.data);
            }else if(event.event=="Habilitar"){
               this.open_enable_data(event.data);
            }else if(event.event=="Enviar Encuesta"){
               this.open_send_survey(event.data);
            }else if(event.event=="Descargar Excel"){
               this.open_download_csv(event.data);
            }
        }

        enabled_datatable_get_results(data, type){
            this.enabled_datatable_filters = {
                current_offset:data.current_offset,
                view_length:data.view_length,
                sort_order:data.sort_order,
                sort_ascendent:data.sort_ascendent
            }
            var response;
            var load = {
                current_offset:this.enabled_datatable_filters.current_offset,
                sort_ascendent:this.enabled_datatable_filters.sort_ascendent,
                sort_order:this.enabled_datatable_filters.sort_order,
                view_length:this.enabled_datatable_filters.view_length,
                search_word:data.search_word
            }
            this.endpoint.get_enabled_surveys(load).subscribe(
                data => response = data,
                err => {
                    this.enabled_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        if(type == "OFFSET"){
                            this.enabled_datatable_ref.set_results_offset_change(response.list);
                        }else if(type == "FILTER"){
                            this.enabled_datatable_ref.set_results_filter_change(response.list, response.count);
                        }else if(type == "UPDATE"){
                            this.enabled_datatable_ref.set_results_update_list(response.list, response.count); 
                        }
                    }catch(error){
                        this.enabled_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                    
                }
            );
        }

        disabled_datatable_get_results(data, type){
            this.disabled_datatable_filters = {
                current_offset:data.current_offset,
                view_length:data.view_length,
                sort_order:data.sort_order,
                sort_ascendent:data.sort_ascendent
            }
            var response;
            var load = {
                current_offset:this.disabled_datatable_filters.current_offset,
                sort_ascendent:this.disabled_datatable_filters.sort_ascendent,
                sort_order:this.disabled_datatable_filters.sort_order,
                view_length:this.disabled_datatable_filters.view_length,
                search_word:data.search_word
            }
            this.endpoint.get_disabled_surveys(load).subscribe(
                data => response = data,
                err => {
                    this.disabled_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        if(type == "OFFSET"){
                            this.disabled_datatable_ref.set_results_offset_change(response.list);
                        }else if(type == "FILTER"){
                            this.disabled_datatable_ref.set_results_filter_change(response.list, response.count);
                        }else if(type == "UPDATE"){
                            this.disabled_datatable_ref.set_results_update_list(response.list, response.count); 
                        }
                    }catch(error){
                        this.disabled_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                    
                }
            );
        }

        open_insert_data(){
            this.data = {
                id:"",
                name:"",
                survey_code:"",
                remitente:"",
                id_area:"",
                id_empresa:"",
                id_contacto:"",
                remitentegw:"",
                area_name:"",
                empresa_name:"",
                contacto_name:"",
                sms_mensaje_original:"",
                sms_mensaje_parseado:"",
                test_url:""
            }
            if(this.remitentes_list.length > 0){
                this.data.remitente = this.remitentes_list[0].id;
            }
            if(this.areas_list.length > 0){
                this.data.id_area = this.areas_list[0].id_subreseller;
            }
            if(this.empresas_list.length > 0){
                this.data.id_empresa = this.empresas_list[0].id;
            }
            if(this.contactos_list.length > 0){
                this.data.id_contacto = this.contactos_list[0].id;
            }
            this.data_view = 1;
            this.data_loading = false;
            this.data_submitted = false;
            this.get_labels();
            this.data_modal.show();
        }

        open_update_data(data){
            this.data = {
                id:data.id,
                name:data.name,
                survey_code:data.survey_code,
                remitente:data.remitente,
                id_area:data.id_area,
                id_empresa:data.id_empresa,
                id_contacto:data.id_contacto,
                remitentegw:data.remitentegw,
                area_name:data.area_name,
                empresa_name:data.empresa_name,
                contacto_name:data.contacto_name,
                sms_mensaje_original:data.sms_mensaje_original,
                sms_mensaje_parseado:data.sms_mensaje_parseado,
                test_url:data.test_url
            }
            this.data_view = 2;
            this.data_loading = false;
            this.data_submitted = false;
            this.get_labels();
            this.data_modal.show();
        }

        open_disable_data(data){
            this.alertService.option_alert("Deshabilitar Encuesta","¿Está seguro que desea deshabilitar la encuesta seleccionada?<br><br><b>Encuesta:<br></b><b class='text-success'> "+data.name+"</b>","Sí, Deshabilitar").then((result) => {
                if(result.value){
                    this.disable_data(data);
                }
            }).catch(()=>{return false;});
        }

        open_enable_data(data){
            this.alertService.option_alert("Habilitar Encuesta","¿Está seguro que desea habilitar la encuesta seleccionada?<br><br><b>Encuesta:<br></b><b class='text-success'> "+data.name+"</b>","Sí, Habilitar").then((result) => {
                if(result.value){
                    this.enable_data(data);
                }
            }).catch(()=>{return false;});
        }

        insert_data(){
            if(this.data_form.valid && (this.data.sms_mensaje_original + " ").split("$cli_url ").length > 1){
                this.data_submitted = false;
                this.data_loading = true;
                for(var i = 0;i<this.remitentes_list.length;i++){
                    if(this.data.remitente==this.remitentes_list[i].id){
                        this.data.remitentegw = this.remitentes_list[i].short_shortcode;
                    }
                }
                var mensaje_original = this.data.sms_mensaje_original.replace(/  +/g, ' ');
                var mensaje_parsed = this.data.sms_mensaje_original.replace(/  +/g, ' ') + " ";
                for(var j = 0;j<this.label_list.length;j++){
                    var alias_to_replace = new RegExp("\#" + this.label_list[j].var_alias + " ","g");
                    var field_to_insert = "$" + this.label_list[j].var_campo + " ";
                    mensaje_parsed = mensaje_parsed.replace(alias_to_replace,field_to_insert);
                }
                var bad_chars = this.validate_sms_caracters();
                if(bad_chars.length > 0){
                    var error_text = ["Caracteres: " + bad_chars.join(", ")];
                    this.alertService.alert_multiple_error("Error de Caracteres", "Se encontraron caracteres no permitidos dentro del mensaje", error_text);
                    this.data_loading = false;
                    return false;
                }else{
                    var load = JSON.parse(JSON.stringify(this.data));
                    load.sms_mensaje_original = mensaje_original.trim();
                    load.sms_mensaje_parseado = mensaje_parsed.trim();
                    var response;
                    this.endpoint.insert_survey(load).subscribe(
                        data => response = data,
                        err => {
                            this.data_loading = false;
                            if(err.status && err.error){
                                this.alertService.alert_message(err.status ,err.error);
                            }else{
                                this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                            }
                        },
                        ()=> {
                            try{
                                this.data_modal.hide();
                                this.enabled_datatable_ref.emit_get_results_update_list();
                                //this.alertService.alert_success(response.title, response.message);
                                this.data_loading = false;
                            }catch(error){
                                this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                                this.data_loading = false;
                            }
                        }
                    );
                }
                
            }else{
                this.data_submitted = true;
            }
        }

        update_data(){
            if(this.data_form.valid){
                this.alertService.option_alert("Editar Encuesta","¿Está seguro que desea editar la encuesta seleccionada?","Sí, Editar").then((result) => {
                    if(result.value){
                        this.data_submitted = false;
                        this.data_loading = true;
                        for(var i = 0;i<this.remitentes_list.length;i++){
                            if(this.data.remitente==this.remitentes_list[i].id){
                                this.data.remitentegw = this.remitentes_list[i].short_shortcode;
                            }
                        }
                        var mensaje_original = this.data.sms_mensaje_original.replace(/  +/g, ' ');
                        var mensaje_parsed = this.data.sms_mensaje_original.replace(/  +/g, ' ') + " ";
                        for(var j = 0;j<this.label_list.length;j++){
                            var alias_to_replace = new RegExp("\#" + this.label_list[j].var_alias + " ","g");
                            var field_to_insert = "$" + this.label_list[j].var_campo + " ";
                            mensaje_parsed = mensaje_parsed.replace(alias_to_replace,field_to_insert);
                        }
                        var bad_chars = this.validate_sms_caracters();
                        if(bad_chars.length > 0){
                            var error_text = ["Caracteres: " + bad_chars.join(", ")];
                            this.alertService.alert_multiple_error("Error de Caracteres", "Se encontraron caracteres no permitidos dentro del mensaje", error_text);
                            this.data_loading = false;
                            return false;
                        }else{
                            var load = JSON.parse(JSON.stringify(this.data));
                            load.sms_mensaje_original = mensaje_original.trim();
                            load.sms_mensaje_parseado = mensaje_parsed.trim();
                            var response;
                            this.endpoint.update_survey(load).subscribe(
                                data => response = data,
                                err => {
                                    this.data_loading = false;
                                    if(err.status && err.error){
                                        this.alertService.alert_message(err.status ,err.error);
                                    }else{
                                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                                    }
                                },
                                ()=> {
                                    try{
                                        this.data_modal.hide();
                                        this.enabled_datatable_ref.emit_get_results_update_list();
                                        //this.alertService.alert_success(response.title, response.message);
                                        this.data_loading = false;
                                    }catch(error){
                                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                                        this.data_loading = false;
                                    }
                                }
                            );
                        }
                    }
                }).catch(()=>{return false;});
            }else{
                this.data_submitted = true;
            }
        }

        disable_data(data){
            this.enabled_datatable_ref.set_loading(true);
            var load = {
                id:data.id
            };
            var response;
            this.endpoint.disable_survey(load).subscribe(
                data => response = data,
                err => {
                    this.enabled_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.enabled_datatable_ref.emit_get_results_update_list();
                        //this.alertService.alert_success(response.title, response.message);
                        //this.enabled_datatable_ref.set_loading(false);
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.enabled_datatable_ref.set_loading(false);
                    }
                }
            );
        }

        enable_data(data){
            this.disabled_datatable_ref.set_loading(true);
            var load = {
                id:data.id
            };
            var response;
            this.endpoint.enable_survey(load).subscribe(
                data => response = data,
                err => {
                    this.disabled_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.disabled_datatable_ref.emit_get_results_update_list();
                        //this.alertService.alert_success(response.title, response.message);
                        //this.disabled_datatable_ref.set_loading(false);
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.disabled_datatable_ref.set_loading(false);
                    }
                }
            );
        }

    //SURVEYS ################################################################
    //########################################################################

    //########################################################################
    //CREATE SURVEY ##########################################################

        open_design_survey(survey){
            this.view = 2;
            this.survey_view = 1;
            this.submitted = false;
            this.survey_data = {
                id:survey.id,
                name:survey.name,
                survey_code:survey.survey_code,
                remitentegw:survey.remitentegw,
                area_name:survey.area_name,
                empresa_name:survey.empresa_name?survey.empresa_name:"",
                contacto_name:survey.contacto_name?survey.contacto_name:"",
                sms_mensaje_original:survey.sms_mensaje_original,
                sms_mensaje_parseado:survey.sms_mensaje_parseado,
                test_url:survey.test_url,
                sections:[
                ]
            }
            this.get_labels();
            this.get_survey_sections(survey.id);
        }

        get_survey_sections(survey_id){
            var response;
            this.loading = true;
            this.endpoint.get_survey_sections({survey_id:survey_id}).subscribe(
                data => response = data,
                err => {
                    this.survey_data.sections = [];
                    this.loading = false;
                },
                ()=> {
                    try{
                        this.survey_data.sections = [];
                        for(var i = 0;i<response.length;i++){
                            var survey_data = JSON.parse(response[i].data);
                            var survey_section = {
                                order:survey_data.order?survey_data.order:"",
                                logo_data:survey_data.logo_data?survey_data.logo_data:null,
                                type:survey_data.type?survey_data.type:3,
                                background_color:survey_data.background_color?survey_data.background_color:"#fff",
                                background_image:survey_data.background_image?survey_data.background_image:"",
                                text_color:survey_data.text_color?survey_data.text_color:"#3E3E3E",
                                text_size:survey_data.text_size?survey_data.text_size:"14px",
                                satisfaction_survey:survey_data.satisfaction_survey?survey_data.satisfaction_survey:
                                    {
                                        text_intro:"Estimado cliente",
                                        text_question:"¿Cómo calificarías el nivel de satisfacción de nuestros servicios?",
                                        excellent:true,
                                        good:true,
                                        neutral:true,
                                        bad:true,
                                        terrible:true,
                                        comments_example:"",
                                        action:1,
                                        action_url:""                        
                                    }
                                ,
                                form_survey:survey_data.form_survey?survey_data.form_survey:{
                                    text_intro: "Estimado cliente",
                                    description: "Ayudanos a mejorar nuestros servicios respondiendo las siguientes preguntas",
                                    types: [
                                        {
                                            value: 1,
                                            label: "Texto"
                                        },
                                        {
                                            value: 2,
                                            label: "Número entero"
                                        },
                                        {
                                            value: 3,
                                            label: "Número decimal"
                                        },
                                        {
                                            value: 4,
                                            label: "Listado"
                                        },
                                        {
                                            value: 5,
                                            label: "Radio Botón"
                                        },
                                        {
                                            value: 6,
                                            label: "Checkbox"
                                        },
                                    ],
                                    selected: 0,
                                    sections: [
                                        { type: 1, required: true, question: "", answer: "", options: [{ value: +new Date(), text:"" }] }
                                    ],                        
                                    comments_example: "",
                                    action: 1,
                                    action_url: ""
                                },
                                text_survey:survey_data.text_survey?survey_data.text_survey:{
                                    text: "Estimado cliente",                        
                                    align_y_list: [
                                        {
                                            value: 1,
                                            label: "fas fa-angle-up"
                                        },
                                        {
                                            value: 2,
                                            label: "fas fa-minus"
                                        },
                                        {
                                            value: 3,
                                            label: "fas fa-angle-down"
                                        },
                                    ],
                                    align_x_list: [
                                        {
                                            value: 1,
                                            label: "fas fa-align-left"
                                        },
                                        {
                                            value: 2,
                                            label: "fas fa-align-center"
                                        },
                                        {
                                            value: 3,
                                            label: "fas fa-align-right"
                                        },
                                    ],
                                    align_X: 1,
                                    align_y: 1,
                                    comments_example: "",
                                    action: 1,
                                    action_url: ""
                                },
                                image_survey:survey_data.image_survey?survey_data.image_survey:{
                                    action: 1,
                                    action_url: ""
                                }
                            }
                            this.survey_data.sections.push(survey_section);
                        }
                        this.loading = false;
                    }catch(error){
                        this.survey_data.sections = [];
                        this.loading = false;
                    }
                }
            );
        }

        close_insert_survey(){
            this.alertService.option_alert("Regresar", "Cualquier cambio que no haya sido guardado se perderá. ¿Desea regresar al menú principal?", "Sí, Regresar").then((result) => {
                if(result.value){
                    this.go_to_enabled();
                    this.view = 1;
                    this.survey_view = 1;
                    this.datatable_view = 1;
                    this.survey_data = {
                        id:"",
                        name:"",
                        survey_code:"",
                        remitentegw:"",
                        area_name:"",
                        empresa_name:"",
                        contacto_name:"",
                        sms_mensaje_original:"",
                        sms_mensaje_parseado:"",
                        test_url:"",
                        sections:[
                        ]
                    }
                }
            }).catch(() => { return false; });
        }

        save_survey(){
            if(this.general_form.valid){
                this.submitted = false;
                this.loading = true;
                for(var i = 0;i<this.survey_data.sections.length;i++){
                    this.survey_data.sections[i].order = (i+1); 

                    this.survey_data.sections[i].form_survey.sections.forEach(element => {
                        if(element.type == 6) {
                            element.answer = [];
                        } else {
                            element.answer = '';
                        }
                    });
                }
                var response;
                this.endpoint.save_survey(this.survey_data).subscribe(
                    data => response = data,
                    err => {
                        this.loading = false;
                        if(err.status && err.error){
                            this.alertService.alert_message(err.status ,err.error);
                        }else{
                            this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                        }
                    },
                    ()=> {
                        try{

                            this.loading = false;
                        }catch(error){
                            this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                            this.loading = false;
                        }
                    }
                );
            }else{
                this.submitted = true;
            }
        }

        insert_survey_section(){
            if(this.survey_data.sections.length < 99){
                this.survey_data.sections.push({
                    order:"",
                    logo_data:null,
                    type:3,
                    background_color:"#fff",
                    background_image:"",
                    text_color:"#3E3E3E",
                    text_size:"14px",                                        
                    satisfaction_survey:{
                        text_intro:"Estimado cliente",
                        text_question:"¿Cómo calificarías el nivel de satisfacción de nuestros servicios?",
                        excellent:true,
                        good:true,
                        neutral:true,
                        bad:true,
                        terrible:true,
                        comments_example:"",
                        action:1,
                        action_url:""                        
                    },
                    form_survey:{
                        text_intro: "Estimado cliente",
                        description: "Ayudanos a mejorar nuestros servicios respondiendo las siguientes preguntas",
						types: [
							{
								value: 1,
								label: "Texto"
							},
							{
								value: 2,
								label: "Número entero"
							},
							{
								value: 3,
								label: "Número decimal"
							},
							{
								value: 4,
								label: "Listado"
							},
							{
								value: 5,
								label: "Radio Botón"
							},
							{
								value: 6,
								label: "Checkbox"
							},
						],
						selected: 0,
						sections: [
							{ type: 1, required: true, question: "", answer: "", options: [{ value: +new Date(), text:"" }] }
						],						
                        comments_example: "",
                        action: 4,
						action_url: "",
						action_text:"Aceptar",
						button_color:"#3E3E3E",
                        btn_block: "false",
                        icon: "",
						text_color:"#FFFFFF",
                    },
                    text_survey:{
						text: "Estimado cliente",                        
						align_y_list: [
							{
								value: 1,
								label: "fas fa-angle-up"
							},
							{
								value: 2,
								label: "fas fa-minus"
							},
							{
								value: 3,
								label: "fas fa-angle-down"
							},
						],
						align_x_list: [
							{
								value: 1,
								label: "fas fa-align-left"
							},
							{
								value: 2,
								label: "fas fa-align-center"
							},
							{
								value: 3,
								label: "fas fa-align-right"
							},
						],
						align_X: 1,
						align_y: 1,
                        comments_example: "",
                        action: 4,
						action_url: "",
                        btn_block: "false",
                        icon: "",
						button_color:"#3E3E3E",
						text_color:"#FFFFFF",
						action_text:"Aceptar",
                    },
                    image_survey:{
                        action: 4,
						action_url: "",
                        btn_block: "false",
                        icon: "",
						button_color:"#3E3E3E",
						text_color:"#FFFFFF",
						action_text:"Aceptar",
                    }
                })
            }
        }

        delete_survey_section(index){
            this.alertService.option_alert("Eliminar Sección", "¿Está seguro que desea eliminar la sección?", "Sí, Eliminar").then((result) => {
                if(result.value){
                    this.survey_data.sections.splice(index, 1);
                }
            }).catch(() => { return false; });
        }

        order_survey_up(index){
            if(index > 0){
                var old_array = JSON.parse(JSON.stringify(this.survey_data.sections));
                var new_array = [];
                for(var i = 0;i<old_array.length;i++){
                    if(i==index-1){
                        new_array.push(this.survey_data.sections[index]);
                        new_array.push(this.survey_data.sections[i]);
                    }else if(i==index){

                    }else{
                        new_array.push(this.survey_data.sections[i]);
                    }
                }
                this.survey_data.sections = [];
                this.survey_data.sections = new_array;
            }
        }

        order_survey_down(index){
            if(index + 1 < this.survey_data.sections.length){
                var old_array = JSON.parse(JSON.stringify(this.survey_data.sections));
                var new_array = [];
                for(var i = 0;i<old_array.length;i++){
                    if(i==index){
                        new_array.push(this.survey_data.sections[i + 1]);
                        new_array.push(this.survey_data.sections[index]);
                        
                    }else if(i==index + 1){

                    }else{
                        new_array.push(this.survey_data.sections[i]);
                    }
                }
                this.survey_data.sections = [];
                this.survey_data.sections = new_array;
            }
        }

        upload_logo(event, index){
            let fileList: FileList = event.target.files;
            if(fileList.length > 0) {
                let file: File = fileList[0];
                var input = event.target;

                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                reader.onload = (data) => {
                    try{
                        this.survey_data.sections[index].logo_data = reader.result;
                        (<HTMLInputElement>document.getElementById('logo_input' + index)).value = "";
                    }catch(err){
                        swal({
                            title: "Error al Cargar Archivo",
                            text: "Se produjo un error al intentar cargar el archivo, favor verificar que el formato del archivo sea el correcto",
                            type: "warning",
                            allowOutsideClick: false
                        }).catch(swal.noop);
                        (<HTMLInputElement>document.getElementById('logo_input' + index)).value = "";
                        
                    }
                }
                reader.onerror = function () {
                    swal({
                        title: "Error al Cargar Archivo",
                        text: "Se produjo un error al intentar cargar el archivo, favor verificar que el formato del archivo sea el correcto",
                        type: "warning",
                        allowOutsideClick: false
                    }).catch(swal.noop)
                };
            }
        }

        remove_logo(index){
            this.survey_data.sections[index].logo_data = null;
            (<HTMLInputElement>document.getElementById('logo_input' + index)).value = "";
        }

        upload_background(event, index){
            let fileList: FileList = event.target.files;
            if(fileList.length > 0) {
                let file: File = fileList[0];
                var input = event.target;

                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                reader.onload = (data) => {
                    try{
                        this.survey_data.sections[index].background_image = reader.result;
                        (<HTMLInputElement>document.getElementById('background_image' + index)).value = "";
                    }catch(err){
                        swal({
                            title: "Error al Cargar Archivo",
                            text: "Se produjo un error al intentar cargar el archivo, favor verificar que el formato del archivo sea el correcto",
                            type: "warning",
                            allowOutsideClick: false
                        }).catch(swal.noop);
                        (<HTMLInputElement>document.getElementById('background_image' + index)).value = "";
                        
                    }
                }
                reader.onerror = function () {
                    swal({
                        title: "Error al Cargar Archivo",
                        text: "Se produjo un error al intentar cargar el archivo, favor verificar que el formato del archivo sea el correcto",
                        type: "warning",
                        allowOutsideClick: false
                    }).catch(swal.noop)
                };
            }
        }

        remove_background(index){
            this.survey_data.sections[index].background_image = "";
            (<HTMLInputElement>document.getElementById('background_image' + index)).value = "";
        }

    //CREATE SURVEY ##########################################################
    //########################################################################

    //########################################################################
    //SEND SURVEY ############################################################

        open_send_survey(survey){
            this.view = 3;
            this.send_view = 1;
            this.send_submitted = false;
            this.send_data = {
                id:survey.id,
                remitente:survey.remitente,
                id_area:survey.id_area,
                id_empresa:survey.id_empresa,
                id_contacto:survey.id_contacto,
                name:survey.name,
                survey_code:survey.survey_code,
                remitentegw:survey.remitentegw,
                area_name:survey.area_name,
                empresa_name:survey.empresa_name?survey.empresa_name:"",
                contacto_name:survey.contacto_name?survey.contacto_name:"",
                sms_mensaje_original:survey.sms_mensaje_original,
                sms_mensaje_parseado:survey.sms_mensaje_parseado,
                test_url:survey.test_url
            }
            this.get_labels();
        }

        close_send_survey(){
            this.alertService.option_alert("Regresar", "Cualquier cambio que no haya sido guardado se perderá. ¿Desea regresar al menú principal?", "Sí, Regresar").then((result) => {
                if(result.value){
                    this.go_to_enabled();
                    this.view = 1;
                    this.send_view = 1;
                    this.send_submitted = false;
                    this.datatable_view = 1;
                    this.send_data = {
                        id:"",
                        name:"",
                        survey_code:"",
                        remitente:"",
                        id_area:"",
                        id_empresa:"",
                        id_contacto:"",
                        remitentegw:"",
                        area_name:"",
                        empresa_name:"",
                        contacto_name:"",
                        sms_mensaje_original:"",
                        sms_mensaje_parseado:"",
                        test_url:""
                    }
                }
            }).catch(() => { return false; });
        }

        trigger_calendarized(){
            this.calendarized_form.clean_form();
            if(!this.calendarized_sms){
                this.calendarized_dates = [];
            }
        }

        add_calendarized_day(){
            if(this.calendarized_form.valid()){
                var date = {
                    sms_dia:this.calendarized_form.get_values().sms_dia.split("/").reverse().join("-"),
                    sms_hora:this.calendarized_form.get_values().sms_hora,
                    string_time:this.time_pretty_print(this.calendarized_form.get_values().sms_hora),
                    string_date:this.calendarized_form.get_values().sms_dia
                }
                var time = new Date(this.calendarized_form.get_values().sms_dia.split("/").reverse().join("-") + " " + this.calendarized_form.get_values().sms_hora);
                time.setMinutes(time.getMinutes() - 1)
                if(time <= this.current_time){
                    this.alertService.alert_error("Fecha Incorrecta", "La fecha y hora calendarizada debe de ser como mínimo un minuto mayor a la fecha y hora actual");
                }else{
                    this.calendarized_dates.push(date);
                    this.calendarized_form.set_value("sms_dia","");
                }
            }
        }

        remove_calendarized_day(index){
            this.calendarized_dates.splice(index,1);
        }

        time_pretty_print(time){
            time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) {
                time = time.slice (1);
                time[5] = +time[0] < 12 ? ' AM' : ' PM';
                time[0] = +time[0] % 12 || 12;
            }
            return time.join ('');
        }

        get_current_time(){
            var response;
            this.endpoint.get_current_time().subscribe(
                data => response = data,
                err => {
                    this.current_time = null;
                },
                ()=> {
                    try{
                        this.current_time = new Date(response.date);
                        setInterval(()=>{ 
                            this.current_time.setSeconds(this.current_time.getSeconds() + 1);
                        }, 1000);
                    }catch(error){
                        this.current_time = null;
                    }
                }
            );
        }

        sms_preview(){
            this.sms_preview_data.remitente = this.send_data.remitentegw;
            this.sms_preview_data.text = this.send_data.sms_mensaje_parseado;
            this.sms_preview_data.hour = (new Date()).toLocaleTimeString('es-hn',{ hour: '2-digit', minute: '2-digit', hour12: true });
            this.sms_vista_previa.show();
        }

        open_upload_files() {
            if(((this.calendarized_sms && this.calendarized_dates.length>0) || !this.calendarized_sms) && this.send_data.sms_mensaje_original.length > 0){
                var dates_errors = [];
                for(var r = 0;r<this.calendarized_dates.length;r++){
                    var time = new Date(this.calendarized_dates[r].sms_dia + " " + this.calendarized_dates[r].sms_hora);
                    time.setMinutes(time.getMinutes() - 1)
                    if(time <= this.current_time){
                        dates_errors.push("Hora de Calendarización: " +  this.calendarized_dates[r].string_date + " " + this.calendarized_dates[r].string_time);
                    }
                }
                if(dates_errors.length>0){
                    this.alertService.alert_multiple_error("Error de Fechas", "La fecha y hora calendarizada debe de ser como mínimo un minuto mayor a la fecha y hora actual", dates_errors);
                }else{
                    this.upload_files_loading = false;
                    this.upload_files_modal_view = 1;
                    this.upload_files_list = [];
                    this.upload_files_ok_list = [];
                    this.upload_files_formatted_list = [];
                    this.data_load = {
                        sms_mensaje_original:this.send_data.sms_mensaje_original,
                        sms_mensaje_parseado:this.send_data.sms_mensaje_parseado,
                        nom_camp:this.send_data.name,
                        tipo_campania:0,
                        num_gest:"",
                        cif:"",
                        remitente:this.send_data.remitente,
                        remitentegw:this.send_data.remitentegw,
                        id_area:this.send_data.id_area,
                        id_empresa:this.send_data.id_empresa?this.send_data.id_empresa:0,
                        id_contacto:this.send_data.id_contacto?this.send_data.id_contacto:0,
                        groups_selected:[],
                        clients_selected:[], 
                        calendarized_dates:this.calendarized_dates,
                        calendarized_sms:this.calendarized_sms,
                        sms_send_type:2,
                        limpiar_miembros:0,
                        subgroup_id:"",
                        survey_id:this.send_data.id,
                        test_url:this.send_data.test_url,
                        group_survey_id:""
                    }
                    this.upload_files_modal.show();
                }
            }else{
                this.alertService.alert_error("Error de Formulario", "Se encontraron errores en el formulario de envío de SMS");
                this.send_submitted = true;
            }
        }

        close_upload_files() {
            this.upload_files_list = [];
            this.upload_files_ok_list = [];
            this.upload_files_formatted_list = [];
            this.current_file_datatable_ref.set_results([]);
            this.current_file_datatable_ref.set_headers([]);
            this.upload_files_modal.hide();
        }

        close_upload_files_confirmation() {
            this.alertService.option_alert("Cancelar Campaña", "¿Está seguro que desea cancelar el envío de Campaña de Encuestas?", "Sí, Cancelar").then((result) => {
                if (result.value) {
                    this.upload_files_list = [];
                    this.upload_files_ok_list = [];
                    this.upload_files_modal.hide();
                    this.current_file_datatable_ref.set_results([]);
                    this.current_file_datatable_ref.set_headers([]);
                }
            }).catch(() => { return false; });
        }

        file_count_valid() {
            var total = 0;
            for (var i = 0; i < this.upload_files_list.length; i++) {
                if (this.upload_files_list[i].status == "OK") {
                    total++;
                }
            }
            if (total > 0) {
                return true;
            } else {
                return false;
            }
        }

        drop_file(data) {
            if(data.files.length > 1){
                this.alertService.alert_error("Error", "Sólo se puede cargar 1 archivo al momento de enviar encuestas de satisfacción");
            }else{
                this.upload_files_list = [];
                if (data.files.length > 0) {
                    this.upload_files_loading = true;
                }
                if (data.files[0].fileEntry.isFile) {
                    var fileEntry = data.files[0].fileEntry as FileSystemFileEntry;
                    fileEntry.file((file: File) => {
                        try {
                            if (file.name.substring(0, 2) != "~$" && (file.name.split(".")[file.name.split(".").length - 1] == "csv" || file.name.split(".")[file.name.split(".").length - 1] == "xls" || file.name.split(".")[file.name.split(".").length - 1] == "xlsx")) {
                                var reader = new FileReader();
                                reader.readAsBinaryString(file);
                                reader.onload = (loaded_data) => {
                                    const binary_string = reader.result;
                                    const wb: XLSX.WorkBook = XLSX.read(binary_string, { type: 'binary' });
                                    const wsname: string = wb.SheetNames[0];
                                    const ws: XLSX.WorkSheet = wb.Sheets[wsname];
                                    var list_ref_temp: any[] = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
                                    var list_ref = [];
                                    for(var p = 0;p<list_ref_temp.length;p++){
                                        if(list_ref_temp[p] && list_ref_temp[p] instanceof Array && list_ref_temp[p].length > 0){
                                            list_ref.push(list_ref_temp[p]);
                                        }
                                    }
                                    if (list_ref.length == 0) {
                                        this.upload_files_list.push({ name: file.name, status: "ERROR", status_message: "archivo vacío", values: [], length: 0 });
                                    } else if (list_ref.length == 1) {
                                        if (this.validate_is_header(list_ref[0])) {
                                            this.upload_files_list.push({ name: file.name, status: "ERROR", status_message: "archivo vacío", values: [], length: 0 });
                                        } else {
                                            var max = -Infinity;
                                            list_ref.forEach(function (a) {
                                                if (a.length > max) {
                                                    max = a.length;
                                                }
                                            });
                                            if (max > 19) {
                                                this.upload_files_list.push({ name: file.name, status: "ERROR", status_message: "archivo excede 20 columnas", values: [], length: 0 });
                                            } else {
                                                this.upload_files_list.push({ name: file.name, status: "OK", values: list_ref, length: 1 });
                                            }

                                        }
                                    } else {
                                        if (this.validate_is_header(list_ref[0])) {
                                            var max = -Infinity;
                                            list_ref.forEach(function (a) {
                                                if (a.length > max) {
                                                    max = a.length;
                                                }
                                            });
                                            if (max > 20) {
                                                this.upload_files_list.push({ name: file.name, status: "ERROR", status_message: "archivo excede 20 columnas", values: [], length: 0 });
                                            } else {
                                                this.upload_files_list.push({ name: file.name, status: "OK", values: list_ref, length: list_ref.length - 1 });
                                            }

                                        } else {
                                            var max = -Infinity;
                                            list_ref.forEach(function (a) {
                                                if (a.length > max) {
                                                    max = a.length;
                                                }
                                            });
                                            if (max > 20) {
                                                this.upload_files_list.push({ name: file.name, status: "ERROR", status_message: "archivo excede 20 columnas", values: [], length: 0 });
                                            } else {
                                                this.upload_files_list.push({ name: file.name, status: "OK", values: list_ref, length: list_ref.length });
                                            }
                                        }

                                    }
                                    this.upload_files_loading = false;
                                }
                                reader.onerror = () => {
                                    this.upload_files_list.push({ name: file.name, status: "ERROR", status_message: "error de carga", values: [], length: 0 });
                                    this.upload_files_loading = false;
                                };
                            } else {
                                this.upload_files_list.push({ name: file.name, status: "ERROR", status_message: "extensión no permitida", values: [], length: 0 });
                                this.upload_files_loading = false;
                            }
                        } catch (err) {
                            this.upload_files_list.push({ name: file.name, status: "ERROR", status_message: "error de carga", values: [], length: 0 });
                            this.upload_files_loading = false;
                        }
                    });
                }
            }
        }

        remove_file(index) {
            this.upload_files_list.splice(index, 1);
        }

        to_valid_header(row) {
            var headers = [];
            for (var i = 0; i < row.length; i++) {
                if ((row[i] + "").includes('cli_identidad')) {
                    headers.push('cli_identidad');
                } else if ((row[i] + "").includes('cli_nombre')) {
                    headers.push('cli_nombre');
                } else if ((row[i] + "").includes('cli_sexo')) {
                    headers.push('cli_sexo');
                } else if ((row[i] + "").includes('cli_fecha_nac')) {
                    headers.push('cli_fecha_nac');
                } else if ((row[i] + "").includes('cli_celular')) {
                    headers.push('cli_celular');
                } else if ((row[i] + "").includes('cli_telefono')) {
                    headers.push('cli_telefono');
                } else if ((row[i] + "").includes('cli_email')) {
                    headers.push('cli_email');
                } else if ((row[i] + "").includes('cli_direccion')) {
                    headers.push('cli_direccion');
                } else if ((row[i] + "").includes('cli_tarjeta')) {
                    headers.push('cli_tarjeta');
                } else if ((row[i] + "").includes('cli_campo1')) {
                    headers.push('cli_campo1');
                } else if ((row[i] + "").includes('cli_campo2')) {
                    headers.push('cli_campo2');
                } else if ((row[i] + "").includes('cli_campo3')) {
                    headers.push('cli_campo3');
                } else if ((row[i] + "").includes('cli_campo4')) {
                    headers.push('cli_campo4');
                } else if ((row[i] + "").includes('cli_campo5')) {
                    headers.push('cli_campo5');
                } else if ((row[i] + "").includes('cli_campo6')) {
                    headers.push('cli_campo6');
                } else if ((row[i] + "").includes('cli_campo7')) {
                    headers.push('cli_campo7');
                } else if ((row[i] + "").includes('cli_mensaje')) {
                    headers.push('cli_mensaje');
                } else if ((row[i] + "").includes('cli_cif')) {
                    headers.push('cli_cif');
                } else if ((row[i] + "").includes('cli_cm')) {
                    headers.push('cli_cm');
                } else if ((row[i] + "").includes('cli_errores')) {
                    headers.push('eliminar_columna');
                } else {
                    headers.push('');
                }
            }
            return headers;
        }

        open_assign_fields_columns() {
            try {
                this.upload_files_ok_list = [];
                for (var i = 0; i < this.upload_files_list.length; i++) {
                    if (this.upload_files_list[i].status == "OK") {
                        var max = -Infinity;
                        this.upload_files_list[i].values.forEach(function (a) {
                            if (a.length > max) {
                                max = a.length;
                            }
                        });
                        for (var j = 0; j < this.upload_files_list[i].values.length; j++) {
                            for (var k = this.upload_files_list[i].values[j].length; k < max; k++) {
                                this.upload_files_list[i].values[j].push('');
                            }
                        }
                        if (this.validate_is_header(this.upload_files_list[i].values[0])) {
                            var headers = [];
                            headers = JSON.parse(JSON.stringify(this.upload_files_list[i].values[0]));
                            this.upload_files_list[i].values.splice(0, 1);
                            var headers_ref = this.to_valid_header(headers);
                            var columns = this.upload_files_list[i].values.reduce(
                                (r, a) => (a.forEach((v, c) => r[c] = r[c] || v), r),
                                []
                            )
                            var empty_columns = [];
                            for (var d = 0; d < columns.length; d++) {
                                if (columns[d]) {
                                    empty_columns.push(true);
                                } else {
                                    empty_columns.push(false);
                                }
                            }
                            for (var l = empty_columns.length - 1; l >= 0; l--) {
                                if (!empty_columns[l]) {
                                    headers_ref.splice(l, 1);
                                }
                            }
                            for (var p = 0; p < this.upload_files_list[i].values.length; p++) {
                                for (var l = empty_columns.length - 1; l >= 0; l--) {
                                    if (!empty_columns[l]) {
                                        this.upload_files_list[i].values[p].splice(l, 1);
                                    }
                                }
                            }
                            this.upload_files_ok_list.push({
                                values: {
                                    data: this.upload_files_list[i].values,
                                    max: max,
                                    headers: headers_ref
                                },
                                name: this.upload_files_list[i].name
                            });
                        } else {
                            var headers = [];
                            var columns = this.upload_files_list[i].values.reduce(
                                (r, a) => (a.forEach((v, c) => r[c] = r[c] || v), r),
                                []
                            )
                            var empty_columns = [];
                            for (var d = 0; d < columns.length; d++) {
                                if (columns[d]) {
                                    empty_columns.push(true);
                                } else {
                                    empty_columns.push(false);
                                }
                            }
                            for (var p = 0; p < this.upload_files_list[i].values.length; p++) {
                                for (var l = empty_columns.length - 1; l >= 0; l--) {
                                    if (!empty_columns[l]) {
                                        this.upload_files_list[i].values[p].splice(l, 1);
                                    }
                                }
                            }
                            for (var p = 0; p < this.upload_files_list[i].values[0].length; p++) {
                                headers.push('');
                            }
                            this.upload_files_ok_list.push({
                                values: {
                                    data: this.upload_files_list[i].values,
                                    max: max,
                                    headers: headers
                                },
                                name: this.upload_files_list[i].name
                            });
                        }
                    }
                }
                if (this.upload_files_ok_list.length > 0) {
                    this.upload_files_loading = false;
                    this.upload_files_modal_view = 2;
                    this.current_file_datatable_ref.set_results(this.upload_files_ok_list[0].values.data);
                    this.current_file_datatable_ref.set_headers(this.upload_files_ok_list[0].values.headers);
                    this.upload_files_formatted_list = [];
                } else {
                    this.alertService.alert_error("Error", "No se han cargado archivos válidos");
                }
            } catch (error) {
                this.alertService.alert_error("Error de Lectura", "Error al intentar leer los archivos proporcionados");
            }
        }

        current_file_datatable_events(event) {
            if (event.event == 'Eliminar Registro') {
                this.current_file_datatable_ref.remove_value(event.index);
            }
        }

        set_assign_columns() {
            if(this.current_file_datatable_ref.valid_headers()){
                this.upload_files_formatted_list = [];
                this.upload_files_formatted_list.push({ raw_headers: [], progress: 0, error_data: [], success_data: [], data: this.current_file_datatable_ref.get_results(), filename: this.upload_files_ok_list[0].name, groups: [] });
                this.upload_files_modal_view = 3;
            }
        }

        insert_group(){
            this.upload_files_loading = false;
            var response;
            this.endpoint.insert_group_for_survey(this.data_load).subscribe(
                data => response = data,
                err => {
                    this.upload_files_loading = false;
                    this.upload_files_modal_view = 9;
                },
                ()=> {
                    try{
                        this.data_load.subgroup_id = response.subgroup_id;
                        this.data_load.group_survey_id = response.group_survey_id;
                        this.data_load.groups_selected = [{subgroups:[{id:response.subgroup_id}]}];
                        this.upload_list(0, 0);

                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.upload_files_loading = false;
                    }
                }
            );
        }

        upload_clients() {
            this.alertService.option_alert_upload("Cargar Registros", "¿Está seguro que desea iniciar la carga de registros al sistema? Esto puede tomar varios minutos.", "Sí, Cargar").then((result) => {
                if (result.value) {
                    this.upload_files_modal_view = 4;
                    var response;
                    this.insert_group();
                }
            }).catch(() => { return false; });
        }

        upload_list(index, offset) {
            var offset_range = 1000;
            if (index < this.upload_files_formatted_list.length) {
                var load = {
                    subgroup_id: this.data_load.subgroup_id,
                    survey_id: this.data_load.survey_id,
                    data: [],
                    headers: this.upload_files_formatted_list[index].data.headers,
                    labels: this.label_list,

                }
                if (this.upload_files_formatted_list[index].data.results.length - offset > 0) {
                    if (this.upload_files_formatted_list[index].data.results.length - offset <= offset_range) {
                        for (var i = offset; i < this.upload_files_formatted_list[index].data.results.length; i++) {
                            load.data.push(this.upload_files_formatted_list[index].data.results[i]);
                        }
                    } else {
                        for (var i = offset; i < offset + offset_range; i++) {
                            load.data.push(this.upload_files_formatted_list[index].data.results[i]);
                        }
                    }
                    var response;
                    this.endpoint.upload_clients_for_survey(load).subscribe(
                        data => response = data,
                        err => {
                            this.upload_files_loading = false;
                            this.upload_files_modal_view = 9;
                        },
                        () => {
                            if (response.error_data) {
                                if (response.error_data instanceof Array) {
                                    for (var m = 0; m < response.error_data.length; m++) {
                                        this.upload_files_formatted_list[index].error_data.push(response.error_data[m]);
                                    }
                                }
                            }
                            if (response.valid_data) {
                                if (response.valid_data instanceof Array) {
                                    for (var m = 0; m < response.valid_data.length; m++) {
                                        this.upload_files_formatted_list[index].success_data.push(response.valid_data[m]);
                                    }
                                }
                            }
                            if (this.upload_files_formatted_list[index].data.results.length > 0) {
                                this.upload_files_formatted_list[index].progress = Math.ceil(((this.upload_files_formatted_list[index].success_data.length + this.upload_files_formatted_list[index].error_data.length) / this.upload_files_formatted_list[index].data.results.length) * 100);
                            }
                            if (response.headers) {
                                this.upload_files_formatted_list[index].raw_headers = response.headers
                            }
                            this.upload_list(index, offset + offset_range);
                        }
                    );

                } else {
                    this.upload_list(index + 1, 0);
                }
            } else {
                this.upload_files_modal_view = 5;
            }
        }

        got_to_preview_sms(){
            this.sms_preview_data.remitente = this.send_data.remitentegw;
            this.sms_preview_data.text = this.send_data.sms_mensaje_parseado;
            this.sms_preview_data.hour = (new Date()).toLocaleTimeString('es-hn',{ hour: '2-digit', minute: '2-digit', hour12: true });
            if(localStorage.getItem("SMS_TEST_NUMBER_REF")){
                this.test_sms = conformToMask(localStorage.getItem("SMS_TEST_NUMBER_REF"),this.test_sms_mask,{guide: false}).conformedValue;
            }else{
                this.test_sms = "";
            }
            this.test_sms_view = 1;
            this.test_sms_submitted = false;
            this.upload_files_modal_view = 6;
        }

        validate_is_header(row) {
            var is_header = true;
            for (var i = 0; i < row.length; i++) {
                if ((row[i] + "") &&
                    !(row[i] + "").includes('cli_identidad') &&
                    !(row[i] + "").includes('cli_nombre') &&
                    !(row[i] + "").includes('cli_sexo') &&
                    !(row[i] + "").includes('cli_fecha_nac') &&
                    !(row[i] + "").includes('cli_celular') &&
                    !(row[i] + "").includes('cli_telefono') &&
                    !(row[i] + "").includes('cli_email') &&
                    !(row[i] + "").includes('cli_direccion') &&
                    !(row[i] + "").includes('cli_tarjeta') &&
                    !(row[i] + "").includes('cli_campo1') &&
                    !(row[i] + "").includes('cli_campo2') &&
                    !(row[i] + "").includes('cli_campo3') &&
                    !(row[i] + "").includes('cli_campo4') &&
                    !(row[i] + "").includes('cli_campo5') &&
                    !(row[i] + "").includes('cli_campo6') &&
                    !(row[i] + "").includes('cli_campo7') &&
                    !(row[i] + "").includes('cli_mensaje') &&
                    !(row[i] + "").includes('cli_cif') &&
                    !(row[i] + "").includes('cli_errores') &&
                    !(row[i] + "").includes('cli_cm')){
                    is_header = false;
                }
            }
            return is_header;
        }

        get_total_survey_sms_count(){
            this.upload_files_loading  = true;
            var response;
            this.endpoint.get_survey_sms_count(this.data_load).subscribe(
                data => response = data,
                err => {
                    this.upload_files_loading  = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        var night_date = false;
                        for(var i = 0;i<this.calendarized_dates.length;i++){
                             var time = new Date(this.calendarized_dates[i].sms_dia + " " + this.calendarized_dates[i].sms_hora);
                             var hour = time.getHours();
                             if(time.getMinutes()>0){
                                 hour++;
                             }
                             if(hour>21 || hour<7){
                                 night_date = true;
                             }
                        }
                        if(this.calendarized_dates.length==0 && !this.calendarized_sms){
                            var hour = this.current_time.getHours();
                            if(this.current_time.getMinutes()>0){
                                 hour++;
                            }
                            if(hour>21 || hour<7){
                                 night_date = true;
                             }
                        }
                        var message = "" +
                        ((night_date)?"<br><h6 class='text-danger'>Su calendarización contiene envíos nocturnos</h6>":"") + 
                        "¿Está seguro que desea enviar la Encuesta SMS a los clientes seleccionados?<br><br><b class='text-primary'>Total SMS a Enviar<br></b>"+
                        "<h3 style='border-bottom: solid 1px #dcdcdc;padding-bottom: 20px;'><b>" + response.total_sms_count.toLocaleString('es-HN') + "</b></h3>" +
                        "<div class='text-center' style='float:left;width:33%'>" +
                        "<p style='font-size:12px !important;margin-bottom:0px !important;' class='text-muted'><b>SMS Largo 1</b></p> " +
                        "<p style='font-size:12px !important;margin-bottom:0px !important;margin-right:10px;'><b>" + response.total_sms_count_length1.toLocaleString('es-HN') + "</b></p>" +
                        "</div>" +
                        "<div class='text-center' style='float:left;width:33%'>" +
                        "<p style='font-size:12px !important;margin-bottom:0px !important;' class='text-muted'><b>SMS Largo 2</b></p> " +
                        "<p style='font-size:12px !important;margin-bottom:0px !important;margin-right:10px;'><b>" + response.total_sms_count_length2.toLocaleString('es-HN') + "</b></p>" +
                        "</div>" +
                        "<div class='text-center' style='float:left;width:33%'>" +
                        "<p style='font-size:12px !important;margin-bottom:0px !important;' class='text-muted'><b>SMS Largo 3+</b></p> " +
                        "<p style='font-size:12px !important;margin-bottom:0px !important;margin-right:10px;'><b>" + response.total_sms_count_length3.toLocaleString('es-HN') + "</b></p>" +
                        "</div>";
                        var delete_db = "";
                        this.alertService.option_alert("Enviar SMS", message + delete_db,"Sí, Enviar").then((result) => {
                            if(result.value){
                                this.send_survey(this.data_load);
                            }else{
                                this.upload_files_loading  = false;
                            }
                        }).catch(()=>{return false;});
                        this.upload_files_loading  = false;
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.upload_files_loading  = false;
                    }
                }
            );
        }

        send_survey(data){
            this.upload_files_loading  = true;
            var response;
            this.endpoint.send_survey(data).subscribe(
                data => response = data,
                err => {
                    this.upload_files_loading  = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.upload_files_modal_view = 10;
                        this.upload_files_loading  = false;
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.upload_files_loading  = false;
                    }
                }
            );
        }

        reset_send_test_sms(){
            this.test_sms_view = 1;
            this.upload_files_loading = false;
            this.test_sms_submitted = false;
        }

        got_to_preview_survey(){
            this.upload_files_modal_view = 7;
        }

        download_error_exel(response, raw_headers) {
            var headers = [];
            for (var j = 0; j < raw_headers.length; j++) {
                headers.push(raw_headers[j]);
            }
            headers.push("cli_errores");
            var options = {
                showLabels: true,
                headers: headers
            };
            for (var i = 0; i < response.length; i++) {
                for (var j = 0; j < response[i].length; j++) {
                    if (!response[i][j]) {
                        response[i][j] = "";
                    }
                }
            }
            new ngxCsv(response, 'My Report', options);
        }

        download_success_excel(response, raw_headers) {
            var headers = [];
            for (var j = 0; j < raw_headers.length; j++) {
                headers.push(raw_headers[j]);
            }
            var options = {
                showLabels: true,
                headers: headers
            };
            for (var i = 0; i < response.length; i++) {
                for (var j = 0; j < response[i].length; j++) {
                    if (!response[i][j]) {
                        response[i][j] = "";
                    }
                }
            }
            new ngxCsv(response, 'My Report', options);
        }

        send_test_sms(){
            this.test_sms_view = 1;
            this.upload_files_loading = false;
            if(this.test_sms_form.valid){
                this.test_sms_submitted = false;
                localStorage.setItem("SMS_TEST_NUMBER_REF", this.test_sms);
                var data = {
                    sms_mensaje_original:this.send_data.sms_mensaje_original,
                    sms_mensaje_parseado:this.send_data.sms_mensaje_parseado,
                    nom_camp:"SMS Prueba - Encuesta",
                    tipo_campania:0,
                    num_gest:"",
                    cif:"",
                    remitente:this.send_data.remitente,
                    remitentegw:this.send_data.remitentegw,
                    id_area:this.send_data.id_area,
                    id_empresa:this.send_data.id_empresa?this.send_data.id_empresa:0,
                    id_contacto:this.send_data.id_contacto?this.send_data.id_contacto:0,
                    groups_selected:this.data_load.groups_selected,
                    clients_selected:[], 
                    calendarized_dates:[],
                    calendarized_sms:false,
                    sms_send_type:1,
                    limpiar_miembros:0,
                    test_number:this.test_sms.replace(/-/g, ''),
                    test_url:this.data_load.test_url
                }
                var response;
                this.upload_files_loading = true;
                this.endpoint.send_survey_test_sms(data).subscribe(
                    data => response = data,
                    err => {
                        this.upload_files_loading = false;
                        if(err.status && err.error){
                            this.alertService.alert_message(err.status ,err.error);
                        }else{
                            this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                        }
                    },
                    ()=> {
                        try{
                            this.test_sms_view = 2;
                            this.upload_files_loading = false;
                        }catch(error){
                            this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                            this.upload_files_loading = false;
                        }
                    }
                );
            }else{
                this.test_sms_submitted = true;
            }
        }

    //SEND SURVEY ############################################################
    //########################################################################

    //########################################################################
    //SATISFACTION ###########################################################

        call_to_action_change(index){
            this.survey_data.sections[index].satisfaction_survey.action_url = "";
        }

    //SATISFACTION ###########################################################
    //########################################################################

    //########################################################################
    //FORM ###################################################################

		change_section_type(index, section_index, type) {	
			if(type == 6) {
				this.survey_data.sections[index].form_survey.sections[section_index].answer = [];
			} else {
				this.survey_data.sections[index].form_survey.sections[section_index].answer = '';
			}
		}

        select_section(index, section_index){
			if(section_index < this.survey_data.sections[index].form_survey.sections.length && section_index > -1) {
				this.survey_data.sections[index].form_survey.selected = section_index;
			}
		}

        delete_section(index, section_index){
			if(this.survey_data.sections[index].form_survey.sections.length > 1) {
				this.survey_data.sections[index].form_survey.sections = this.survey_data.sections[index].form_survey.sections.filter((value, i_index) => i_index != section_index);
			}
		}
		
        add_section(index, section_index){			
            this.survey_data.sections[index].form_survey.sections = [
				...this.survey_data.sections[index].form_survey.sections.filter((value, i_index) => i_index <= section_index),
				{ type: 1, required: true, question: "", answer: "", options:[{ value: +new Date(), text: "" }] },
				...this.survey_data.sections[index].form_survey.sections.filter((value, i_index) => i_index > section_index)
			];
			this.select_section(index, section_index + 1);
		}
		
        move_up_section(index, section_index){
			this.survey_data.sections[index].form_survey.sections = [
				...this.survey_data.sections[index].form_survey.sections.filter((value, i_index) => i_index < section_index - 1),
				this.survey_data.sections[index].form_survey.sections[section_index],
				...section_index - 1 > -1 ? [this.survey_data.sections[index].form_survey.sections[section_index - 1]] : [],
				...this.survey_data.sections[index].form_survey.sections.filter((value, i_index) => i_index > section_index)
			];
			this.select_section(index, section_index - 1);
		}
		
        move_down_section(index, section_index){
			this.survey_data.sections[index].form_survey.sections = [
				...this.survey_data.sections[index].form_survey.sections.filter((value, i_index) => i_index < section_index),
				...section_index + 1 < this.survey_data.sections[index].form_survey.sections.length ? [this.survey_data.sections[index].form_survey.sections[section_index + 1]] : [],
				this.survey_data.sections[index].form_survey.sections[section_index],
				...this.survey_data.sections[index].form_survey.sections.filter((value, i_index) => i_index > section_index + 1)
			];
			this.select_section(index, section_index + 1);
		}

		delete_section_option(index, section_index, option_index){
			if(this.survey_data.sections[index].form_survey.sections[section_index].options.length > 1) {
				this.survey_data.sections[index].form_survey.sections[section_index].options = this.survey_data.sections[index].form_survey.sections[section_index].options.filter((value, i_index) => i_index != option_index);
			}
		}
		
        add_section_option(index, section_index, option_index){
            this.survey_data.sections[index].form_survey.sections[section_index].options = [
				...this.survey_data.sections[index].form_survey.sections[section_index].options.filter((value, i_index) => i_index <= option_index),
				{ value: +new Date(), text: "" },
				...this.survey_data.sections[index].form_survey.sections[section_index].options.filter((value, i_index) => i_index > option_index)
			];
		}
		
        move_up_section_option(index, section_index, option_index){
			this.survey_data.sections[index].form_survey.sections[section_index].options = [
				...this.survey_data.sections[index].form_survey.sections[section_index].options.filter((value, i_index) => i_index < option_index - 1),
				this.survey_data.sections[index].form_survey.sections[section_index].options[option_index],
				...option_index - 1 > -1 ? [this.survey_data.sections[index].form_survey.sections[section_index].options[option_index - 1]] : [],
				...this.survey_data.sections[index].form_survey.sections[section_index].options.filter((value, i_index) => i_index > option_index)
			];
		}
		
        move_down_section_option(index, section_index, option_index){
			this.survey_data.sections[index].form_survey.sections[section_index].options = [
				...this.survey_data.sections[index].form_survey.sections[section_index].options.filter((value, i_index) => i_index < option_index),
				...option_index + 1 < this.survey_data.sections[index].form_survey.sections[section_index].options.length ? [this.survey_data.sections[index].form_survey.sections[section_index].options[option_index + 1]] : [],
				this.survey_data.sections[index].form_survey.sections[section_index].options[option_index],
				...this.survey_data.sections[index].form_survey.sections[section_index].options.filter((value, i_index) => i_index > option_index + 1)
			];
		}
	
		add_answer(index, section_index, option_index) {
			this.survey_data.sections[index].form_survey.sections[section_index].answer.push(this.survey_data.sections[index].form_survey.sections[section_index].options[option_index]);
		}
	
		delete_answer(index, section_index, option_index) {
			this.survey_data.sections[index].form_survey.sections[section_index].answer = this.survey_data.sections[index].form_survey.sections[section_index].answer.filter(value => value.value != this.survey_data.sections[index].form_survey.sections[section_index].options[option_index].value);
		}

		is_answer(index, section_index, option_index) {
			return this.survey_data.sections[index].form_survey.sections[section_index].answer.find(value => value.value == this.survey_data.sections[index].form_survey.sections[section_index].options[option_index].value);
		}

    //FORM ###################################################################
    //########################################################################

    //########################################################################
    //MISC ###################################################################

        get_shortcodes(){
            var response;
            this.endpoint.get_shortcodes().subscribe(
                data => response = data,
                err => {
                    this.remitentes_list = [];
                },
                ()=> {
                    try{
                        this.remitentes_list = [];
                        this.remitentes_list = response;
                    }catch(error){
                        this.remitentes_list = [];
                    }
                }
            );
        }

        get_subresellers(){
            var response;
            this.endpoint.get_subresellers().subscribe(
                data => response = data,
                err => {
                    this.areas_list = [];
                },
                ()=> {
                    try{
                        this.areas_list = [];
                        this.areas_list = response;
                        if(this.areas_list.length > 0){
                            if(this.areas_list.length > 0){
                                this.get_companies();
                                this.get_contacts();
                            }
                        }
                    }catch(error){
                        this.areas_list = [];
                    }
                }
            );
        }

        get_companies(){
            var response;
            this.endpoint.get_companies().subscribe(
                data => response = data,
                err => {
                    this.empresas_list = [];
                },
                ()=> {
                    try{
                        this.empresas_list = [];
                        this.empresas_list = response;
                    }catch(error){
                        this.empresas_list = [];
                    }
                }
            );
        }

        get_contacts(){
            var response;
            this.endpoint.get_contacts().subscribe(
                data => response = data,
                err => {
                    this.contactos_list = [];
                },
                ()=> {
                    try{
                        this.contactos_list = [];
                        this.contactos_list = response;
                    }catch(error){
                        this.contactos_list = [];
                    }
                }
            );
        }

        get_survey_types(){
            var response;
            this.endpoint.get_survey_types().subscribe(
                data => response = data,
                err => {
                    this.survey_type = [];
                },
                ()=> {
                    try{
                        this.survey_type = [];
                        this.survey_type = response;
                    }catch(error){
                        this.survey_type = [];
                    }
                }
            );
        }

        remove_double_spaces(){
            this.data.sms_mensaje_original = this.data.sms_mensaje_original.replace(/  +/g, ' ').trim();
        }

        clean_spaces(){
            this.data.sms_mensaje_original = this.data.sms_mensaje_original.replace(/á/g, 'a');
            this.data.sms_mensaje_original = this.data.sms_mensaje_original.replace(/é/g, 'e');
            this.data.sms_mensaje_original = this.data.sms_mensaje_original.replace(/í/g, 'i');
            this.data.sms_mensaje_original = this.data.sms_mensaje_original.replace(/ó/g, 'o');
            this.data.sms_mensaje_original = this.data.sms_mensaje_original.replace(/ú/g, 'u');
            this.data.sms_mensaje_original = this.data.sms_mensaje_original.replace(/Á/g, 'A');
            this.data.sms_mensaje_original = this.data.sms_mensaje_original.replace(/É/g, 'E');
            this.data.sms_mensaje_original = this.data.sms_mensaje_original.replace(/Í/g, 'I');
            this.data.sms_mensaje_original = this.data.sms_mensaje_original.replace(/Ó/g, 'O');
            this.data.sms_mensaje_original = this.data.sms_mensaje_original.replace(/Ú/g, 'U');
            this.data.sms_mensaje_original = this.data.sms_mensaje_original.trim();
            for(var i = this.data.sms_mensaje_original.length-1;i>=0;i--){
                var is_valid = false;
                for(var j = 0;j<this.endpoint.get_sms_allowed_characters_full().length;j++){
                    if(this.data.sms_mensaje_original[i]==this.endpoint.get_sms_allowed_characters_full()[j]){
                        is_valid = true;
                        break;
                    }
                }
                if(!is_valid){
                    this.data.sms_mensaje_original = this.data.sms_mensaje_original.slice(0,i) + this.data.sms_mensaje_original.slice(i+1);
                }
            }
        }

        add_alias(alias){
            if((this.data.sms_mensaje_original + " #" + alias + " ").length > 612){
                if((this.data.sms_mensaje_original + " #" + alias).length == 612){
                    this.data.sms_mensaje_original = this.data.sms_mensaje_original + " #" + alias;
                    this.data.sms_mensaje_original = this.data.sms_mensaje_original.replace(/  +/g, ' ')
                }
            }else if(this.data.sms_mensaje_original.length == 0){
                this.data.sms_mensaje_original = this.data.sms_mensaje_original + "#" + alias + " ";
                this.data.sms_mensaje_original = this.data.sms_mensaje_original.replace(/  +/g, ' ')
            }else{
                this.data.sms_mensaje_original = this.data.sms_mensaje_original + " #" + alias + " ";
                this.data.sms_mensaje_original = this.data.sms_mensaje_original.replace(/  +/g, ' ')
            }
            this.sms_mensaje_original.nativeElement.focus();
        }

        add_url(){
            if((this.data.sms_mensaje_original + " $cli_url ").length > 612){
                if((this.data.sms_mensaje_original + " $cli_url").length == 612){
                    this.data.sms_mensaje_original = this.data.sms_mensaje_original + " $cli_url";
                    this.data.sms_mensaje_original = this.data.sms_mensaje_original.replace(/  +/g, ' ')
                }
            }else if(this.data.sms_mensaje_original.length == 0){
                this.data.sms_mensaje_original = this.data.sms_mensaje_original + "$cli_url ";
                this.data.sms_mensaje_original = this.data.sms_mensaje_original.replace(/  +/g, ' ')
            }else{
                this.data.sms_mensaje_original = this.data.sms_mensaje_original + " $cli_url ";
                this.data.sms_mensaje_original = this.data.sms_mensaje_original.replace(/  +/g, ' ')
            }
            this.sms_mensaje_original.nativeElement.focus();
        }

        validate_sms_caracters(){
            var chars = [];
            for(var i = 0;i<this.data.sms_mensaje_original.length;i++){
                var is_valid = false;
                for(var j = 0;j<this.endpoint.get_sms_allowed_characters_full().length;j++){
                    if(this.data.sms_mensaje_original[i]==this.endpoint.get_sms_allowed_characters_full()[j]){
                        is_valid = true;
                        break;
                    }
                }
                if(!is_valid){
                    chars.push("<b style='margin-right:10px;'>" + this.data.sms_mensaje_original[i] + "</b>");
                }
            }
            return chars;
        }

        get_labels(){
            var response;
            this.data_loading = true;
            this.endpoint.get_labels().subscribe(
                data => response = data,
                err => {
                    this.label_list = [];
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                    this.data_loading = false;
                },
                ()=> {
                    try{
                        this.label_list = [];
                        for(var i = 0;i<response.length;i++){
                            if(response[i].var_alias){
                                this.label_list.push(response[i])
                            }
                        }
                        this.data_loading = false;
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.data_loading = false;
                    }
                }
            );
        }

        get_api_params(){
            var mensaje_original = this.data.sms_mensaje_original.replace(/  +/g, ' ');
            var mensaje_parsed = this.data.sms_mensaje_original.replace(/  +/g, ' ') + " ";
            var api_params = "survey_code";
            for(var j = 0;j<this.label_list.length;j++){
                var alias_to_replace = new RegExp("\#" + this.label_list[j].var_alias + " ","g");
                var field_to_insert = "" + this.label_list[j].var_campo;
                if(mensaje_parsed.match(alias_to_replace)){
                    api_params = api_params +  ", " + field_to_insert;
                }
            }
            return api_params;
        }

        get_api_params_survey(){
            var mensaje_original = this.survey_data.sms_mensaje_original.replace(/  +/g, ' ');
            var mensaje_parsed = this.survey_data.sms_mensaje_original.replace(/  +/g, ' ') + " ";
            var api_params = "survey_code";
            for(var j = 0;j<this.label_list.length;j++){
                var alias_to_replace = new RegExp("\#" + this.label_list[j].var_alias + " ","g");
                var field_to_insert = "" + this.label_list[j].var_campo;
                if(mensaje_parsed.match(alias_to_replace)){
                    api_params = api_params +  ", " + field_to_insert;
                }
            }
            return api_params;
        }

        get_api_params_send(){
            var mensaje_original = this.send_data.sms_mensaje_original.replace(/  +/g, ' ');
            var mensaje_parsed = this.send_data.sms_mensaje_original.replace(/  +/g, ' ') + " ";
            var api_params = "survey_code";
            for(var j = 0;j<this.label_list.length;j++){
                var alias_to_replace = new RegExp("\#" + this.label_list[j].var_alias + " ","g");
                var field_to_insert = "" + this.label_list[j].var_campo;
                if(mensaje_parsed.match(alias_to_replace)){
                    api_params = api_params +  ", " + field_to_insert;
                }
            }
            return api_params;
        }

    //MISC ###################################################################
    //########################################################################
    
	//########################################################################
	//DOWNLOAD ###############################################################

		open_download_csv(data){
            this.download_data = data;
			this.download_loading = false;
			this.download_view = 1;
			this.download_csv_modal.show();
            this.download_csv();
		}

		download_csv() {
			this.download_loading = true;
		
			var load = {
				id: this.download_data.id
			}
			var response;
			this.endpoint.get_survey_answers_csv(load).subscribe(
                    data => {
                        response = data;
                    },
                    err => {
                        if (err.status && err.error) {
                            this.alertService.alert_message(err.status, err.error);
                        } else {
                            this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                        }
                        this.download_loading = false;
                    },
                    () => {
                        this.download_data = response;
                        this.save_csv(this.download_data);
                    }
                );
            }
            
            save_csv(data) {
                this.excelService.survey_answers_to_excel("Respuestas de Encuesta", data, ()=> {
                    this.download_loading = false;
                    this.download_view = 2;          
            });
        }

	//DOWNLOAD ###############################################################
	//########################################################################

    ngOnDestroy() {
        setTimeout(() => this.layoutService.off('resize.app-home'));
    }

    form_survey_btn_block_change(i) {
        this.survey_data.sections[i].form_survey.btn_block = this.survey_data.sections[i].form_survey.btn_block == "true";
    }

    image_survey_btn_block_change(i) {
        this.survey_data.sections[i].image_survey.btn_block = this.survey_data.sections[i].image_survey.btn_block == "true";
    }
    
    text_survey_btn_block_change(i) {
        this.survey_data.sections[i].text_survey.btn_block = this.survey_data.sections[i].text_survey.btn_block == "true";
    }
    
    form_survey_btn_icon_change(i, icon){
        this.survey_data.sections[i].form_survey.icon = icon;
    }

    image_survey_btn_icon_change(i, icon){
        this.survey_data.sections[i].image_survey.icon = icon;
    }

    text_survey_btn_icon_change(i, icon){
        this.survey_data.sections[i].text_survey.icon = icon;
    }
}
