import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, OnInit, ElementRef, Renderer2, Output, EventEmitter, Input } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { AppService } from '../../app.service';
import { AppEndpoints } from '../../app.endpoints';
import { LayoutService } from '../../layout/layout.service';
import { ModalDirective } from 'ngx-bootstrap';
import { default as swal } from 'sweetalert2';
import { FormRendererComponent } from '../form_renderer/form_renderer.component';
import { AlertService } from '../alert_service/alert.service';
import { ExcelService } from '../excel_service/excel.service';
import { FileSystemFileEntry } from 'ngx-file-drop';
import { ServiceDatatableComponent } from '../service_datatable/service-datatable.component';
import { UploadDatatableComponent } from '../../components/upload_datatable/upload_datatable.component';
import { conformToMask } from 'angular2-text-mask/dist/angular2TextMask';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import { ngxCsv } from 'ngx-csv/ngx-csv';

@Component({
    selector: 'send-survey',
    templateUrl: './send_survey.template.html',
    styleUrls:[
    '../../../vendor/libs/spinkit/spinkit.scss'
    ]
})

export class SendSurveyComponent implements OnInit{
    @Output() send_sms_event: EventEmitter<any> = new EventEmitter<any>();
    @Input() disable_groups:boolean;
    @Input() selected_group:any;
    @ViewChild('sms_form') sms_form: FormControlDirective;
    @ViewChild('sms_mensaje_original') sms_mensaje_original:ElementRef;
    @ViewChild('calendarized_form') calendarized_form: FormRendererComponent;
    public sms_send_type:number;
    public sms_loading:boolean;
    public sms_view:number;
    public sms_submitted:boolean;
    public sms_data = {
        sms_mensaje_original:"",
        nom_camp:"Encuesta de Satisfacción",
        tipo_campania:"",
        num_gest:"",
        cif:"",
        remitente:"",
        remitentegw:"",
        id_area:"",
        id_empresa:"",
        id_contacto:""
    }
    public search_group_word:string;

    public groups_loading:boolean;
    public groups_list:any[];
    public groups_selected:any[];

    @ViewChild('client_datatable_ref') client_datatable_ref: ServiceDatatableComponent;
    public clients_loading:boolean;
    public clients_list:any[];
    public clients_selected:any[];
    public client_datatable:any;

    @ViewChild('template_datatable_ref') template_datatable_ref: ServiceDatatableComponent;
    @ViewChild('template_modal') template_modal: ModalDirective;
    public template_loading:boolean;
    public template_list:any[];
    public template_selected:any[];
    public template_datatable:any;

    public plantillas_label_list:any[];
    public remitentes_list:any[];
    public areas_list:any[];
    public empresas_list:any[];
    public contactos_list:any[];

    public calendarized_dates:any[];
    public calendarized_inputs = [
        {
            class:"row",
            columns:[
                {
                    class:"col-md-12",
                    inputs:[
                        {
                            type:"calendar",
                            extra:"popup",
                            name:"sms_dia",
                            label:"",
                            icon:"",
                            class:"form-control form-control-sm",
                            placeholder:"",
                            minlength:null,
                            maxlength:null,
                            pattern:null,
                            error_required:"Requerido",
                            error_pattern:null,
                            error_minlength:"",
                            list_data:{
                                value:"",
                                text:""
                            },
                            list:()=>{
                                return []
                            },
                            textmask:()=>{
                                return false;
                            },
                            required:()=>{
                                return true;
                            },
                            disabled:()=>{
                                return !this.calendarized_sms;
                            },
                            change:(event)=>{
                            },
                            input:()=>{
                            }
                        }
                    ]
                },
                {
                    class:"col-md-12",
                    inputs:[
                        {
                            type:"time",
                            extra:"",
                            name:"sms_hora",
                            label:"",
                            icon:"",
                            class:"form-control form-control-sm",
                            placeholder:"",
                            minlength:null,
                            maxlength:null,
                            pattern:null,
                            error_required:"Requerido",
                            error_pattern:null,
                            error_minlength:"",
                            list_data:{
                                value:"",
                                text:""
                            },
                            list:()=>{
                                return []
                            },
                            textmask:()=>{
                                return false;
                            },
                            required:()=>{
                                return true;
                            },
                            disabled:()=>{
                                return !this.calendarized_sms;
                            },
                            change:(event)=>{
                            },
                            input:()=>{
                            }
                        }
                    ]
                }
            ]
        },
    ]

    @ViewChild('test_sms_form') test_sms_form: FormRendererComponent;
    public test_sms_submitted:boolean;
    public test_sms_view:number;
    public test_sms:string;
    public test_sms_mask = [/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/];

    @ViewChild('sms_vista_previa') sms_vista_previa: ModalDirective;
    public sms_preview_data = {
        text:"",
        url:"",
        remitente:"",
        hour:""
    }

    @ViewChild('survey_vista_previa') survey_vista_previa: ModalDirective;
    @ViewChild('logo_input') logo_input: any;
    public survey_data = {
        text_intro:"Estimado cliente",
        text_question:"¿Cómo calificarías el nivel de satisfacción de nuestros servicios?",
        text_comments:"Comentarios y Observaciones",
        text_close:"¡Muchas Gracias!\n\nTu retroalimentación es muy valiosa para nosotros",
        excellent:true,
        good:true,
        neutral:true,
        bad:true,
        terrible:true,
        comments_example:"",
        logo_data:null
    }

    public label_list: any[];

    @ViewChild('upload_files_modal') upload_files_modal: ModalDirective;
    @ViewChild('current_file_datatable_ref') current_file_datatable_ref: UploadDatatableComponent;
    public current_file_datatable: any;
    public upload_files_list: any[];
    public upload_files_ok_list: any[];
    public upload_files_formatted_list: any[];
    public upload_files_modal_view: number;
    public upload_files_loading: boolean;

    public current_time:Date;
    public calendarized_sms:boolean;
    public data_load:any;

    constructor(public endpoint : AppEndpoints, private alertService: AlertService, private renderer: Renderer2, private excelService: ExcelService) {
        this.sms_loading = false;
        this.sms_submitted = false;
        this.sms_view = 1;
        this.sms_send_type = 2;

        this.test_sms_submitted = false;
        this.test_sms = "";
        this.test_sms_view = 1;

        this.groups_loading = false;
        this.groups_list = [];
        this.groups_selected = [];
        this.search_group_word = "";

        this.clients_loading = false;
        this.clients_list = [];
        this.clients_selected = [];
        this.client_datatable = {
            title:"Clientes Disponibles",
            show_search:true,
            search_placeholder:"- Buscar -",
            object_description:"clientes",
            empty_text:"No se encontraron clientes",
            columns:[
                {   
                    column:"cli_nombre",
                    class:"col-8",
                    header:"Nombre Cliente",
                    type:"text"
                },
                {   
                    column:"cli_celular",
                    class:"col-4",
                    header:"Celular",
                    type:"text"
                }
            ],
            navigation_offsets:[5,10,15,20,25,50],
            show_search_field:true
        }

        this.template_loading = false;
        this.template_list = [];
        this.template_selected = [];
        this.template_datatable = {
            title:"",
            white_bg:true,
            show_search:true,
            search_placeholder:"- Buscar -",
            object_description:"plantillas",
            empty_text:"No se encontraron plantillas",
            columns:[
                {   
                    column:"categoria_nombre",
                    class:"w-25",
                    header:"Categoría",
                    type:"text"
                },
                {   
                    column:"templates_nombre",
                    class:"w-25",
                    header:"Plantilla",
                    type:"text"
                },
                {   
                    column:"sms_mensaje_original",
                    class:"w-50",
                    header:"Mensaje",
                    type:"text"
                }
            ],
            navigation_offsets:[5,10,15,20,25,50],
            show_search_field:true
        }


        this.plantillas_label_list = [];
        this.remitentes_list = [];
        this.areas_list = [];
        this.empresas_list = [];
        this.contactos_list = [];

        this.calendarized_dates = [];
        this.label_list = [];
        this.current_time = null;
        this.calendarized_sms = false;

        this.current_file_datatable = {
            object_description: "registros",
            header_options: ['cli_identidad', 'cli_nombre', 'cli_sexo', 'cli_fecha_nac', 'cli_celular', 'cli_telefono', 'cli_email', 'cli_direccion', 'cli_tarjeta', 'cli_campo1', 'cli_campo2', 'cli_campo3', 'cli_campo4', 'cli_campo5', 'cli_campo6', 'cli_campo7', 'cli_tarjeta', 'cli_mensaje', 'cli_cif', 'cli_cm'],
            empty_text: "No se encontraron registros en el listado",
            events: [
                {
                    name: "Eliminar Registro",
                    style: "color:#FB5D5D",
                    hover_style: "cursor:pointer; color:#FB5D5D; background-color:#FEDCDC !important;",
                    icon: "trash-alt"
                }
            ],
            navigation_offsets: [5, 10, 15, 20, 25, 50],
            navigation_starting_offset_index: 1,
            table_icon: "caret-right"
        }

        this.upload_files_formatted_list = [];
    }

    ngOnInit() {
        this.get_labels();
        this.get_template_labels();
        this.get_shortcodes();
        this.get_subresellers();
        this.get_current_time();
    }

    AfterViewInit(){
    }

    //########################################################################
    //SMS ####################################################################

        get_total_sms_count(data){
            this.sms_loading  = true;
            var response;
            this.endpoint.get_total_sms_count(data).subscribe(
                data => response = data,
                err => {
                    this.sms_loading  = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        var night_date = false;
                        for(var i = 0;i<this.calendarized_dates.length;i++){
                             var time = new Date(this.calendarized_dates[i].sms_dia + " " + this.calendarized_dates[i].sms_hora);
                             var hour = time.getHours();
                             if(time.getMinutes()>0){
                                 hour++;
                             }
                             if(hour>21 || hour<7){
                                 night_date = true;
                             }
                        }
                        if(this.calendarized_dates.length==0 && !this.calendarized_sms){
                            var hour = this.current_time.getHours();
                            if(this.current_time.getMinutes()>0){
                                 hour++;
                            }
                            if(hour>21 || hour<7){
                                 night_date = true;
                             }
                        }
                        var message = "" +
                        ((night_date)?"<br><h6 class='text-danger'>Su calendarización contiene envíos nocturnos</h6>":"") + 
                        "¿Está seguro que desea enviar el SMS a los clientes seleccionados?<br><br><b class='text-primary'>Total SMS a Enviar<br></b>"+
                        "<h3 style='border-bottom: solid 1px #dcdcdc;padding-bottom: 20px;'><b>" + response.total_sms_count.toLocaleString('es-HN') + "</b></h3>" +
                        "<div class='text-center' style='float:left;width:33%'>" +
                        "<p style='font-size:12px !important;margin-bottom:0px !important;' class='text-muted'><b>SMS Largo 1</b></p> " +
                        "<p style='font-size:12px !important;margin-bottom:0px !important;margin-right:10px;'><b>" + response.total_sms_count_length1.toLocaleString('es-HN') + "</b></p>" +
                        "</div>" +
                        "<div class='text-center' style='float:left;width:33%'>" +
                        "<p style='font-size:12px !important;margin-bottom:0px !important;' class='text-muted'><b>SMS Largo 2</b></p> " +
                        "<p style='font-size:12px !important;margin-bottom:0px !important;margin-right:10px;'><b>" + response.total_sms_count_length2.toLocaleString('es-HN') + "</b></p>" +
                        "</div>" +
                        "<div class='text-center' style='float:left;width:33%'>" +
                        "<p style='font-size:12px !important;margin-bottom:0px !important;' class='text-muted'><b>SMS Largo 3+</b></p> " +
                        "<p style='font-size:12px !important;margin-bottom:0px !important;margin-right:10px;'><b>" + response.total_sms_count_length3.toLocaleString('es-HN') + "</b></p>" +
                        "</div>";
                        var delete_db = "";
                        if(data.limpiar_miembros==1){
                            delete_db = "<br><br><b class='text-danger'>ADVERTENCIA: </b><b>La opción de eliminar clientes está seleccionada, las membresías y clientes de TODA LA BASE DE DATOS se eliminarán después de realizar el envío</b>";
                        }

                        this.alertService.option_alert("Enviar SMS", message + delete_db,"Sí, Enviar").then((result) => {
                            if(result.value){
                                this.send_sms_event.emit(data);
                            }
                        }).catch(()=>{return false;});
                        this.sms_loading  = false;
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.sms_loading  = false;
                    }
                }
            );
        }

        set_loading(value:boolean){
            this.sms_loading = value;
        }

        clean_sms_text(){
            this.sms_data.sms_mensaje_original = "";
        }

        get_shortcodes(){
            var response;
            this.endpoint.get_shortcodes().subscribe(
                data => response = data,
                err => {
                    this.remitentes_list = [];
                },
                ()=> {
                    try{
                        this.remitentes_list = [];
                        this.remitentes_list = response;
                        if(this.remitentes_list.length > 0){
                            this.sms_data.remitente = this.remitentes_list[0].id;
                        }
                    }catch(error){
                        this.remitentes_list = [];
                    }
                }
            );
        }

        get_subresellers(){
            var response;
            this.endpoint.get_subresellers().subscribe(
                data => response = data,
                err => {
                    this.areas_list = [];
                },
                ()=> {
                    try{
                        this.areas_list = [];
                        this.areas_list = response;
                        if(this.areas_list.length > 0){
                            this.sms_data.id_area = this.areas_list[0].id_subreseller;
                            if(this.areas_list.length > 0){
                                this.get_companies();
                                this.get_contacts();
                            }
                        }
                    }catch(error){
                        this.areas_list = [];
                    }
                }
            );
        }

        get_companies(){
            var response;
            this.endpoint.get_companies().subscribe(
                data => response = data,
                err => {
                    this.empresas_list = [];
                },
                ()=> {
                    try{
                        this.empresas_list = [];
                        this.empresas_list = response;
                        if(this.empresas_list.length > 0){
                            this.sms_data.id_empresa = this.empresas_list[0].id;
                        }
                    }catch(error){
                        this.empresas_list = [];
                    }
                }
            );
        }

        get_contacts(){
            var response;
            this.endpoint.get_contacts().subscribe(
                data => response = data,
                err => {
                    this.contactos_list = [];
                },
                ()=> {
                    try{
                        this.contactos_list = [];
                        this.contactos_list = response;
                        if(this.contactos_list.length > 0){
                            this.sms_data.id_contacto = this.contactos_list[0].id;
                        }
                    }catch(error){
                        this.contactos_list = [];
                    }
                }
            );
        }

        get_current_time(){
            var response;
            this.endpoint.get_current_time().subscribe(
                data => response = data,
                err => {
                    this.current_time = null;
                },
                ()=> {
                    try{
                        this.current_time = new Date(response.date);
                        setInterval(()=>{ 
                            this.current_time.setSeconds(this.current_time.getSeconds() + 1);
                        }, 1000);
                    }catch(error){
                        this.current_time = null;
                    }
                }
            );
        }

        trigger_calendarized(){
            this.calendarized_form.clean_form();
            if(!this.calendarized_sms){
                this.calendarized_dates = [];
            }
        }

        clean_calendarized_inputs(){
            this.calendarized_form.clean_form();
        }

        add_calendarized_day(){
            if(this.calendarized_form.valid()){
                var date = {
                    sms_dia:this.calendarized_form.get_values().sms_dia.split("/").reverse().join("-"),
                    sms_hora:this.calendarized_form.get_values().sms_hora,
                    string_time:this.time_pretty_print(this.calendarized_form.get_values().sms_hora),
                    string_date:this.calendarized_form.get_values().sms_dia
                }
                var time = new Date(this.calendarized_form.get_values().sms_dia.split("/").reverse().join("-") + " " + this.calendarized_form.get_values().sms_hora);
                time.setMinutes(time.getMinutes() - 1)
                if(time <= this.current_time){
                    this.alertService.alert_error("Fecha Incorrecta", "La fecha y hora calendarizada debe de ser como mínimo un minuto mayor a la fecha y hora actual");
                }else{
                    this.calendarized_dates.push(date);
                    this.calendarized_form.set_value("sms_dia","");
                }
            }
        }

        remove_calendarized_day(index){
            this.calendarized_dates.splice(index,1);
        }

        time_pretty_print(time){
            time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) {
                time = time.slice (1);
                time[5] = +time[0] < 12 ? ' AM' : ' PM';
                time[0] = +time[0] % 12 || 12;
            }
            return time.join ('');
        }

        clean_spaces(){
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/á/g, 'a');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/é/g, 'e');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/í/g, 'i');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/ó/g, 'o');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/ú/g, 'u');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/Á/g, 'A');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/É/g, 'E');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/Í/g, 'I');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/Ó/g, 'O');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/Ú/g, 'U');
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.trim();
            for(var i = this.sms_data.sms_mensaje_original.length-1;i>=0;i--){
                var is_valid = false;
                for(var j = 0;j<this.endpoint.get_sms_allowed_characters_full().length;j++){
                    if(this.sms_data.sms_mensaje_original[i]==this.endpoint.get_sms_allowed_characters_full()[j]){
                        is_valid = true;
                        break;
                    }
                }
                if(!is_valid){
                    this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.slice(0,i) + this.sms_data.sms_mensaje_original.slice(i+1);
                }
            }
        }

        validate_sms_caracters(){
            var chars = [];
            for(var i = 0;i<this.sms_data.sms_mensaje_original.length;i++){
                var is_valid = false;
                for(var j = 0;j<this.endpoint.get_sms_allowed_characters_full().length;j++){
                    if(this.sms_data.sms_mensaje_original[i]==this.endpoint.get_sms_allowed_characters_full()[j]){
                        is_valid = true;
                        break;
                    }
                }
                if(!is_valid){
                    chars.push("<b style='margin-right:10px;'>" + this.sms_data.sms_mensaje_original[i] + "</b>");
                }
            }
            return chars;
        }

        validate_input(event){
            const key = (event.which) ? event.which : event.keyCode;
            return true;
        }

        remove_double_spaces(){
            this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ').trim();
        }

        sms_preview(){
            var mensaje_original = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ');
            var mensaje_parsed = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ') + " ";
            for(var j = 0;j<this.plantillas_label_list.length;j++){
                var alias_to_replace = new RegExp("\#" + this.plantillas_label_list[j].var_alias + " ","g");
                var field_to_insert = "$" + this.plantillas_label_list[j].var_campo + " ";
                mensaje_parsed = mensaje_parsed.replace(alias_to_replace,field_to_insert);
            }
            var bad_chars = this.validate_sms_caracters();
            if(bad_chars.length > 0){
                var error_text = ["Caracteres: " + bad_chars.join(", ")];
                this.alertService.alert_multiple_error("Error de Caracteres", "Se encontraron caracteres no permitidos dentro del mensaje", error_text);
            }else{
                var shortcode ="";
                for(var m = 0;m<this.remitentes_list.length;m++){
                    if(this.remitentes_list[m].id == this.sms_data.remitente){
                        shortcode = this.remitentes_list[m].short_shortcode;
                    }
                };
                this.sms_preview_data.remitente = shortcode;
                this.sms_preview_data.text = mensaje_parsed + "\n\n";
                this.sms_preview_data.url = "URL: https://encuestas.impactmobile.net/xxxxxxxxxx";
                this.sms_preview_data.hour = (new Date()).toLocaleTimeString('es-hn',{ hour: '2-digit', minute: '2-digit', hour12: true });

                this.sms_vista_previa.show();
            }
        }

        survey_preview(){
            this.survey_vista_previa.show();
        }

        upload_image_add(event){
            let fileList: FileList = event.target.files;
            if(fileList.length > 0) {
                let file: File = fileList[0];
                var input = event.target;

                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                reader.onload = (data) => {
                    try{
                        this.survey_data.logo_data = reader.result;
                        this.logo_input.nativeElement.value = "";
                    }catch(err){
                        swal({
                            title: "Error al Cargar Archivo",
                            text: "Se produjo un error al intentar cargar el archivo, favor verificar que el formato del archivo sea el correcto",
                            type: "warning",
                            allowOutsideClick: false
                        }).catch(swal.noop)
                        this.logo_input.nativeElement.value = "";
                    }
                }
                reader.onerror = function () {
                    swal({
                        title: "Error al Cargar Archivo",
                        text: "Se produjo un error al intentar cargar el archivo, favor verificar que el formato del archivo sea el correcto",
                        type: "warning",
                        allowOutsideClick: false
                    }).catch(swal.noop)
                };
            }
        }

        remove_logo(){
            this.survey_data.logo_data = null;
            this.logo_input.nativeElement.value = "";
        }

    //SMS ####################################################################
    //########################################################################

    //########################################################################
    //CLIENTS ################################################################

        set_clients_view(){
            if(this.sms_send_type!=1){
                this.client_datatable_ref.set_show_length(5);
                this.sms_send_type = 1;
            }
        }

        client_datatable_events(event){
            var exists = false;
            for(var i = 0;i<this.clients_selected.length;i++){
                if(this.clients_selected[i].id==event.data.id){
                    exists = true;
                }
            }
            if(!exists){
                this.clients_selected.push(event.data);
            }
        }

        client_remove(index){
            this.clients_selected.splice(index, 1);
        }

        client_datatable_get_results_offset_change(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                search_word:data.search_word
            }
            this.endpoint.get_clients_short(load).subscribe(
                data => response = data,
                err => {
                    this.client_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.client_datatable_ref.set_results_offset_change(response.list);
                    }catch(error){
                        this.client_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                    
                }
            );
        }

        client_datatable_get_results_filter_change(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                search_word:data.search_word
            }
            this.endpoint.get_clients_short(load).subscribe(
                data => response = data,
                err => {
                    this.client_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.client_datatable_ref.set_results_filter_change(response.list, response.count);
                    }catch(error){
                        this.client_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

        client_datatable_get_results_update_list(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                search_word:data.search_word
            }
            this.endpoint.get_clients_short(load).subscribe(
                data => response = data,
                err => {
                    this.client_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.client_datatable_ref.set_results_update_list(response.list, response.count);
                    }catch(error){
                        this.client_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

    //CLIENTS ################################################################
    //########################################################################

    //########################################################################
    //GROUPS #################################################################

        set_groups_view(){
            if(this.sms_send_type!=2){
                this.sms_send_type = 2;  
            }
        }

        get_groups_for_sms(){
            this.sms_loading = true;
            var response;
            this.endpoint.get_groups_for_sms().subscribe(
                data => response = data,
                err => {
                    this.groups_list = [];
                    this.groups_selected = [];
                    this.sms_loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.groups_list = [];
                        this.groups_selected = [];
                        for(var i = 0;i<response.length;i++){
                            var exists = false;
                            for(var j = 0;j<this.groups_list.length;j++){
                                if(response[i].grp_id == this.groups_list[j].grp_id){
                                    exists = true;
                                    this.groups_list[j].subgroups.push(response[i]);
                                }
                            }
                            if(!exists){
                                this.groups_list.push({
                                    grp_id:response[i].grp_id,
                                    grp_nombre:response[i].grp_nombre,
                                    subgroups:[response[i]],
                                    extended:false
                                })
                            }
                        }
                        this.sms_loading = false;
                    }catch(error){
                        this.sms_loading = false;
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

        select_group(group){
            var exists = false;
            for(var j = 0;j<this.groups_selected.length;j++){
                if(group.grp_id == this.groups_selected[j].grp_id){
                    exists = true;
                    var sbgrp_exists = false;
                    for(var i = 0 ;i<this.groups_selected[j].subgroups.length;i++){
                        if(this.groups_selected[j].subgroups[i].id==group.id){
                            sbgrp_exists = true;
                        }
                    }
                    if(!sbgrp_exists){
                        this.groups_selected[j].subgroups.push(group);
                    }
                }
            }
            if(!exists){
                this.groups_selected.push({
                    grp_id:group.grp_id,
                    grp_nombre:group.grp_nombre,
                    subgroups:[group]
                })
            }
        }

        remove_group(grp_index,sgrp_index){
            this.groups_selected[grp_index].subgroups.splice(sgrp_index,1);
            if(this.groups_selected[grp_index].subgroups.length==0){
               this.groups_selected.splice(grp_index,1); 
            }
        }

        count_clients(){
            var count = 0;
            for(var j = 0;j<this.groups_selected.length;j++){
                for(var i = 0 ;i<this.groups_selected[j].subgroups.length;i++){
                    count = count + this.groups_selected[j].subgroups[i].total_clientes;
                }
            }
            return count.toLocaleString('en-us');
        }

        search_count_clients(){
            var count = 0;
            for(var j = 0;j<this.groups_list.length;j++){
                for(var i = 0 ;i<this.groups_list[j].subgroups.length;i++){
                    if(this.groups_list[j].subgroups[i].id==this.selected_group.id){
                        count = count + this.groups_list[j].subgroups[i].total_clientes;
                    }
                }
            }
            return count.toLocaleString('en-us');
        }

        remove_all_groups(){
            this.groups_selected = [];
        }

    //GROUPS #################################################################
    //########################################################################

    //########################################################################
    //TEMPLATES ##############################################################

        get_template_labels(){
            var response;
            this.sms_loading = true;
            this.endpoint.get_labels().subscribe(
                data => response = data,
                err => {
                    this.plantillas_label_list = [];
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                    this.sms_loading = false;
                },
                ()=> {
                    try{
                        this.plantillas_label_list = [];    
                        for(var i = 0;i<response.length;i++){
                            if(response[i].var_alias){
                                this.plantillas_label_list.push(response[i])
                            }
                        }
                        this.get_groups_for_sms();
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.sms_loading = false;
                    }
                }
            );
        }

        add_alias(alias){
            if((this.sms_data.sms_mensaje_original + " #" + alias + " ").length > 320){
                if((this.sms_data.sms_mensaje_original + " #" + alias).length == 320){
                    this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original + " #" + alias;
                    this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ')
                }
            }else if(this.sms_data.sms_mensaje_original.length == 0){
                this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original + "#" + alias + " ";
                this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ')
            }else{
                this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original + " #" + alias + " ";
                this.sms_data.sms_mensaje_original = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ')
            }
            this.sms_mensaje_original.nativeElement.focus();
        }

        open_templates(){
            this.template_modal.show();
            this.template_datatable_ref.set_show_length(5);
        }

        template_datatable_get_results_offset_change(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                search_word:data.search_word
            }
            this.endpoint.get_templates(load).subscribe(
                data => response = data,
                err => {
                    this.template_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.template_datatable_ref.set_results_offset_change(response.list);
                    }catch(error){
                        this.template_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                    
                }
            );
        }

        template_datatable_get_results_filter_change(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                search_word:data.search_word
            };
            this.endpoint.get_templates(load).subscribe(
                data => response = data,
                err => {
                    this.template_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.template_datatable_ref.set_results_filter_change(response.list, response.count);
                    }catch(error){
                        this.template_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

        template_datatable_get_results_update_list(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                search_word:data.search_word
            }
            this.endpoint.get_templates(load).subscribe(
                data => response = data,
                err => {
                    this.template_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.template_datatable_ref.set_results_update_list(response.list, response.count);
                    }catch(error){
                        this.template_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

        template_datatable_events(event){
            this.sms_data.sms_mensaje_original = event.data.sms_mensaje_original;
            this.template_modal.hide();
        }

    //TEMPLATES ##############################################################
    //########################################################################

    //########################################################################
    //TEST SMS ###############################################################

        send_test_sms(){
            this.test_sms_view = 1;
            this.upload_files_loading = false;
            if(this.test_sms_form.valid){
                this.test_sms_submitted = false;
                localStorage.setItem("SMS_TEST_NUMBER_REF", this.test_sms);
                var mensaje_original = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ');
                var mensaje_parsed = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ') + " ";
                for(var j = 0;j<this.plantillas_label_list.length;j++){
                    var alias_to_replace = new RegExp("\#" + this.plantillas_label_list[j].var_alias + " ","g");
                    var field_to_insert = "$" + this.plantillas_label_list[j].var_campo + " ";
                    mensaje_parsed = mensaje_parsed.replace(alias_to_replace,field_to_insert);
                }
                var bad_chars = this.validate_sms_caracters();
                if(bad_chars.length > 0){
                    var error_text = ["Caracteres: " + bad_chars.join(", ")];
                    this.alertService.alert_multiple_error("Error de Caracteres", "Se encontraron caracteres no permitidos dentro del mensaje", error_text);
                    return false;
                }else{
                    var shortcode ="";
                    for(var m = 0;m<this.remitentes_list.length;m++){
                        if(this.remitentes_list[m].id == this.sms_data.remitente){
                            shortcode = this.remitentes_list[m].short_shortcode;
                        }
                    };
                    var data = {
                        sms_mensaje_original:mensaje_original.trim(),
                        sms_mensaje_parseado:mensaje_parsed.trim(),
                        nom_camp:"SMS Prueba - Encuesta",
                        tipo_campania:this.sms_data.tipo_campania?this.sms_data.tipo_campania:0,
                        num_gest:this.sms_data.num_gest,
                        cif:this.sms_data.cif,
                        remitente:this.sms_data.remitente,
                        remitentegw:shortcode,
                        id_area:this.sms_data.id_area,
                        id_empresa:this.sms_data.id_empresa?this.sms_data.id_empresa:0,
                        id_contacto:this.sms_data.id_contacto?this.sms_data.id_contacto:0,
                        groups_selected:this.data_load.groups_selected,
                        clients_selected:[], 
                        calendarized_dates:[],
                        calendarized_sms:false,
                        sms_send_type:1,
                        limpiar_miembros:0,
                        test_number:this.test_sms.replace(/-/g, ''),
                        test_url:this.data_load.test_url
                    }
                    var response;
                    this.upload_files_loading = true;
                    this.endpoint.send_test_survey_sms(data).subscribe(
                        data => response = data,
                        err => {
                            this.upload_files_loading = false;
                            if(err.status && err.error){
                                this.alertService.alert_message(err.status ,err.error);
                            }else{
                                this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                            }
                        },
                        ()=> {
                            try{
                                this.test_sms_view = 2;
                                this.upload_files_loading = false;
                            }catch(error){
                                this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                                this.upload_files_loading = false;
                            }
                        }
                    );
                }
            }else{
                this.test_sms_submitted = true;
            }
        }

    //TEST SMS ###############################################################
    //########################################################################

    //########################################################################
    //UTILS ##################################################################

        download_error_exel(response, raw_headers) {
            var headers = [];
            for (var j = 0; j < raw_headers.length; j++) {
                headers.push(raw_headers[j]);
            }
            headers.push("cli_errores");
            var options = {
                showLabels: true,
                headers: headers
            };
            for (var i = 0; i < response.length; i++) {
                for (var j = 0; j < response[i].length; j++) {
                    if (!response[i][j]) {
                        response[i][j] = "";
                    }
                }
            }
            new ngxCsv(response, 'My Report', options);
        }

        download_success_excel(response, raw_headers) {
            var headers = [];
            for (var j = 0; j < raw_headers.length; j++) {
                headers.push(raw_headers[j]);
            }
            var options = {
                showLabels: true,
                headers: headers
            };
            for (var i = 0; i < response.length; i++) {
                for (var j = 0; j < response[i].length; j++) {
                    if (!response[i][j]) {
                        response[i][j] = "";
                    }
                }
            }
            new ngxCsv(response, 'My Report', options);
        }

        get_labels() {
            var response;
            this.endpoint.get_labels().subscribe(
                data => response = data,
                err => {
                    this.label_list = [];
                    if (err.status && err.error) {
                        this.alertService.alert_message(err.status, err.error);
                    } else {
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                () => {
                    this.label_list = [];
                    this.label_list = response;
                }
            );
        }

        validate_is_header(row) {
            var is_header = true;
            for (var i = 0; i < row.length; i++) {
                if ((row[i] + "") &&
                    !(row[i] + "").includes('cli_identidad') &&
                    !(row[i] + "").includes('cli_nombre') &&
                    !(row[i] + "").includes('cli_sexo') &&
                    !(row[i] + "").includes('cli_fecha_nac') &&
                    !(row[i] + "").includes('cli_celular') &&
                    !(row[i] + "").includes('cli_telefono') &&
                    !(row[i] + "").includes('cli_email') &&
                    !(row[i] + "").includes('cli_direccion') &&
                    !(row[i] + "").includes('cli_tarjeta') &&
                    !(row[i] + "").includes('cli_campo1') &&
                    !(row[i] + "").includes('cli_campo2') &&
                    !(row[i] + "").includes('cli_campo3') &&
                    !(row[i] + "").includes('cli_campo4') &&
                    !(row[i] + "").includes('cli_campo5') &&
                    !(row[i] + "").includes('cli_campo6') &&
                    !(row[i] + "").includes('cli_campo7') &&
                    !(row[i] + "").includes('cli_mensaje') &&
                    !(row[i] + "").includes('cli_cif') &&
                    !(row[i] + "").includes('cli_errores') &&
                    !(row[i] + "").includes('cli_cm')){
                    is_header = false;
                }
            }
            return is_header;
        }

        export_format_to_excel() {
            var headers = [];
            for (var i = 0; i < this.label_list.length; i++) {
                if (this.label_list[i].var_alias && this.label_list[i].var_campo) {
                    headers.push({
                        label: this.label_list[i].var_alias,
                        field: this.label_list[i].var_campo

                    });
                }
            }
            this.excelService.upload_format_to_excel(headers, "formato_carga_clientes");
        }

        open_upload_files() {
            if(((this.calendarized_sms && this.calendarized_dates.length>0) || !this.calendarized_sms) && this.sms_form.valid && this.sms_data.sms_mensaje_original.length > 0){
                var mensaje_original = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ');
                var mensaje_parsed = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ') + " ";
                for(var j = 0;j<this.plantillas_label_list.length;j++){
                    var alias_to_replace = new RegExp("\#" + this.plantillas_label_list[j].var_alias + " ","g");
                    var field_to_insert = "$" + this.plantillas_label_list[j].var_campo + " ";
                    mensaje_parsed = mensaje_parsed.replace(alias_to_replace,field_to_insert);
                }
                var bad_chars = this.validate_sms_caracters();
                if(bad_chars.length > 0){
                    var error_text = ["Caracteres: " + bad_chars.join(", ")];
                    this.alertService.alert_multiple_error("Error de Caracteres", "Se encontraron caracteres no permitidos dentro del mensaje", error_text);
                    return false;
                }else{
                    var dates_errors = [];
                    for(var r = 0;r<this.calendarized_dates.length;r++){
                        var time = new Date(this.calendarized_dates[r].sms_dia + " " + this.calendarized_dates[r].sms_hora);
                        time.setMinutes(time.getMinutes() - 1)
                        if(time <= this.current_time){
                            dates_errors.push("Hora de Calendarización: " +  this.calendarized_dates[r].string_date + " " + this.calendarized_dates[r].string_time);
                        }
                    }
                    if(dates_errors.length>0){
                        this.alertService.alert_multiple_error("Error de Fechas", "La fecha y hora calendarizada debe de ser como mínimo un minuto mayor a la fecha y hora actual", dates_errors);
                    }else{
                        var shortcode ="";
                        for(var m = 0;m<this.remitentes_list.length;m++){
                            if(this.remitentes_list[m].id == this.sms_data.remitente){
                                shortcode = this.remitentes_list[m].short_shortcode;
                            }
                        };
                        this.upload_files_loading = false;
                        this.upload_files_modal_view = 1;
                        this.upload_files_list = [];
                        this.upload_files_ok_list = [];
                        this.upload_files_formatted_list = [];
                        this.data_load = {
                            sms_mensaje_original:mensaje_original.trim(),
                            sms_mensaje_parseado:mensaje_parsed.trim(),
                            nom_camp:"Encuesta de Satisfacción",
                            tipo_campania:0,
                            num_gest:"",
                            cif:"",
                            remitente:this.sms_data.remitente,
                            remitentegw:shortcode,
                            id_area:this.sms_data.id_area,
                            id_empresa:this.sms_data.id_empresa?this.sms_data.id_empresa:0,
                            id_contacto:this.sms_data.id_contacto?this.sms_data.id_contacto:0,
                            groups_selected:[],
                            clients_selected:[], 
                            calendarized_dates:this.calendarized_dates,
                            calendarized_sms:this.calendarized_sms,
                            sms_send_type:2,
                            limpiar_miembros:0,
                            text_intro:this.survey_data.text_intro,
                            text_question:this.survey_data.text_question,
                            text_comments:this.survey_data.text_comments,
                            text_close:this.survey_data.text_close,
                            excellent:this.survey_data.excellent,
                            good:this.survey_data.good,
                            neutral:this.survey_data.neutral,
                            bad:this.survey_data.bad,
                            terrible:this.survey_data.terrible,
                            logo_data:this.survey_data.logo_data,
                            subgroup_id:"",
                            survey_id:"",
                            test_url:""
                        }
                        this.upload_files_modal.show();
                    }
                }
            }else{
                this.alertService.alert_error("Error de Formulario", "Se encontraron errores en el formulario de envío de SMS");
                this.sms_submitted = true;
            }
        }

        close_upload_files() {
            this.upload_files_list = [];
            this.upload_files_ok_list = [];
            this.upload_files_formatted_list = [];
            this.current_file_datatable_ref.set_results([]);
            this.current_file_datatable_ref.set_headers([]);
            this.upload_files_modal.hide();
        }

        close_upload_files_confirmation() {
            this.alertService.option_alert("Cancelar Campaña", "¿Está seguro que desea cancelar el envío de Campaña de Encuestas?", "Sí, Cancelar").then((result) => {
                if (result.value) {
                    this.upload_files_list = [];
                    this.upload_files_ok_list = [];
                    this.upload_files_modal.hide();
                    this.current_file_datatable_ref.set_results([]);
                    this.current_file_datatable_ref.set_headers([]);
                }
            }).catch(() => { return false; });
        }

        file_count_valid() {
            var total = 0;
            for (var i = 0; i < this.upload_files_list.length; i++) {
                if (this.upload_files_list[i].status == "OK") {
                    total++;
                }
            }
            if (total > 0) {
                return true;
            } else {
                return false;
            }
        }

        drop_file(data) {
            if(data.files.length > 1){
                this.alertService.alert_error("Error", "Sólo se puede cargar 1 archivo al momento de enviar encuestas de satisfacción");
            }else{
                this.upload_files_list = [];
                if (data.files.length > 0) {
                    this.upload_files_loading = true;
                }
                if (data.files[0].fileEntry.isFile) {
                    var fileEntry = data.files[0].fileEntry as FileSystemFileEntry;
                    fileEntry.file((file: File) => {
                        try {
                            if (file.name.substring(0, 2) != "~$" && (file.name.split(".")[file.name.split(".").length - 1] == "csv" || file.name.split(".")[file.name.split(".").length - 1] == "xls" || file.name.split(".")[file.name.split(".").length - 1] == "xlsx")) {
                                var reader = new FileReader();
                                reader.readAsBinaryString(file);
                                reader.onload = (loaded_data) => {
                                    const binary_string = reader.result;
                                    const wb: XLSX.WorkBook = XLSX.read(binary_string, { type: 'binary' });
                                    const wsname: string = wb.SheetNames[0];
                                    const ws: XLSX.WorkSheet = wb.Sheets[wsname];
                                    var list_ref_temp: any[] = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
                                    var list_ref = [];
                                    for(var p = 0;p<list_ref_temp.length;p++){
                                        if(list_ref_temp[p] && list_ref_temp[p] instanceof Array && list_ref_temp[p].length > 0){
                                            list_ref.push(list_ref_temp[p]);
                                        }
                                    }
                                    if (list_ref.length == 0) {
                                        this.upload_files_list.push({ name: file.name, status: "ERROR", status_message: "archivo vacío", values: [], length: 0 });
                                    } else if (list_ref.length == 1) {
                                        if (this.validate_is_header(list_ref[0])) {
                                            this.upload_files_list.push({ name: file.name, status: "ERROR", status_message: "archivo vacío", values: [], length: 0 });
                                        } else {
                                            var max = -Infinity;
                                            list_ref.forEach(function (a) {
                                                if (a.length > max) {
                                                    max = a.length;
                                                }
                                            });
                                            if (max > 19) {
                                                this.upload_files_list.push({ name: file.name, status: "ERROR", status_message: "archivo excede 20 columnas", values: [], length: 0 });
                                            } else {
                                                this.upload_files_list.push({ name: file.name, status: "OK", values: list_ref, length: 1 });
                                            }

                                        }
                                    } else {
                                        if (this.validate_is_header(list_ref[0])) {
                                            var max = -Infinity;
                                            list_ref.forEach(function (a) {
                                                if (a.length > max) {
                                                    max = a.length;
                                                }
                                            });
                                            if (max > 20) {
                                                this.upload_files_list.push({ name: file.name, status: "ERROR", status_message: "archivo excede 20 columnas", values: [], length: 0 });
                                            } else {
                                                this.upload_files_list.push({ name: file.name, status: "OK", values: list_ref, length: list_ref.length - 1 });
                                            }

                                        } else {
                                            var max = -Infinity;
                                            list_ref.forEach(function (a) {
                                                if (a.length > max) {
                                                    max = a.length;
                                                }
                                            });
                                            if (max > 20) {
                                                this.upload_files_list.push({ name: file.name, status: "ERROR", status_message: "archivo excede 20 columnas", values: [], length: 0 });
                                            } else {
                                                this.upload_files_list.push({ name: file.name, status: "OK", values: list_ref, length: list_ref.length });
                                            }
                                        }

                                    }
                                    this.upload_files_loading = false;
                                }
                                reader.onerror = () => {
                                    this.upload_files_list.push({ name: file.name, status: "ERROR", status_message: "error de carga", values: [], length: 0 });
                                    this.upload_files_loading = false;
                                };
                            } else {
                                this.upload_files_list.push({ name: file.name, status: "ERROR", status_message: "extensión no permitida", values: [], length: 0 });
                                this.upload_files_loading = false;
                            }
                        } catch (err) {
                            this.upload_files_list.push({ name: file.name, status: "ERROR", status_message: "error de carga", values: [], length: 0 });
                            this.upload_files_loading = false;
                        }
                    });
                }
            }
        }

        remove_file(index) {
            this.upload_files_list.splice(index, 1);
        }

        to_valid_header(row) {
            var headers = [];
            for (var i = 0; i < row.length; i++) {
                if ((row[i] + "").includes('cli_identidad')) {
                    headers.push('cli_identidad');
                } else if ((row[i] + "").includes('cli_nombre')) {
                    headers.push('cli_nombre');
                } else if ((row[i] + "").includes('cli_sexo')) {
                    headers.push('cli_sexo');
                } else if ((row[i] + "").includes('cli_fecha_nac')) {
                    headers.push('cli_fecha_nac');
                } else if ((row[i] + "").includes('cli_celular')) {
                    headers.push('cli_celular');
                } else if ((row[i] + "").includes('cli_telefono')) {
                    headers.push('cli_telefono');
                } else if ((row[i] + "").includes('cli_email')) {
                    headers.push('cli_email');
                } else if ((row[i] + "").includes('cli_direccion')) {
                    headers.push('cli_direccion');
                } else if ((row[i] + "").includes('cli_tarjeta')) {
                    headers.push('cli_tarjeta');
                } else if ((row[i] + "").includes('cli_campo1')) {
                    headers.push('cli_campo1');
                } else if ((row[i] + "").includes('cli_campo2')) {
                    headers.push('cli_campo2');
                } else if ((row[i] + "").includes('cli_campo3')) {
                    headers.push('cli_campo3');
                } else if ((row[i] + "").includes('cli_campo4')) {
                    headers.push('cli_campo4');
                } else if ((row[i] + "").includes('cli_campo5')) {
                    headers.push('cli_campo5');
                } else if ((row[i] + "").includes('cli_campo6')) {
                    headers.push('cli_campo6');
                } else if ((row[i] + "").includes('cli_campo7')) {
                    headers.push('cli_campo7');
                } else if ((row[i] + "").includes('cli_mensaje')) {
                    headers.push('cli_mensaje');
                } else if ((row[i] + "").includes('cli_cif')) {
                    headers.push('cli_cif');
                } else if ((row[i] + "").includes('cli_cm')) {
                    headers.push('cli_cm');
                } else if ((row[i] + "").includes('cli_errores')) {
                    headers.push('eliminar_columna');
                } else {
                    headers.push('');
                }
            }
            return headers;
        }

        open_assign_fields_columns() {
            try {
                this.upload_files_ok_list = [];
                for (var i = 0; i < this.upload_files_list.length; i++) {
                    if (this.upload_files_list[i].status == "OK") {
                        var max = -Infinity;
                        this.upload_files_list[i].values.forEach(function (a) {
                            if (a.length > max) {
                                max = a.length;
                            }
                        });
                        for (var j = 0; j < this.upload_files_list[i].values.length; j++) {
                            for (var k = this.upload_files_list[i].values[j].length; k < max; k++) {
                                this.upload_files_list[i].values[j].push('');
                            }
                        }
                        if (this.validate_is_header(this.upload_files_list[i].values[0])) {
                            var headers = [];
                            headers = JSON.parse(JSON.stringify(this.upload_files_list[i].values[0]));
                            this.upload_files_list[i].values.splice(0, 1);
                            var headers_ref = this.to_valid_header(headers);
                            var columns = this.upload_files_list[i].values.reduce(
                                (r, a) => (a.forEach((v, c) => r[c] = r[c] || v), r),
                                []
                            )
                            var empty_columns = [];
                            for (var d = 0; d < columns.length; d++) {
                                if (columns[d]) {
                                    empty_columns.push(true);
                                } else {
                                    empty_columns.push(false);
                                }
                            }
                            for (var l = empty_columns.length - 1; l >= 0; l--) {
                                if (!empty_columns[l]) {
                                    headers_ref.splice(l, 1);
                                }
                            }
                            for (var p = 0; p < this.upload_files_list[i].values.length; p++) {
                                for (var l = empty_columns.length - 1; l >= 0; l--) {
                                    if (!empty_columns[l]) {
                                        this.upload_files_list[i].values[p].splice(l, 1);
                                    }
                                }
                            }
                            this.upload_files_ok_list.push({
                                values: {
                                    data: this.upload_files_list[i].values,
                                    max: max,
                                    headers: headers_ref
                                },
                                name: this.upload_files_list[i].name
                            });
                        } else {
                            var headers = [];
                            var columns = this.upload_files_list[i].values.reduce(
                                (r, a) => (a.forEach((v, c) => r[c] = r[c] || v), r),
                                []
                            )
                            var empty_columns = [];
                            for (var d = 0; d < columns.length; d++) {
                                if (columns[d]) {
                                    empty_columns.push(true);
                                } else {
                                    empty_columns.push(false);
                                }
                            }
                            for (var p = 0; p < this.upload_files_list[i].values.length; p++) {
                                for (var l = empty_columns.length - 1; l >= 0; l--) {
                                    if (!empty_columns[l]) {
                                        this.upload_files_list[i].values[p].splice(l, 1);
                                    }
                                }
                            }
                            for (var p = 0; p < this.upload_files_list[i].values[0].length; p++) {
                                headers.push('');
                            }
                            this.upload_files_ok_list.push({
                                values: {
                                    data: this.upload_files_list[i].values,
                                    max: max,
                                    headers: headers
                                },
                                name: this.upload_files_list[i].name
                            });
                        }
                    }
                }
                if (this.upload_files_ok_list.length > 0) {
                    this.upload_files_loading = false;
                    this.upload_files_modal_view = 2;
                    this.current_file_datatable_ref.set_results(this.upload_files_ok_list[0].values.data);
                    this.current_file_datatable_ref.set_headers(this.upload_files_ok_list[0].values.headers);
                    this.upload_files_formatted_list = [];
                } else {
                    this.alertService.alert_error("Error", "No se han cargado archivos válidos");
                }
            } catch (error) {
                this.alertService.alert_error("Error de Lectura", "Error al intentar leer los archivos proporcionados");
            }
        }

        current_file_datatable_events(event) {
            if (event.event == 'Eliminar Registro') {
                this.current_file_datatable_ref.remove_value(event.index);
            }
        }

        set_assign_columns() {
            if(this.current_file_datatable_ref.valid_headers()){
                this.upload_files_formatted_list = [];
                this.upload_files_formatted_list.push({ raw_headers: [], progress: 0, error_data: [], success_data: [], data: this.current_file_datatable_ref.get_results(), filename: this.upload_files_ok_list[0].name, groups: [] });
                this.upload_files_modal_view = 3;
            }
        }

        insert_group(){
            this.upload_files_loading = false;
            var response;
            this.endpoint.insert_group_for_satisfaction_survey(this.data_load).subscribe(
                data => response = data,
                err => {
                    this.upload_files_loading = false;
                    this.upload_files_modal_view = 9;
                },
                ()=> {
                    try{
                        this.data_load.subgroup_id = response.subgroup_id;
                        this.data_load.groups_selected = [{subgroups:[{id:response.subgroup_id}]}]
                        this.data_load.survey_id = response.survey_id;
                        this.data_load.test_url = response.test_url;
                        this.upload_list(0, 0);

                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.upload_files_loading = false;
                    }
                }
            );
        }

        upload_clients() {
            this.alertService.option_alert_upload("Cargar Registros", "¿Está seguro que desea iniciar la carga de registros al sistema? Esto puede tomar varios minutos.", "Sí, Cargar").then((result) => {
                if (result.value) {
                    this.upload_files_modal_view = 4;
                    var response;
                    this.insert_group();
                }
            }).catch(() => { return false; });
        }

        upload_list(index, offset) {
            var offset_range = 1000;
            if (index < this.upload_files_formatted_list.length) {
                var load = {
                    subgroup_id: this.data_load.subgroup_id,
                    survey_id: this.data_load.survey_id,
                    data: [],
                    headers: this.upload_files_formatted_list[index].data.headers,
                    labels: this.label_list,

                }
                if (this.upload_files_formatted_list[index].data.results.length - offset > 0) {
                    if (this.upload_files_formatted_list[index].data.results.length - offset <= offset_range) {
                        for (var i = offset; i < this.upload_files_formatted_list[index].data.results.length; i++) {
                            load.data.push(this.upload_files_formatted_list[index].data.results[i]);
                        }
                    } else {
                        for (var i = offset; i < offset + offset_range; i++) {
                            load.data.push(this.upload_files_formatted_list[index].data.results[i]);
                        }
                    }
                    var response;
                    this.endpoint.upload_clients_for_satisfaction_survey(load).subscribe(
                        data => response = data,
                        err => {
                            this.upload_files_loading = false;
                            this.upload_files_modal_view = 9;
                        },
                        () => {
                            if (response.error_data) {
                                if (response.error_data instanceof Array) {
                                    for (var m = 0; m < response.error_data.length; m++) {
                                        this.upload_files_formatted_list[index].error_data.push(response.error_data[m]);
                                    }
                                }
                            }
                            if (response.valid_data) {
                                if (response.valid_data instanceof Array) {
                                    for (var m = 0; m < response.valid_data.length; m++) {
                                        this.upload_files_formatted_list[index].success_data.push(response.valid_data[m]);
                                    }
                                }
                            }
                            if (this.upload_files_formatted_list[index].data.results.length > 0) {
                                this.upload_files_formatted_list[index].progress = Math.ceil(((this.upload_files_formatted_list[index].success_data.length + this.upload_files_formatted_list[index].error_data.length) / this.upload_files_formatted_list[index].data.results.length) * 100);
                            }
                            if (response.headers) {
                                this.upload_files_formatted_list[index].raw_headers = response.headers
                            }
                            this.upload_list(index, offset + offset_range);
                        }
                    );

                } else {
                    this.upload_list(index + 1, 0);
                }
            } else {
                this.upload_files_modal_view = 5;
            }
        }

        got_to_preview_sms(){
            var mensaje_original = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ');
            var mensaje_parsed = this.sms_data.sms_mensaje_original.replace(/  +/g, ' ') + " ";
            for(var j = 0;j<this.plantillas_label_list.length;j++){
                var alias_to_replace = new RegExp("\#" + this.plantillas_label_list[j].var_alias + " ","g");
                var field_to_insert = "$" + this.plantillas_label_list[j].var_campo + " ";
                mensaje_parsed = mensaje_parsed.replace(alias_to_replace,field_to_insert);
            }
            var bad_chars = this.validate_sms_caracters();
            if(bad_chars.length > 0){
                var error_text = ["Caracteres: " + bad_chars.join(", ")];
                this.alertService.alert_multiple_error("Error de Caracteres", "Se encontraron caracteres no permitidos dentro del mensaje", error_text);
            }else{
                var shortcode ="";
                for(var m = 0;m<this.remitentes_list.length;m++){
                    if(this.remitentes_list[m].id == this.sms_data.remitente){
                        shortcode = this.remitentes_list[m].short_shortcode;
                    }
                };
                this.sms_preview_data.remitente = shortcode;
                this.sms_preview_data.text = mensaje_parsed + "\n\n";
                this.sms_preview_data.url = "URL: https://encuestas.impactmobile.net/xxxxxxxxxx";
                this.sms_preview_data.hour = (new Date()).toLocaleTimeString('es-hn',{ hour: '2-digit', minute: '2-digit', hour12: true });
                if(localStorage.getItem("SMS_TEST_NUMBER_REF")){
                    this.test_sms = conformToMask(localStorage.getItem("SMS_TEST_NUMBER_REF"),this.test_sms_mask,{guide: false}).conformedValue;
                }else{
                    this.test_sms = "";
                }
                this.test_sms_view = 1;
                this.test_sms_submitted = false;
                this.upload_files_modal_view = 6;
            }
        }

        get_total_survey_sms_count(){
            this.upload_files_loading  = true;
            var response;
            this.endpoint.get_total_survey_sms_count(this.data_load).subscribe(
                data => response = data,
                err => {
                    this.upload_files_loading  = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        var night_date = false;
                        for(var i = 0;i<this.calendarized_dates.length;i++){
                             var time = new Date(this.calendarized_dates[i].sms_dia + " " + this.calendarized_dates[i].sms_hora);
                             var hour = time.getHours();
                             if(time.getMinutes()>0){
                                 hour++;
                             }
                             if(hour>21 || hour<7){
                                 night_date = true;
                             }
                        }
                        if(this.calendarized_dates.length==0 && !this.calendarized_sms){
                            var hour = this.current_time.getHours();
                            if(this.current_time.getMinutes()>0){
                                 hour++;
                            }
                            if(hour>21 || hour<7){
                                 night_date = true;
                             }
                        }
                        var message = "" +
                        ((night_date)?"<br><h6 class='text-danger'>Su calendarización contiene envíos nocturnos</h6>":"") + 
                        "¿Está seguro que desea enviar la Encuesta SMS a los clientes seleccionados?<br><br><b class='text-primary'>Total SMS a Enviar<br></b>"+
                        "<h3 style='border-bottom: solid 1px #dcdcdc;padding-bottom: 20px;'><b>" + response.total_sms_count.toLocaleString('es-HN') + "</b></h3>" +
                        "<div class='text-center' style='float:left;width:33%'>" +
                        "<p style='font-size:12px !important;margin-bottom:0px !important;' class='text-muted'><b>SMS Largo 1</b></p> " +
                        "<p style='font-size:12px !important;margin-bottom:0px !important;margin-right:10px;'><b>" + response.total_sms_count_length1.toLocaleString('es-HN') + "</b></p>" +
                        "</div>" +
                        "<div class='text-center' style='float:left;width:33%'>" +
                        "<p style='font-size:12px !important;margin-bottom:0px !important;' class='text-muted'><b>SMS Largo 2</b></p> " +
                        "<p style='font-size:12px !important;margin-bottom:0px !important;margin-right:10px;'><b>" + response.total_sms_count_length2.toLocaleString('es-HN') + "</b></p>" +
                        "</div>" +
                        "<div class='text-center' style='float:left;width:33%'>" +
                        "<p style='font-size:12px !important;margin-bottom:0px !important;' class='text-muted'><b>SMS Largo 3+</b></p> " +
                        "<p style='font-size:12px !important;margin-bottom:0px !important;margin-right:10px;'><b>" + response.total_sms_count_length3.toLocaleString('es-HN') + "</b></p>" +
                        "</div>";
                        var delete_db = "";
                        this.alertService.option_alert("Enviar SMS", message + delete_db,"Sí, Enviar").then((result) => {
                            if(result.value){
                                this.upload_files_loading  = false;
                                this.upload_files_modal.hide();
                                this.send_sms_event.emit(this.data_load);
                            }
                        }).catch(()=>{return false;});
                        this.upload_files_loading  = false;
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.upload_files_loading  = false;
                    }
                }
            );
        }

        reset_send_test_sms(){
            this.test_sms_view = 1;
            this.upload_files_loading = false;
            this.test_sms_submitted = false;
        }

        got_to_preview_survey(){
            this.upload_files_modal_view = 7;
        }

    //UTILS ##################################################################
    //########################################################################
}