import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, OnInit  } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { AppService } from '../../../app.service';
import { AppEndpoints } from '../../../app.endpoints';
import { LayoutService } from '../../../layout/layout.service';
import { ModalDirective } from 'ngx-bootstrap';
import { default as swal } from 'sweetalert2';
import { FormRendererComponent } from '../../../components/form_renderer/form_renderer.component';
import { AlertService } from '../../../components/alert_service/alert.service';
import { ExcelService } from '../../../components/excel_service/excel.service';
import { ServiceDatatableComponent } from '../../../components/service_datatable/service-datatable.component';

@Component({
    selector: 'enviar-encuesta-satisfaccion',
    templateUrl: './enviar_encuesta_satisfaccion.component.html',
    styleUrls:[
        '../../../../vendor/libs/spinkit/spinkit.scss'
    ]
})
export class EnviarEncuestaSatisfaccionComponent implements OnInit{
    @ViewChild("send_survey_ref") send_survey_ref;
    public view:number;

    constructor(private appService: AppService, public endpoint : AppEndpoints,  private layoutService: LayoutService, private alertService: AlertService, private excelService: ExcelService) {
        this.appService.pageTitle = 'Encuesta de Satisfacción';
        this.view = 1;
    }

    ngOnInit(){

    }

    ngAfterViewInit(){    
    }

    send_sms(data){
        this.send_survey_ref.set_loading(true);
        var response;
        this.endpoint.send_survey_sms(data).subscribe(
            data => response = data,
            err => {
                this.send_survey_ref.set_loading(false);
                if(err.status && err.error){
                    this.alertService.alert_message(err.status ,err.error);
                }else{
                    this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                }
            },
            ()=> {
                try{
                    this.view = 2;
                    this.send_survey_ref.set_loading(false);
                }catch(error){
                    this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    this.send_survey_ref.set_loading(false);
                }
            }
        );
    }

    new_sms(){
        this.view = 1;
    }

    ngOnDestroy() {
        setTimeout(() => this.layoutService.off('resize.app-home'));
    }

}
