import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, OnInit  } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { AppService } from '../../../app.service';
import { AppEndpoints } from '../../../app.endpoints';
import { LayoutService } from '../../../layout/layout.service';
import { ModalDirective } from 'ngx-bootstrap';
import { default as swal } from 'sweetalert2';
import { FormRendererComponent } from '../../../components/form_renderer/form_renderer.component';
import { AlertService } from '../../../components/alert_service/alert.service';
import { ExcelService } from '../../../components/excel_service/excel.service';

@Component({
    selector: 'delete-clients',
    templateUrl: './delete_clients.component.html',
    styleUrls:[
        '../../../../vendor/libs/spinkit/spinkit.scss'
    ]
})
export class DeleteClientsComponent implements OnInit{
    public loading:boolean;
    public view:number;
    public confirmation_text:string;
    public submitted:boolean;
    public mask = [/[Ee]/,/[Ll]/,/[Ii]/,/[Mm]/,/[Ii]/,/[Nn]/,/[Aa]/,/[Rr]/];

    constructor(private appService: AppService, public endpoint : AppEndpoints,  private layoutService: LayoutService, private alertService: AlertService, private excelService: ExcelService) {
        this.appService.pageTitle = 'Borrar Base de Datos';
        this.loading = false;
        this.submitted = false;
        this.view = 1;
        this.confirmation_text = "";
    }

    ngOnInit(){}

    //########################################################################
    //DELETE #################################################################

        delete_clientes(){
            if(this.confirmation_text.toUpperCase()=="ELIMINAR"){
                 this.alertService.option_alert("Eliminar Base de Datos","¿Está seguro que desea eliminar su base de datos de clientes?","Sí, Eliminar").then((result) => {
                    if(result.value){
                        this.loading = true;
                        this.submitted = false;
                        var response;
                        this.endpoint.delete_client_database().subscribe(
                            data => response = data,
                            err => {
                                this.loading = false;
                                if(err.status && err.error){
                                    this.alertService.alert_message(err.status ,err.error);
                                }else{
                                    this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                                }
                            },
                            ()=> {
                                try{
                                    //this.alertService.alert_success(response.title, response.message);
                                    this.loading = false;
                                    this.submitted = false;
                                    this.view = 2;
                                    this.confirmation_text = "";
                                }catch(error){
                                    this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                                    this.loading = false;
                                }
                            }
                        );
                    }
                }).catch(()=>{return false;});
            }else{
                this.submitted = true;
            }
        }

    //DELETE #################################################################
    //########################################################################


    ngOnDestroy() {
        setTimeout(() => this.layoutService.off('resize.app-home'));
    }

}
