import { Component, OnInit, Input } from '@angular/core';
import { AppFunctions } from '../../app.functions';

@Component({
	selector: 'daily_shipings_chart',
	templateUrl: './daily_shipings_chart.component.html',
	styleUrls: [
		'../../../vendor/libs/spinkit/spinkit.scss'
	]
})

/**
  * @name DailyShipingsChart
  * @description Genera un gráfico de envíos diarios del cliente basado en el endpoint _get_daily_history_.
  */
export class DailyShipingsChart implements OnInit {
	@Input() valid:boolean;
	@Input() loading:boolean;
	@Input() set data(data){
		this.labels = [];
		this.month_labels = [];
		this.total = 0;
		var dataset = {
			data: [],
			borderWidth: 1,
			pointRadius: 1,
			lineTension: 0
		}
		for(var i = 0;i<data.length;i++){
			this.total = this.total + data[i].total;
			this.labels.push("Día: " + data[i].dia);
			this.month_labels.push(this.functions.get_month_string(data[i].mes-1));
			dataset.data.push(data[i].total);
		}
		if(data.length > 0){
			this.total_string = (this.total/data.length).toLocaleString("es-HN", {maximumFractionDigits: 2});
		}else{
			this.total_string = "0";
		}
		
		this.datasets = [];
		this.datasets.push(dataset);
	}
	
	public total: number = 0;
	public total_string: string = "";

	public datasets = [
		{
			data: [],
			borderWidth: 1,
			pointRadius: 1,
			lineTension: 0
		},
		{
			data: [],
			borderWidth: 1,
			pointRadius: 0,
			type: 'line',
			fill: false
		}
	];
	public labels = [];
	public month_labels = [];
	public options = {
		scales: {
			xAxes: [{
				display: false,
			}],
			yAxes: [{
				display: false,
				ticks: {
					beginAtZero: true
				}
			}]
		},
		legend: {
			display: false
		},
		tooltips: {
			enabled: true,
			callbacks: {
				label: function (tooltipItems, data) {
				        return data.datasets[0].data[tooltipItems.index].toLocaleString('es-hn');
		        },
		        title: (tooltipItems, data)=> {
			        return this.labels[tooltipItems[0].index] +" - "+this.month_labels[tooltipItems[0].index];
		        }
	    	}
		},
		responsive: true,
		maintainAspectRatio: false
	};

	public colors = [{
		backgroundColor: '#28c3d7'
	}];



	constructor(public functions: AppFunctions) {}

	ngOnInit() {
	}
}