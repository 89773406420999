import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, OnInit, ElementRef, Renderer2, Output, EventEmitter, Input } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { AppEndpoints } from '../../app.endpoints';
import { ModalDirective } from 'ngx-bootstrap';
import { default as swal } from 'sweetalert2';
import { FormRendererComponent } from '../form_renderer/form_renderer.component';
import { AlertService } from '../alert_service/alert.service';
import { ExcelService } from '../excel_service/excel.service';
import { ServiceDatatableComponent } from '../service_datatable/service-datatable.component';
import { conformToMask } from 'angular2-text-mask/dist/angular2TextMask';

@Component({
    selector: 'view-sms',
    templateUrl: './view_sms.template.html',
    styleUrls:[
    '../../../vendor/libs/spinkit/spinkit.scss'
    ]
})

export class ViewSmsComponent implements OnInit{
    @ViewChild('sms_form') sms_form: FormControlDirective;
    @ViewChild('sms_mensaje_original') sms_mensaje_original:ElementRef;
    @ViewChild('calendarized_form') calendarized_form: FormRendererComponent;
    public sms_loading:boolean;
    public sms_view:number;
    public sms_data = {
        id:"",
        sms_mensaje_original:"",
        nom_camp:"",
        tipo_campania:"",
        num_gest:"",
        cif:"",
        remitente:"",
        remitentegw:"",
        sms_grupos:"",
        sms_cli_celular:"",
        sms_tipo:"",
        sms_grupo_nombre:"",
        smsadmin_resellers_nombre:"",
        empresa_nombre:"",
        contacto_nombre:"",
        tipo_campania_nombre:"",
        sms_status:""
    }

    public calendarized_inputs = [
        {
            class:"row",
            columns:[
                {
                    class:"col-md-12",
                    inputs:[
                        {
                            type:"calendar",
                            extra:"popup",
                            name:"sms_dia",
                            label:"",
                            icon:"",
                            class:"form-control form-control-sm bg-white",
                            placeholder:"",
                            minlength:null,
                            maxlength:null,
                            pattern:null,
                            error_required:"Requerido",
                            error_pattern:null,
                            error_minlength:"",
                            list_data:{
                                value:"",
                                text:""
                            },
                            list:()=>{
                                return []
                            },
                            textmask:()=>{
                                return false;
                            },
                            required:()=>{
                                return false;
                            },
                            disabled:()=>{
                                return true;
                            },
                            change:(event)=>{
                            },
                            input:()=>{
                            }
                        }
                    ]
                },
                {
                    class:"col-md-12",
                    inputs:[
                        {
                            type:"time",
                            extra:"",
                            name:"sms_hora",
                            label:"",
                            icon:"",
                            class:"form-control form-control-sm bg-white",
                            placeholder:"",
                            minlength:null,
                            maxlength:null,
                            pattern:null,
                            error_required:"Requerido",
                            error_pattern:null,
                            error_minlength:"",
                            list_data:{
                                value:"",
                                text:""
                            },
                            list:()=>{
                                return []
                            },
                            textmask:()=>{
                                return false;
                            },
                            required:()=>{
                                return false;
                            },
                            disabled:()=>{
                                return true;
                            },
                            change:(event)=>{
                            },
                            input:()=>{
                            }
                        }
                    ]
                }
            ]
        },
    ]
    public current_time:Date;

    constructor(public endpoint : AppEndpoints, private alertService: AlertService, private renderer: Renderer2) {
        this.sms_loading = false;
        this.sms_view = 1;
        this.current_time = null;
    }

    ngOnInit() {
        this.get_current_time();
    }

    AfterViewInit(){
    }

    //########################################################################
    //SMS ####################################################################

        reset_sms(){
            this.sms_data = {
                id:"",
                sms_mensaje_original:"",
                nom_camp:"",
                tipo_campania:"",
                num_gest:"",
                cif:"",
                remitente:"",
                remitentegw:"",
                sms_grupos:"",
                sms_cli_celular:"",
                sms_tipo:"",
                sms_grupo_nombre:"",
                smsadmin_resellers_nombre:"",
                empresa_nombre:"",
                contacto_nombre:"",
                tipo_campania_nombre:"",
                sms_status:""
            }
            this.sms_loading = false;
            this.sms_view = 1;
        }

        set_values_sms(data){
            var original_message = data.sms_mensaje_original.split(".");
            var remitente = "";
            if(original_message.length > 1){
                remitente = original_message.pop();
            }
            
            this.sms_data = {
                id:data.id,
                sms_mensaje_original:original_message.join("."),
                nom_camp:data.nom_camp,
                tipo_campania:data.tipo_campania,
                num_gest:data.num_gest,
                cif:data.cif,
                remitente:data.remitente,
                remitentegw:data.sms_tipo==2?remitente:data.remitentegw,
                sms_grupos:data.sms_grupos,
                sms_cli_celular:data.sms_cli_celular,
                sms_tipo:data.sms_tipo,
                sms_grupo_nombre:"",
                smsadmin_resellers_nombre:data.smsadmin_resellers_nombre,
                empresa_nombre:data.empresa_nombre,
                contacto_nombre:data.contacto_nombre,
                tipo_campania_nombre:data.tipo_campania_nombre,
                sms_status:data.sms_status
            }
            if(data.sms_tipo==2 && data.sms_grupos){
               this.get_group_name(data.sms_grupos); 
           }
            this.calendarized_form.set_values_with_date({
                sms_dia:data.sms_fecha_string.split(" ")[0].split("-").reverse().join("/"),
                sms_hora:data.sms_fecha_string.split(" ")[1]
            });
        }

        set_loading(value:boolean){
            this.sms_loading = value;
        }

        clean_sms_text(){
            this.sms_data.sms_mensaje_original = "";
        }

        get_group_name(id){
            var response;
            this.sms_data.sms_grupo_nombre = "cargando...";
            var load = {
                id:id
            }
            this.endpoint.get_group_name(load).subscribe(
                data => response = data,
                err => {
                    this.sms_data.sms_grupo_nombre = "";
                },
                ()=> {
                    try{
                        this.sms_data.sms_grupo_nombre = response.grp_nombre + " - " + response.sgp_nombre;
                    }catch(error){
                        this.sms_data.sms_grupo_nombre = "";
                    }
                }
            );
        }

        get_current_time(){
            var response;
            this.endpoint.get_current_time().subscribe(
                data => response = data,
                err => {
                    this.current_time = null;
                },
                ()=> {
                    try{
                        this.current_time = new Date(response.date);
                        setInterval(()=>{ 
                            this.current_time.setSeconds(this.current_time.getSeconds() + 1);
                        }, 1000);
                    }catch(error){
                        this.current_time = null;
                    }
                }
            );
        }

        time_pretty_print(time){
            time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) {
                time = time.slice (1);
                time[5] = +time[0] < 12 ? ' AM' : ' PM';
                time[0] = +time[0] % 12 || 12;
            }
            return time.join ('');
        }

    //SMS ####################################################################
    //########################################################################
}