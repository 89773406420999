import { Component, ViewChildren, QueryList, AfterViewInit, OnDestroy } from '@angular/core';
import { AppService } from '../app.service';
import { AppEndpoints } from '../app.endpoints';
import { BaseChartDirective } from 'ng2-charts';
import { AlertService } from '../components/alert_service/alert.service';
import { LayoutService } from '../layout/layout.service';
import { AppFunctions } from '../app.functions';
@Component({
	selector: 'app-home',
	templateUrl: './home.component.html'
})
export class HomeComponent {
	@ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;
	@ViewChildren("recent_shipments_chart_ref") recent_shipments_chart_ref;
	public cards_data:any;
	public loading: boolean;
	public valid: boolean;
	public sent_sms_campaign_data:any[];
	public daily_sms_data:any[];
	public monthly_sms_data:any[];
	public area_usage_data:any[];
	public smpp_cards_data:any;
	public smpp_data:any[];

	constructor(private appService: AppService, public endpoint: AppEndpoints, private layoutService: LayoutService, public functions: AppFunctions) {
		this.appService.pageTitle = 'Inicio';
		this.cards_data = {
			mensajes_enviados:"",			
			mensajes_enviados_total:"",
			mensajes_recibidos:"",
			mensajes_recibidos_total:"",
			reseller_tipo:"",
			tipo_descripcion:"",
			cuotas_xmes:"",
			extension_permitido:"",
			acumulados:"",
			disponible:"",
			percentage:"",
			mes:"",
			anio:""
		};
		this.sent_sms_campaign_data = [];
		this.daily_sms_data = [];
		this.monthly_sms_data = [];
		this.area_usage_data = [];
		this.smpp_cards_data = {
			mensajes_disponibles:"",
			mensajes_enviados:"",
			reseller_tipo_descripcion:"",
			anio:"",
			mes:""
		}
		this.smpp_data = [];
	}

	ngOnInit() {
		this.get_dashboard();
	}

   	get_dashboard(){
   		if(this.endpoint.get_session().role!=22 && this.endpoint.get_session().role!=23 && this.endpoint.get_session().role!=19 && this.endpoint.get_session().role!=17){
   			this.loading = true;
		   	this.endpoint.get_dashboard().subscribe(
		   		data => {
		   			if(data.length > 0){
		   				this.valid = true;
		   				this.loading = false;
		   				var cards_data =  data[0];
		   				this.cards_data = {
		   					reseller_tipo:cards_data[0].reseller_tipo,
		   					reseller_tipo_descripcion:cards_data[0].tipo_descripcion,
		   					anio:cards_data[0].anio,
							mes:this.functions.get_month_string(cards_data[0].mes-1),
							mensajes_enviados:cards_data[0].mensajes_enviados.toLocaleString('es-HN'),
							mensajes_enviados_total:(cards_data[0].mensajes_enviados).toLocaleString('es-HN'),
							mensajes_disponibles:(cards_data[0].reseller_tipo==2)?"SMS Ilimitados":cards_data[0].disponible.toLocaleString('es-HN'),
							porcentaje:cards_data[0].percentage,
							porcentaje_consumo:cards_data[0].percentage.toLocaleString('es-HN'),
							mensajes_acumulados:(cards_data[0].reseller_tipo==2)?"N/A":cards_data[0].acumulados.toLocaleString('es-HN'),
							cuotas_xmes:(cards_data[0].reseller_tipo==2)?"N/A":cards_data[0].cuotas_xmes.toLocaleString('es-HN'),
							extension_permitido:(cards_data[0].reseller_tipo==2)?"N/A":cards_data[0].extension_permitido
		   				}
		   				if(data.length > 1){
		   					this.daily_sms_data = data[1];
		   				}
		   				if(data.length > 2){
		   					this.monthly_sms_data = data[2];
		   				}
		   				if(data.length > 3){
		   					var total = 0;
		   					for(var i = 0;i<data[3].length;i++){
		   						total = total + data[3][i].total;
		   					}
		   					for(var i = 0;i<data[3].length;i++){
		   						var values = {
		   							percentage:0,
		   							area:data[3][i].res_nombre,
		   							sent:data[3][i].total,
		   							sent_string:data[3][i].total.toLocaleString('es-HN')
		   						}
		   						if(total > 0){
		   							values.percentage = (data[3][i].total/total)*100;
		   						}
		   						this.area_usage_data.push(values);
		   					}
		   				}
		   				if(data.length > 4){
		   					for(var i = 0;i<data[4].length;i++){
		   						data[4][i].fecha_string = new Date(data[4][i].sms_fecha).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit', hour12: true });
		   					}
		   					this.sent_sms_campaign_data = data[4];
		   				}
		   			}else{
		   				this.valid = false;
		   				this.loading = false;
		   			}
		   		},
		   		err => {
		   			this.valid = false;
		   			this.loading = false;
		   		}
		   	);
   		}else if(this.endpoint.get_session().role==22){
   			this.loading = true;
		   	this.endpoint.get_smpp_dashboard().subscribe(
		   		data => {
					console.log(data)
					const { results, stats_results } = data;

					console.log(stats_results[1].slice(-1)[0])

		   			if(results.length > 0){
		   				this.valid = true;
		   				this.loading = false;
		   				var smpp_cards_data =  stats_results[1].slice(-1) //results[0];
		   				this.smpp_cards_data = {
							mensajes_enviados:(smpp_cards_data[0].total).toLocaleString('es-HN'),
							anio:smpp_cards_data[0].anio,
							mes:this.functions.get_month_string(smpp_cards_data[0].mes-1),
							mensajes_disponibles:"SMS Ilimitados",
							reseller_tipo_descripcion:"Usuario de Reportes SMPP"
		   				}
		   				if(results.length > 1){
		   					for(var i = 0;i<results[1].length;i++){
		   						results[1][i].fecha_string = new Date(results[1][i].fecha).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit', hour12: true });
		   					}
		   					this.smpp_data = results[1];
		   				}
						if(stats_results.length > 0){
							this.daily_sms_data = stats_results[0];
						}
						if(stats_results.length > 1){
							this.monthly_sms_data = stats_results[1];
						}
		   			}else{
		   				this.valid = false;
		   				this.loading = false;
		   			}
		   		},
		   		err => {
		   			this.valid = false;
		   			this.loading = false;
		   		}
		   	);
   		}else if(this.endpoint.get_session().role==23){
   			this.loading = true;
		   	this.endpoint.get_smpp_dashboard_limited().subscribe(
		   		data => {
		   			if(data.length > 0){
		   				this.valid = true;
		   				this.loading = false;
		   				var smpp_cards_data =  data;
		   				this.smpp_cards_data = {
							mensajes_enviados:(smpp_cards_data[0].total).toLocaleString('es-HN'),
							anio:smpp_cards_data[0].anio,
							mes:this.functions.get_month_string(smpp_cards_data[0].mes-1),
							mensajes_disponibles:"SMS Ilimitados",
							reseller_tipo_descripcion:"Usuario de Reportes SMPP"
		   				}
		   			}else{
		   				this.valid = false;
		   				this.loading = false;
		   			}
		   		},
		   		err => {
		   			this.valid = false;
		   			this.loading = false;
		   		}
		   	);
   		}else if(this.endpoint.get_session().role==17){
			//Show inbox menus
			this.loading = true;
		   	this.endpoint.get_dashboard().subscribe(
		   		data => {
		   			if(data.length > 0){
						console.log(data)
		   				this.valid = true;
		   				this.loading = false;
		   				var cards_data =  data[0];
		   				this.cards_data = {
		   					reseller_tipo:cards_data[0].reseller_tipo,
		   					reseller_tipo_descripcion:cards_data[0].tipo_descripcion,
		   					anio:cards_data[0].anio,
							mes:this.functions.get_month_string(cards_data[0].mes-1),
							mensajes_enviados:cards_data[0].mensajes_enviados.toLocaleString('es-HN'),
							mensajes_enviados_total:(cards_data[0].mensajes_enviados).toLocaleString('es-HN'),

							mensajes_recibidos:cards_data[0].mensajes_recibidos.toLocaleString('es-HN'),
							mensajes_recibidos_total:(cards_data[0].mensajes_recibidos).toLocaleString('es-HN'),

							mensajes_disponibles:(cards_data[0].reseller_tipo==2)?"SMS Ilimitados":cards_data[0].disponible.toLocaleString('es-HN'),
							porcentaje:cards_data[0].percentage,
							porcentaje_consumo:cards_data[0].percentage.toLocaleString('es-HN'),
							mensajes_acumulados:(cards_data[0].reseller_tipo==2)?"N/A":cards_data[0].acumulados.toLocaleString('es-HN'),
							cuotas_xmes:(cards_data[0].reseller_tipo==2)?"N/A":cards_data[0].cuotas_xmes.toLocaleString('es-HN'),
							extension_permitido:(cards_data[0].reseller_tipo==2)?"N/A":cards_data[0].extension_permitido
		   				}
		   				if(data.length > 1){
		   					this.daily_sms_data = data[1];
		   				}
		   				if(data.length > 2){
		   					this.monthly_sms_data = data[2];
		   				}
		   				if(data.length > 3){
		   					var total = 0;
		   					for(var i = 0;i<data[3].length;i++){
		   						total = total + data[3][i].total;
		   					}
		   					for(var i = 0;i<data[3].length;i++){
		   						var values = {
		   							percentage:0,
		   							area:data[3][i].res_nombre,
		   							sent:data[3][i].total,
		   							sent_string:data[3][i].total.toLocaleString('es-HN')
		   						}
		   						if(total > 0){
		   							values.percentage = (data[3][i].total/total)*100;
		   						}
		   						this.area_usage_data.push(values);
		   					}
		   				}
		   				if(data.length > 4){
		   					for(var i = 0;i<data[4].length;i++){
		   						data[4][i].fecha_string = new Date(data[4][i].sms_fecha).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit', hour12: true });
		   					}
		   					this.sent_sms_campaign_data = data[4];
		   				}
		   			}else{
		   				this.valid = false;
		   				this.loading = false;
		   			}
		   		},
		   		err => {
		   			this.valid = false;
		   			this.loading = false;
		   		}
		   	);
   		}else if(this.endpoint.get_session().role==19){
			//Usuario bitacora
		}
   	}

	ngAfterViewInit() {
		setTimeout(() => {
			const resizeCharts = () => this.charts.forEach(chart => chart.chart.resize());
			resizeCharts();
			this.layoutService.on('resize.app-home', resizeCharts);
		});
	}

	ngOnDestroy() {
		setTimeout(() => this.layoutService.off('resize.app-home'));
	}
}
