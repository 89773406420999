import { Component, OnInit, Input } from '@angular/core';
import { AppEndpoints } from '../../app.endpoints';
import { AlertService } from '../../components/alert_service/alert.service';

@Component({
  selector: 'recent_campaign_shipments_chart',
  templateUrl: './recent_campaign_shipments_chart.component.html',
  styleUrls:[
  '../../../vendor/libs/spinkit/spinkit.scss'
  ]
})

/**
  * @name RecentCampaignShipmentsChart
  * @description Genera un listado de los envíos recientes basado en el endpoint _get_scheduled_.
*/
export class RecentCampaignShipmentsChart implements OnInit {
  @Input() valid:boolean;
  @Input() data:any[];
  @Input() loading:boolean;
  public current_offset:number;

    constructor(public endpoint: AppEndpoints, private alertService: AlertService) {
      this.current_offset = 0;
      this.data = [];
    }

    ngOnInit() {
    }

    get_data(){
       this.loading = true;
       this.endpoint.get_sms_sent_campaign_dashboard({current_offset:(this.current_offset*15)}).subscribe(
         data => {
           for(var i = 0;i<data.length;i++){
            data[i].fecha_string = new Date(data[i].sms_fecha).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit', hour12: true });
        }
           this.data = data;
           this.loading = false;
         },
         err => {
            this.loading = false;
      });
    }

    set_offset(val){
      if(val>this.current_offset){
        this.current_offset = val;
        this.get_data();
      }else if(val<this.current_offset){
        if(val<0){
          this.current_offset = 0;
          this.get_data();
        }else{
          this.current_offset = val;
          this.get_data();
        }
      }
    }

    set_data(data){
       this.data = data;
    }
}