import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, OnInit  } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { AppService } from '../../../app.service';
import { AppEndpoints } from '../../../app.endpoints';
import { LayoutService } from '../../../layout/layout.service';
import { ModalDirective } from 'ngx-bootstrap';
import { default as swal } from 'sweetalert2';
import { FormRendererComponent } from '../../../components/form_renderer/form_renderer.component';
import { AlertService } from '../../../components/alert_service/alert.service';
import { ExcelService } from '../../../components/excel_service/excel.service';
import { ServiceDatatableComponent } from '../../../components/service_datatable/service-datatable.component';
import { conformToMask } from 'angular2-text-mask/dist/angular2TextMask';

@Component({
    selector: 'groups',
    templateUrl: './groups.component.html',
    styleUrls:[
        '../../../../vendor/libs/spinkit/spinkit.scss'
    ]
})
export class GroupsComponent implements OnInit{
    @ViewChild('group_modal') group_modal: ModalDirective;
    @ViewChild('group_form') group_form: FormRendererComponent;
    public group_view:number;
    public group_modal_view:number;
    public group_list:any[];
    public group_loading:boolean;
    public group_datatable:any;
    public group_datatable_loading:boolean;
    public group_inputs=[
        {
            class:"row",
            columns:[
                {
                    class:"col-md-12",
                    inputs:[
                        {
                            type:"text",
                            extra:"",
                            name:"grp_nombre",
                            label:"Nombre del Grupo",
                            icon:"",
                            class:"form-control",
                            placeholder:"",
                            minlength:null,
                            maxlength:"50",
                            pattern:null,
                            error_required:"Requerido",
                            error_pattern:"",
                            error_minlength:"",
                            list_data:{
                                value:"",
                                text:""
                            },
                            list:()=>{
                                return []
                            },
                            textmask:()=>{
                                return false;
                            },
                            required:()=>{
                                return true;
                            },
                            disabled:()=>{
                                return false;
                            },
                            change:(event)=>{
                            },
                            input:()=>{
                            }
                        }
                    ]
                }
            ]
        }
    ];
    public group_data = {
        id:"",
        grp_nombre:""
    };
    public group_datatable_search:string;

    @ViewChild('subgroup_modal') subgroup_modal: ModalDirective;
    @ViewChild('subgroup_form') subgroup_form: FormRendererComponent;
    public subgroup_modal_view:number;
    public subgroup_list:any[];
    public subgroup_loading:boolean;
    public subgroup_datatable:any;
    public subgroup_inputs=[
        {
            class:"row",
            columns:[
                {
                    class:"col-md-12",
                    inputs:[
                        {
                            type:"text",
                            extra:"",
                            name:"sgp_nombre",
                            label:"Nombre del Subgrupo",
                            icon:"",
                            class:"form-control",
                            placeholder:"",
                            minlength:null,
                            maxlength:"50",
                            pattern:null,
                            error_required:"Requerido",
                            error_pattern:"",
                            error_minlength:"",
                            list_data:{
                                value:"",
                                text:""
                            },
                            list:()=>{
                                return []
                            },
                            textmask:()=>{
                                return false;
                            },
                            required:()=>{
                                return true;
                            },
                            disabled:()=>{
                                return false;
                            },
                            change:(event)=>{
                            },
                            input:()=>{
                            }
                        }
                    ]
                }
            ]
        }
    ];
    public subgroup_data = {
        id:"",
        sgp_nombre:"",
        group_index:"",
        total:""
    }

    public search_word:string;

    /*@ViewChild('membership_modal') membership_modal: ModalDirective;
    public membership_modal_view:number;
    public membership_loading:boolean;*/

    @ViewChild('group_client_form_view') group_client_form_view: FormRendererComponent;
    @ViewChild('group_client_datatable_ref') group_client_datatable_ref: ServiceDatatableComponent;
    @ViewChild('subgroup_client_form_view') subgroup_client_form_view: FormRendererComponent;
    @ViewChild('subgroup_client_datatable_ref') subgroup_client_datatable_ref: ServiceDatatableComponent;
    @ViewChild('unassigned_client_datatable_ref') unassigned_client_datatable_ref: ServiceDatatableComponent;
    public client_datatable:any;
    public client_datatable_add:any;
    public client_inputs_view=[];
    public client_data = {
        id:"",
        cli_nombre:"",
        cli_identidad:"",
        cli_celular:""
    };

    public label_list:any[];

    public cellphone_mask=[/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/];
    public phone_mask=[/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/];
    public id_mask=[/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/];
    constructor(private appService: AppService, public endpoint : AppEndpoints,  private layoutService: LayoutService, private alertService: AlertService, private excelService: ExcelService) {
        this.appService.pageTitle = 'Grupos y Subgrupos';
        this.group_view = 1;
        this.search_word = "";
        this.group_modal_view = 1;
        this.group_list = [];
        this.group_loading = false;
        this.group_datatable_loading = false;
        this.group_datatable = {
            title:"Listado de Grupos",
            icon:"apartment",
            object_description:"grupos",
            empty_text:"No existen grupos registrados dentro del sistema",
            header_class: "custom-th-dark",
            columns:[
                {   
                    column:"grp_nombre",
                    wrap_column:true,
                    header:"Nombre del Grupo",
                    wrap_header:true,
                    type:"text"
                },
            ],
            events:[
                {
                    name:"Miembros",
                    style:"color:#57A473",
                    hover_style:"cursor:pointer; color:#57A473; background-color:#C1FFD7 !important;",
                    icon:"user-friends"
                },
                {
                    name:"Subgrupos",
                    style:"color:#39B7CB",
                    hover_style:"cursor:pointer; color:#39B7CB; background-color:#BDF0FF !important;",
                    icon:"layer-group"
                },
                {
                    name:"Editar Grupo",
                    style:"color:#ffb300",
                    hover_style:"cursor:pointer; color:#ffb300; background-color:#FFF7C6 !important;",
                    icon:"edit"
                },
                {
                    name:"Eliminar Grupo",
                    style:"color:#FB5D5D",
                    hover_style:"cursor:pointer; color:#FB5D5D; background-color:#FEDCDC !important;",
                    icon:"trash-alt"
                },
                {
                    name:"Limpiar",
                    style:"color:#EF54C9",
                    hover_style:"cursor:pointer; color:#EF54C9; background-color:#FFD6FD !important;",
                    icon:"user-slash"
                },
            ],
            navigation_offsets:[5,10,15,20,25,50],
            navigation_starting_offset_index:1,
            show_search_field:true,
            table_icon:"caret-right"
        };
        this.group_datatable_search = "";

        this.subgroup_modal_view = 1;
        this.subgroup_list = [];
        this.subgroup_loading = false;
        this.subgroup_datatable = {
            title:"Listado de Subgrupos",
            icon:"layers",
            object_description:"subgrupos",
            empty_text:"No existen subgrupos registrados dentro del grupo seleccionado",
            columns:[
                {   
                    column:"sgp_nombre",
                    wrap_column:true,
                    header:"Nombre del Subgrupo",
                    wrap_header:true,
                    type:"text"
                },
            ],
            events:[
                {
                    name:"Miembros",
                    style:"color:#57A473",
                    hover_style:"cursor:pointer; color:#57A473; background-color:#C1FFD7 !important;",
                    icon:"user-friends"
                },
                {
                    name:"Editar Subgrupo",
                    style:"color:#ffb300",
                    hover_style:"cursor:pointer; color:#ffb300; background-color:#FFF7C6 !important;",
                    icon:"edit"
                },
                {
                    name:"Eliminar Subgrupo",
                    style:"color:#FB5D5D",
                    hover_style:"cursor:pointer; color:#FB5D5D; background-color:#FEDCDC !important;",
                    icon:"trash-alt"
                },
                {
                    name:"Limpiar",
                    style:"color:#EF54C9",
                    hover_style:"cursor:pointer; color:#EF54C9; background-color:#FFD6FD !important;",
                    icon:"user-slash"
                },
            ],
            navigation_offsets:[5,10,15,20,25,50],
            navigation_starting_offset_index:1,
            show_search_field:true,
            table_icon:"caret-right"
        }

        //this.membership_modal_view = 1;
        //this.membership_loading = false;

        this.label_list = [];
    }

    ngOnInit(){
        this.get_labels();
        this.get_groups();
    }

    //########################################################################
    //CLIENTS ################################################################

        /*group_client_datatable_open_view_client_group(data){
            this.group_client_form_view.clean_form();
            this.group_client_form_view.set_values(data);
            this.client_data = {
                id:data.id,
                cli_nombre:data.cli_nombre,
                cli_identidad:data.cli_identidad,
                cli_celular:data.cli_celular
            }
            this.membership_modal_view = 2;
        }

        group_client_datatable_close_view_client(){
            this.group_client_form_view.clean_form();
            this.client_data = {
                id:"",
                cli_nombre:"",
                cli_identidad:"",
                cli_celular:""
            }
            this.membership_modal_view = 1;
        }*/

        /*group_client_datatable_events(event){
            if(event.event == "Datos del Cliente"){
                this.group_client_datatable_open_view_client_group(event.data);
            }else if(event.event == "Quitar Miembro"){
                this.unassign_membership_from_group(event.data.id, this.group_data.id);
            }
        }

        group_client_datatable_get_results_offset_change(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                smsreseller_grupos_id:this.group_data.id,
                search_word:data.search_word
            }
            this.endpoint.get_clients_by_group(load).subscribe(
                data => response = data,
                err => {
                    this.group_client_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        for(var i = 0;i<response.list.length;i++){
                            if(response.list[i].cli_celular){
                                var masked_number = conformToMask(
                                    response.list[i].cli_celular,
                                    this.cellphone_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_celular = masked_number.conformedValue;
                            }
                            if(response.list[i].cli_telefono){
                                var masked_phone = conformToMask(
                                    response.list[i].cli_telefono,
                                    this.phone_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_telefono = masked_phone.conformedValue;
                            }
                            if(response.list[i].cli_identidad){
                                var masked_id = conformToMask(
                                    response.list[i].cli_identidad,
                                    this.id_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_identidad = masked_id.conformedValue;
                            }  
                        }
                        this.group_client_datatable_ref.set_results_offset_change(response.list);
                    }catch(error){
                        this.group_client_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

        group_client_datatable_get_results_filter_change(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                smsreseller_grupos_id:this.group_data.id,
                search_word:data.search_word
            }
            this.endpoint.get_clients_by_group(load).subscribe(
                data => response = data,
                err => {
                    this.group_client_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        for(var i = 0;i<response.list.length;i++){
                            if(response.list[i].cli_celular){
                                var masked_number = conformToMask(
                                    response.list[i].cli_celular,
                                    this.cellphone_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_celular = masked_number.conformedValue;
                            }
                            if(response.list[i].cli_telefono){
                                var masked_phone = conformToMask(
                                    response.list[i].cli_telefono,
                                    this.phone_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_telefono = masked_phone.conformedValue;
                            }
                            if(response.list[i].cli_identidad){
                                var masked_id = conformToMask(
                                    response.list[i].cli_identidad,
                                    this.id_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_identidad = masked_id.conformedValue;
                            }  
                        }
                        this.group_client_datatable_ref.set_results_filter_change(response.list, response.count);
                    }catch(error){
                        this.group_client_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

        group_client_datatable_get_results_update_list(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                smsreseller_grupos_id:this.group_data.id,
                search_word:data.search_word
            }
            this.endpoint.get_clients_by_group(load).subscribe(
                data => response = data,
                err => {
                    this.group_client_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        for(var i = 0;i<response.list.length;i++){
                            if(response.list[i].cli_celular){
                                var masked_number = conformToMask(
                                    response.list[i].cli_celular,
                                    this.cellphone_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_celular = masked_number.conformedValue;
                            }
                            if(response.list[i].cli_telefono){
                                var masked_phone = conformToMask(
                                    response.list[i].cli_telefono,
                                    this.phone_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_telefono = masked_phone.conformedValue;
                            }
                            if(response.list[i].cli_identidad){
                                var masked_id = conformToMask(
                                    response.list[i].cli_identidad,
                                    this.id_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_identidad = masked_id.conformedValue;
                            }  
                        }
                        this.group_client_datatable_ref.set_results_update_list(response.list, response.count);
                    }catch(error){
                        this.group_client_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }*/

        subgroup_client_datatable_open_view_client_group(data){
            this.subgroup_client_form_view.clean_form();
            this.subgroup_client_form_view.set_values(data);
            this.client_data = {
                id:data.id,
                cli_nombre:data.cli_nombre,
                cli_identidad:data.cli_identidad,
                cli_celular:data.cli_celular
            }
            this.subgroup_modal_view = 4;
        }

        subgroup_client_datatable_close_view_client(){
            this.subgroup_client_form_view.clean_form();
            this.client_data = {
                id:"",
                cli_nombre:"",
                cli_identidad:"",
                cli_celular:""
            }
            this.subgroup_modal_view = 3;
        }

        subgroup_client_datatable_close_view_memberships(){
            this.client_data = {
                id:"",
                cli_nombre:"",
                cli_identidad:"",
                cli_celular:""
            };
            this.extend_memberships(this.subgroup_data.group_index, this.group_data);
            this.subgroup_modal.hide();
        }

        subgroup_client_datatable_events(event){
            if(event.event == "Datos del Cliente"){
                this.subgroup_client_datatable_open_view_client_group(event.data);
            }else if(event.event == "Quitar Miembro"){
                this.unassign_membership_from_subgroup(event.data.id, this.subgroup_data.id);
            }
        }

        subgroup_client_datatable_get_results_offset_change(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                smsreseller_subgrupos_id:this.subgroup_data.id,
                search_word:data.search_word
            }
            this.endpoint.get_clients_by_subgroup(load).subscribe(
                data => response = data,
                err => {
                    this.subgroup_client_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        for(var i = 0;i<response.list.length;i++){
                            if(response.list[i].cli_celular){
                                var masked_number = conformToMask(
                                    response.list[i].cli_celular,
                                    this.cellphone_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_celular = masked_number.conformedValue;
                            }
                            if(response.list[i].cli_telefono){
                                var masked_phone = conformToMask(
                                    response.list[i].cli_telefono,
                                    this.phone_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_telefono = masked_phone.conformedValue;
                            }
                            if(response.list[i].cli_identidad){
                                var masked_id = conformToMask(
                                    response.list[i].cli_identidad,
                                    this.id_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_identidad = masked_id.conformedValue;
                            }  
                        }
                        this.subgroup_client_datatable_ref.set_results_offset_change(response.list);
                    }catch(error){
                        this.subgroup_client_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                    
                }
            );
        }

        subgroup_client_datatable_get_results_filter_change(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                smsreseller_subgrupos_id:this.subgroup_data.id,
                search_word:data.search_word
            }
            this.endpoint.get_clients_by_subgroup(load).subscribe(
                data => response = data,
                err => {
                    this.subgroup_client_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        for(var i = 0;i<response.list.length;i++){
                            if(response.list[i].cli_celular){
                                var masked_number = conformToMask(
                                    response.list[i].cli_celular,
                                    this.cellphone_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_celular = masked_number.conformedValue;
                            }
                            if(response.list[i].cli_telefono){
                                var masked_phone = conformToMask(
                                    response.list[i].cli_telefono,
                                    this.phone_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_telefono = masked_phone.conformedValue;
                            }
                            if(response.list[i].cli_identidad){
                                var masked_id = conformToMask(
                                    response.list[i].cli_identidad,
                                    this.id_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_identidad = masked_id.conformedValue;
                            }  
                        }
                        this.subgroup_client_datatable_ref.set_results_filter_change(response.list, response.count);
                    }catch(error){
                        this.subgroup_client_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                    
                }
            );
        }

        subgroup_client_datatable_get_results_update_list(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                smsreseller_subgrupos_id:this.subgroup_data.id,
                search_word:data.search_word
            }
            this.endpoint.get_clients_by_subgroup(load).subscribe(
                data => response = data,
                err => {
                    this.subgroup_client_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        for(var i = 0;i<response.list.length;i++){
                            if(response.list[i].cli_celular){
                                var masked_number = conformToMask(
                                    response.list[i].cli_celular,
                                    this.cellphone_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_celular = masked_number.conformedValue;
                            }
                            if(response.list[i].cli_telefono){
                                var masked_phone = conformToMask(
                                    response.list[i].cli_telefono,
                                    this.phone_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_telefono = masked_phone.conformedValue;
                            }
                            if(response.list[i].cli_identidad){
                                var masked_id = conformToMask(
                                    response.list[i].cli_identidad,
                                    this.id_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_identidad = masked_id.conformedValue;
                            }  
                        }
                        this.subgroup_client_datatable_ref.set_results_update_list(response.list, response.count);
                    }catch(error){
                        this.subgroup_client_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                    
                }
            );
        }

    //CLIENTS ################################################################
    //########################################################################

    //########################################################################
    //MEMBERSHIPS ############################################################

        /*open_view_group_membership(data){
            this.group_data = {
                id:data.id,
                grp_nombre:data.grp_nombre
            };
            this.group_client_datatable_ref.set_show_length(10);
            this.membership_modal_view = 1;
            this.membership_loading = false;
            this.membership_modal.show();
        }*/

        open_view_subgroup_membership(group_index, data, group_data){
            this.subgroup_data = {
                id:data.id,
                sgp_nombre:data.sgp_nombre,
                group_index:group_index,
                total:data.total_clientes + ""
            }
            this.group_data = {
                id:group_data.id,
                grp_nombre:group_data.grp_nombre
            };
            this.subgroup_client_datatable_ref.set_show_length(10);
            this.subgroup_modal_view = 3;
            this.subgroup_modal.show();
        }

        open_unassign_membership_by_group(data){
            this.alertService.option_alert("Limpiar Membresías","¿Está seguro que desea limpiar las membresías el grupo seleccionado?<br><br><b>Grupo:<br></b><b class='text-success'> "+data.grp_nombre+"</b>","Sí, Limpiar").then((result) => {
                if(result.value){
                    this.unassign_membership_by_group(data.id);
                }
            }).catch(()=>{return false;});
        }

        unassign_membership_by_group(id){
            this.group_datatable_loading = true;
            var load = {
                smsreseller_grupos_id:id
            };
            var response;
            this.endpoint.unassign_membership_by_group(load).subscribe(
                data => response = data,
                err => {
                    this.group_datatable_loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.get_groups();
                        this.alertService.alert_success(response.title, response.message);
                        this.group_datatable_loading = false;
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.group_datatable_loading = false;
                    }
                }
            );
        }

        unassign_client_from_group(id){
            this.group_client_datatable_ref.set_loading(true);
            var load = {
                smsreseller_grupos_id:id
            };
            var response;
            this.endpoint.unassign_membership_by_group(load).subscribe(
                data => response = data,
                err => {
                    this.group_client_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        //this.get_groups();
                        this.alertService.alert_success(response.title, response.message);
                        this.group_client_datatable_ref.set_loading(false);
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.group_client_datatable_ref.set_loading(false);
                    }
                }
            );
        }

        unassign_membership_from_group(client_id, group_id){
            this.group_client_datatable_ref.set_loading(true);
            var load = {
                smsreseller_clientes_id:client_id,
                smsreseller_grupos_id:group_id
            };
            var response;
            this.endpoint.unassign_membership_from_group(load).subscribe(
                data => response = data,
                err => {
                    this.group_client_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.group_client_datatable_ref.emit_get_results_update_list();
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.group_client_datatable_ref.set_loading(false);
                    }
                }
            );
        }

        unassign_membership_from_subgroup(client_id, subgroup_id){
            this.subgroup_client_datatable_ref.set_loading(true);
            var load = {
                smsreseller_clientes_id:client_id,
                smsreseller_subgrupos_id:subgroup_id
            };
            var response;
            this.endpoint.unassign_membership_from_subgroup(load).subscribe(
                data => response = data,
                err => {
                    this.subgroup_client_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.subgroup_data.total = (parseInt(this.subgroup_data.total) -1)+"";
                        this.subgroup_client_datatable_ref.emit_get_results_update_list();
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.subgroup_client_datatable_ref.set_loading(false);
                    }
                }
            );
        }

        open_add_subgroup_membership(){
            this.unassigned_client_datatable_ref.set_show_length(10);
            this.subgroup_modal_view = 5;
        }

        open_remove_subgroup_membership(){
            this.subgroup_client_datatable_ref.set_show_length(10);
            this.subgroup_modal_view = 3;
        }

        unassigned_memberships_datatable_get_results_offset_change(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                smsreseller_subgrupos_id:this.subgroup_data.id,
                search_word:data.search_word
            }
            this.endpoint.get_clients_not_in_subgroup(load).subscribe(
                data => response = data,
                err => {
                    this.unassigned_client_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        for(var i = 0;i<response.list.length;i++){
                            if(response.list[i].cli_celular){
                                var masked_number = conformToMask(
                                    response.list[i].cli_celular,
                                    this.cellphone_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_celular = masked_number.conformedValue;
                            }
                            if(response.list[i].cli_telefono){
                                var masked_phone = conformToMask(
                                    response.list[i].cli_telefono,
                                    this.phone_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_telefono = masked_phone.conformedValue;
                            }
                            if(response.list[i].cli_identidad){
                                var masked_id = conformToMask(
                                    response.list[i].cli_identidad,
                                    this.id_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_identidad = masked_id.conformedValue;
                            }  
                        }
                        this.unassigned_client_datatable_ref.set_results_offset_change(response.list);
                    }catch(error){
                        this.unassigned_client_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

        unassigned_memberships_datatable_get_results_filter_change(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                smsreseller_subgrupos_id:this.subgroup_data.id,
                search_word:data.search_word
            }
            this.endpoint.get_clients_not_in_subgroup(load).subscribe(
                data => response = data,
                err => {
                    this.unassigned_client_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        for(var i = 0;i<response.list.length;i++){
                            if(response.list[i].cli_celular){
                                var masked_number = conformToMask(
                                    response.list[i].cli_celular,
                                    this.cellphone_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_celular = masked_number.conformedValue;
                            }
                            if(response.list[i].cli_telefono){
                                var masked_phone = conformToMask(
                                    response.list[i].cli_telefono,
                                    this.phone_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_telefono = masked_phone.conformedValue;
                            }
                            if(response.list[i].cli_identidad){
                                var masked_id = conformToMask(
                                    response.list[i].cli_identidad,
                                    this.id_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_identidad = masked_id.conformedValue;
                            }  
                        }
                        this.unassigned_client_datatable_ref.set_results_filter_change(response.list, response.count);
                    }catch(error){
                        this.unassigned_client_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

        unassigned_memberships_datatable_get_results_update_list(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                smsreseller_subgrupos_id:this.subgroup_data.id,
                search_word:data.search_word
            }
            this.endpoint.get_clients_not_in_subgroup(load).subscribe(
                data => response = data,
                err => {
                    this.unassigned_client_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        for(var i = 0;i<response.list.length;i++){
                            if(response.list[i].cli_celular){
                                var masked_number = conformToMask(
                                    response.list[i].cli_celular,
                                    this.cellphone_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_celular = masked_number.conformedValue;
                            }
                            if(response.list[i].cli_telefono){
                                var masked_phone = conformToMask(
                                    response.list[i].cli_telefono,
                                    this.phone_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_telefono = masked_phone.conformedValue;
                            }
                            if(response.list[i].cli_identidad){
                                var masked_id = conformToMask(
                                    response.list[i].cli_identidad,
                                    this.id_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_identidad = masked_id.conformedValue;
                            }  
                        }
                        this.unassigned_client_datatable_ref.set_results_update_list(response.list, response.count);
                    }catch(error){
                        this.unassigned_client_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

        unassigned_memberships_datatable_events(event){
            if(event.event == "Datos del Cliente"){
                this.unassigned_memberships_datatable_open_view_client_group(event.data);
            }else if(event.event == "Agregar Miembro"){
                this.assign_membership_to_subgroup(event.data.id, this.subgroup_data.id);
            }
        }

        unassigned_memberships_close_view_memberships(){
            this.client_data = {
                id:"",
                cli_nombre:"",
                cli_identidad:"",
                cli_celular:""
            };
            this.extend_memberships(this.subgroup_data.group_index, this.group_data);
            this.subgroup_modal.hide();
        }

        unassigned_memberships_datatable_open_view_client_group(data){
            this.subgroup_client_form_view.clean_form();
            this.subgroup_client_form_view.set_values(data);
            this.client_data = {
                id:data.id,
                cli_nombre:data.cli_nombre,
                cli_identidad:data.cli_identidad,
                cli_celular:data.cli_celular
            }
            this.subgroup_modal_view = 6;
        }

        unassigned_memberships_datatable_close_view_client(){
            this.subgroup_client_form_view.clean_form();
            this.client_data = {
                id:"",
                cli_nombre:"",
                cli_identidad:"",
                cli_celular:""
            }
            this.subgroup_modal_view = 5;
        }

        assign_membership_to_subgroup(client_id, subgroup_id){
            this.unassigned_client_datatable_ref.set_loading(true);
            var load = {
                smsreseller_clientes_id:client_id,
                smsreseller_subgrupos_id:subgroup_id
            };
            var response;
            this.endpoint.assign_membership_to_subgroup(load).subscribe(
                data => response = data,
                err => {
                    this.unassigned_client_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.subgroup_data.total = (parseInt(this.subgroup_data.total) +1)+"";
                        this.unassigned_client_datatable_ref.emit_get_results_update_list();
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.unassigned_client_datatable_ref.set_loading(false);
                    }
                }
            );
        }

    //MEMBERSHIPS ############################################################
    //########################################################################

    //########################################################################
    //GROUPS #################################################################

        group_datatable_events(index, event){
            if(event.event == "Editar Grupo"){
                this.open_update_group(event.data);
            }else if(event.event == "Eliminar Grupo"){
                this.open_delete_group(event.data);
            }else if(event.event == "Limpiar"){
                this.open_unassign_membership_by_group(event.data);
            }else if(event.event == "Agregar Subgrupo"){
                this.open_insert_subgroup(index, event.data);
            }
        }

        get_groups(){
            this.group_datatable_loading = true;
            var response;
            this.endpoint.get_groups().subscribe(
                data => response = data,
                err => {
                    this.group_list = [];
                    this.group_datatable_loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    this.group_list = [];
                    for(var i = 0;i<response.length;i++){
                        response[i].subgroups = [];
                        response[i].extended = false;
                    }
                    this.group_list = response;
                    this.group_datatable_loading = false;
                }
            );
        }

        extend_memberships(index, data){
            this.group_list[index].extended = true;
            this.group_datatable_loading = true;
            var load = {
                smsreseller_grupos_id:data.id
            };
            var response;
            this.endpoint.get_subgroups(load).subscribe(
                data => response = data,
                err => {
                    this.group_list[index].subgroups = [];
                    this.group_datatable_loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    this.group_list[index].subgroups = [];
                    this.group_list[index].subgroups = response;
                    this.group_datatable_loading = false;
                }
            );
        }

        contract_memberships(index, data){
            this.group_list[index].extended = false;
            this.group_list[index].subgroups = [];
        }

        open_insert_group(){
            this.group_loading = false;
            this.group_form.clean_form();
            this.group_modal_view = 1;
            this.group_modal.show();
        }

        insert_group(){
            if(this.group_form.valid()){
                this.group_loading = true;
                var form_values = this.group_form.get_values();
                var load = {
                    grp_nombre:form_values.grp_nombre
                };
                var response;
                this.endpoint.insert_group(load).subscribe(
                    data => response = data,
                    err => {
                        this.group_loading = false;
                        if(err.status && err.error){
                            this.alertService.alert_message(err.status ,err.error);
                        }else{
                            this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                        }
                    },
                    ()=> {
                        try{
                            this.group_modal.hide();
                            this.get_groups();
                            this.alertService.alert_success(response.title, response.message);
                            this.group_loading = false;
                        }catch(error){
                            this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                            this.group_loading = false;
                        }
                    }
                );
            }
        }

        open_update_group(data){
            this.group_form.clean_form();
            this.group_form.set_values(data);
            this.group_loading = false;
            this.group_modal_view = 2;
            this.group_modal.show();
        }

        update_group(){
            if(this.group_form.valid()){
                this.alertService.option_alert("Editar Grupo","¿Está seguro que desea editar el grupo seleccionado?","Sí, Editar").then((result) => {
                    if(result.value){
                        this.group_loading = true;
                        var form_values = this.group_form.get_values();
                        var load = {
                            id:form_values.id,
                            grp_nombre:form_values.grp_nombre
                        };
                        var response;
                        this.endpoint.update_group(load).subscribe(
                            data => response = data,
                            err => {
                                this.group_loading = false;
                                if(err.status && err.error){
                                    this.alertService.alert_message(err.status ,err.error);
                                }else{
                                    this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                                }
                            },
                            ()=> {
                                try{
                                    this.group_modal.hide();
                                    this.get_groups();
                                    this.alertService.alert_success(response.title, response.message);
                                    this.group_loading = false;
                                }catch(error){
                                    this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                                    this.group_loading = false;
                                }
                            }
                        );
                    }
                }).catch(()=>{return false;}); 
            }
        }

        open_delete_group(data){
            this.alertService.option_alert("Eliminar Grupo","¿Está seguro que desea eliminar el grupo seleccionado?<br><br><b>Grupo:<br></b><b class='text-success'> "+data.grp_nombre+"</b>","Sí, Eliminar").then((result) => {
                if(result.value){
                    this.delete_group(data.id);
                }
            }).catch(()=>{return false;});
        }

        delete_group(id){
            this.group_datatable_loading = true;
            var load = {
                id:id
            };
            var response;
            this.endpoint.delete_group(load).subscribe(
                data => response = data,
                err => {
                    this.group_datatable_loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.get_groups();
                        this.alertService.alert_success(response.title, response.message);
                        this.group_datatable_loading = false;
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.group_datatable_loading = false;
                    }
                }
            );
        }

    //GROUPS #################################################################
    //########################################################################

    //########################################################################
    //SUBGROUPS ##############################################################

        subgroup_datatable_events(group_index, event){
            if(event.event == "Editar Subgrupo"){
                this.open_update_subgroup(group_index, event.data, event.group_data);
            }else if(event.event == "Eliminar Subgrupo"){
                this.open_delete_subgroup(group_index, event.data, event.group_data);
            }else if(event.event == "Miembros"){
                this.open_view_subgroup_membership(group_index, event.data, event.group_data);
            }else if(event.event == "Limpiar"){
                this.open_unassign_membership_subgroup(group_index, event.data, event.group_data);
            }
        }

        open_insert_subgroup(index, data){
            this.group_data = {
                id:data.id,
                grp_nombre:data.grp_nombre
            }
            this.subgroup_modal_view = 1;
            this.subgroup_modal.show();
            this.subgroup_form.clean_form();
            this.subgroup_loading = false;
        }

        insert_subgroup(){
            if(this.subgroup_form.valid()){
                this.subgroup_loading = true;
                var form_values = this.subgroup_form.get_values();
                var load = {
                    smsreseller_grupos_id:this.group_data.id,
                    sgp_nombre:form_values.sgp_nombre
                };
                var response;
                this.endpoint.insert_subgroup(load).subscribe(
                    data => response = data,
                    err => {
                        this.subgroup_loading = false;
                        if(err.status && err.error){
                            this.alertService.alert_message(err.status ,err.error);
                        }else{
                            this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                        }
                    },
                    ()=> {
                        try{
                            this.subgroup_modal.hide();
                            var index = -1;
                            for(var i = 0;i<this.group_list.length;i++){
                                if(this.group_list[i].id == this.group_data.id){
                                    index = i;
                                }
                            }
                            this.extend_memberships(index, this.group_data);
                            this.subgroup_loading = false;
                        }catch(error){
                            this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                            this.subgroup_loading = false;
                        }
                    }
                );
            }
        }

        open_update_subgroup(group_index, data, group_data){
            this.group_data = {
                id:group_data.id,
                grp_nombre:group_data.grp_nombre
            }
            data.group_index = group_index;
            this.subgroup_form.clean_form();
            this.subgroup_form.set_values(data);
            this.subgroup_modal_view = 2;
            this.subgroup_modal.show();
            this.subgroup_loading = false;
        }

        update_subgroup(){
            if(this.subgroup_form.valid()){
                this.alertService.option_alert("Editar Subgrupo","¿Está seguro que desea editar el subgrupo seleccionado?","Sí, Editar").then((result) => {
                    if(result.value){
                        this.subgroup_loading = true;
                        var form_values = this.subgroup_form.get_values();
                        var load = {
                            id:form_values.id,
                            sgp_nombre:form_values.sgp_nombre
                        };
                        var response;
                        this.endpoint.update_subgroup(load).subscribe(
                            data => response = data,
                            err => {
                                this.subgroup_loading = false;
                                if(err.status && err.error){
                                    this.alertService.alert_message(err.status ,err.error);
                                }else{
                                    this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                                }
                            },
                            ()=> {
                                try{
                                    this.subgroup_modal.hide();
                                    this.extend_memberships(form_values.group_index, this.group_data);
                                    this.alertService.alert_success(response.title, response.message);
                                    this.subgroup_loading = false;
                                }catch(error){
                                    this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                                    this.subgroup_loading = false;
                                }
                            }
                        );
                    }
                }).catch(()=>{return false;}); 
            }
        }

        open_delete_subgroup(index, data, group_data){
            this.alertService.option_alert("Eliminar Subgrupo","¿Está seguro que desea eliminar el subgrupo seleccionado?<br><br><b>Subgrupo:<br></b><b class='text-success'> "+data.sgp_nombre+"</b>","Sí, Eliminar").then((result) => {
                if(result.value){
                    this.delete_subgroup(index, data.id, group_data);
                }
            }).catch(()=>{return false;});
        }

        delete_subgroup(index, id, group_data){
            this.group_datatable_loading = true;
            var load = {
                id:id
            };
            var response;
            this.endpoint.delete_subgroup(load).subscribe(
                data => response = data,
                err => {
                    this.group_datatable_loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.extend_memberships(index, group_data);
                        this.alertService.alert_success(response.title, response.message);
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.group_datatable_loading = false;
                    }
                }
            );
        }

        open_unassign_membership_subgroup(index, data, group_data){
            this.alertService.option_alert("Limpiar Membresías","¿Está seguro que desea limpiar las membresías el subgrupo seleccionado?<br><br><b>SubGrupo:<br></b><b class='text-success'> "+data.sgp_nombre+"</b>","Sí, Limpiar").then((result) => {
                if(result.value){
                    this.unassign_membership_subgroup(index, data.id, group_data);
                }
            }).catch(()=>{return false;});
        }

        unassign_membership_subgroup(index, id, group_data){
            this.group_datatable_loading = true;
            var load = {
                smsreseller_subgrupos_id:id
            };
            var response;
            this.endpoint.unassign_membership_by_subgroup(load).subscribe(
                data => response = data,
                err => {
                    this.group_datatable_loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.extend_memberships(index, group_data);
                        this.alertService.alert_success(response.title, response.message);
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.group_datatable_loading = false;
                    }
                }
            );
        }

    //SUBGROUPS ##############################################################
    //########################################################################

    //########################################################################
    //CATALOGS ###############################################################

        get_labels(){
            var response;
            this.endpoint.get_labels().subscribe(
                data => response = data,
                err => {
                    this.label_list = [];
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    this.label_list = [];
                    this.label_list = response;
                    this.set_datatable(this.label_list);
                    this.set_inputs(this.label_list);
                }
            );
        }

    //CATALOGS ###############################################################
    //########################################################################

    //########################################################################
    //MISC ###################################################################

        set_datatable(labels:any[]){
            this.client_datatable = {
                title:"",
                show_search:true,
                search_placeholder:"- Buscar -",
                icon:"users",
                object_description:"clientes",
                empty_text:"El elemento seleccionado no posee miembros asignados",
                columns:[
                    {   
                        column:"cli_nombre",
                        wrap_column:false,
                        header:"Nombre del Cliente",
                        wrap_header:true,
                        type:"text"
                    },
                    {   
                        column:"cli_celular",
                        wrap_column:true,
                        header:"Número de Celular",
                        wrap_header:true,
                        type:"text"
                    }
                ],
                events:[
                    {
                        name:"Datos del Cliente",
                        style:"color:#39B7CB",
                        hover_style:"cursor:pointer; color:#39B7CB; background-color:#BDF0FF !important;",
                        icon:"search"
                    },
                    {
                        name:"Quitar Miembro",
                        style:"color:#FB5D5D",
                        hover_style:"cursor:pointer; color:#FB5D5D; background-color:#FEDCDC !important;",
                        icon:"user-slash",
                        text:"Quitar"
                    }
                ],
                navigation_offsets:[5,10,15,20,25,50],
                navigation_starting_offset_index:1,
                show_search_field:true,
                table_icon:"caret-right"
            }
            this.client_datatable_add = {
                title:"",
                show_search:true,
                search_placeholder:"- Buscar -",
                icon:"users",
                object_description:"clientes",
                empty_text:"No se encontraron clientes",
                columns:[
                    {   
                        column:"cli_nombre",
                        wrap_column:false,
                        header:"Nombre del Cliente",
                        wrap_header:true,
                        type:"text"
                    },
                    {   
                        column:"cli_celular",
                        wrap_column:true,
                        header:"Número de Celular",
                        wrap_header:true,
                        type:"text"
                    }
                ],
                events:[
                    {
                        name:"Datos del Cliente",
                        style:"color:#39B7CB",
                        hover_style:"cursor:pointer; color:#39B7CB; background-color:#BDF0FF !important;",
                        icon:"search"
                    },
                    {
                        name:"Agregar Miembro",
                        style:"color:#57A473",
                        hover_style:"cursor:pointer; color:#57A473; background-color:#C1FFD7 !important;",
                        icon:"user-plus",
                        text:"Agregar"
                    }
                ],
                navigation_offsets:[5,10,15,20,25,50],
                navigation_starting_offset_index:1,
                show_search_field:true,
                table_icon:"caret-right"
            }
            for(var i = 0;i<labels.length;i++){
                for(var j = 0;j<this.client_datatable.columns.length;j++){
                    if(labels[i].var_campo==this.client_datatable.columns[j].column){
                        this.client_datatable.columns[j].header = labels[i].var_alias;
                    }
                }
                
            }
            for(var i = 0;i<labels.length;i++){
                for(var j = 0;j<this.client_datatable_add.columns.length;j++){
                    if(labels[i].var_campo==this.client_datatable_add.columns[j].column){
                        this.client_datatable_add.columns[j].header = labels[i].var_alias;
                    }
                }
                
            }
        }

        set_inputs(labels:any[]){
            this.client_inputs_view=[
                {
                    class:"row",
                    columns:[
                        {
                            class:"col-md-3",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_celular",
                                    label:"Número de Celular",
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"###-####-####",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"Formato Inválido",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                        {
                            class:"col-md-3",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_identidad",
                                    label:"No. de Identidad",
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"####-####-#####",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"Formato Inválido",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                        {
                            class:"col-md-6",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_nombre",
                                    label:"Nombre del Cliente",
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    class:"row",
                    columns:[                      
                        {
                            class:"col-md-3",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_fecha_nac",
                                    label:"Nacimiento",
                                    icon:"",
                                    class:"form-control",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:"50",
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return false;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                        {
                            class:"col-md-3",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_sexo",
                                    label:"Sexo",
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"value",
                                        text:"text"
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                        {
                            class:"col-md-6",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_email",
                                    label:"Correo Electrónico",
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                    ]
                },
                {
                    class:"row",
                    columns:[
                        {
                            class:"col-md-3",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_telefono",
                                    label:"Teléfono Fijo",
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"####-####",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"Formato Inválido",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                        {
                            class:"col-md-9",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_direccion",
                                    label:"Dirección",
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                    ]
                },
                {
                    class:"row",
                    columns:[]
                }
            ];
            for(var i = 0;i<labels.length;i++){
                if(labels[i].var_alias && labels[i].id && (labels[i].var_campo=="cli_campo1" ||
                   labels[i].var_campo=="cli_campo2" ||
                   labels[i].var_campo=="cli_campo3" ||
                   labels[i].var_campo=="cli_campo4" ||
                   labels[i].var_campo=="cli_campo5" ||
                   labels[i].var_campo=="cli_campo6" ||
                   labels[i].var_campo=="cli_campo7")){

                    var type = "";
                    var maxlength = "";
                    var pattern = "";
                    var mask;
                    var error_pattern = "";
                    var extra = "";

                    if(labels[i].tipo_etiqueta_id==1){
                        type = "text";
                        maxlength = "70";
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==2){
                        type = "integer";
                        maxlength = null;
                        pattern = "";
                        mask = false;
                        error_pattern = "Formato Inválido";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==3){
                        type = "decimal";
                        maxlength = null;
                        pattern = "";
                        mask = false;
                        error_pattern = "Formato Inválido";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==4){
                        type = "date";
                        maxlength = null;
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==5){
                        type = "time";
                        maxlength = null;
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==6){
                        type = "calendar";
                        maxlength = null;
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "popup";
                    }
                    this.client_inputs_view[3].columns.push(
                        {
                            class:"col-md-6",
                            inputs:[
                                {
                                    type:type,
                                    extra:extra,
                                    name:labels[i].var_campo,
                                    label:labels[i].var_alias,
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                    );
                }else if(labels[i].var_alias && labels[i].id && (labels[i].var_campo=="cli_cif")){
                    if(labels[i].tipo_etiqueta_id==1){
                        type = "text";
                        maxlength = "50";
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==2){
                        type = "integer";
                        maxlength = null;
                        pattern = "";
                        mask = false;
                        error_pattern = "Formato Inválido";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==3){
                        type = "decimal";
                        maxlength = null;
                        pattern = "";
                        mask = false;
                        error_pattern = "Formato Inválido";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==4){
                        type = "date";
                        maxlength = null;
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==5){
                        type = "time";
                        maxlength = null;
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==6){
                        type = "calendar";
                        maxlength = null;
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "popup";
                    }
                    this.client_inputs_view[3].columns.push(
                        {
                            class:"col-md-6",
                            inputs:[
                                {
                                    type:type,
                                    extra:extra,
                                    name:labels[i].var_campo,
                                    label:labels[i].var_alias,
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                    );
                }else if(labels[i].var_alias && labels[i].id && (labels[i].var_campo=="cli_cm")){
                    if(labels[i].tipo_etiqueta_id==1){
                        type = "text";
                        maxlength = "30";
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==2){
                        type = "integer";
                        maxlength = null;
                        pattern = "";
                        mask = false;
                        error_pattern = "Formato Inválido";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==3){
                        type = "decimal";
                        maxlength = null;
                        pattern = "";
                        mask = false;
                        error_pattern = "Formato Inválido";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==4){
                        type = "date";
                        maxlength = null;
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==5){
                        type = "time";
                        maxlength = null;
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==6){
                        type = "calendar";
                        maxlength = null;
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "popup";
                    }
                    this.client_inputs_view[3].columns.push(
                        {
                            class:"col-md-6",
                            inputs:[
                                {
                                    type:type,
                                    extra:extra,
                                    name:labels[i].var_campo,
                                    label:labels[i].var_alias,
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                    );
                }else if(labels[i].var_alias && labels[i].id && (labels[i].var_campo=="cli_tarjeta")){
                    if(labels[i].tipo_etiqueta_id==1){
                        type = "text";
                        maxlength = "50";
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==2){
                        type = "integer";
                        maxlength = null;
                        pattern = "";
                        mask = false;
                        error_pattern = "Formato Inválido";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==3){
                        type = "decimal";
                        maxlength = null;
                        pattern = "";
                        mask = false;
                        error_pattern = "Formato Inválido";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==4){
                        type = "date";
                        maxlength = null;
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==5){
                        type = "time";
                        maxlength = null;
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==6){
                        type = "calendar";
                        maxlength = null;
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "popup";
                    }
                    this.client_inputs_view[3].columns.push(
                        {
                            class:"col-md-6",
                            inputs:[
                                {
                                    type:type,
                                    extra:extra,
                                    name:labels[i].var_campo,
                                    label:labels[i].var_alias,
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                    );
                }
            }
            for(var i = 0;i<labels.length;i++){
                for(var j = 0;j<this.client_inputs_view.length;j++){
                    for(var m = 0;m<this.client_inputs_view[j].columns.length;m++){
                        for(var n = 0;n<this.client_inputs_view[j].columns[m].inputs.length;n++){
                            if(this.client_inputs_view[j].columns[m].inputs[n].name == labels[i].var_campo){
                                this.client_inputs_view[j].columns[m].inputs[n].label = labels[i].var_alias;
                            }
                        }
                    }
                }
            }
        }

    //MISC ###################################################################
    //########################################################################

    ngOnDestroy() {
        setTimeout(() => this.layoutService.off('resize.app-home'));
    }

}
