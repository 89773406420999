import { Component, ViewChildren, QueryList, AfterViewInit, OnDestroy, OnInit  } from '@angular/core';
import { AppService } from '../../app.service';
import { AppEndpoints } from '../../app.endpoints';
import { LayoutService } from '../../layout/layout.service';

@Component({
    selector: 'menu_reports',
    templateUrl: './menu_reports.component.html'
})
export class MenuReportsComponent implements OnInit{

    public datatable:any;

    constructor(private appService: AppService, public endpoint : AppEndpoints,  private layoutService: LayoutService) {
        this.appService.pageTitle = 'Menú Reportería';
    }

    ngOnInit(){

    }

    ngAfterViewInit() {

    }

    ngOnDestroy() {
        setTimeout(() => this.layoutService.off('resize.app-home'));
    }

}
