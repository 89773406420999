import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuard } from './app.loginguard';

// *******************************************************************************
// Layouts

import { LayoutComponent } from './layout/layout/layout.component';

// *******************************************************************************
// Pages

import { LoginComponent } from './login/login.component';
import { RecoverPasswordComponent } from './recover_password/recover_password.component';
import { NotFoundComponent } from './not_found/not_found.component';
import { HomeComponent } from './home/home.component';
import { ManualComponent } from './manual/manual.component';

/** Users */
import { UsersComponent } from './menus/menu_users/users/users.component';

/** Clients */
import { MenuClientsComponent } from './menus/menu_clients/menu_clients.component';
import { GroupsComponent } from './menus/menu_clients/groups/groups.component';
import { ClientsComponent } from './menus/menu_clients/clients/clients.component';
import { LabelsComponent } from './menus/menu_clients/labels/labels.component';
import { ImportClientsComponent } from './menus/menu_clients/import_clients/import_clients.component';
import { DeleteClientsComponent } from './menus/menu_clients/delete_clients/delete_clients.component';

/** SMS */
import { MenuSmsComponent } from './menus/menu_sms/menu_sms.component';
import { PlantillasSmsComponent } from './menus/menu_sms/plantillas_sms/plantillas_sms.component';
import { EnviarSmsComponent } from './menus/menu_sms/enviar_sms/enviar_sms.component';
import { ImportarEnviarSmsComponent } from './menus/menu_sms/importar_enviar_sms/importar_enviar_sms.component';
import { CalendarioSmsComponent } from './menus/menu_sms/calendario_sms/calendario_sms.component';

/** Surveys */
import { MenuSurveyComponent } from './menus/menu_survey/menu_survey.component';
import { EnviarEncuestaSatisfaccionComponent } from './menus/menu_survey/enviar_encuesta_satisfaccion/enviar_encuesta_satisfaccion.component';
import { ResultadosEncuestaSatisfaccionComponent } from './menus/menu_survey/resultados_encuesta_satisfaccion/resultados_encuesta_satisfaccion.component';
import { CreateSurveyComponent } from './menus/menu_survey/create_survey/create_survey.component';

/** Reports */
import { MenuReportsComponent } from './menus/menu_reports/menu_reports.component';
import { InboxComponent } from './menus/menu_reports/inbox/inbox.component';
import { OutboxComponent } from './menus/menu_reports/outbox/outbox.component';
import { SentShortcodeComponent } from './menus/menu_reports/sent_shortcode/sent_shortcode.component';
import { OutboxSmppComponent } from './menus/menu_reports/outbox_smpp/outbox_smpp.component';
import { OutboxSmppLimitedComponent } from './menus/menu_reports/outbox_smpp_limited/outbox_smpp_limited.component';
import { SmsSentNumberComponent } from './menus/menu_reports/sms_sent_number/sms_sent_number.component';
import { ShipingDetailComponent } from './menus/menu_reports/shiping_detail/shiping_detail.component';
import { BitacoraDetailComponent } from './menus/menu_reports/bitacora_detail/bitacora_detail.component';
import { AreaShipingHistoryComponent } from './menus/menu_reports/area_shiping_history/area_shiping_history.component';
import { DailyShipingHistoryComponent } from './menus/menu_reports/daily_shiping_history/daily_shiping_history.component';
import { MonthlyShipingHistoryComponent } from './menus/menu_reports/monthly_shiping_history/monthly_shiping_history.component';
import { YearlyShipingHistoryComponent } from './menus/menu_reports/yearly_shiping_history/yearly_shiping_history.component';
import { AvailableSMSComponent } from './menus/menu_reports/available_sms/available_sms.component';
import { CostExplorerComponent } from './menus/menu_reports/cost_explorer/cost_explorer.component';
import { ConsolidatedBalancesComponent } from './menus/menu_reports/consolidated_balances/consolidated_balances.component';
import { OutboxSmppLimited24HComponent } from './menus/menu_reports/outbox_smpp_limited_24h/outbox_smpp_limited_24h.component';

// *******************************************************************************
// Routes

const routes: Routes = [
    {
        path: '',
        component: LoginComponent
    },
    {
        path: 'conectarse',
        redirectTo: '',
        pathMatch: 'full'
    },
    {
        path: 'recuperar',
        component: RecoverPasswordComponent
    },
    {
        path: '404',
        component: NotFoundComponent
    },
    {
        path: 'plataforma',
        component: LayoutComponent,
        children: [
            {
                path: '',
                redirectTo: 'inicio',
                pathMatch: 'full'
            },
            {
                path: 'inicio',
                component: HomeComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'manual',
                component: ManualComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'admin_usuarios',
                component: UsersComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_clientes',
                component: MenuClientsComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_clientes/grupos',
                component: GroupsComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_clientes/clientes',
                component: ClientsComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_clientes/etiquetas',
                component: LabelsComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_clientes/importar_exportar',
                component: ImportClientsComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_clientes/borrar_clientes',
                component: DeleteClientsComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_sms',
                component: MenuSmsComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_sms/plantillas_sms',
                component: PlantillasSmsComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_sms/enviar_sms',
                component: EnviarSmsComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_sms/importar_enviar_sms',
                component: ImportarEnviarSmsComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_sms/calendario_sms',
                component: CalendarioSmsComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_sms',
                component: MenuSmsComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_encuestas',
                component: MenuSurveyComponent,
                canActivate: [LoginGuard]
            },
            /*{
                path: 'menu_encuestas/encuesta_satisfaccion',
                component: EnviarEncuestaSatisfaccionComponent,
                canActivate: [LoginGuard]
            },*/
            {
                path: 'menu_encuestas/encuestas_personalizadas',
                component: CreateSurveyComponent,
                canActivate: [LoginGuard]
            },
            /*{
                path: 'menu_encuestas/resultados_satisfaccion',
                component: ResultadosEncuestaSatisfaccionComponent,
                canActivate: [LoginGuard]
            },*/
            {
                path: 'detalle_envios',
                component: ShipingDetailComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'detalle_bitacoras',
                component: BitacoraDetailComponent,
                canActivate: [LoginGuard]
            },
            /*{
                path: 'menu_reporteria/bandeja_salida',
                component: OutboxComponent,
                canActivate: [LoginGuard]
            },*/
            {
                path: 'menu_reporteria',
                component: MenuReportsComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_reporteria/bandeja_entrada',
                component: InboxComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_reporteria/enviados_remitente',
                component: SentShortcodeComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_reporteria/bandeja_salida_smpp',
                component: OutboxSmppComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_reporteria/bandeja_salida_smpp_24h',
                component: OutboxSmppLimited24HComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_reporteria/bandeja_salida_smpp_acceso_limitado',
                component: OutboxSmppLimitedComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_reporteria/bandeja_salida_smpp_acceso_limitado_24h',
                component: OutboxSmppLimited24HComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_reporteria/historico_envio_area',
                component: AreaShipingHistoryComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_reporteria/historico_envio_diario',
                component: DailyShipingHistoryComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_reporteria/historico_envio_mensual',
                component: MonthlyShipingHistoryComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_reporteria/historico_envio_anual',
                component: YearlyShipingHistoryComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_reporteria/sms_disponible',
                component: AvailableSMSComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'menu_reporteria/reporte_numero',
                component: SmsSentNumberComponent,
                canActivate: [LoginGuard]
            },
            /*{
                path: 'menu_reporteria/explorador_costos',
                component: CostExplorerComponent,
                canActivate: [LoginGuard]
            },*/
            {
                path: 'menu_reporteria/balances_consolidados',
                component: ConsolidatedBalancesComponent,
                canActivate: [LoginGuard]
            }
        ]
    },
    {
        path: '**',
        redirectTo: '/404',
        pathMatch: 'full'
    }
];

// *******************************************************************************
//

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
