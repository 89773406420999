import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, OnInit  } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { AppService } from '../../../app.service';
import { AppEndpoints } from '../../../app.endpoints';
import { LayoutService } from '../../../layout/layout.service';
import { ModalDirective } from 'ngx-bootstrap';
import { default as swal } from 'sweetalert2';
import { FormRendererComponent } from '../../../components/form_renderer/form_renderer.component';
import { AlertService } from '../../../components/alert_service/alert.service';
import { ExcelService } from '../../../components/excel_service/excel.service';
import { FileSystemFileEntry } from 'ngx-file-drop';
import { UploadDatatableComponent } from '../../../components/upload_datatable/upload_datatable.component';
import { GroupDatatableComponent } from '../../../components/group-datatable/group-datatable.component';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import * as FileSaver from 'file-saver';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
    selector: 'importar-enviar-sms',
    templateUrl: './importar_enviar_sms.component.html',
    styleUrls:[
        '../../../../vendor/libs/spinkit/spinkit.scss'
    ]
})

export class ImportarEnviarSmsComponent implements OnInit{
    @ViewChild("send_sms_ref") send_sms_ref;
    @ViewChild('upload_files_modal') upload_files_modal: ModalDirective;
    @ViewChild('current_file_datatable_ref') current_file_datatable_ref: UploadDatatableComponent;
    public view:number;
    public loading:boolean;
    public upload_files_modal_view:number;
    public upload_files_loading:boolean;
    public upload_files_config = {
        url: '/upload',
        parallelUploads: 2,
        maxFilesize:     50000,
        filesizeBase:    1000,
        addRemoveLinks:  true
    }
    public upload_files_list:any[];
    public upload_files_ok_list:any[];
    public upload_files_formatted_list:any[];

    public current_file_counter:number;
    public current_file_datatable:any;
    public current_file_datatable_loading:boolean;

    public label_list:any[];
    public group_list:any[];
    public selected_membership_index:number;

    public subgroup_datatable:any;
    public subgroup_datatable_loading:boolean;
    public subgroup_selected:any[];

    public send_sms_loading:boolean;

    public group_data = {
        id:"",
        grp_nombre:"",
        index:""
    };
    public search_word:string;

    constructor(private appService: AppService, public endpoint : AppEndpoints,  private layoutService: LayoutService, private alertService: AlertService, private excelService: ExcelService) {
        this.appService.pageTitle = 'Importar/Exportar Clientes';
        this.subgroup_selected = [];
        this.search_word = "";
        this.view = 1;
        this.loading = false;
        this.subgroup_datatable_loading = false;
        this.subgroup_datatable = {
            title:"Importar y Enviar SMS",
            icon:"enter",
            object_description:"grupos",
            empty_text:"No existen grupos registrados dentro del sistema",
            header_class: "custom-th-dark",
            columns:[
                {   
                    column:"grp_nombre",
                    type:"text"
                },
                {   
                    column:"sgp_nombre",
                    type:"text"
                },
            ],
            events:[
                {
                    name:"Importar Registros",
                    style:"color:#57A473",
                    hover_style:"cursor:pointer; color:#57A473; background-color:#C1FFD7 !important;",
                    icon:"upload"
                },
                {
                    name:"Enviar SMS",
                    style:"color:#39B7CB",
                    hover_style:"cursor:pointer; color:#39B7CB; background-color:#BDF0FF !important;",
                    icon:"share-square"
                }
            ],
            navigation_offsets:[5,10,15,20,25,50],
            navigation_starting_offset_index:1,
            show_search_field:true,
            table_icon:"caret-right"
        }
        this.upload_files_loading = false;
        this.upload_files_modal_view = 1;
        this.upload_files_list = [];
        this.upload_files_ok_list = [];
        this.upload_files_formatted_list = [];
        this.current_file_counter = 0;
        this.current_file_datatable_loading = false;
        this.current_file_datatable = {
            object_description:"registros",
            header_options:['cli_identidad', 'cli_nombre', 'cli_sexo', 'cli_fecha_nac', 'cli_celular', 'cli_telefono', 'cli_email', 'cli_direccion', 'cli_tarjeta', 'cli_campo1', 'cli_campo2', 'cli_campo3', 'cli_campo4', 'cli_campo5', 'cli_campo6', 'cli_campo7', 'cli_tarjeta', 'cli_mensaje', 'cli_cif', 'cli_cm'],
            empty_text:"No se encontraron registros en el listado",
            events:[
                {
                    name:"Eliminar Registro",
                    style:"color:#FB5D5D",
                    hover_style:"cursor:pointer; color:#FB5D5D; background-color:#FEDCDC !important;",
                    icon:"trash-alt"
                }
            ],
            navigation_offsets:[5,10,15,20,25,50],
            navigation_starting_offset_index:1,
            table_icon:"caret-right"
        }
        this.label_list = [];
        this.group_list = [];
        this.selected_membership_index = -1;

        this.send_sms_loading = false;
    }

    ngOnInit(){
        this.get_labels();
        //this.get_memberships();
        this.get_groups();
    }

    //########################################################################
    //FILES UPLOAD ###########################################################

        open_upload_files(){
            this.subgroup_selected = [];
            this.upload_files_loading = false;
            this.upload_files_modal_view = 1;
            this.upload_files_list = [];
            this.upload_files_ok_list = [];
            this.upload_files_formatted_list = [];
            this.upload_files_modal.show();
            this.group_data = {
                id:"",
                grp_nombre:"",
                index:""
            }
        }

        close_upload_files(){
            this.send_sms_loading = false;
            this.upload_files_list = [];
            this.upload_files_ok_list = [];
            this.upload_files_formatted_list = [];
            this.upload_files_modal.hide();
            this.current_file_datatable_ref.set_results([]);
            this.current_file_datatable_ref.set_headers([]);
            if(this.group_data.index){
                this.extend_memberships(this.group_data.index, this.group_data);
            }
        }

        close_upload_files_confirmation(){
            this.alertService.option_alert("Cancelar Carga de Archivos","¿Está seguro que desea cancelar la carga de archivos?","Sí, Cancelar").then((result) => {
                if(result.value){
                    this.upload_files_list = [];
                    this.upload_files_ok_list = [];
                    this.upload_files_formatted_list = [];
                    this.upload_files_modal.hide();
                    this.current_file_datatable_ref.set_results([]);
                    this.current_file_datatable_ref.set_headers([]);
                    if(this.group_data.index){
                        this.extend_memberships(this.group_data.index, this.group_data);
                    }
                }
            }).catch(()=>{return false;});
        }

        drop_file(data){
            if(data.files.length>0){
                this.upload_files_loading = true;
            }
            var total_files = 0;
            var counted_files = 0;
            for(var i = 0;i<data.files.length;i++){
                if(data.files[i].fileEntry.isFile){
                    total_files++;
                }
            }
            for(var i = 0;i<data.files.length;i++){
                if(data.files[i].fileEntry.isFile){
                    var fileEntry = data.files[i].fileEntry as FileSystemFileEntry;
                    fileEntry.file((file: File) => {
                        try{
                            if(file.name.substring(0,2)!="~$" && (file.name.split(".")[file.name.split(".").length-1] == "csv" || file.name.split(".")[file.name.split(".").length-1] == "xls" || file.name.split(".")[file.name.split(".").length-1] == "xlsx")){
                                var reader = new FileReader();
                                reader.readAsBinaryString(file);
                                reader.onload = (loaded_data) => {
                                    const binary_string = reader.result;
                                    const wb: XLSX.WorkBook = XLSX.read(binary_string, {type: 'binary'});
                                    const wsname: string = wb.SheetNames[0];
                                    const ws: XLSX.WorkSheet = wb.Sheets[wsname];
                                    var list_ref:any[] = (XLSX.utils.sheet_to_json(ws, {header: 1}));

                                    if(list_ref.length == 0){
                                        this.upload_files_list.push({name:file.name, status:"ERROR", status_message:"archivo vacío", values:[], length:0});
                                    }else if(list_ref.length == 1){
                                        if(this.validate_is_header(list_ref[0])){
                                            this.upload_files_list.push({name:file.name, status:"ERROR", status_message:"archivo vacío", values:[], length:0});
                                        }else{
                                            var max = -Infinity;
                                            list_ref.forEach(function(a){
                                                if (a.length>max) {
                                                    max = a.length;
                                                }
                                            });
                                            if(max>19){
                                                this.upload_files_list.push({name:file.name, status:"ERROR", status_message:"archivo excede 20 columnas", values:[], length:0});
                                            }else{
                                                this.upload_files_list.push({name:file.name, status:"OK", values:list_ref, length:1});
                                            }
                                            
                                        }
                                    }else{
                                        if(this.validate_is_header(list_ref[0])){
                                            var max = -Infinity;
                                            list_ref.forEach(function(a){
                                                if (a.length>max) {
                                                    max = a.length;
                                                }
                                            });
                                            if(max>20){
                                                this.upload_files_list.push({name:file.name, status:"ERROR", status_message:"archivo excede 20 columnas", values:[], length:0});
                                            }else{
                                                this.upload_files_list.push({name:file.name, status:"OK", values:list_ref, length:list_ref.length - 1});
                                            }
                                            
                                        }else{
                                            var max = -Infinity;
                                            list_ref.forEach(function(a){
                                                if (a.length>max) {
                                                    max = a.length;
                                                }
                                            });
                                            if(max>20){
                                                this.upload_files_list.push({name:file.name, status:"ERROR", status_message:"archivo excede 20 columnas", values:[], length:0});
                                            }else{
                                                this.upload_files_list.push({name:file.name, status:"OK", values:list_ref, length:list_ref.length});
                                            }                                            
                                        }
                                        
                                    }
                                    counted_files++;
                                    if(counted_files == total_files){
                                        this.upload_files_loading = false;
                                    }
                                }
                                reader.onerror = ()=> {
                                    this.upload_files_list.push({name:file.name, status:"ERROR", status_message:"error de carga", values:[], length:0});
                                    counted_files++;
                                    if(counted_files == total_files){
                                        this.upload_files_loading = false;
                                    }
                                }; 
                            }else{
                                this.upload_files_list.push({name:file.name, status:"ERROR", status_message:"extensión no permitida", values:[], length:0});
                                counted_files++;
                                if(counted_files == total_files){
                                    this.upload_files_loading = false;
                                }
                            }
                        }catch(err){
                            this.upload_files_list.push({name:file.name, status:"ERROR", status_message:"error de carga", values:[], length:0});
                            counted_files++;
                            if(counted_files == total_files){
                                this.upload_files_loading = false;
                            }
                        }
                    });
                }
            }
        }

        remove_file(index){
            this.upload_files_list.splice(index, 1);
        }

        file_count_valid(){
            var total = 0;
            for(var i = 0;i<this.upload_files_list.length;i++){
                if(this.upload_files_list[i].status == "OK"){
                    total++;
                }
            }
            if(total > 0){
                return true;
            }else{
                return false;
            }
        }

        open_assign_fields_columns(){
            try{
                this.upload_files_ok_list = [];
                for(var i = 0;i<this.upload_files_list.length;i++){
                    if(this.upload_files_list[i].status == "OK"){
                        var max = -Infinity;
                        this.upload_files_list[i].values.forEach(function(a){
                          if (a.length>max) {
                            max = a.length;
                          }
                        });
                        for(var j = 0;j<this.upload_files_list[i].values.length;j++){
                            for(var k = this.upload_files_list[i].values[j].length;k<max;k++){
                                this.upload_files_list[i].values[j].push('');
                            }
                        }
                        if(this.validate_is_header(this.upload_files_list[i].values[0])){
                            var headers = [];
                            headers = JSON.parse(JSON.stringify(this.upload_files_list[i].values[0]));
                            this.upload_files_list[i].values.splice(0,1);
                            var headers_ref = this.to_valid_header(headers);
                            var columns = this.upload_files_list[i].values.reduce(
                                (r, a) => (a.forEach((v, c) => r[c] = r[c] || v), r),
                                []
                            )
                            var empty_columns = [];
                            for(var d = 0;d<columns.length;d++){
                                if(columns[d]){
                                    empty_columns.push(true);
                                }else{
                                    empty_columns.push(false);
                                }
                            }
                            for(var l = empty_columns.length-1;l>=0;l--){
                                if(!empty_columns[l]){
                                    headers_ref.splice(l,1);
                                }
                            }
                            for(var p = 0;p<this.upload_files_list[i].values.length;p++){
                                for(var l = empty_columns.length-1;l>=0;l--){
                                    if(!empty_columns[l]){
                                        this.upload_files_list[i].values[p].splice(l,1);
                                    }
                                }
                            }
                            this.upload_files_ok_list.push({
                                values:{
                                    data:this.upload_files_list[i].values,
                                    max:max,
                                    headers:headers_ref
                                },
                                name:this.upload_files_list[i].name
                            });
                        }else{
                            var headers = [];
                            var columns = this.upload_files_list[i].values.reduce(
                                (r, a) => (a.forEach((v, c) => r[c] = r[c] || v), r),
                                []
                            )
                            var empty_columns = [];
                            for(var d = 0;d<columns.length;d++){
                                if(columns[d]){
                                    empty_columns.push(true);
                                }else{
                                    empty_columns.push(false);
                                }
                            }
                            for(var p = 0;p<this.upload_files_list[i].values.length;p++){
                                for(var l = empty_columns.length-1;l>=0;l--){
                                    if(!empty_columns[l]){
                                        this.upload_files_list[i].values[p].splice(l,1);
                                    }
                                }
                            }
                            for(var p = 0;p<this.upload_files_list[i].values[0].length;p++){
                                headers.push('');
                            }
                            this.upload_files_ok_list.push({
                                values:{
                                    data:this.upload_files_list[i].values,
                                    max:max,
                                    headers:headers
                                },
                                name:this.upload_files_list[i].name
                            });
                        }
                    }
                }
                if(this.upload_files_ok_list.length > 0){
                    this.current_file_counter = 0;
                    this.set_archive();
                }else{
                    this.alertService.alert_error("Error", "No se han cargado archivos válidos");
                }
            }catch(error){
                this.alertService.alert_error("Error de Lectura", "Error al intentar leer los archivos proporcionados");
            }
        }

        set_archive(){
            if(this.upload_files_ok_list.length > this.current_file_counter){
                this.upload_files_loading = false;
                this.upload_files_modal_view = 2;
                this.current_file_datatable_ref.set_results(this.upload_files_ok_list[this.current_file_counter].values.data);
                this.current_file_datatable_ref.set_headers(this.upload_files_ok_list[this.current_file_counter].values.headers);
                this.upload_files_formatted_list = [];
            }
        }

        set_assign_columns(){
            if(this.current_file_datatable_ref.valid_headers()){
                this.upload_files_formatted_list.push({raw_headers:[],progress:0, error_data:[], success_data:[], data:this.current_file_datatable_ref.get_results(), filename:this.upload_files_ok_list[this.current_file_counter].name, groups:[]});
                var counter_ref = this.current_file_counter + 1;
                if(this.upload_files_ok_list.length > counter_ref){
                    this.current_file_counter++;
                    this.current_file_datatable_ref.set_results(this.upload_files_ok_list[this.current_file_counter].values.data);
                    this.current_file_datatable_ref.set_headers(this.upload_files_ok_list[this.current_file_counter].values.headers);
                }else{
                    this.alertService.option_alert_upload("Cargar Registros","¿Está seguro que desea iniciar la carga de registros al sistema? Esto puede tomar varios minutos.","Sí, Cargar").then((result) => {
                        if(result.value){
                            this.selected_membership_index = -1;
                            this.current_file_datatable_ref.set_results([]);
                            this.current_file_datatable_ref.set_headers([]);
                            if (this.subgroup_selected.length > 0) {
                                for (var i = 0; i < this.upload_files_formatted_list.length; i++) {
                                    for(var c = 0;c<this.subgroup_selected.length;c++){
                                        this.upload_files_formatted_list[i].groups.push(
                                            {
                                                id: this.subgroup_selected[c].id,
                                                group: this.subgroup_selected[c].grp_nombre,
                                                subgroup: this.subgroup_selected[c].sgp_nombre
                                            }
                                        );
                                    }
                                }
                            }
                            this.upload_files_modal_view = 4;
                            this.upload_list(0,0);
                        }
                    }).catch(()=>{return false;});
                }
            }
        }

        validate_is_header(row){
            var is_header = true;
            for(var i = 0;i<row.length;i++){
                if( (row[i] + "") &&
                    !(row[i] + "").includes('cli_identidad') && 
                    !(row[i] + "").includes('cli_nombre') && 
                    !(row[i] + "").includes('cli_sexo') && 
                    !(row[i] + "").includes('cli_fecha_nac') && 
                    !(row[i] + "").includes('cli_celular') && 
                    !(row[i] + "").includes('cli_telefono') && 
                    !(row[i] + "").includes('cli_email') && 
                    !(row[i] + "").includes('cli_direccion') && 
                    !(row[i] + "").includes('cli_tarjeta') && 
                    !(row[i] + "").includes('cli_campo1') && 
                    !(row[i] + "").includes('cli_campo2') && 
                    !(row[i] + "").includes('cli_campo3') && 
                    !(row[i] + "").includes('cli_campo4') && 
                    !(row[i] + "").includes('cli_campo5') && 
                    !(row[i] + "").includes('cli_campo6') && 
                    !(row[i] + "").includes('cli_campo7') &&
                    !(row[i] + "").includes('cli_mensaje') && 
                    !(row[i] + "").includes('cli_cif') && 
                    !(row[i] + "").includes('cli_errores') && 
                    !(row[i] + "").includes('cli_cm')){
                    is_header = false;
                }
            }
            return is_header;
        }

        to_valid_header(row){
            var headers = [];
            for(var i = 0;i<row.length;i++){
                if((row[i] + "").includes('cli_identidad')){
                    headers.push('cli_identidad');
                }else if((row[i] + "").includes('cli_nombre')){
                    headers.push('cli_nombre');
                }else if((row[i] + "").includes('cli_sexo')){
                    headers.push('cli_sexo');
                }else if((row[i] + "").includes('cli_fecha_nac')){
                    headers.push('cli_fecha_nac');
                }else if((row[i] + "").includes('cli_celular')){
                    headers.push('cli_celular');
                }else if((row[i] + "").includes('cli_telefono')){
                    headers.push('cli_telefono');
                }else if((row[i] + "").includes('cli_email')){
                    headers.push('cli_email');
                }else if((row[i] + "").includes('cli_direccion')){
                    headers.push('cli_direccion');
                }else if((row[i] + "").includes('cli_tarjeta')){
                    headers.push('cli_tarjeta');
                }else if((row[i] + "").includes('cli_campo1')){
                    headers.push('cli_campo1');
                }else if((row[i] + "").includes('cli_campo2')){
                    headers.push('cli_campo2');
                }else if((row[i] + "").includes('cli_campo3')){
                    headers.push('cli_campo3');
                }else if((row[i] + "").includes('cli_campo4')){
                    headers.push('cli_campo4');
                }else if((row[i] + "").includes('cli_campo5')){
                    headers.push('cli_campo5');
                }else if((row[i] + "").includes('cli_campo6')){
                    headers.push('cli_campo6');
                }else if((row[i] + "").includes('cli_campo7')){
                    headers.push('cli_campo7');
                }else if((row[i] + "").includes('cli_mensaje')){
                    headers.push('cli_mensaje');
                }else if((row[i] + "").includes('cli_cif')){
                    headers.push('cli_cif');
                }else if((row[i] + "").includes('cli_cm')){
                    headers.push('cli_cm');
                }else if((row[i] + "").includes('cli_errores')){
                    headers.push('eliminar_columna');
                }else{
                    headers.push('');
                }
            }
            return headers;
        }

        add_subgroup(index, id, group, subgroup){
            var exists = false;
            for(var i = 0;i<this.upload_files_formatted_list[index].groups.length;i++){
                if(this.upload_files_formatted_list[index].groups[i].id==id){
                    exists = true;
                }
            }
            if(!exists){
                this.upload_files_formatted_list[index].groups.push({
                    id:id,
                    group:group,
                    subgroup:subgroup
                })
            }
        }

        remove_subgroup(index, group_index){
            this.upload_files_formatted_list[index].groups.splice(group_index,1);
        }

        clear_subgroup(index){
            this.upload_files_formatted_list[index].groups = [];
        }

        upload_clients(){
            this.alertService.option_alert_upload("Cargar Registros","¿Está seguro que desea iniciar la carga de registros al sistema? Esto puede tomar varios minutos.","Sí, Cargar").then((result) => {
                if(result.value){
                    this.upload_files_modal_view = 4;
                    var response;
                    this.upload_list(0,0);
                }
            }).catch(()=>{return false;});
        }

        upload_list(index, offset){
            var offset_range = 1000;
            if(index<this.upload_files_formatted_list.length){
                var load = {
                    groups:this.upload_files_formatted_list[index].groups,
                    data:[],
                    headers:this.upload_files_formatted_list[index].data.headers,
                    labels:this.label_list
                }
                if(this.upload_files_formatted_list[index].data.results.length - offset > 0){
                    if(this.upload_files_formatted_list[index].data.results.length - offset <= offset_range){
                        for(var i = offset;i<this.upload_files_formatted_list[index].data.results.length;i++){
                            load.data.push(this.upload_files_formatted_list[index].data.results[i]);
                        }
                    }else{
                        for(var i = offset;i<offset + offset_range;i++){
                            load.data.push(this.upload_files_formatted_list[index].data.results[i]);
                        }
                    }
                    var response;
                    this.endpoint.upload_clients(load).subscribe(
                        data => response = data,
                        err => {
                            if(err.status && err.error){
                                this.alertService.alert_message(err.status ,err.error);
                            }else{
                                this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                            }
                        },
                        ()=> {
                            if(response.error_data){
                                if(response.error_data instanceof Array){
                                    for(var m = 0;m<response.error_data.length;m++){
                                        this.upload_files_formatted_list[index].error_data.push(response.error_data[m]);
                                    }
                                }
                            }
                            if(response.valid_data){
                                if(response.valid_data instanceof Array){
                                    for(var m = 0;m<response.valid_data.length;m++){
                                        this.upload_files_formatted_list[index].success_data.push(response.valid_data[m]);
                                    }
                                }
                            }
                            if(this.upload_files_formatted_list[index].data.results.length > 0){
                                this.upload_files_formatted_list[index].progress = Math.ceil(((this.upload_files_formatted_list[index].success_data.length + this.upload_files_formatted_list[index].error_data.length)/this.upload_files_formatted_list[index].data.results.length)*100);
                            }
                            if(response.headers){
                                this.upload_files_formatted_list[index].raw_headers = response.headers
                            }
                            this.upload_list(index, offset + offset_range);
                        }
                    );
                    
                }else{
                    this.upload_list(index + 1, 0);
                }
            }else{
                this.view = 2;
                this.send_sms_loading = false;
                this.upload_files_modal.hide();
                //this.upload_files_modal_view = 5;
            }
        }

        download_error_exel(response, raw_headers){
            var headers = [];
            for(var j = 0;j<raw_headers.length;j++){
                headers.push(raw_headers[j]);
            }
            headers.push("cli_errores");
            var options = {
                showLabels: true,
                headers: headers
            };
            for(var i = 0;i<response.length;i++){
                for(var j = 0;j<response[i].length;j++){
                    if(!response[i][j]){
                        response[i][j] = "";
                    }
                }
            }
            new ngxCsv(response, 'My Report', options);
        }

        download_success_excel(response, raw_headers){
            var headers = [];
            for(var j = 0;j<raw_headers.length;j++){
                headers.push(raw_headers[j]);
            }
            var options = {
                showLabels: true,
                headers: headers
            };
            for(var i = 0;i<response.length;i++){
                for(var j = 0;j<response[i].length;j++){
                    if(!response[i][j]){
                        response[i][j] = "";
                    }
                }
            }
            new ngxCsv(response, 'My Report', options);
        }

    //FILES UPLOAD ###########################################################
    //########################################################################

    //########################################################################
    //FIELDS COLUMNS #########################################################

        current_file_datatable_events(event){
            if(event.event == 'Eliminar Registro'){
                this.current_file_datatable_ref.remove_value(event.index);
            }
        }

    //FIELDS COLUMNS #########################################################
    //########################################################################

    //########################################################################
    //EXPORT FORMAT ##########################################################

        export_format_to_excel(){
            var headers = [];
            for(var i = 0;i<this.label_list.length;i++){
                if(this.label_list[i].var_alias && this.label_list[i].var_campo){
                    headers.push({
                        label:this.label_list[i].var_alias,
                        field:this.label_list[i].var_campo

                    }); 
                }
            }
            this.excelService.upload_format_to_excel(headers, "formato_carga_clientes");
        }

    //EXPORT FORMAT ##########################################################
    //########################################################################

    //########################################################################
    //SEND SMS ###############################################################

        send_sms(data){
            this.send_sms_ref.set_loading(true);
            var response;
            this.endpoint.send_sms(data).subscribe(
                data => response = data,
                err => {
                    this.send_sms_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.view = 4;
                        //this.alertService.alert_success(response.title, response.message);
                        this.send_sms_ref.set_loading(false);
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.send_sms_ref.set_loading(false);
                    }
                }
            );
        }

        close_send_sms(){
            this.alertService.option_alert("Cancelar Envío de SMS","¿Está seguro que desea cancelar el envío de SMS?","Sí, Cancelar").then((result) => {
                if(result.value){
                    this.send_sms_loading = false;
                    this.upload_files_list = [];
                    this.upload_files_ok_list = [];
                    this.upload_files_formatted_list = [];
                    this.current_file_datatable_ref.set_results([]);
                    this.current_file_datatable_ref.set_headers([]);
                    this.view = 1;
                }
            }).catch(()=>{return false;});
        }

        return_close_send_sms(){
            this.send_sms_loading = false;
            this.upload_files_list = [];
            this.upload_files_ok_list = [];
            this.upload_files_formatted_list = [];
            this.current_file_datatable_ref.set_results([]);
            this.current_file_datatable_ref.set_headers([]);
            this.view = 1;
        }

    //FIELDS COLUMNS #########################################################
    //########################################################################

    //########################################################################
    //CATALOGS ###############################################################

        get_labels(){
            var response;
            this.endpoint.get_labels().subscribe(
                data => response = data,
                err => {
                    this.label_list = [];
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    this.label_list = [];
                    this.label_list = response;
                }
            );
        }

        get_groups(){
            this.loading = true;
            var response;
            this.endpoint.get_groups().subscribe(
                data => response = data,
                err => {
                    this.group_list = [];
                    this.loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    this.group_list = [];
                    for(var i = 0;i<response.length;i++){
                        response[i].subgroups = [];
                        response[i].extended = false;
                    }
                    this.group_list = response;
                    this.loading = false;
                }
            );
        }

        extend_memberships(index, data){
            this.group_list[index].extended = true;
            this.loading = true;
            var load = {
                smsreseller_grupos_id:data.id
            };
            var response;
            this.endpoint.get_subgroups(load).subscribe(
                data => response = data,
                err => {
                    this.group_list[index].subgroups = [];
                    this.loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    this.group_list[index].subgroups = [];
                    this.group_list[index].subgroups = response;
                    this.loading = false;
                }
            );
        }

        contract_memberships(index, data){
            this.group_list[index].extended = false;
            this.group_list[index].subgroups = [];
        }

    //CATALOGS ###############################################################
    //########################################################################

    //########################################################################
    //SUBGROUPS ##############################################################

        subgroup_datatable_events(group_index, event){
            if(event.event == "Enviar SMS"){
                this.send_sms_loading = false;
                this.subgroup_selected = [];
                this.subgroup_selected.push({
                    id:event.data.id,
                    grp_nombre:event.group_data.grp_nombre,
                    sgp_nombre:event.data.sgp_nombre
                })
                this.view = 3;
            }else if(event.event == "Importar Registros"){
                this.send_sms_loading = false;
                this.subgroup_selected = [];
                this.subgroup_selected.push({
                    id:event.data.id,
                    grp_nombre:event.group_data.grp_nombre,
                    sgp_nombre:event.data.sgp_nombre
                })
                this.group_data = {
                    id:event.group_data.id,
                    grp_nombre:event.group_data.grp_nombre,
                    index:group_index
                }
                this.upload_files_loading = false;
                this.upload_files_modal_view = 1;
                this.upload_files_list = [];
                this.upload_files_ok_list = [];
                this.upload_files_formatted_list = [];
                this.upload_files_modal.show();
            }
        }

    //SUBGROUPS ##############################################################
    //########################################################################

    ngOnDestroy() {
        setTimeout(() => this.layoutService.off('resize.app-home'));
    }

}
