import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs, 'es-hn');
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
defineLocale('es-hn', esLocale); 
import { DragDropModule } from '@angular/cdk/drag-drop';
// *******************************************************************************
// Modules

import { ChartsModule } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TextMaskModule } from 'angular2-text-mask';
import { ModalModule } from 'ngx-bootstrap';
import { FileDropModule } from 'ngx-file-drop';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ColorPickerModule } from 'ngx-color-picker';

// *******************************************************************************
// App

import { AppRoutingModule } from './app.routes';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { AppFunctions } from './app.functions';
import { AppEndpoints } from './app.endpoints';
import { LoginGuard } from './app.loginguard';
import { AuthInterceptor } from './app.httpinterceptor';
import { LayoutModule } from './layout/layout.module';
import { DatatableModule } from './components/datatable/datatable.module';
import { SendSmsModule } from './components/send_sms/send_sms.module';
import { SendSurveyModule } from './components/send_survey/send_survey.module';
import { EditSmsModule } from './components/edit_sms/edit_sms.module';
import { ViewSmsModule } from './components/view_sms/view_sms.module';
import { GroupDatatableModule } from './components/group-datatable/group-datatable.module';
import { ServiceDatatableModule } from './components/service_datatable/service-datatable.module';
import { ServiceDatatableNoCountModule } from './components/service_datatable_nocount/service-datatable-nocount.module';
import { ServiceDatatableSmModule } from './components/service_datatable_sm/service_datatable_sm.module';
import { UploadDatatableModule } from './components/upload_datatable/upload_datatable.module';
import { FormRendererModule } from './components/form_renderer/form_renderer.module';
import { AlertService } from './components/alert_service/alert.service';
import { ExcelService } from './components/excel_service/excel.service';

// *******************************************************************************
// Pages

import { LoginComponent } from './login/login.component';
import { RecoverPasswordComponent } from './recover_password/recover_password.component';
import { NotFoundComponent } from './not_found/not_found.component';
import { HomeComponent } from './home/home.component';
import { ManualComponent } from './manual/manual.component';

/** Users */
import { UsersComponent } from './menus/menu_users/users/users.component';

/** Clients */
import { MenuClientsComponent } from './menus/menu_clients/menu_clients.component';
import { GroupsComponent } from './menus/menu_clients/groups/groups.component';
import { ClientsComponent } from './menus/menu_clients/clients/clients.component';
import { LabelsComponent } from './menus/menu_clients/labels/labels.component';
import { ImportClientsComponent } from './menus/menu_clients/import_clients/import_clients.component';
import { DeleteClientsComponent } from './menus/menu_clients/delete_clients/delete_clients.component';

/** SMS */
import { MenuSmsComponent } from './menus/menu_sms/menu_sms.component';
import { PlantillasSmsComponent } from './menus/menu_sms/plantillas_sms/plantillas_sms.component';
import { EnviarSmsComponent } from './menus/menu_sms/enviar_sms/enviar_sms.component';
import { ImportarEnviarSmsComponent } from './menus/menu_sms/importar_enviar_sms/importar_enviar_sms.component';
import { CalendarioSmsComponent } from './menus/menu_sms/calendario_sms/calendario_sms.component';

/** Surveys */
import { MenuSurveyComponent } from './menus/menu_survey/menu_survey.component';
import { CreateSurveyComponent } from './menus/menu_survey/create_survey/create_survey.component';
import { EnviarEncuestaSatisfaccionComponent } from './menus/menu_survey/enviar_encuesta_satisfaccion/enviar_encuesta_satisfaccion.component';
import { ResultadosEncuestaSatisfaccionComponent } from './menus/menu_survey/resultados_encuesta_satisfaccion/resultados_encuesta_satisfaccion.component';

/** Reports */
import { MenuReportsComponent } from './menus/menu_reports/menu_reports.component';
import { OutboxComponent } from './menus/menu_reports/outbox/outbox.component';
import { SentShortcodeComponent } from './menus/menu_reports/sent_shortcode/sent_shortcode.component';
import { InboxComponent } from './menus/menu_reports/inbox/inbox.component';
import { OutboxSmppComponent } from './menus/menu_reports/outbox_smpp/outbox_smpp.component';
import { OutboxSmppLimitedComponent } from './menus/menu_reports/outbox_smpp_limited/outbox_smpp_limited.component';
import { OutboxSmppLimited24HComponent } from './menus/menu_reports/outbox_smpp_limited_24h/outbox_smpp_limited_24h.component';
import { OutboxSmpp24HComponent } from './menus/menu_reports/outbox_smpp_24h/outbox_smpp_24h.component';
import { SmsSentNumberComponent } from './menus/menu_reports/sms_sent_number/sms_sent_number.component';
import { ShipingDetailComponent } from './menus/menu_reports/shiping_detail/shiping_detail.component';
import { BitacoraDetailComponent } from './menus/menu_reports/bitacora_detail/bitacora_detail.component';
import { AreaShipingHistoryComponent } from './menus/menu_reports/area_shiping_history/area_shiping_history.component';
import { DailyShipingHistoryComponent } from './menus/menu_reports/daily_shiping_history/daily_shiping_history.component';
import { MonthlyShipingHistoryComponent } from './menus/menu_reports/monthly_shiping_history/monthly_shiping_history.component';
import { YearlyShipingHistoryComponent } from './menus/menu_reports/yearly_shiping_history/yearly_shiping_history.component';
import { AvailableSMSComponent } from './menus/menu_reports/available_sms/available_sms.component';

// *******************************************************************************
// Dashboard Components
import { AreaUsageChart } from './dashboard_components/area_usage_chart/area_usage_chart.component';
import { UsageSummaryChart } from './dashboard_components/usage_summary_chart/usage_summary_chart.component';
import { SmppUsageSummaryChart } from './dashboard_components/smpp_usage_summary_chart/smpp_usage_summary_chart.component';
import { RecentCampaignShipmentsChart } from './dashboard_components/recent_campaign_shipments_chart/recent_campaign_shipments_chart.component';
import { RecentSmppShipmentsChart } from './dashboard_components/recent_smpp_shipments_chart/recent_smpp_shipments_chart.component';
import { DashboardCard } from './dashboard_components/dashboard_card/dashboard_card.component';
import { SentSmsGraphsComponent } from './dashboard_components/sent_sms_graphs/sent_sms_graphs.component';
import { DailyTrendCard } from './dashboard_components/daily_trend_card/daily_trend_card.component';
import { MonthlyTrendCard } from './dashboard_components/monthly_trend_card/monthly_trend_card.component';
import { MonthlyShipingsChart } from './dashboard_components/monthly_shipings_chart/monthly_shipings_chart.component';
import { DailyShipingsChart } from './dashboard_components/daily_shipings_chart/daily_shipings_chart.component';
import { CostExplorerComponent } from './menus/menu_reports/cost_explorer/cost_explorer.component';
import { ConsolidatedBalancesComponent } from './menus/menu_reports/consolidated_balances/consolidated_balances.component';

// *******************************************************************************
//

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		RecoverPasswordComponent,
		NotFoundComponent,
		ManualComponent,
		//USERS
		UsersComponent,
		//CLIENTS
		MenuClientsComponent,
		HomeComponent,
		GroupsComponent,
		ClientsComponent,
		LabelsComponent,
		ImportClientsComponent,
		DeleteClientsComponent,
		//SMS
		MenuSmsComponent,
		PlantillasSmsComponent,
		EnviarSmsComponent,
		ImportarEnviarSmsComponent,
		CalendarioSmsComponent,
		//SURVEY
		MenuSurveyComponent,
		CreateSurveyComponent,
		EnviarEncuestaSatisfaccionComponent,
		ResultadosEncuestaSatisfaccionComponent,
		//DASHBOARD_COMPONENTS
		DashboardCard,
		AreaUsageChart,
		UsageSummaryChart,
		SmppUsageSummaryChart,
		RecentCampaignShipmentsChart,
		RecentSmppShipmentsChart,
		SentSmsGraphsComponent,
		DailyTrendCard,
		MonthlyTrendCard,
		MonthlyShipingsChart,
		DailyShipingsChart,
		//REPORTS
		MenuReportsComponent,
		OutboxComponent,
		InboxComponent,
		OutboxSmppComponent,
		OutboxSmppLimitedComponent,
		SentShortcodeComponent,
		ShipingDetailComponent,
		BitacoraDetailComponent,
		AreaShipingHistoryComponent,
		DailyShipingHistoryComponent,
		MonthlyShipingHistoryComponent,
		YearlyShipingHistoryComponent,
		AvailableSMSComponent,
		SmsSentNumberComponent,
		CostExplorerComponent,
		ConsolidatedBalancesComponent,
		OutboxSmppLimited24HComponent,
		OutboxSmpp24HComponent
	],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		DragDropModule,
		NgbModule,
		HttpClientModule,
		ColorPickerModule,
		FormsModule,
		AppRoutingModule,
		LayoutModule,
		ChartsModule,
		TextMaskModule,
		DatatableModule,
		GroupDatatableModule,
		ServiceDatatableModule,
		ServiceDatatableNoCountModule,
		ServiceDatatableSmModule,
		UploadDatatableModule,
		FormRendererModule,
		ModalModule.forRoot(),
		BsDatepickerModule.forRoot(),
		FileDropModule,
		SendSmsModule,
		SendSurveyModule,
		EditSmsModule,
		ViewSmsModule,
	    CalendarModule.forRoot({
	        provide: DateAdapter,
	        useFactory: adapterFactory
	    }),
	    InfiniteScrollModule
	],
	providers: [
		Title,
		AppService,
		AppFunctions,
		AppEndpoints,
		AlertService,
		ExcelService,
		LoginGuard,
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }

	],
	bootstrap: [
		AppComponent
	]
})
export class AppModule { }
