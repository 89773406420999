import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit, ElementRef, OnDestroy, OnInit  } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { AppService } from '../../../app.service';
import { AppEndpoints } from '../../../app.endpoints';
import { LayoutService } from '../../../layout/layout.service';
import { ModalDirective } from 'ngx-bootstrap';
import { default as swal } from 'sweetalert2';
import { FormRendererComponent } from '../../../components/form_renderer/form_renderer.component';
import { AlertService } from '../../../components/alert_service/alert.service';
import { ExcelService } from '../../../components/excel_service/excel.service';
import { ServiceDatatableComponent } from '../../../components/service_datatable/service-datatable.component';

@Component({
    selector: 'plantillas-sms',
    templateUrl: './plantillas_sms.component.html',
    styleUrls:[
        '../../../../vendor/libs/spinkit/spinkit.scss'
    ]
})
export class PlantillasSmsComponent implements OnInit{
    @ViewChild('label_modal') label_modal: ModalDirective;
    @ViewChild('label_form') label_form: FormRendererComponent;
    @ViewChild('plantillas_form') plantillas_form: FormControlDirective;
    @ViewChild('plantillas_modal') plantillas_modal: ModalDirective;
    @ViewChild('sms_mensaje_original') sms_mensaje_original:ElementRef;
    @ViewChild('plantillas_datatable_ref') plantillas_datatable_ref: ServiceDatatableComponent;
    public plantillas_label_list:any[];
    public plantillas_submitted:boolean;
    public plantillas_loading:boolean;
    public plantillas_datatable_loading:boolean;
    public plantillas_datatable:any;
    public plantillas_view:number;
    public plantillas_filter_data={
        sms_mensaje_original:"",
        sms_categoria_id:"",
        templates_nombre:"",
        smstemplate_status:""
    };
    public plantillas_data={
        id:"",
        sms_mensaje_original:"",
        sms_categoria_id:"",
        templates_nombre:"",
        smstemplate_status:true
    }

    @ViewChild('categoria_modal') categoria_modal: ModalDirective;
    @ViewChild('categoria_form') categoria_form: FormRendererComponent;
    @ViewChild('categoria_datatable_ref') categoria_datatable_ref: ServiceDatatableComponent;
    public category_list:any[];
    public category_catalog:any[];
    public category_view:number;
    public category_datatable:any;
    public category_loading:boolean;
    public category_data = {
        categoria_nombre:""
    };
    public category_inputs=[
        {
            class:"row",
            columns:[
                {
                    class:"col-md-12",
                    inputs:[
                        {
                            type:"text",
                            extra:"",
                            name:"categoria_nombre",
                            label:"Nombre de la Categoría",
                            icon:"",
                            class:"form-control",
                            placeholder:"",
                            minlength:null,
                            maxlength:"50",
                            pattern:null,
                            error_required:"Requerido",
                            error_pattern:"",
                            error_minlength:"",
                            list_data:{
                                value:"",
                                text:""
                            },
                            list:()=>{
                                return []
                            },
                            textmask:()=>{
                                return false;
                            },
                            required:()=>{
                                return true;
                            },
                            disabled:()=>{
                                return false;
                            },
                            change:(event)=>{
                            },
                            input:()=>{
                            }
                        }
                    ]
                }
            ]
        }
    ];

    constructor(private appService: AppService, public endpoint : AppEndpoints,  private layoutService: LayoutService, private alertService: AlertService, private excelService: ExcelService) {
        this.appService.pageTitle = 'Plantillas SMS';
        this.plantillas_label_list = [];
        this.plantillas_view = 1;
        this.plantillas_loading = false;
        this.plantillas_submitted = false;
        this.plantillas_datatable_loading = false;
        this.plantillas_datatable = {
            title:"Listado de Plantillas",
            show_search:true,
            search_placeholder:"- Buscar -",
            icon:"keyboard",
            object_description:"plantillas",
            empty_text:"No se encontraron plantillas",
            header_class: "custom-th-dark",
            columns:[
                {   
                    column:"templates_nombre",
                    wrap_column:false,
                    header:"Nombre de la Plantilla",
                    wrap_header:true,
                    type:"text"
                },
                {   
                    column:"sms_mensaje_original",
                    wrap_column:false,
                    header:"Mensaje",
                    wrap_header:true,
                    type:"text"
                },
                {   
                    column:"smstemplate_status",
                    wrap_column:true,
                    header:"Estado",
                    wrap_header:true,
                    type:"text"
                },
                {   
                    column:"categoria_nombre",
                    wrap_column:true,
                    header:"Categoría",
                    wrap_header:true,
                    type:"text"
                }
            ],
            events:[
                {
                    name:"Ver Plantilla",
                    style:"color:#39B7CB",
                    hover_style:"cursor:pointer; color:#39B7CB; background-color:#BDF0FF !important;",
                    icon:"search"
                },
                {
                    name:"Editar Plantilla",
                    style:"color:#ffb300",
                    hover_style:"cursor:pointer; color:#ffb300; background-color:#FFF7C6 !important;",
                    icon:"edit"
                },
                {
                    name:"Eliminar Plantilla",
                    style:"color:#FB5D5D",
                    hover_style:"cursor:pointer; color:#FB5D5D; background-color:#FEDCDC !important;",
                    icon:"trash-alt"
                }
            ],
            navigation_offsets:[5,10,15,20,25,50],
            show_search_field:true,
            table_icon:"caret-right"
        }
        this.category_catalog = [];
        this.category_view = 1;
        this.category_loading = false;
        this.category_datatable = {
            title:"Listado de Categorías",
            show_search:true,
            search_placeholder:"- Buscar -",
            icon:"list",
            object_description:"categorías",
            empty_text:"No se encontraron categorías",
            header_class: "custom-th-dark",
            columns:[
                {   
                    column:"categoria_nombre",
                    wrap_column:false,
                    header:"Nombre de la Categoría",
                    wrap_header:true,
                    type:"text"
                }
            ],
            events:[
                {
                    name:"Editar Categoría",
                    style:"color:#ffb300",
                    hover_style:"cursor:pointer; color:#ffb300; background-color:#FFF7C6 !important;",
                    icon:"edit"
                },
                {
                    name:"Eliminar Categoría",
                    style:"color:#FB5D5D",
                    hover_style:"cursor:pointer; color:#FB5D5D; background-color:#FEDCDC !important;",
                    icon:"trash-alt"
                }
            ],
            navigation_offsets:[5,10,15,20,25,50],
            show_search_field:true,
            table_icon:"caret-right"
        }
    }

    ngOnInit(){
        this.get_plantillas_labels();
    }

    ngAfterViewInit(){    
    }

    //########################################################################
    //TEMPLATES ##############################################################

        get_plantillas_labels(){
            var response;
            this.plantillas_datatable_loading = true;
            this.plantillas_datatable_ref.set_loading(true);
            this.endpoint.get_labels().subscribe(
                data => response = data,
                err => {
                    this.plantillas_label_list = [];
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                    this.plantillas_datatable_loading = false;
                    this.plantillas_datatable_ref.set_loading(false);
                },
                ()=> {
                    try{
                        this.plantillas_label_list = [];
                        for(var i = 0;i<response.length;i++){
                            if(response[i].var_alias){
                                this.plantillas_label_list.push(response[i])
                            }
                        }
                        this.plantillas_datatable_ref.set_show_length(10);
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.plantillas_datatable_ref.set_loading(false);
                    }
                    this.plantillas_datatable_loading = false;
                    
                }
            );
        }

        plantillas_datatable_events(event){
            if(event.event=="Ver Plantilla"){
                this.open_view_plantilla(event.data);
            }else if(event.event=="Editar Plantilla"){
                this.open_update_plantilla(event.data);
            }else if(event.event=="Eliminar Plantilla"){
                this.open_delete_plantilla(event.data);
            }
        }

        plantillas_datatable_get_results_offset_change(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                //sms_mensaje_original:this.plantillas_filter_data.sms_mensaje_original,
                //sms_categoria_id:this.plantillas_filter_data.sms_categoria_id,
                //templates_nombre:this.plantillas_filter_data.templates_nombre,
                //smstemplate_status:this.plantillas_filter_data.smstemplate_status,
                search_word:data.search_word
            }
            this.endpoint.get_templates(load).subscribe(
                data => response = data,
                err => {
                    this.plantillas_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.plantillas_datatable_ref.set_results_offset_change(response.list);
                    }catch(error){
                        this.plantillas_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                    
                }
            );
        }

        plantillas_datatable_get_results_filter_change(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                //sms_mensaje_original:this.plantillas_filter_data.sms_mensaje_original,
                //sms_categoria_id:this.plantillas_filter_data.sms_categoria_id,
                //templates_nombre:this.plantillas_filter_data.templates_nombre,
                //smstemplate_status:this.plantillas_filter_data.smstemplate_status,
                search_word:data.search_word
            };
            this.endpoint.get_templates(load).subscribe(
                data => response = data,
                err => {
                    this.plantillas_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.plantillas_datatable_ref.set_results_filter_change(response.list, response.count);
                    }catch(error){
                        this.plantillas_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

        plantillas_datatable_get_results_update_list(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                //sms_mensaje_original:this.plantillas_filter_data.sms_mensaje_original,
                //sms_categoria_id:this.plantillas_filter_data.sms_categoria_id,
                //templates_nombre:this.plantillas_filter_data.templates_nombre,
                //smstemplate_status:this.plantillas_filter_data.smstemplate_status,
                search_word:data.search_word
            }
            this.endpoint.get_templates(load).subscribe(
                data => response = data,
                err => {
                    this.plantillas_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.plantillas_datatable_ref.set_results_update_list(response.list, response.count);
                    }catch(error){
                        this.plantillas_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

        close_plantillas(){
            this.plantillas_data={
                id:"",
                sms_mensaje_original:"",
                sms_categoria_id:"",
                templates_nombre:"",
                smstemplate_status:true
            };
            this.plantillas_loading = false;
            this.plantillas_view = 1;
            this.plantillas_modal.hide();
        }

        open_insert_plantilla(){
            this.plantillas_data={
                id:"",
                sms_mensaje_original:"",
                sms_categoria_id:"",
                templates_nombre:"",
                smstemplate_status:true
            };
            this.plantillas_submitted = false;
            this.plantillas_loading = false;
            this.plantillas_view = 2;
            this.plantillas_modal.show();
            this.get_category_catalog();
        }

        close_insert_plantilla(){
            this.plantillas_data={
                id:"",
                sms_mensaje_original:"",
                sms_categoria_id:"",
                templates_nombre:"",
                smstemplate_status:true
            };
            this.plantillas_loading = false;
            this.plantillas_view = 1;
            this.plantillas_modal.hide();
        }

        insert_plantilla(){
            if(this.plantillas_form.valid){
                this.plantillas_submitted = false;
                var mensaje_original = this.plantillas_data.sms_mensaje_original.replace(/  +/g, ' ');
                var mensaje_parsed = this.plantillas_data.sms_mensaje_original.replace(/  +/g, ' ') + " ";
                for(var j = 0;j<this.plantillas_label_list.length;j++){
                    var alias_to_replace = new RegExp("\#" + this.plantillas_label_list[j].var_alias + " ","g");
                    var field_to_insert = "$" + this.plantillas_label_list[j].var_campo + " ";
                    mensaje_parsed = mensaje_parsed.replace(alias_to_replace,field_to_insert);
                }
                var bad_chars = this.validate_sms_caracters();
                if(bad_chars.length > 0){
                    var error_text = ["Caracteres: " + bad_chars.join(", ")];
                    this.alertService.alert_multiple_error("Error de Caracteres", "Se encontraron caracteres no permitidos dentro del mensaje", error_text);
                    return false;
                }else{
                    this.plantillas_loading = true;
                    var status = "INACTIVO";
                    if(this.plantillas_data.smstemplate_status){
                        status = "ACTIVE";
                    };
                    var load = {
                        sms_mensaje_original:mensaje_original.trim(),
                        sms_mensaje_parseado:mensaje_parsed.trim(),
                        smstemplate_status:status,
                        templates_nombre:this.plantillas_data.templates_nombre,
                        sms_categoria_id:this.plantillas_data.sms_categoria_id,
                    };
                    var response;
                    this.endpoint.insert_template(load).subscribe(
                        data => response = data,
                        err => {
                            this.plantillas_loading = false;
                            if(err.status && err.error){
                                this.alertService.alert_message(err.status ,err.error);
                            }else{
                                this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                            }
                        },
                        ()=> {
                            try{
                                this.close_insert_plantilla();
                                this.plantillas_datatable_ref.emit_get_results_update_list();
                                this.alertService.alert_success(response.title, response.message);
                            }catch(error){
                                this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                                this.plantillas_loading = false;
                            }
                        }
                    );
                }
            }else{
                this.plantillas_submitted = true;
            }
        }

        add_alias(alias){
            if((this.plantillas_data.sms_mensaje_original + " #" + alias + " ").length > 320){
                if((this.plantillas_data.sms_mensaje_original + " #" + alias).length == 320){
                    this.plantillas_data.sms_mensaje_original = this.plantillas_data.sms_mensaje_original + " #" + alias;
                    this.plantillas_data.sms_mensaje_original = this.plantillas_data.sms_mensaje_original.replace(/  +/g, ' ')
                }
            }else if(this.plantillas_data.sms_mensaje_original.length == 0){
                this.plantillas_data.sms_mensaje_original = this.plantillas_data.sms_mensaje_original + "#" + alias + " ";
                this.plantillas_data.sms_mensaje_original = this.plantillas_data.sms_mensaje_original.replace(/  +/g, ' ')
            }else{
                this.plantillas_data.sms_mensaje_original = this.plantillas_data.sms_mensaje_original + " #" + alias + " ";
                this.plantillas_data.sms_mensaje_original = this.plantillas_data.sms_mensaje_original.replace(/  +/g, ' ')
            }
            this.sms_mensaje_original.nativeElement.focus();
        }

        open_update_plantilla(data){
            var status = true;
            if(data.smstemplate_status == "INACTIVO"){
                status = false;
            };
            var categoria = "";
            if(data.sms_categoria_id){
                categoria = data.sms_categoria_id;
            }
            this.plantillas_data={
                id:data.id,
                sms_mensaje_original:data.sms_mensaje_original,
                sms_categoria_id:categoria,
                templates_nombre:data.templates_nombre,
                smstemplate_status:status
            };
            this.plantillas_submitted = false;
            this.plantillas_loading = false;
            this.plantillas_view = 3;
            this.plantillas_modal.show();
            this.get_category_catalog();
        }

        update_plantilla(){
            if(this.plantillas_form.valid){
                this.plantillas_submitted = false;
                var mensaje_original = this.plantillas_data.sms_mensaje_original.replace(/  +/g, ' ');
                var mensaje_parsed = this.plantillas_data.sms_mensaje_original.replace(/  +/g, ' ') + " ";
                for(var j = 0;j<this.plantillas_label_list.length;j++){
                    var alias_to_replace = new RegExp("\#" + this.plantillas_label_list[j].var_alias + " ","g");
                    var field_to_insert = "$" + this.plantillas_label_list[j].var_campo + " ";
                    mensaje_parsed = mensaje_parsed.replace(alias_to_replace,field_to_insert);
                }
                var bad_chars = this.validate_sms_caracters();
                if(bad_chars.length > 0){
                    var error_text = ["Caracteres: " + bad_chars.join(", ")];
                    this.alertService.alert_multiple_error("Error de Caracteres", "Se encontraron caracteres no permitidos dentro del mensaje", error_text);
                    return false;
                }else{
                    this.plantillas_loading = true;
                    var status = "INACTIVO";
                    if(this.plantillas_data.smstemplate_status){
                        status = "ACTIVE";
                    };
                    var load = {
                        id:this.plantillas_data.id,
                        sms_mensaje_original:mensaje_original.trim(),
                        sms_mensaje_parseado:mensaje_parsed.trim(),
                        smstemplate_status:status,
                        templates_nombre:this.plantillas_data.templates_nombre,
                        sms_categoria_id:this.plantillas_data.sms_categoria_id,
                    };
                    var response;
                    this.endpoint.update_template(load).subscribe(
                        data => response = data,
                        err => {
                            this.plantillas_loading = false;
                            if(err.status && err.error){
                                this.alertService.alert_message(err.status ,err.error);
                            }else{
                                this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                            }
                        },
                        ()=> {
                            try{
                                this.close_insert_plantilla();
                                this.plantillas_datatable_ref.emit_get_results_update_list();
                                this.alertService.alert_success(response.title, response.message);
                            }catch(error){
                                this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                                this.plantillas_loading = false;
                            }
                        }
                    );
                }
            }else{
                this.plantillas_submitted = true;
            }
        }

        close_update_plantilla(){
            this.plantillas_data={
                id:"",
                sms_mensaje_original:"",
                sms_categoria_id:"",
                templates_nombre:"",
                smstemplate_status:true
            };
            this.plantillas_loading = false;
            this.plantillas_view = 1;
            this.plantillas_modal.hide();
        }

        open_view_plantilla(data){
            var status = true;
            if(data.smstemplate_status == "INACTIVO"){
                status = false;
            };
            var categoria = "";
            if(data.sms_categoria_id){
                categoria = data.sms_categoria_id;
            }
            this.plantillas_data={
                id:data.id,
                sms_mensaje_original:data.sms_mensaje_original,
                sms_categoria_id:categoria,
                templates_nombre:data.templates_nombre,
                smstemplate_status:status
            };
            this.plantillas_submitted = false;
            this.plantillas_loading = false;
            this.plantillas_view = 1;
            this.plantillas_modal.show();
            this.get_category_catalog();
        }

        open_delete_plantilla(data){
            this.alertService.option_alert("Eliminar Plantilla","¿Está seguro que desea eliminar la plantilla seleccionada?<br><br><b>Plantilla:<br></b><b class='text-success'> "+data.templates_nombre+"</b>","Sí, Eliminar").then((result) => {
                if(result.value){
                    this.delete_plantilla(data.id);
                }
            }).catch(()=>{return false;});
        }

        delete_plantilla(id){
            this.plantillas_loading = true;
            var load = {
                id:id
            };
            var response;
            this.endpoint.delete_template(load).subscribe(
                data => response = data,
                err => {
                    this.plantillas_loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.plantillas_datatable_ref.emit_get_results_update_list();
                        this.alertService.alert_success(response.title, response.message);
                        this.plantillas_loading = false;
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.plantillas_loading = false;
                    }
                }
            );
        }

        validate_input(event){
            const key = (event.which) ? event.which : event.keyCode;
            return true;
        }

        remove_double_spaces(){
            this.plantillas_data.sms_mensaje_original = this.plantillas_data.sms_mensaje_original.replace(/  +/g, ' ').trim();
        }

        clean_sms_text(){
            this.plantillas_data.sms_mensaje_original = "";
        }

        clean_spaces(){
            this.plantillas_data.sms_mensaje_original = this.plantillas_data.sms_mensaje_original.replace(/á/g, 'a');
            this.plantillas_data.sms_mensaje_original = this.plantillas_data.sms_mensaje_original.replace(/é/g, 'e');
            this.plantillas_data.sms_mensaje_original = this.plantillas_data.sms_mensaje_original.replace(/í/g, 'i');
            this.plantillas_data.sms_mensaje_original = this.plantillas_data.sms_mensaje_original.replace(/ó/g, 'o');
            this.plantillas_data.sms_mensaje_original = this.plantillas_data.sms_mensaje_original.replace(/ú/g, 'u');
            this.plantillas_data.sms_mensaje_original = this.plantillas_data.sms_mensaje_original.replace(/Á/g, 'A');
            this.plantillas_data.sms_mensaje_original = this.plantillas_data.sms_mensaje_original.replace(/É/g, 'E');
            this.plantillas_data.sms_mensaje_original = this.plantillas_data.sms_mensaje_original.replace(/Í/g, 'I');
            this.plantillas_data.sms_mensaje_original = this.plantillas_data.sms_mensaje_original.replace(/Ó/g, 'O');
            this.plantillas_data.sms_mensaje_original = this.plantillas_data.sms_mensaje_original.replace(/Ú/g, 'U');
            this.plantillas_data.sms_mensaje_original = this.plantillas_data.sms_mensaje_original.trim();
            for(var i = this.plantillas_data.sms_mensaje_original.length-1;i>=0;i--){
                var is_valid = false;
                for(var j = 0;j<this.endpoint.get_sms_allowed_characters_full().length;j++){
                    if(this.plantillas_data.sms_mensaje_original[i]==this.endpoint.get_sms_allowed_characters_full()[j]){
                        is_valid = true;
                        break;
                    }
                }
                if(!is_valid){
                    this.plantillas_data.sms_mensaje_original = this.plantillas_data.sms_mensaje_original.slice(0,i) + this.plantillas_data.sms_mensaje_original.slice(i+1);
                }
            }
        }

        validate_sms_caracters(){
            var chars = [];
            for(var i = 0;i<this.plantillas_data.sms_mensaje_original.length;i++){
                var is_valid = false;
                for(var j = 0;j<this.endpoint.get_sms_allowed_characters_full().length;j++){
                    if(this.plantillas_data.sms_mensaje_original[i]==this.endpoint.get_sms_allowed_characters_full()[j]){
                        is_valid = true;
                        break;
                    }
                }
                if(!is_valid){
                    chars.push("<b style='margin-right:10px;'>" + this.plantillas_data.sms_mensaje_original[i] + "</b>");
                }
            }
            return chars;
        }

    //TEMPLATES ##############################################################
    //########################################################################

    //########################################################################
    //CATEGORÍAS #############################################################

        open_category(){
            this.categoria_datatable_ref.set_show_length(10);
            this.categoria_modal.show();
            this.category_data = {
                categoria_nombre:""
            };
            this.category_view = 1;
        }

        get_category_catalog(){
            var response;
            this.plantillas_loading = true;
            this.endpoint.get_category_catalog().subscribe(
                data => response = data,
                err => {
                    this.category_catalog = [];
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                    this.plantillas_loading = false;
                },
                ()=> {
                    this.category_catalog = [];
                    this.category_catalog = response;
                    this.plantillas_loading = false;
                }
            );
        }

        category_datatable_get_results_offset_change(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                search_word:data.search_word
            }
            this.endpoint.get_categories(load).subscribe(
                data => response = data,
                err => {
                    this.categoria_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.categoria_datatable_ref.set_results_offset_change(response.list);
                    }catch(error){
                        this.categoria_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

        category_datatable_get_results_filter_change(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                search_word:data.search_word
            }
            this.endpoint.get_categories(load).subscribe(
                data => response = data,
                err => {
                    this.categoria_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.categoria_datatable_ref.set_results_filter_change(response.list, response.count);
                    }catch(error){
                        this.categoria_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

        category_datatable_get_results_update_list(data){
            var response;
            var load = {
                current_offset:data.current_offset,
                sort_ascendent:data.sort_ascendent,
                sort_order:data.sort_order,
                view_length:data.view_length,
                search_word:data.search_word
            }
            this.endpoint.get_categories(load).subscribe(
                data => response = data,
                err => {
                    this.categoria_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.categoria_datatable_ref.set_results_update_list(response.list, response.count);
                    }catch(error){
                        this.categoria_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

        category_datatable_events(event){
            if(event.event == "Editar Categoría"){
                this.open_update_category(event.data);
            }else if(event.event == "Eliminar Categoría"){
                this.open_delete_category(event.data);
            }
        }

        open_insert_category(){
            this.category_loading = false;
            this.categoria_form.clean_form();
            this.category_view = 2;
        }

        close_insert_category(){
            this.category_loading = false;
            this.categoria_form.clean_form();
            this.category_view = 1;
        }

        insert_category(){
            if(this.categoria_form.valid()){
                this.category_loading = true;
                var form_values = this.categoria_form.get_values();
                var load = {
                    categoria_nombre:form_values.categoria_nombre
                };
                var response;
                this.endpoint.insert_category(load).subscribe(
                    data => response = data,
                    err => {
                        this.category_loading = false;
                        if(err.status && err.error){
                            this.alertService.alert_message(err.status ,err.error);
                        }else{
                            this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                        }
                    },
                    ()=> {
                        try{
                            this.category_view = 1;
                            this.categoria_datatable_ref.emit_get_results_update_list();
                            this.alertService.alert_success(response.title, response.message);
                            this.category_loading = false;
                        }catch(error){
                            this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                            this.category_loading = false;
                        }
                    }
                );
            }
        }

        open_update_category(data){
            this.categoria_form.clean_form();
            this.categoria_form.set_values(data);
            this.category_loading = false;
            this.category_view = 3;
        }

        close_update_category(){
            this.category_loading = false;
            this.categoria_form.clean_form();
            this.category_view = 1;
        }

        update_category(){
            if(this.categoria_form.valid()){
                this.alertService.option_alert("Editar Categoría","¿Está seguro que desea editar la categoría seleccionado?","Sí, Editar").then((result) => {
                    if(result.value){
                        this.category_loading = true;
                        var form_values = this.categoria_form.get_values();
                        var load = {
                            id:form_values.id,
                            categoria_nombre:form_values.categoria_nombre
                        };
                        var response;
                        this.endpoint.update_category(load).subscribe(
                            data => response = data,
                            err => {
                                this.category_loading = false;
                                if(err.status && err.error){
                                    this.alertService.alert_message(err.status ,err.error);
                                }else{
                                    this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                                }
                            },
                            ()=> {
                                try{
                                    this.category_view = 1;
                                    this.categoria_datatable_ref.emit_get_results_update_list();
                                    this.alertService.alert_success(response.title, response.message);
                                    this.category_loading = false;
                                }catch(error){
                                    this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                                    this.category_loading = false;
                                }
                            }
                        );
                    }
                }).catch(()=>{return false;}); 
            }
        }

        open_delete_category(data){
            this.alertService.option_alert("Eliminar Categoría","¿Está seguro que desea eliminar la categoría seleccionada?<br><br><b>Categoría:<br></b><b class='text-success'> "+data.categoria_nombre+"</b>","Sí, Eliminar").then((result) => {
                if(result.value){
                    this.delete_category(data.id);
                }
            }).catch(()=>{return false;});
        }

        delete_category(id){
            this.category_loading = true;
            var load = {
                id:id
            };
            var response;
            this.endpoint.delete_category(load).subscribe(
                data => response = data,
                err => {
                    this.category_loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.categoria_datatable_ref.emit_get_results_update_list();
                        this.alertService.alert_success(response.title, response.message);
                        this.category_loading = false;
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.category_loading = false;
                    }
                }
            );
        }

    //CATEGORÍAS #############################################################
    //########################################################################

    //########################################################################
    //CATALOGS ###############################################################


    //CATALOGS ###############################################################
    //########################################################################

    ngOnDestroy() {
        setTimeout(() => this.layoutService.off('resize.app-home'));
    }

}
