import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { AppService } from '../../../app.service';
import { AppEndpoints } from '../../../app.endpoints';
import { LayoutService } from '../../../layout/layout.service';
import { AppFunctions } from '../../../app.functions';
import { default as swal } from 'sweetalert2';
import { AlertService } from '../../../components/alert_service/alert.service';

@Component({
	selector: 'available_sms',
	templateUrl: './available_sms.component.html',
	styleUrls: [
		'../../../../vendor/styles/circle-progressbar.scss',
		'../../../../vendor/libs/spinkit/spinkit.scss'
	]
})
export class AvailableSMSComponent implements OnInit {
	public data_formatted:any;
	public loading: boolean;
	public valid: boolean;

	constructor(private appService: AppService, public endpoint: AppEndpoints, private layoutService: LayoutService, private alertService: AlertService, public functions: AppFunctions) {
		this.appService.pageTitle = 'Histórico de Envío Mensual';
		this.data_formatted = {
			mensajes_enviados:"",
			mensajes_enviados_total:"",
			reseller_tipo:"",
			tipo_descripcion:"",
			cuotas_xmes:"",
			extension_permitido:"",
			acumulados:"",
			disponible:"",
			percentage:"",
			mes:"",
			anio:""
		}
	}

	ngOnInit() {
		this.get_user_sms_data();
	}

	get_user_sms_data(){
	   	this.loading = true;
	   	this.endpoint.get_user_sms_data().subscribe(
	   		data => {
	   			if(data.length){
	   				this.valid = true;
	   				this.loading = false;
	   				this.data_formatted = {
	   					reseller_tipo:data[0].reseller_tipo,
	   					reseller_tipo_descripcion:data[0].tipo_descripcion,
	   					anio:data[0].anio,
						mes:this.functions.get_month_string(data[0].mes-1),
						mensajes_enviados:data[0].mensajes_enviados.toLocaleString('es-HN'),
						mensajes_enviados_total:(data[0].mensajes_enviados).toLocaleString('es-HN'),
						mensajes_disponibles:(data[0].reseller_tipo==2)?"SMS Ilimitados":data[0].disponible.toLocaleString('es-HN'),
						porcentaje:data[0].percentage,
						porcentaje_consumo:data[0].percentage.toLocaleString('es-HN'),
						mensajes_acumulados:(data[0].reseller_tipo==2)?"N/A":data[0].acumulados.toLocaleString('es-HN'),
						cuotas_xmes:(data[0].reseller_tipo==2)?"N/A":data[0].cuotas_xmes.toLocaleString('es-HN'),
						extension_permitido:(data[0].reseller_tipo==2)?"N/A":data[0].extension_permitido
	   				}
	   			}else{
	   				this.valid = false;
	   				this.loading = false;
	   			}
	   		},
	   		err => {
	   			this.valid = false;
	   			this.loading = false;
	   		}
	   	);
   	}

   	getClass(){
		return `c100 p${Math.trunc(this.getPercent())}`;
	}

	getPercent(){
		return parseFloat(this.data_formatted.porcentaje.toFixed(2));
	}

	ngOnDestroy() {
		setTimeout(() => this.layoutService.off('resize.app-home'));
	}

}
