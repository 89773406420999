import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule,ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from '@angular/common';
import { SendSurveyComponent } from "./send_survey.component";
import { ServiceDatatableSmModule } from '../service_datatable_sm/service_datatable_sm.module';
import { UploadDatatableModule } from '../upload_datatable/upload_datatable.module';
import { FormRendererModule } from '../form_renderer/form_renderer.module'; 
import { ModalModule }          from 'ngx-bootstrap';
import { TextMaskModule } from 'angular2-text-mask';
import { FileDropModule } from 'ngx-file-drop';
import { DatatableModule } from '../datatable/datatable.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    SendSurveyComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    ServiceDatatableSmModule,
    FormRendererModule,
    FileDropModule,
    UploadDatatableModule,
    ModalModule.forRoot(),
    TextMaskModule,
    DatatableModule
  ],
  exports: [
    SendSurveyComponent
  ],
})

export class SendSurveyModule {}
