import {Component, OnInit, ViewChild, Input, Output, AfterViewInit, EventEmitter, Sanitizer } from '@angular/core';

@Component({
    selector: 'service-datatable-nocount',
    templateUrl: './service-datatable-nocount.template.html',
    styleUrls:[
    '../../../vendor/libs/spinkit/spinkit.scss',
    './service-datatable-nocount.style.css'
    ]
})

export class ServiceDatatableNoCountComponent implements OnInit{
    @Input() set datatable_options(option:datatableOptions) {
        if(option){
            this.options = option;
        }
    };
    @Output() option_event: EventEmitter<any> = new EventEmitter<any>();
    @Output() get_results_offset_change: EventEmitter<any> = new EventEmitter<any>();
    @Output() get_results_filter_change: EventEmitter<any> = new EventEmitter<any>();
    @Output() get_results_update_list: EventEmitter<any> = new EventEmitter<any>();

    private differ: any;
    public options:any;
    public order:string;
    public ascendent:boolean;
    public current_offset:number;
    public show_length:number;
    public results:any[];
    public loading:boolean;
    public filtering:boolean;
    public hovered_index:number;
    public hovered_event_index:number;
    public search_word:string;


    constructor(public sanitizer:Sanitizer) {
        this.order = "";
        this.search_word = "";
        this.ascendent = false;
        this.current_offset = 0;
        this.results = [];
        this.loading = false;
        this.filtering = false;
        this.hovered_index = -1;
        this.hovered_event_index = -1;
    }

    ngOnInit() {
    }

    AfterViewInit(){
    }

    set_show_length(num){
        this.show_length = num;
        this.order = "";
        this.ascendent = false;
        this.current_offset = 0;
        this.results = [];
        this.loading = false;
        this.filtering = false;
        this.hovered_index = -1;
        this.hovered_event_index = -1;
        this.emit_get_results_filter_change();
    }

    set_loading(value:boolean){
        this.loading = value;
    }

    get_loading(){
        return this.loading;
    }

    emit_option_event(index, event){
        this.option_event.emit({event:event, data:this.results[index]});
    }

    emit_get_results_offset_change(){
        this.loading = true;
        this.get_results_offset_change.emit({search_word: this.search_word, current_offset:this.current_offset, view_length:parseInt(this.show_length + ""), sort_order:this.order, sort_ascendent:this.ascendent});
    }

    emit_get_results_filter_change(){
        this.loading = true;
        this.get_results_filter_change.emit({search_word: this.search_word, current_offset:0, view_length:parseInt(this.show_length + ""), sort_order:this.order, sort_ascendent:this.ascendent});
    }

    emit_get_results_update_list(){
        this.loading = true;
        this.get_results_update_list.emit({search_word: this.search_word, current_offset:this.current_offset, view_length:parseInt(this.show_length + ""), sort_order:this.order, sort_ascendent:this.ascendent});
    }

    set_results_offset_change(results:any[]){
        this.results = results;
        this.loading = false;
    }

    set_results_filter_change(results:any[]){
        this.results = results;
        this.current_offset = 0;
        this.loading = false;
    }

    set_results_update_list(results:any[]){
        this.results = results;
        this.update_offsets_update_list();
    }

    search_results(){
        this.emit_get_results_filter_change();
    }

    clean_search(){
        this.search_word = "";
        this.order = "";
    }

    set_offset_view(selected){
        this.show_length = parseInt(selected);
        this.emit_get_results_filter_change();
    } 

    update_offsets_update_list(){
        if(this.results.length > 0){
            this.loading = false;
        }else{
            if(this.current_offset > 0){
                this.current_offset = this.current_offset-1;
                this.emit_get_results_offset_change();
            }else{
                this.loading = false;
            }
        }
    }

    updateRequestStart(){
        if(this.current_offset!=0){
            this.current_offset = 0;
            this.emit_get_results_offset_change();
        }
    }

    updateRequestsGoRight(){
        if(this.results.length==this.show_length + 1){
            this.current_offset = this.current_offset + 1;
            this.emit_get_results_offset_change();
        }
    }

    updateRequestsGoLeft(){
        if(this.current_offset!=0 && this.results.length>0){
            this.current_offset = this.current_offset - 1;
            this.emit_get_results_offset_change();
        }
        
    }

    sort_column(index){
        if(this.order == this.options.columns[index].column && this.ascendent == false){
            this.ascendent = true;

        }else if(this.order == this.options.columns[index].column && this.ascendent == true){
            this.ascendent = false;

        }else{
            this.order = this.options.columns[index].column;
            this.ascendent = false;
        }
        this.emit_get_results_filter_change();
    }

    nav_min_value(){
        return 1;
    }
}

export class datatableOptions {
    object_description:string;
    empty_text:string;
    columns:datatableColumns[];
    events:datatableEvents[];
    navigation_offsets:number[];
    navigation_starting_offset_index:number;
    show_search_field:boolean;
    table_icon:string;
};

export class datatableColumns {
    column:string;
    wrap_column:boolean;
    header:string;
    wrap_header:boolean;
    type:string;
    class:string;
};

export class datatableEvents {
    name:string;
    style:string;
    hover_style:string;
    icon:string;
};