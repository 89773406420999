import { Component, HostBinding } from '@angular/core';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-layout-footer',
  templateUrl: './layout-footer.component.html',
  styles: [':host { display: block; }']
})
export class LayoutFooterComponent {
  @HostBinding('class.layout-footer') private hostClassMain = true;
  public year:string;

  constructor(private appService: AppService) {
  	this.year = (new Date().getFullYear()) + "";
  }

  currentBg() {
    return `bg-${this.appService.layoutFooterBg}`;
  }
}
