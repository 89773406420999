import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppEndpoints } from './app.endpoints';

@Injectable()
export class LoginGuard implements CanActivate {

	constructor(private router:Router, private endpoint : AppEndpoints) {}

  	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):boolean {
  		if(this.endpoint.get_session().valid){
  			// ROLE ACCESO COMPLETO
  			if(this.endpoint.get_session().role == 1){
  				return true;
  			}
        // ROLE BASICO B CON REPORTES Y SATISFACTION
        else if(this.endpoint.get_session().role == 18 && this.endpoint.get_session().habilitar_satisfaccion && state.url != "/plataforma/menu_encuestas"){
          if(this.endpoint.get_role_18_routes().includes(state.url) || this.endpoint.get_satisfaction_routes().includes(state.url)){
              return true;
          }else{
                return false;
          }
        }
  			// ROLE ADMIN USUARIOS
  			else if(this.endpoint.get_session().role == 19){
  				if(this.endpoint.get_role_19_routes().includes(state.url)){
  					return true;
  				}else{
  					return false;
  				}
  			}
        // ROLE BASICO B CON REPORTES
        else if(this.endpoint.get_session().role == 18){
          if(this.endpoint.get_role_18_routes().includes(state.url)){
            return true;
          }else{
            return false;
          }
        }
  			// ROLE GESTOR GATEWAY
  			else if(this.endpoint.get_session().role == 7){
  				if(this.endpoint.get_role_7_routes().includes(state.url)){
  					return true;
  				}else{
  					return false;
  				}
  			}
			  // ROLE ADMIN BITACORA
		  	else if(this.endpoint.get_session().role == 3){
  				if(this.endpoint.get_role_3_routes().includes(state.url)){
  					return true;
  				}else{
  					return false;
  				}
  			}
			  // ROLE REPORTES SMPP
			  else if(this.endpoint.get_session().role == 22){
  				if(this.endpoint.get_role_22_routes().includes(state.url)){
  					return true;
  				}else{
  					return false;
  				}
  			}
        // ROLE REPORTES SMPP ACCESO LIMITADO
        else if(this.endpoint.get_session().role == 23){
          if(this.endpoint.get_role_23_routes().includes(state.url)){
            return true;
          }else{
            return false;
          }
        }
			  // ROLE GESTOR CONSULTAS INBOX
			  else if(this.endpoint.get_session().role == 17){
  				if(this.endpoint.get_role_17_routes().includes(state.url)){
  					return true;
  				}else{
  					return false;
  				}
  			}
			  // ROLE OTHER
			  else{
				  return false;
			  }
  		}else{
  			this.endpoint.reset_session();
  			return false;
  		}
  	}
}