import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { AppService } from '../../../app.service';
import { AppEndpoints } from '../../../app.endpoints';
import { LayoutService } from '../../../layout/layout.service';
import { ModalDirective } from 'ngx-bootstrap';
import { default as swal } from 'sweetalert2';
import { FormRendererComponent } from '../../../components/form_renderer/form_renderer.component';
import { AlertService } from '../../../components/alert_service/alert.service';
import { ExcelService } from '../../../components/excel_service/excel.service';
import { conformToMask } from 'angular2-text-mask/dist/angular2TextMask';
import { ServiceDatatableNoCountComponent } from '../../../components/service_datatable_nocount/service-datatable-nocount.component';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
	selector: 'bitacora_detail',
	templateUrl: './bitacora_detail.component.html',
	styleUrls: [
		'../../../../vendor/libs/spinkit/spinkit.scss'
	]
})
export class BitacoraDetailComponent implements OnInit {
	@ViewChild('shiping_detail_datatable_ref') shiping_detail_datatable_ref: ServiceDatatableNoCountComponent;
	@ViewChild('view_sms_modal') view_sms_modal: ModalDirective;
	@ViewChild("view_sms_ref") view_sms_ref;
	public load = {
		current_offset: null,
		sort_ascendent: null,
		sort_order: null,
		view_length: null,
		fecha_in: null,
		fecha_fin: null,
		sms_status: null,
		area: null,
		id_usuario: null,
		search_word: null
	};
	public shiping_detail_view: number;
	public shiping_detail_modal_view: number;
	public shiping_detail_list: any[];
	public shiping_detail_loading: boolean;
	public shiping_detail_datatable: any;
	public shiping_detail_filters = {
		current_offset: 1,
		view_length: 10,
		sort_order: "",
		sort_ascendent: false,
		search_word:""
	};
	public areas = [];
	public users = [];

	@ViewChild('shiping_detail_search_modal') shiping_detail_search_modal: ModalDirective;
	public shiping_detail_search_data = {
		fecha_in: '',
		fecha_fin: '',
		sms_status: "",
		area: "",
		id_usuario: ""
	}
	public search_data = {
		dates:[],
		sms_status: "",
		area: "",
		id_usuario: ""
	}

	public label_list: any[];
	public cellphone_mask = ['+', /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
	public locale = 'es-HN';

	@ViewChild('edit_sms_modal') edit_sms_modal: ModalDirective;
    @ViewChild("edit_sms_ref") edit_sms_ref;
	public edit_sms_loading:boolean;

	@ViewChild('download_csv_modal') download_csv_modal: ModalDirective;
	public download_loading:boolean;
	public download_view:number;
	public download_link:string;
	public download_data = {
		dates:[],
		sms_status: "",
		area: "",
		id_usuario: ""
	}

	@ViewChild('download_sms_csv_modal') download_sms_csv_modal: ModalDirective;
	public download_sms_loading:boolean;
	public download_sms_view:number;
	public download_sms_link:string;
	public download_sms_data = {
		id_calendarizado:"",
		name:""
	}
	

	constructor(private appService: AppService, public endpoint: AppEndpoints, private layoutService: LayoutService, private alertService: AlertService, private excelService: ExcelService, private localeService: BsLocaleService) {
		this.appService.pageTitle = 'Detalle de Envíos';
		this.localeService.use(this.locale);
		this.shiping_detail_view = 1;
		this.shiping_detail_modal_view = 1;
		this.shiping_detail_list = [];
		this.shiping_detail_loading = false;
		this.label_list = [];
		this.edit_sms_loading = false;

		this.download_loading = false;
		this.download_view = 1;
		this.download_link = "";
		this.download_data = {
			dates:[],
			sms_status: "",
			area: "",
			id_usuario: ""
		}

		this.download_sms_loading = false;
		this.download_sms_view = 1;
		this.download_sms_link = "";
		this.download_sms_data = {
			id_calendarizado:"",
			name:""
		}
	
	}

	ngOnInit() {
		this.set_datatable();
		this.get_bitacora_areas();
		this.get_bitacora_users();
		//this.get_areas();
		this.shiping_detail_datatable_ref.set_show_length(10);
	}

	//########################################################################
	//CLIENTS ################################################################

		shiping_detail_datatable_events(event) {
			if(event.event == "Datos del Mensaje"){
				var view_data = {
					id:event.data.id,
			        sms_mensaje_original:event.data.sms_mensaje_original,
			        nom_camp:event.data.nom_camp,
			        tipo_campania:event.data.tipo_campana,
			        num_gest:event.data.num_gest,
			        cif:event.data.cif,
			        remitente:event.data.remitente,
			        remitentegw:event.data.remitentegw,
			        sms_grupos:event.data.sms_grupos,
			        sms_cli_celular:event.data.sms_cli_celular,
			        sms_tipo:event.data.sms_tipo,
			        sms_grupo_nombre:"",
			        smsadmin_resellers_nombre:event.data.smsadmin_resellers_nombre,
			        empresa_nombre:"",
			        contacto_nombre:"",
			        tipo_campania_nombre:"",
			        sms_status:event.data.sms_status,
			        sms_fecha_string:event.data.sms_fecha_string
				}
				this.view_sms_ref.reset_sms();
	            this.view_sms_ref.set_values_sms(view_data);
	            this.view_sms_modal.show();
			}else if(event.event == "Editar Envío"){
				var edit_data = {
					id:event.data.id,
			        sms_mensaje_original:event.data.sms_mensaje_original,
			        nom_camp:event.data.nom_camp,
			        tipo_campania:event.data.tipo_campana,
			        num_gest:event.data.num_gest,
			        cif:event.data.cif,
			        remitente:event.data.remitente,
			        remitentegw:event.data.remitentegw,
			        sms_grupos:event.data.sms_grupos,
			        sms_cli_celular:event.data.sms_cli_celular,
			        sms_tipo:event.data.sms_tipo,
			        sms_grupo_nombre:"",
			        smsadmin_resellers_nombre:event.data.smsadmin_resellers_nombre,
			        empresa_nombre:"",
			        contacto_nombre:"",
			        tipo_campania_nombre:"",
			        sms_status:event.data.sms_status,
			        sms_fecha_string:event.data.sms_fecha_string
				}
				this.edit_sms_ref.reset_sms();
	            this.edit_sms_ref.set_values_sms(edit_data);
	            this.edit_sms_modal.show();
			}else if(event.event == "Eliminar Mensaje"){
				this.delete_sms(event.data);
			}else if(event.event == "Exportar Detalle"){
				this.open_download_sms(event.data);
			}
		}

		delete_sms(data){
			this.alertService.option_alert("Eliminar SMS","¿Está seguro que desea eliminar el SMS seleccionado?","Sí, Eliminar").then((result) => {
	            if(result.value){
	                this.shiping_detail_datatable_ref.set_loading(true);
	                var response;
	                var load = {
	                    id:data.id
	                }
	                this.endpoint.delete_sms(load).subscribe(
	                    data => response = data,
	                    err => {
	                        this.shiping_detail_datatable_ref.set_loading(false);
	                        if(err.status && err.error){
	                            this.alertService.alert_message(err.status ,err.error);
	                        }else{
	                            this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
	                        }
	                    },
	                    ()=> {
	                        try{
	                            this.alertService.alert_success(response.title, response.message);
	                            this.shiping_detail_datatable_get_results_update_list(this.shiping_detail_filters);
	                        }catch(error){
	                            this.alertService.alert_aplication_error("Error Interno del Aplicativo");
	                            this.shiping_detail_datatable_ref.set_loading(false);
	                        }
	                    }
	                );
	            }
	        }).catch(()=>{return false;});
		}

		edit_sms(data){
            this.edit_sms_loading = true;
            var response;
            this.endpoint.edit_sms(data).subscribe(
                data => response = data,
                err => {
                    this.edit_sms_loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.edit_sms_modal.hide();
                        this.edit_sms_ref.reset_sms();
                        this.alertService.alert_success(response.title, response.message);
                        this.shiping_detail_datatable_get_results_refresh();
                        this.edit_sms_loading = false;
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.edit_sms_loading = false;
                    }
                }
            );
        }

		shiping_detail_datatable_get_results_offset_change(data) {
			this.shiping_detail_filters = {
				current_offset: data.current_offset,
				view_length: data.view_length,
				sort_order: data.sort_order,
				sort_ascendent: data.sort_ascendent,
				search_word:data.search_word
			}
			var response;
			this.load = {
				current_offset: this.shiping_detail_filters.current_offset,
				sort_ascendent: this.shiping_detail_filters.sort_ascendent,
				sort_order: this.shiping_detail_filters.sort_order,
				view_length: this.shiping_detail_filters.view_length,
				fecha_in: this.shiping_detail_search_data.fecha_in,
				fecha_fin: this.shiping_detail_search_data.fecha_fin,
				sms_status: this.shiping_detail_search_data.sms_status,
				area: this.shiping_detail_search_data.area,
				id_usuario: this.shiping_detail_search_data.id_usuario,
				search_word: data.search_word
			}
			this.endpoint.get_bitacora_details(this.load).subscribe(
				data => {
					response = data;
				},
				err => {
					this.shiping_detail_datatable_ref.set_loading(false);
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
				},
				() => {
					try {
						for (var i = 0; i < response.list.length; i++) {
							if (response.list[i].sms_fecha) {
								response.list[i].sms_fecha_string = response.list[i].sms_fecha;
								response.list[i].sms_fecha = new Date(response.list[i].sms_fecha).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
							}
							if (response.list[i].fecha_finalizacion) {
								response.list[i].fecha_finalizacion = new Date(response.list[i].fecha_finalizacion).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
							}
							if (response.list[i].efectividad!=null && response.list[i].efectividad!=undefined) {
								response.list[i].efectividad = response.list[i].efectividad + "%";
							}
						}
						this.shiping_detail_datatable_ref.set_results_offset_change(response.list);
					} catch (error) {
						this.shiping_detail_datatable_ref.set_loading(false);
						this.alertService.alert_aplication_error("Error Interno del Aplicativo");
					}

				}
			);
		}

		shiping_detail_datatable_get_results_filter_change(data) {
			this.shiping_detail_filters = {
				current_offset: data.current_offset,
				view_length: data.view_length,
				sort_order: data.sort_order,
				sort_ascendent: data.sort_ascendent,
				search_word:data.search_word
			}
			var response;
			this.load = {
				current_offset: this.shiping_detail_filters.current_offset,
				sort_ascendent: this.shiping_detail_filters.sort_ascendent,
				sort_order: this.shiping_detail_filters.sort_order,
				view_length: this.shiping_detail_filters.view_length,
				fecha_in: this.shiping_detail_search_data.fecha_in,
				fecha_fin: this.shiping_detail_search_data.fecha_fin,
				sms_status: this.shiping_detail_search_data.sms_status,
				area: this.shiping_detail_search_data.area,
				id_usuario: this.shiping_detail_search_data.id_usuario,
				search_word: data.search_word
			}
			this.endpoint.get_bitacora_details(this.load).subscribe(
				data => {
					response = data;
				},
				err => {
					this.shiping_detail_datatable_ref.set_loading(false);
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
				},
				() => {
					try {
						for (var i = 0; i < response.list.length; i++) {
							if (response.list[i].sms_fecha) {
								response.list[i].sms_fecha_string = response.list[i].sms_fecha;
								response.list[i].sms_fecha = new Date(response.list[i].sms_fecha).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
							}
							if (response.list[i].fecha_finalizacion) {
								response.list[i].fecha_finalizacion = new Date(response.list[i].fecha_finalizacion).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
							}
							if (response.list[i].efectividad!=null && response.list[i].efectividad!=undefined) {
								response.list[i].efectividad = response.list[i].efectividad + "%";
							}					
						}
						this.shiping_detail_datatable_ref.set_results_filter_change(response.list);
					} catch (error) {
						this.shiping_detail_datatable_ref.set_loading(false);
						this.alertService.alert_aplication_error("Error Interno del Aplicativo");
					}
				}
			);
		}

		shiping_detail_datatable_get_results_update_list(data) {
			this.shiping_detail_filters = {
				current_offset: data.current_offset,
				view_length: data.view_length,
				sort_order: data.sort_order,
				sort_ascendent: data.sort_ascendent,
				search_word:data.search_word
			}
			var response;
			this.load = {
				current_offset: this.shiping_detail_filters.current_offset,
				sort_ascendent: this.shiping_detail_filters.sort_ascendent,
				sort_order: this.shiping_detail_filters.sort_order,
				view_length: this.shiping_detail_filters.view_length,
				fecha_in: this.shiping_detail_search_data.fecha_in,
				fecha_fin: this.shiping_detail_search_data.fecha_fin,
				sms_status: this.shiping_detail_search_data.sms_status,
				area: this.shiping_detail_search_data.area,
				id_usuario: this.shiping_detail_search_data.id_usuario,
				search_word: data.search_word
			}
			this.endpoint.get_bitacora_details(this.load).subscribe(
				data => {
					response = data;
				},
				err => {
					this.shiping_detail_datatable_ref.set_loading(false);
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
				},
				() => {
					try {
						for (var i = 0; i < response.list.length; i++) {
							if (response.list[i].sms_fecha) {
								response.list[i].sms_fecha_string = response.list[i].sms_fecha;
								response.list[i].sms_fecha = new Date(response.list[i].sms_fecha).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
							}
							if (response.list[i].fecha_finalizacion) {
								response.list[i].fecha_finalizacion = new Date(response.list[i].fecha_finalizacion).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
							}
							if (response.list[i].efectividad!=null && response.list[i].efectividad!=undefined) {
								response.list[i].efectividad = response.list[i].efectividad + "%";
							}
						}
						this.shiping_detail_datatable_ref.set_results_update_list(response.list);
					} catch (error) {
						this.shiping_detail_datatable_ref.set_loading(false);
						this.alertService.alert_aplication_error("Error Interno del Aplicativo");
					}
				}
			);
		}

		shiping_detail_datatable_get_results_refresh() {
			this.shiping_detail_datatable_ref.set_loading(true);
			var response;
			this.load = {
				current_offset: this.shiping_detail_filters.current_offset,
				sort_ascendent: this.shiping_detail_filters.sort_ascendent,
				sort_order: this.shiping_detail_filters.sort_order,
				view_length: this.shiping_detail_filters.view_length,
				fecha_in: this.shiping_detail_search_data.fecha_in,
				fecha_fin: this.shiping_detail_search_data.fecha_fin,
				sms_status: this.shiping_detail_search_data.sms_status,
				area: this.shiping_detail_search_data.area,
				id_usuario: this.shiping_detail_search_data.id_usuario,
				search_word: this.shiping_detail_filters.search_word,

			}
			this.endpoint.get_bitacora_details(this.load).subscribe(
				data => {
					response = data;
				},
				err => {
					this.shiping_detail_datatable_ref.set_loading(false);
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
				},
				() => {
					try {
						for (var i = 0; i < response.list.length; i++) {
							if (response.list[i].sms_fecha) {
								response.list[i].sms_fecha_string = response.list[i].sms_fecha;
								response.list[i].sms_fecha = new Date(response.list[i].sms_fecha).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
							}
							if (response.list[i].fecha_finalizacion) {
								response.list[i].fecha_finalizacion = new Date(response.list[i].fecha_finalizacion).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
							}
							if (response.list[i].efectividad!=null && response.list[i].efectividad!=undefined) {
								response.list[i].efectividad = response.list[i].efectividad + "%";
							}	
						}
						this.shiping_detail_datatable_ref.set_results_update_list(response.list);
					} catch (error) {
						this.shiping_detail_datatable_ref.set_loading(false);
						this.alertService.alert_aplication_error("Error Interno del Aplicativo");
					}
				}
			);
		}

		shiping_detail_open_search() {
			this.shiping_detail_search_modal.show();
		}

		search_shiping_details() {
			this.shiping_detail_search_data.sms_status = this.search_data.sms_status;
			this.shiping_detail_search_data.id_usuario = this.search_data.id_usuario;
			this.shiping_detail_search_data.area = this.search_data.area;
			if(this.search_data.dates && this.search_data.dates.length == 2 && this.search_data.dates[0] instanceof Date && this.search_data.dates[1] instanceof Date){
				if(this.valid_date()){
					this.shiping_detail_search_data.fecha_in = (this.search_data.dates[0].getDate()<10 ? "0" + this.search_data.dates[0].getDate() : "" + this.search_data.dates[0].getDate()) + "/" + ((this.search_data.dates[0].getMonth() + 1)<10 ? "0" + (this.search_data.dates[0].getMonth() + 1) : "" + (this.search_data.dates[0].getMonth() + 1)) + "/"+this.search_data.dates[0].getFullYear();
					this.shiping_detail_search_data.fecha_fin = (this.search_data.dates[1].getDate()<10 ? "0" + this.search_data.dates[1].getDate() : "" + this.search_data.dates[1].getDate()) + "/" + ((this.search_data.dates[1].getMonth() + 1)<10 ? "0" + (this.search_data.dates[1].getMonth() + 1) : "" + (this.search_data.dates[1].getMonth() + 1)) + "/"+this.search_data.dates[1].getFullYear();
					this.shiping_detail_search_modal.hide();
					this.shiping_detail_datatable_ref.emit_get_results_filter_change();
				}
			}else{
				this.shiping_detail_search_data.fecha_in = "";
				this.shiping_detail_search_data.fecha_fin = "";
				this.shiping_detail_search_modal.hide();
				this.shiping_detail_datatable_ref.emit_get_results_filter_change();
			}	
		}

		valid_date(){
			if(this.search_data.dates && this.search_data.dates.length == 2 && this.search_data.dates[0] instanceof Date && this.search_data.dates[1] instanceof Date){
				var diff = this.search_data.dates[1].getTime() - this.search_data.dates[0].getTime();
				if(diff/(1000 * 3600 * 24) > 30){
					return false;
				}else{
					return true;
				}
			}else{
				return true;
			}
		}

		clean_search_shiping_detail() {
			this.shiping_detail_search_data = {
				fecha_in: "",
				fecha_fin: "",
				sms_status: "",
				area: "",
				id_usuario:""
			}
			this.search_data = {
				dates:[],
				sms_status: "",
				area: "",
				id_usuario:""
			}
			this.shiping_detail_search_modal.hide();
			this.shiping_detail_datatable_ref.clean_search();
			this.shiping_detail_datatable_ref.emit_get_results_filter_change();
		}

	//CLIENTS ################################################################
	//########################################################################

	//########################################################################
	//CATALOGS ###############################################################

		get_areas(){
			this.endpoint.get_areas({}).subscribe(
				data => this.areas = data,
				err => {
					this.areas = [];
				}
			);
		}

	//CATALOGS ###############################################################
	//########################################################################

	//########################################################################
	//DOWNLOAD SMS ###########################################################

		open_download_sms(data){
			this.download_sms_loading = false;
			this.download_sms_view = 1;
			this.download_sms_link = "";
			this.download_sms_data = {
				id_calendarizado:data.id,
				name:data.sms_fecha_string + " - " + data.smsadmin_resellers_nombre
			}
			this.download_sms_csv_modal.show();
		}

		download_sms(){
			this.download_sms_loading = true;
			var response;
			var load = {
				id_calendarizado:this.download_sms_data.id_calendarizado
			}
			this.endpoint.get_sms_detail_csv(load).subscribe(
				data => {
					response = data;
				},
				err => {
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
					this.download_sms_loading = false;
				},
				() => {
					let url = response.filename;
	        		window.open(this.endpoint.get_endpoint() + "/download/" + url);
	        		this.download_sms_link = this.endpoint.get_endpoint() + "/download/" + response.filename;
	        		this.download_sms_loading = false;
	        		this.download_sms_view = 2;
				}
			);
		}

	//DOWNLOAD SMS ###########################################################
	//########################################################################

	//########################################################################
	//MISC ###################################################################

		set_datatable() {
			this.shiping_detail_datatable = {
				title: "Listado de Mensajes",
				show_search: true,
				search_placeholder: "- Buscar campañas -",
				icon: "menu-circle",
				object_description: "mensajes enviados por área",
				empty_text: "No se encontraron registros",
				header_class: "custom-th-dark",
				columns: [
					{
						column: "sms_fecha",
						wrap_column: true,
						header: "Fecha Inicio",
						wrap_header: true,
						type: "text",
						class: "custom-small-table-cell"
					},
					{
						column: "nom_camp",
						wrap_column: false,
						header: "Campaña",
						wrap_header: true,
						type: "text",
						class: "custom-small-table-cell"
					},
					{
						column: "smsadmin_resellers_nombre",
						wrap_column: false,
						header: "Área",
						wrap_header: true,
						type: "text",
						class: "custom-small-table-cell"
					},
					{
						column: "cantidad_numeros_envio",
						wrap_column: true,
						header: "Clientes",
						wrap_header: true,
						type: "text",
						class: "custom-small-table-cell"
					},
					{
						column: "sms_status",
						wrap_column: true,
						header: "Estado",
						wrap_header: true,
						type: "sms_status",
						class: "custom-small-table-cell"
					},
					{
						column: "auth_username",
						wrap_column: false,
						header: "Usuario",
						wrap_header: true,
						type: "text",
						class: "custom-small-table-cell"
					},
					{
						column: "fecha_finalizacion",
						wrap_column: true,
						header: "Fecha Fin",
						wrap_header: true,
						type: "text",
						class: "custom-small-table-cell"
					}
				],
				events: [
					{
						name: "Datos del Mensaje",
						style: "color:#39B7CB",
						hover_style: "cursor:pointer; color:#39B7CB; background-color:#BDF0FF !important;",
						icon: "comment-dots"
					},
					{
						name: "Editar Envío",
						style: "color:#ffb300;",
						hover_style: "cursor:pointer; color:#ffb300; background-color:#FFF7C6 !important;",
						icon: "edit",
						disabled:(x)=>{
							if(x.sms_status!="QUEUED" || x.detenido=='SI'){
								return true;
							}else{
								return false;
							}
						}
					},
					{
						name: "Exportar Detalle",
						style: "color:#57A473",
						hover_style:"cursor:pointer; color:#57A473; background-color:#C1FFD7 !important;",
	                    icon:"table",
	                    disabled:(x)=>{
							if(x.sms_status!="SENT" && x.sms_status!="STOPPED"){
								return true;
							}else{
								return false;
							}
						}
					},
					{
						name: "Eliminar Mensaje",
						style: "color:#FB5D5D",
						hover_style:"cursor:pointer; color:#FB5D5D; background-color:#FEDCDC !important;",
	                    icon:"trash-alt",
	                    disabled:(x)=>{
							if(x.sms_status!="QUEUED" && x.sms_status!="PARSE_ERROR" && x.sms_status!="ERROR"){
								return true;
							}else{
								return false;
							}
						}
					}
				],
				events_header:"Opciones",
				navigation_offsets: [5, 10, 15, 20, 25, 50],
				show_search_field: true,
				table_icon: "caret-right"
			}
		}

		open_download_csv(){
			this.download_csv_modal.show();
			this.download_data = {
				dates:[],
				sms_status: "",
				area: "",
				id_usuario:""
			}
			this.download_loading = false;
			this.download_view = 1;
		}

		valid_download_date(){
			if(this.download_data.dates && this.download_data.dates.length == 2 && this.download_data.dates[0] instanceof Date && this.download_data.dates[1] instanceof Date){
				var diff = this.download_data.dates[1].getTime() - this.download_data.dates[0].getTime();
				if(diff/(1000 * 3600 * 24) > 30){
					return false;
				}else{
					return true;
				}
			}else{
				return false;
			}
		}

		download_csv() {
			this.download_loading = true;
			var load = {
				fecha_in:(this.download_data.dates[0].getDate()<10 ? "0" + this.download_data.dates[0].getDate() : "" + this.download_data.dates[0].getDate()) + "/" + ((this.download_data.dates[0].getMonth() + 1)<10 ? "0" + (this.download_data.dates[0].getMonth() + 1) : "" + (this.download_data.dates[0].getMonth() + 1)) + "/"+this.download_data.dates[0].getFullYear(),
				fecha_fin:(this.download_data.dates[1].getDate()<10 ? "0" + this.download_data.dates[1].getDate() : "" + this.download_data.dates[1].getDate()) + "/" + ((this.download_data.dates[1].getMonth() + 1)<10 ? "0" + (this.download_data.dates[1].getMonth() + 1) : "" + (this.download_data.dates[1].getMonth() + 1)) + "/"+this.download_data.dates[1].getFullYear(),
				sms_status:this.download_data.sms_status,
				area: this.download_data.area,
				id_usuario: this.download_data.id_usuario
			}
			var response;
			this.endpoint.get_bitacora_details_csv(load).subscribe(
				data => {
					response = data;
				},
				err => {
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
					this.download_loading = false;
				},
				() => {
					let url = response.filename;
	        		window.open(this.endpoint.get_endpoint() + "/download/" + url);
	        		this.download_link = this.endpoint.get_endpoint() + "/download/" + response.filename;
	        		this.download_loading = false;
	        		this.download_view = 2;
				}
			);
		}

		get_bitacora_areas(){
            var response;
            this.endpoint.get_bitacora_resellers().subscribe(
                data => response = data,
                err => {
                    this.areas = [];
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.areas = [];
                        this.areas = response;
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                    
                }
            );
        }

        get_bitacora_users(){
            var response;
            var load = {
                id_area:""
            }
            this.endpoint.get_bitacora_users(load).subscribe(
                data => response = data,
                err => {
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        for(var i = 0;i<response.length;i++){
                            if(!response[i].res_nombre){
                                response[i].res_nombre = "";
                            }
                            if(!response[i].auth_fullname){
                                response[i].auth_fullname = "";
                            }
                            if(!response[i].auth_username){
                                response[i].auth_username = "";
                            }
                        }
                        this.users = response;
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                    
                }
            );
        }

	//MISC ###################################################################
	//########################################################################

	ngOnDestroy() {
		setTimeout(() => this.layoutService.off('resize.app-home'));
	}

}
