import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { AppService } from '../../../app.service';
import { AppEndpoints } from '../../../app.endpoints';
import { LayoutService } from '../../../layout/layout.service';
import { ModalDirective } from 'ngx-bootstrap';
import { default as swal } from 'sweetalert2';
import { AlertService } from '../../../components/alert_service/alert.service';
import { AppFunctions } from '../../../app.functions';
import { ServiceDatatableComponent } from '../../../components/service_datatable/service-datatable.component';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
	selector: 'consolidated_balances',
	templateUrl: './consolidated_balances.component.html',
	styleUrls: [
		'../../../../vendor/libs/spinkit/spinkit.scss'
	]
})
export class ConsolidatedBalancesComponent implements OnInit {
	@ViewChild('search_modal') search_modal: ModalDirective;
	public locale = 'es-HN';
	public enable_module:boolean;
	public loading:boolean;
	public loading_platform:boolean;
	public loading_smpp:boolean;
	public submitted:boolean;
	public search_data = {
		dates:[],
		fecha_in: '',
		fecha_fin: ''
	}
	
	public result_data = {
		platform:[],
		smpp:[],
		total_platform:0,
		total_smpp:0
	}

	@ViewChild('download_csv_modal') download_csv_modal: ModalDirective;
	public download_loading:boolean;
	public download_view:number;
	public download_link:string;
	public download_data = {
		dates:[]
	}

	constructor(private appService: AppService, public endpoint: AppEndpoints, private layoutService: LayoutService, private alertService: AlertService, public functions: AppFunctions, private localeService: BsLocaleService){
		this.appService.pageTitle = 'Balances Consolidados';
		this.localeService.use(this.locale);
		this.loading = false;
		this.loading_platform = false;
		this.loading_smpp = false;
		this.enable_module = true;
		this.submitted = false;

	}

	ngOnInit(){
		this.validate_consolidated_balances();
	}

	ngOnDestroy() {
		setTimeout(() => this.layoutService.off('resize.app-home'));
	}

	get_consolidated_balances_platform(){
		var response;
		this.loading_platform = true;
		this.endpoint.get_consolidated_balances_platform(this.search_data).subscribe(
			data => {
				response = data;
			},
			err => {
				if (err.status && err.error) {
					this.alertService.alert_message(err.status, err.error);
				} else {
					this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
				}
				this.loading_platform = false;
				this.enable_module = false;
			},
			() => {
				try{
					this.result_data.total_platform = 0;
					this.result_data.platform = [];
					if(response){
						this.result_data.platform = response;
						for(var i = 0;i<this.result_data.platform.length;i++){
							this.result_data.total_platform = this.result_data.total_platform + this.result_data.platform[i].total_sms;
						}
					}
					this.loading_platform = false;
				}catch(err){
					this.alertService.alert_aplication_error("Error intero de aplicativo");
					this.loading_platform = false;
				}
			}
		);
	}

	get_consolidated_balances_smpp(){
		var response;
		this.loading_smpp = true;
		this.endpoint.get_consolidated_balances_smpp(this.search_data).subscribe(
			data => {
				response = data;
			},
			err => {
				if (err.status && err.error) {
					this.alertService.alert_message(err.status, err.error);
				} else {
					this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
				}
				this.loading_smpp = false;
				this.enable_module = false;
			},
			() => {
				try{
					this.result_data.total_smpp = 0;
					this.result_data.smpp = [];
					if(response){
						this.result_data.smpp = response;
						for(var i = 0;i<this.result_data.smpp.length;i++){
							this.result_data.total_smpp = this.result_data.total_smpp + this.result_data.smpp[i].total_sms;
						}
					}
					this.loading_smpp = false;
				}catch(err){
					this.alertService.alert_aplication_error("Error intero de aplicativo");
					this.loading_smpp = false;
				}
			}
		);
	}

	validate_consolidated_balances(){
		var response;
		this.loading = true;
		this.endpoint.validate_consolidated_balances().subscribe(
			data => {
				response = data;
			},
			err => {
				if (err.status && err.error) {
					this.alertService.alert_message(err.status, err.error);
				} else {
					this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
				}
				this.loading = false;
				this.enable_module = false;
			},
			() => {
				try{
					if(response.enable){
						this.enable_module = true;
					}else{
						this.enable_module = false;

					}
					this.loading = false;
				}catch(err){
					this.alertService.alert_aplication_error("Error intero de aplicativo");
					this.loading = false;
				}
			}
		);
	}

	valid_date(){
		if(this.search_data.dates && this.search_data.dates.length == 2 && this.search_data.dates[0] instanceof Date && this.search_data.dates[1] instanceof Date){
			var diff = this.search_data.dates[1].getTime() - this.search_data.dates[0].getTime();
			if(diff/(1000 * 3600 * 24) > 30){
				return false;
			}else{
				return true;
			}
		}else{
			return true;
		}
	}

	do_search() {
		this.submitted = true;
		if(this.search_data.dates && this.search_data.dates.length == 2 && this.search_data.dates[0] instanceof Date && this.search_data.dates[1] instanceof Date){
			if(this.valid_date()){
				this.search_data.fecha_in = (this.search_data.dates[0].getDate()<10 ? "0" + this.search_data.dates[0].getDate() : "" + this.search_data.dates[0].getDate()) + "/" + ((this.search_data.dates[0].getMonth() + 1)<10 ? "0" + (this.search_data.dates[0].getMonth() + 1) : "" + (this.search_data.dates[0].getMonth() + 1)) + "/"+this.search_data.dates[0].getFullYear();
				this.search_data.fecha_fin = (this.search_data.dates[1].getDate()<10 ? "0" + this.search_data.dates[1].getDate() : "" + this.search_data.dates[1].getDate()) + "/" + ((this.search_data.dates[1].getMonth() + 1)<10 ? "0" + (this.search_data.dates[1].getMonth() + 1) : "" + (this.search_data.dates[1].getMonth() + 1)) + "/"+this.search_data.dates[1].getFullYear();
				this.submitted = false;
				this.get_consolidated_balances_platform();
				this.get_consolidated_balances_smpp();
			}
		}else{
			this.search_data.fecha_in = "";
			this.search_data.fecha_fin = "";
		}	
	}

	//########################################################################
	//DOWNLOAD ###############################################################

		open_download_csv(){
			this.download_loading = false;
			this.download_view = 1;
			this.download_csv_modal.show();
		}

		valid_download_date(){
			if(this.download_data.dates && this.download_data.dates.length == 2 && this.download_data.dates[0] instanceof Date && this.download_data.dates[1] instanceof Date){
				var diff = this.download_data.dates[1].getTime() - this.download_data.dates[0].getTime();
				if(diff/(1000 * 3600 * 24) > 30){
					return false;
				}else{
					return true;
				}
			}else{
				return false;
			}
		}

		download_csv() {
			this.download_loading = true;
			var load = {
				fecha_in:(this.download_data.dates[0].getDate()<10 ? "0" + this.download_data.dates[0].getDate() : "" + this.download_data.dates[0].getDate()) + "/" + ((this.download_data.dates[0].getMonth() + 1)<10 ? "0" + (this.download_data.dates[0].getMonth() + 1) : "" + (this.download_data.dates[0].getMonth() + 1)) + "/"+this.download_data.dates[0].getFullYear(),
				fecha_fin:(this.download_data.dates[1].getDate()<10 ? "0" + this.download_data.dates[1].getDate() : "" + this.download_data.dates[1].getDate()) + "/" + ((this.download_data.dates[1].getMonth() + 1)<10 ? "0" + (this.download_data.dates[1].getMonth() + 1) : "" + (this.download_data.dates[1].getMonth() + 1)) + "/"+this.download_data.dates[1].getFullYear()
			}
			var response;
			this.endpoint.get_shipping_details_csv(load).subscribe(
				data => {
					response = data;
				},
				err => {
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
					this.download_loading = false;
				},
				() => {
					let url = response.filename;
	        		window.open(this.endpoint.get_endpoint() + "/download/" + url);
	        		this.download_link = this.endpoint.get_endpoint() + "/download/" + response.filename;
	        		this.download_loading = false;
	        		this.download_view = 2;
				}
			);
		}

	//DOWNLOAD ###############################################################
	//########################################################################

}
