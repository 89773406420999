import {Component, OnInit, ViewChild, Input, Output, AfterViewInit, EventEmitter, Sanitizer } from '@angular/core';
import { AlertService } from '../alert_service/alert.service';
@Component({
  selector: 'upload-datatable',
  templateUrl: './upload_datatable.template.html',
  styleUrls:[
    '../../../vendor/libs/spinkit/spinkit.scss',
    './upload_datatable.style.css'
  ]
})

export class UploadDatatableComponent implements OnInit {
  @Input() set options(option:datatableOptions) {
    this.options_value = option;
    this.offsetView = this.options_value.navigation_offsets[this.options_value.navigation_starting_offset_index];
    this.update_offsets();
  };
  @Input() loading:any;
  @Output() option_event: EventEmitter<any> = new EventEmitter<any>();

  public options_value:any;
  public requestOffsetRight:number;
  public requestOffsetLeft:number;
  public offsetView:number;
  public displaying:string[];
  public resultados:any[];
  public headers:any[];
  private differ: any;
  public index_clicked:number;
  public hovered_index:number;
  public hovered_event_index:number;
  public header_submitted:boolean;
  public navigation_bar = {
      min6:-6,
      min5:-5,
      min4:-4,
      min3:-3,
      min2:-2,
      min1:-1,
      center:1,
      sum1:2,
      sum2:3,
      sum3:4,
      sum4:5,
      sum5:6,
      sum6:7,
  }

  constructor(public sanitizer:Sanitizer, private alertService: AlertService) {
    this.index_clicked = -1;
    this.offsetView = 0;
    this.hovered_index = -1;
    this.hovered_event_index = -1;
    this.requestOffsetRight = 0;
    this.requestOffsetLeft = 0;
    this.header_submitted = false;
    this.displaying = [];
    this.resultados = [];
    this.headers = [];
  }

  set_navigation_bar(){
      this.navigation_bar.min6 = (this.nav_current_value()-6);
      this.navigation_bar.min5 = (this.nav_current_value()-5);
      this.navigation_bar.min4 = (this.nav_current_value()-4);
      this.navigation_bar.min3 = (this.nav_current_value()-3);
      this.navigation_bar.min2 = (this.nav_current_value()-2);
      this.navigation_bar.min1 = (this.nav_current_value()-1);
      this.navigation_bar.center = (this.nav_current_value());
      this.navigation_bar.sum1 = (this.nav_current_value()+1);
      this.navigation_bar.sum2 = (this.nav_current_value()+2);
      this.navigation_bar.sum3 = (this.nav_current_value()+3);
      this.navigation_bar.sum4 = (this.nav_current_value()+4);
      this.navigation_bar.sum5 = (this.nav_current_value()+5);
      this.navigation_bar.sum6 = (this.nav_current_value()+6);
  }

  ngOnInit() {}

  AfterViewInit(){
    this.update_offsets();
  }

  set_results(data){
    this.resultados = data;
    this.header_submitted = false;
    this.update_offsets();
  }

  get_results(){
    this.loading.value = true;
    var deleting_indexes = [];
    var deleted_counter = 0;
    for(var i = 0;i<this.headers.length;i++){
      if(this.headers[i]=='eliminar_columna'){
        deleting_indexes.push(i - deleted_counter);
        deleted_counter++;
      }
    }
    for(var i = 0;i<deleting_indexes.length;i++){
      this.headers.splice(deleting_indexes[i], 1);
    }
    for(var i = 0;i<this.resultados.length;i++){
      for(var n = 0;n<deleting_indexes.length;n++){
        this.resultados[i].splice(deleting_indexes[n], 1);
      }
    }
    this.loading.value = false;
    return {
      results:this.resultados,
      results_print_length:(this.resultados.length).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0}),
      headers:this.headers
    }
  }

  remove_value(index){
    this.resultados.splice(index,1);
    if(this.resultados.length > 0){
      if(this.resultados.length < this.requestOffsetRight && this.resultados.length >= this.requestOffsetLeft){
        this.requestOffsetRight = this.resultados.length;
      }else if(this.resultados.length < this.requestOffsetLeft && this.resultados.length > this.offsetView){
        this.requestOffsetLeft = this.requestOffsetLeft - this.offsetView;
        this.requestOffsetRight = this.resultados.length;
      }else if(this.resultados.length <= this.offsetView){
        this.requestOffsetRight = this.resultados.length;
        this.requestOffsetLeft = 1;
      }
      this.displaying = [];
      for(var i = this.requestOffsetLeft-1; i < this.requestOffsetRight; i++){
        this.displaying.push(" ");
      }
    }else{
      this.displaying = [];
      this.requestOffsetRight = 0;
      this.requestOffsetLeft = 0;
    }
    this.set_navigation_bar();
  }

  change_header(index, value){
    this.headers[index]=value;
    console.log(value);
  }

  set_headers(data){
    this.headers = data;
  }

  valid_headers(){
    var valid = true;
    var error_list = [];
    var has_cellphone = false;
    var cli_identidad_count = 0;
    var cli_nombre_count = 0;
    var cli_sexo_count = 0;
    var cli_fecha_nac_count = 0;
    var cli_celular_count = 0;
    var cli_telefono_count = 0;
    var cli_email_count = 0;
    var cli_direccion_count = 0;
    var cli_tarjeta_count = 0;
    var cli_campo1_count = 0;
    var cli_campo2_count = 0;
    var cli_campo3_count = 0;
    var cli_campo4_count = 0;
    var cli_campo5_count = 0;
    var cli_campo6_count = 0;
    var cli_campo7_count = 0;
    var cli_tarjeta_count = 0;
    var cli_mensaje_count = 0;
    var cli_cif_count = 0;
    var cli_cm_count = 0;
    for(var i = 0;i<this.headers.length;i++){
      if(!this.headers[i]){
        error_list.push("La selección de la <b>columna " + (i+1) + " </b> se encuentra vacía");
        valid = false;
      }else if(this.headers[i]=='cli_celular'){
        cli_celular_count++;
        has_cellphone = true;
      }else if(this.headers[i]=='cli_identidad'){
        cli_identidad_count++;
      }else if(this.headers[i]=='cli_nombre'){
        cli_nombre_count++;
      }else if(this.headers[i]=='cli_sexo'){
        cli_sexo_count++;
      }else if(this.headers[i]=='cli_fecha_nac'){
        cli_fecha_nac_count++;
      }else if(this.headers[i]=='cli_telefono'){
        cli_telefono_count++;
      }else if(this.headers[i]=='cli_email'){
        cli_email_count++;
      }else if(this.headers[i]=='cli_direccion'){
        cli_direccion_count++;
      }else if(this.headers[i]=='cli_tarjeta'){
        cli_tarjeta_count++;
      }else if(this.headers[i]=='cli_campo1'){
        cli_campo1_count++;
      }else if(this.headers[i]=='cli_campo2'){
        cli_campo2_count++;
      }else if(this.headers[i]=='cli_campo3'){
        cli_campo3_count++;
      }else if(this.headers[i]=='cli_campo4'){
        cli_campo4_count++;
      }else if(this.headers[i]=='cli_campo5'){
        cli_campo5_count++;
      }else if(this.headers[i]=='cli_campo6'){
        cli_campo6_count++;
      }else if(this.headers[i]=='cli_campo7'){
        cli_campo7_count++;
      }else if(this.headers[i]=='cli_tarjeta'){
        cli_tarjeta_count++;
      }else if(this.headers[i]=='cli_mensaje'){
        cli_mensaje_count++;
      }else if(this.headers[i]=='cli_cif'){
        cli_cif_count++;
      }else if(this.headers[i]=='cli_cm'){
        cli_cm_count++;
      }
    }
    if(cli_identidad_count > 1){
      valid = false;
      error_list.push("Se encontró más de una selección de <b>cli_identidad</b>");
    }
    if(cli_nombre_count > 1){
      valid = false;
      error_list.push("Se encontró más de una selección de <b>cli_nombre</b>");
    }
    if(cli_sexo_count > 1){
      valid = false;
      error_list.push("Se encontró más de una selección de <b>cli_sexo</b>");
    }
    if(cli_fecha_nac_count > 1){
      valid = false;
      error_list.push("Se encontró más de una selección de <b>cli_fecha_nac</b>");
    }
    if(cli_celular_count > 1){
      valid = false;
      error_list.push("Se encontró más de una selección de <b>cli_celular</b>");
    }
    if(cli_telefono_count > 1){
      valid = false;
      error_list.push("Se encontró más de una selección de <b>cli_telefono</b>");
    }
    if(cli_email_count > 1){
      valid = false;
      error_list.push("Se encontró más de una selección de <b>cli_email</b>");
    }
    if(cli_direccion_count > 1){
      valid = false;
      error_list.push("Se encontró más de una selección de <b>cli_direccion</b>");
    }
    if(cli_tarjeta_count > 1){
      valid = false;
      error_list.push("Se encontró más de una selección de <b>cli_tarjeta</b>");
    }
    if(cli_campo1_count > 1){
      valid = false;
      error_list.push("Se encontró más de una selección de <b>cli_campo1</b>");
    }
    if(cli_campo2_count > 1){
      valid = false;
      error_list.push("Se encontró más de una selección de <b>cli_campo2</b>");
    }
    if(cli_campo3_count > 1){
      valid = false;
      error_list.push("Se encontró más de una selección de <b>cli_campo3</b>");
    }
    if(cli_campo4_count > 1){
      valid = false;
      error_list.push("Se encontró más de una selección de <b>cli_campo4</b>");
    }
    if(cli_campo5_count > 1){
      valid = false;
      error_list.push("Se encontró más de una selección de <b>cli_campo5</b>");
    }
    if(cli_campo6_count > 1){
      valid = false;
      error_list.push("Se encontró más de una selección de <b>cli_campo6</b>");
    }
    if(cli_campo7_count > 1){
      valid = false;
      error_list.push("Se encontró más de una selección de <b>cli_campo7</b>");
    }
    if(cli_tarjeta_count > 1){
      valid = false;
      error_list.push("Se encontró más de una selección de <b>cli_tarjeta</b>");
    }
    if(cli_mensaje_count > 1){
      valid = false;
      error_list.push("Se encontró más de una selección de <b>cli_mensaje</b>");
    }
    if(cli_cif_count > 1){
      valid = false;
      error_list.push("Se encontró más de una selección de <b>cli_cif</b>");
    }
    if(cli_cm_count > 1){
      valid = false;
      error_list.push("Se encontró más de una selección de <b>cli_cm</b>");
    }
    if(!has_cellphone){
      error_list.push("La selección de <b>cli_celular</b> es requerida");
    }
    if(valid && has_cellphone){
      this.header_submitted = false;
      return true;
    }else{
      this.alertService.alert_multiple_error("Error", "Se encontraron los siguientes errores:", error_list)
      this.header_submitted = true;
      return false;
    }
  }

  emit_option_event(index, event){
    for(var i = 0;i<this.resultados.length;i++){
      if(this.resultados[i]==this.resultados[index]){
        this.option_event.emit({event:event, data:this.resultados[i], index:i});
      }
    } 
    this.index_clicked = index;
  }

  update_offsets(){
    this.requestOffsetRight = 0;
    this.requestOffsetLeft = 0;
    if(this.resultados.length > 0){
      if(this.resultados.length < this.offsetView){
        this.requestOffsetRight = this.resultados.length;
      }else{
        this.requestOffsetRight = this.offsetView;
      }
      this.requestOffsetLeft = 1;
      this.displaying = [];
      for(var i = this.requestOffsetLeft-1; i < this.requestOffsetRight; i++){
        this.displaying.push(" ");
      }
    }else{
      this.displaying = [];
      this.requestOffsetRight = 0;
      this.requestOffsetLeft = 0;
    }
    this.set_navigation_bar();
  }

	updateRequestsGoRight(){
      if(this.nav_current_value()<this.nav_max_value()){
        this.pagination_navigation(this.nav_current_value() + 1);
        this.set_navigation_bar();
      }
  }

  updateRequestsGoLeft(){
      if(this.nav_current_value()>this.nav_min_value()){
        this.pagination_navigation(this.nav_current_value() + -1);
        this.set_navigation_bar();
      }
  }

  pagination_navigation(value){
    this.requestOffsetLeft = (this.offsetView * (value-1)) + 1;
    if(this.offsetView * value > this.resultados.length){
      this.requestOffsetRight = this.resultados.length;
    }else{
      this.requestOffsetRight = this.offsetView * value;
    }
    this.displaying = [];
    for(var i = this.requestOffsetLeft-1; i < this.requestOffsetRight; i++){
      this.displaying.push(" ");
    }
    this.set_navigation_bar();
  }

  nav_current_value(){
    return Math.ceil(this.requestOffsetRight/this.offsetView);
  }

  nav_min_value(){
    return 1;
  }

  nav_max_value(){
    return Math.ceil(this.resultados.length/this.offsetView);
  }
}

export class datatableOptions {
  object_description:string;
  empty_text:string;
  events:datatableEvents[];
  navigation_offsets:number[];
  navigation_starting_offset_index:number;
  show_search_field:boolean;
  table_icon:string;
};

export class datatableEvents {
  name:string;
  style:string;
  hover_style:string;
  icon:string;
};