import { Component, OnInit, Input } from '@angular/core';
import { AppEndpoints } from '../../app.endpoints';
import { AlertService } from '../../components/alert_service/alert.service';
import { AppFunctions } from '../../app.functions';

@Component({
	selector: 'sent_sms_graphs',
	templateUrl: './sent_sms_graphs.component.html',
	styleUrls:[
	'../../../vendor/libs/spinkit/spinkit.scss'
	]
})

export class SentSmsGraphsComponent implements OnInit {
	@Input() valid:boolean;
	@Input() loading:boolean;
	@Input() set day_data(data){
		this.day_labels = [];
		this.day_month_labels = [];
		var dataset = {
			label:["Envíos"],
			data: [],
	        borderWidth: 1,
	        fill: true
		}

		var dataset_inbox = {
			label:["Recibidos"],
			data: [],
	        borderWidth: 1,
	        fill: true
		}

		console.log(data)

		if(data.length == 1){
			this.day_labels.push("Inicio");
			this.day_month_labels.push("");
			dataset.data.push(0);
			dataset_inbox.data.push(0);
		}
		for(var i = 0;i<data.length;i++){
			this.day_labels.push(data[i].dia);
			this.day_month_labels.push(this.functions.get_month_string(data[i].mes-1));
			dataset.data.push(data[i].total);
			dataset_inbox.data.push(data[i].total_recibidos);
		}
		this.day_datasets = [];
		this.day_datasets.push(dataset);
		this.day_datasets.push(dataset_inbox);
	}
	@Input() set month_data(data){
		this.month_labels = [];
		this.month_year_labels = [];
		var dataset = {
			label:["Envíos"],
			data: [],
	        borderWidth: 1,
	        fill: true
		}
		var dataset_inbox_m = {
			label:["Recibidos"],
			data: [],
	        borderWidth: 1,
	        fill: true
		}

		var efective_dataset = {
			label:["Porcentaje de Efectividad"],
			data: [],
	        borderWidth: 1,
	        fill: true
		}
		if(data.length == 1){
			this.month_labels.push("Inicio");
			this.month_year_labels.push("");
			dataset.data.push(0);
			dataset_inbox_m.data.push(0);
			efective_dataset.data.push(0);
		}
		for(var i = 0;i<data.length;i++){
			this.month_labels.push(this.functions.get_month_diminutive_string(data[i].mes - 1));
			this.month_year_labels.push(data[i].anio);
			dataset.data.push(data[i].total);
			dataset_inbox_m.data.push(data[i].total_recibidos);
			if(data[i].total<=0){
				efective_dataset.data.push(0);
			}else{
				efective_dataset.data.push((data[i].efectividad/data[i].total) * 100);
			}
		}
		this.month_datasets = [];
		this.month_datasets.push(dataset);
		this.month_datasets.push(dataset_inbox_m);

		this.month_efective_datasets = [];
		this.month_efective_datasets.push(efective_dataset);
	}
	@Input() set year_data(data){

	}

	public day_labels = [];
	public day_month_labels = [];
	public day_datasets:any[];
	public day_options = {
	    scales: {
	      	xAxes: [
	        	{
	          		gridLines: {
	            		display: true
	          		},
	          		ticks: {
		            	fontColor: '#aaa',
		            	autoSkipPadding: 0
	          		}
	        	}
	      	],
	      	yAxes: [
	        	{
	          		gridLines: {
	            		display: true
	          		},
	          		ticks: {
			            fontColor: '#aaa',
			            maxTicksLimit: 4,
			            beginAtZero: true,
			            callback: function(value, index, values) {
	                        return value.toLocaleString('es-hn');
	                    }
	          		},
	        	}
	      	]
	    },
	    responsive: true,
	    maintainAspectRatio: false,
	    tooltips: {
	      	mode: 'index',
	      	callbacks: {
	        	label: function (tooltipItems, data) {
			        return data.datasets[0].data[tooltipItems.index].toLocaleString('es-hn');
		        },
		        title: (tooltipItems, data)=> {
			        return this.day_labels[tooltipItems[0].index] +" - "+this.day_month_labels[tooltipItems[0].index];
		        },
	      	}
	    },
	};
	public day_colors = [
	    {
	      backgroundColor: 'rgb(175, 122, 197,0.8)',
	      borderColor: '#76448A'
	    }
	];

	public month_labels = [];
	public month_year_labels = [];
	public month_datasets:any[];
	public month_options = {
	    scales: {
	      	xAxes: [
	        	{
	          		gridLines: {
	            		display: true
	          		},
	          		ticks: {
		            	fontColor: '#aaa',
		            	autoSkipPadding: 0
	          		}
	        	}
	      	],
	      	yAxes: [
	        	{
	          		gridLines: {
	            		display: true
	          		},
	          		ticks: {
			            fontColor: '#aaa',
			            maxTicksLimit: 4,
			            beginAtZero: true,
			            callback: function(value, index, values) {
	                        return value.toLocaleString('es-hn');
	                    }
	          		},
	        	}
	      	]
	    },
	    responsive: true,
	    maintainAspectRatio: false,
	    tooltips: {
	      	mode: 'index',
	      	callbacks: {
	        	label: function (tooltipItems, data) {
			        return data.datasets[0].data[tooltipItems.index].toLocaleString('es-hn');
		        },
		        title: (tooltipItems, data)=> {
			        return this.month_labels[tooltipItems[0].index] +" - "+this.month_year_labels[tooltipItems[0].index];
		        },
	      	}
	    },
	};
	public month_colors = [
	    {
	      backgroundColor: 'rgb(84, 153, 199,0.8)',
	      borderColor: '#2471A3'
	    }
	];

	public month_efective_datasets:any[];
	public month_efective_options = {
	    scales: {
	      	xAxes: [
	        	{
	          		gridLines: {
	            		display: true
	          		},
	          		ticks: {
		            	fontColor: '#aaa',
		            	autoSkipPadding: 0,
	          		}
	        	}
	      	],
	      	yAxes: [
	        	{
	          		gridLines: {
	            		display: true
	          		},
	          		ticks: {
			            fontColor: '#aaa',
			            maxTicksLimit: 4,
			            beginAtZero: true,
			            max:100,
			            callback: function(value, index, values) {
	                        return value.toLocaleString('es-hn') + " %";
	                    }
	          		},
	        	}
	      	]
	    },
	    responsive: true,
	    maintainAspectRatio: false,
	    tooltips: {
	      	mode: 'index',
	      	callbacks: {
	        	label: function (tooltipItems, data) {
			        return data.datasets[0].data[tooltipItems.index].toLocaleString('es-hn') + " %";
		        },
		        title: (tooltipItems, data)=> {
			        return this.month_labels[tooltipItems[0].index] +" - "+this.month_year_labels[tooltipItems[0].index];
		        },
	      	}
	    },
	};
	public month_efective_colors = [
	    {
	      backgroundColor: 'rgb(26, 188, 156,0.8)',
	      borderColor: '#148F77'
	    }
	];

	public selected_view:string;

	constructor(public functions: AppFunctions){
		this.selected_view = "month";
	}

	ngOnInit(){
	}

	set_view(view){
		this.selected_view = view;
		this.update_graph();
	}

	update_graph(){

	}
}