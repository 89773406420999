import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpUrlEncodingCodec, HttpParameterCodec, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/observable/throw';

export class FormQueryEncoder implements HttpParameterCodec {
    encodeKey(k: string): string { return encodeURIComponent(k); }
    encodeValue(v: string): string { return encodeURIComponent(v); }
    decodeKey(k: string): string { return encodeURIComponent(k); }
    decodeValue(v: string): string { return encodeURIComponent(v); }
}

@Injectable()
export class AppEndpoints {
    private endpoint: string;
    private print_chars = ("@Δ0¡P¿p£_!1AQaq$Φ\"2BRbr¥Γ#3CScsèΛ¤4DTdtéΩ%5EUeuùΠ&6FVfvìΨ\'7GWgwòΣ(8HXhxÇΘ)9IYiyΞ*:JZjzØ+;KÄkäøÆ,<LÖlöæ-=MÑmñÅß.>NÜnüåÉ/?O§oà").split('').sort();
    private chars = ("@Δ\n0¡P¿p£_!1AQaq$Φ\"2BRbr¥Γ#3CScsèΛ¤4DTdtéΩ%5EUeuùΠ&6FVfvìΨ\'7GWgwòΣ(8HXhxÇΘ)9IYiy Ξ*:JZjzØ+;KÄkäøÆ,<LÖlöæ-=MÑmñÅß.>NÜnüåÉ/?O§oà").split('').sort();

    constructor(private httpClient: HttpClient, private router: Router) {
        this.print_chars.push('SPACE');
        this.print_chars.push('ENTER');
        //this.endpoint = "http://" + window.location.hostname + ":8000/api";
        //this.endpoint = "http://" + window.location.hostname + ":8105/api";
        //this.endpoint = "http://" + window.location.hostname + "/api";
        //this.endpoint = "https://backend.impactmobile.net/api";
        //this.endpoint = "https://impactmobilehn.com:8105/api";
        //this.endpoint = "http://127.0.0.1:8200/api";
        this.endpoint = "https://impactmobile.net/api";
    }

    get_endpoint(){
        //return "http://" + window.location.hostname + ":8000";
        //return "http://" + window.location.hostname + ":8105";
        //return "http://" + window.location.hostname + "";
        //return "https://backend.impactmobile.net";
        //return "https://impactmobilehn.com:8105";
        //return "http://127.0.0.1:8200";
        return "https://impactmobile.net";
    }

    //########################################################################
    //CATALOGS ###############################################################

        get_label_types(): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_label_types", { headers: this.get_headers(), responseType: 'json' });
        }

        get_mt_status(): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_mt_status", { headers: this.get_headers(), responseType: 'json' });
        }

        get_areas(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_areas", { params: params, headers: this.get_headers(), responseType: 'json' });
        }

    //CATALOGS ###############################################################
    //########################################################################

    //########################################################################
    //SMS CALENDAR ###########################################################

        get_calendar_events(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_calendar_events", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

    //SMS CALENDAR ###########################################################
    //########################################################################

    //########################################################################
    //SEND SMS ###############################################################

        get_sms_allowed_characters(){
            return this.print_chars;
        }

        get_sms_allowed_characters_full(){
            return this.chars;
        }

        get_shortcodes():Observable<any>{
            return this.httpClient.get(this.endpoint + "/get_shortcodes", { headers:this.get_headers(), responseType: 'json' });
        }

        get_subresellers():Observable<any>{
            return this.httpClient.get(this.endpoint + "/get_subresellers", { headers:this.get_headers(), responseType: 'json' });
        }

        get_companies():Observable<any>{
            return this.httpClient.get(this.endpoint + "/get_companies", { headers:this.get_headers(), responseType: 'json' });
        }

        get_company_types():Observable<any>{
            return this.httpClient.get(this.endpoint + "/get_company_types", { headers:this.get_headers(), responseType: 'json' });
        }

        get_contacts():Observable<any>{
            return this.httpClient.get(this.endpoint + "/get_contacts", { headers:this.get_headers(), responseType: 'json' });
        }

        get_enable_cif():Observable<any>{
            return this.httpClient.get(this.endpoint + "/get_enable_cif", { headers:this.get_headers(), responseType: 'json' });
        }

        get_current_time():Observable<any>{
            return this.httpClient.get(this.endpoint + "/get_current_time", { headers:this.get_headers(), responseType: 'json' });
        }

        send_sms(payload):Observable<any>{
            return this.httpClient.post(this.endpoint + "/send_sms", payload, { headers:this.get_headers(), responseType: 'json'});
        }

        edit_sms(payload):Observable<any>{
            return this.httpClient.put(this.endpoint + "/edit_sms", payload, { headers:this.get_headers(), responseType: 'json'});
        }

        delete_sms(payload): Observable<any> {
            return this.httpClient.delete(this.endpoint + "/delete_sms", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        stop_sms(payload):Observable<any>{
            return this.httpClient.put(this.endpoint + "/stop_sms", payload, { headers:this.get_headers(), responseType: 'json'});
        }

        get_total_sms_count(payload):Observable<any>{
            return this.httpClient.post(this.endpoint + "/get_total_sms_count", payload, { headers:this.get_headers(), responseType: 'json'});
        }

        send_test_sms(payload):Observable<any>{
            return this.httpClient.post(this.endpoint + "/send_test_sms", payload, { headers:this.get_headers(), responseType: 'json'});
        }

        resend_sms(payload):Observable<any>{
            return this.httpClient.post(this.endpoint + "/resend_sms", payload, { headers:this.get_headers(), responseType: 'json'});
        }

        resend_sms_smpp(payload):Observable<any>{
            return this.httpClient.post(this.endpoint + "/resend_sms_smpp", payload, { headers:this.get_headers(), responseType: 'json'});
        }

    //SEND SMS ###############################################################
    //########################################################################

    //########################################################################
    //SEND SATISFACTION SURVEY ###############################################

        insert_group_for_satisfaction_survey(payload):Observable<any>{
            return this.httpClient.post(this.endpoint + "/insert_group_for_satisfaction_survey", payload, { headers:this.get_headers(), responseType: 'json'});
        }

        upload_clients_for_satisfaction_survey(payload): Observable<any> {
            return this.httpClient.post(this.endpoint + "/upload_clients_for_satisfaction_survey", payload, { headers: this.get_headers(), responseType: 'json' });
        }

        send_test_survey_sms(payload):Observable<any>{
            return this.httpClient.post(this.endpoint + "/send_test_survey_sms", payload, { headers:this.get_headers(), responseType: 'json'});
        }

        get_total_survey_sms_count(payload):Observable<any>{
            return this.httpClient.post(this.endpoint + "/get_total_survey_sms_count", payload, { headers:this.get_headers(), responseType: 'json'});
        }

        send_survey_sms(payload):Observable<any>{
            return this.httpClient.post(this.endpoint + "/send_survey_sms", payload, { headers:this.get_headers(), responseType: 'json'});
        }

        get_satisfaction_surveys(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_satisfaction_surveys", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        get_satisfaction_surveys_csv(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_satisfaction_surveys_csv", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        get_satisfaction_campaigns_csv(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_satisfaction_campaigns_csv", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        get_satisfaction_survey_logo(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_satisfaction_survey_logo", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        edit_satisfaction_survey(payload):Observable<any>{
            return this.httpClient.put(this.endpoint + "/edit_satisfaction_survey", payload, { headers:this.get_headers(), responseType: 'json'});
        }

    //SEND SATISFACTION SURVEY ###############################################
    //########################################################################

    //########################################################################
    //SMS SURVEY #############################################################

        get_enabled_surveys(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_enabled_surveys", { params: payload, responseType: 'json' });
        }

        get_survey_sections(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_survey_sections", { params: payload, responseType: 'json' });
        }

        get_disabled_surveys(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_disabled_surveys", { params: payload, responseType: 'json' });
        }

        insert_survey(payload):Observable<any>{
            return this.httpClient.post(this.endpoint + "/insert_survey", payload, { responseType: 'json'});
        }

        update_survey(payload): Observable<any> {
            return this.httpClient.put(this.endpoint + "/update_survey", payload, { responseType: 'json' });
        }

        disable_survey(payload): Observable<any> {
            return this.httpClient.put(this.endpoint + "/disable_survey", payload, { responseType: 'json' });
        }

        enable_survey(payload): Observable<any> {
            return this.httpClient.put(this.endpoint + "/enable_survey", payload, { responseType: 'json' });
        }

        get_survey_types(): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_survey_types", { headers: this.get_headers(), responseType: 'json' });
        }

        save_survey(payload):Observable<any>{
            return this.httpClient.post(this.endpoint + "/save_survey", payload, { responseType: 'json'});
        }

        insert_group_for_survey(payload):Observable<any>{
            return this.httpClient.post(this.endpoint + "/insert_group_for_survey", payload, { headers:this.get_headers(), responseType: 'json'});
        }

        upload_clients_for_survey(payload): Observable<any> {
            return this.httpClient.post(this.endpoint + "/upload_clients_for_survey", payload, { headers: this.get_headers(), responseType: 'json' });
        }

        send_survey_test_sms(payload):Observable<any>{
            return this.httpClient.post(this.endpoint + "/send_survey_test_sms", payload, { headers:this.get_headers(), responseType: 'json'});
        }

        get_survey_sms_count(payload):Observable<any>{
            return this.httpClient.post(this.endpoint + "/get_survey_sms_count", payload, { headers:this.get_headers(), responseType: 'json'});
        }

        send_survey(payload):Observable<any>{
            return this.httpClient.post(this.endpoint + "/send_survey", payload, { headers:this.get_headers(), responseType: 'json'});
        }

    //SMS SURVEY #############################################################
    //########################################################################

    //########################################################################
    //LABELS #################################################################

        get_labels(): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_labels", { headers: this.get_headers(), responseType: 'json' });
        }

        update_label(payload): Observable<any> {
            return this.httpClient.put(this.endpoint + "/update_label", payload, { headers: this.get_headers(), responseType: 'json' });
        }

        delete_label(payload): Observable<any> {
            return this.httpClient.delete(this.endpoint + "/delete_label", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

    //LABELS #################################################################
    //########################################################################

    //########################################################################
    //CLIENTS ################################################################

        get_clients(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_clients", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        get_clients_short(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_clients_short", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        get_clients_by_group(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_clients_by_group", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        get_clients_by_subgroup(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_clients_by_subgroup", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        get_clients_not_in_subgroup(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_clients_not_in_subgroup", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        insert_client(payload): Observable<any> {
            return this.httpClient.post(this.endpoint + "/insert_client", payload, { headers: this.get_headers(), responseType: 'json' });
        }

        update_client(payload): Observable<any> {
            return this.httpClient.put(this.endpoint + "/update_client", payload, { headers: this.get_headers(), responseType: 'json' });
        }

        delete_client(payload): Observable<any> {
            return this.httpClient.delete(this.endpoint + "/delete_client", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        delete_client_database(): Observable<any> {
            return this.httpClient.delete(this.endpoint + "/delete_client_database", { headers: this.get_headers(), responseType: 'json' });
        }

        get_area_code(): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_area_code", { headers: this.get_headers(), responseType: 'json' });
        }

        download_clients(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/download_clients", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        download_clients_from_group(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/download_clients_from_group", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

    //CLIENTS ################################################################
    //########################################################################

    //########################################################################
    //UPLOAD #################################################################

        upload_clients(payload): Observable<any> {
            return this.httpClient.post(this.endpoint + "/upload_clients", payload, { headers: this.get_headers(), responseType: 'json' });
        }

    //UPLOAD #################################################################
    //########################################################################

    //########################################################################
    //MEMBERSHIPS ############################################################

        get_memberships(): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_memberships", { headers: this.get_headers(), responseType: 'json' });
        }

        get_available_memberships(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_available_memberships", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        get_assigned_memberships(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_assigned_memberships", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        assign_membership(payload): Observable<any> {
            return this.httpClient.post(this.endpoint + "/assign_membership", payload, { headers: this.get_headers(), responseType: 'json' });
        }

        assign_multiple_membership(payload): Observable<any> {
            return this.httpClient.post(this.endpoint + "/assign_multiple_membership", payload, { headers: this.get_headers(), responseType: 'json' });
        }

        unassign_membership(payload): Observable<any> {
            return this.httpClient.post(this.endpoint + "/unassign_membership", payload, { headers: this.get_headers(), responseType: 'json' });
        }

        unassign_multiple_membership(payload): Observable<any> {
            return this.httpClient.post(this.endpoint + "/unassign_multiple_membership", payload, { headers: this.get_headers(), responseType: 'json' });
        }

        unassign_membership_by_group(payload): Observable<any> {
            return this.httpClient.post(this.endpoint + "/unassign_membership_by_group", payload, { headers: this.get_headers(), responseType: 'json' });
        }

        unassign_membership_by_subgroup(payload): Observable<any> {
            return this.httpClient.post(this.endpoint + "/unassign_membership_by_subgroup", payload, { headers: this.get_headers(), responseType: 'json' });
        }

        unassign_membership_from_group(payload): Observable<any> {
            return this.httpClient.post(this.endpoint + "/unassign_membership_from_group", payload, { headers: this.get_headers(), responseType: 'json' });
        }

        unassign_membership_from_subgroup(payload): Observable<any> {
            return this.httpClient.post(this.endpoint + "/unassign_membership_from_subgroup", payload, { headers: this.get_headers(), responseType: 'json' });
        }

        assign_membership_to_subgroup(payload): Observable<any> {
            return this.httpClient.post(this.endpoint + "/assign_membership_to_subgroup", payload, { headers: this.get_headers(), responseType: 'json' });
        }

    //MEMBERSHIPS ############################################################
    //########################################################################

    //########################################################################
    //GROUPS #################################################################

        get_group_name(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_group_name", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        get_groups(): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_groups", { headers: this.get_headers(), responseType: 'json' });
        }
      
        get_groups_for_sms():Observable<any>{
            return this.httpClient.get(this.endpoint + "/get_groups_for_sms", { headers:this.get_headers(), responseType: 'json' });
        }

        insert_group(payload):Observable<any>{
            return this.httpClient.post(this.endpoint + "/insert_group", payload, { headers:this.get_headers(), responseType: 'json'});
        }

        update_group(payload): Observable<any> {
            return this.httpClient.put(this.endpoint + "/update_group", payload, { headers: this.get_headers(), responseType: 'json' });
        }

        delete_group(payload): Observable<any> {
            return this.httpClient.delete(this.endpoint + "/delete_group", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

    //GROUPS #################################################################
    //########################################################################

    //########################################################################
    //SUBGROUPS ##############################################################

        get_subgroups(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_subgroups", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        insert_subgroup(payload): Observable<any> {
            return this.httpClient.post(this.endpoint + "/insert_subgroup", payload, { headers: this.get_headers(), responseType: 'json' });
        }

        update_subgroup(payload): Observable<any> {
            return this.httpClient.put(this.endpoint + "/update_subgroup", payload, { headers: this.get_headers(), responseType: 'json' });
        }

        delete_subgroup(payload): Observable<any> {
            return this.httpClient.delete(this.endpoint + "/delete_subgroup", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

    //SUBGROUPS ##############################################################
    //########################################################################

    //########################################################################
    //CATEGORIES #############################################################

        get_category_catalog(): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_category_catalog", { headers: this.get_headers(), responseType: 'json' });
        }

        get_categories(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_categories", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        insert_category(payload): Observable<any> {
            return this.httpClient.post(this.endpoint + "/insert_category", payload, { headers: this.get_headers(), responseType: 'json' });
        }

        update_category(payload): Observable<any> {
            return this.httpClient.put(this.endpoint + "/update_category", payload, { headers: this.get_headers(), responseType: 'json' });
        }

        delete_category(payload): Observable<any> {
            return this.httpClient.delete(this.endpoint + "/delete_category", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

    //CATEGORIES #############################################################
    //########################################################################

    //########################################################################
    //TEMPLATES ##############################################################

        get_templates(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_templates", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        insert_template(payload): Observable<any> {
            return this.httpClient.post(this.endpoint + "/insert_template", payload, { headers: this.get_headers(), responseType: 'json' });
        }

        update_template(payload): Observable<any> {
            return this.httpClient.put(this.endpoint + "/update_template", payload, { headers: this.get_headers(), responseType: 'json' });
        }

        delete_template(payload): Observable<any> {
            return this.httpClient.delete(this.endpoint + "/delete_template", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

    //TEMPLATES ##############################################################
    //########################################################################

    //########################################################################
    //AUTH ###################################################################

        login(payload): Observable<any> {
            let params = new HttpParams({ encoder: new FormQueryEncoder() })
                .set('auth_username', payload.auth_username)
                .set('auth_password', payload.auth_password);
            return this.httpClient.get(this.endpoint + "/login", { params: params, responseType: 'json' });
        }

        request_recovery_code(payload): Observable<any> {
            return this.httpClient.post(this.endpoint + "/request_recovery_code", payload, { responseType: 'json' });
        }

        validate_recovery_code(payload): Observable<any> {
            let params = new HttpParams({ encoder: new FormQueryEncoder() })
                .set('id', payload.id)
                .set('user_email', payload.user_email)
                .set('restore_code', payload.restore_code);
            return this.httpClient.get(this.endpoint + "/validate_recovery_code", { params: params, responseType: 'json' });
        }

        request_password_change(payload): Observable<any> {
            return this.httpClient.post(this.endpoint + "/request_password_change", payload, { responseType: 'json' });
        }

        request_password_change_first_login(payload): Observable<any> {
            return this.httpClient.post(this.endpoint + "/request_password_change_first_login", payload, { responseType: 'json' });
        }

        logout() {
            this.reset_session();
        }

        get_session() {
            if (localStorage.getItem('sms_marketing_impact_mobile_session')) {
                var object = JSON.parse(localStorage.getItem('sms_marketing_impact_mobile_session'));
                if (object) {
                    return {
                        name: object.name,
                        token: object.token,
                        type: object.type,
                        role: object.role, 
                        habilitar_satisfaccion: (object.habilitar_satisfaccion==1)?true:false,
                        valid: true
                    };
                } else {
                    return {
                        name: "",
                        token: "",
                        type: "",
                        role:"",
                        habilitar_satisfaccion:false,
                        valid: false
                    };
                }
            } else {
                return {
                    name: "",
                    token: "",
                    type: "",
                    role:"",
                    habilitar_satisfaccion:false,
                    valid: false
                };
            }
        }

        set_session(session) {
            localStorage.setItem('sms_marketing_impact_mobile_session', JSON.stringify(session));
        }

        reset_session() {
            this.router.navigateByUrl('/conectarse');
            localStorage.removeItem('sms_marketing_impact_mobile_session');
        }

        get_headers() {
            if (this.get_session() && this.get_session().token) {
                var headers = new HttpHeaders({
                    'Authorization': this.get_session().token
                });
                return headers;
            } else {
                return null;
            }

        }

    //AUTH ###################################################################
    //########################################################################

    //########################################################################
    //USER ADMIN #############################################################

        get_bitacora_areas(): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_bitacora_areas", { headers: this.get_headers(), responseType: 'json' });
        }

        get_bitacora_resellers(): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_bitacora_resellers", { headers: this.get_headers(), responseType: 'json' });
        }

        get_bitacora_users(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_bitacora_users", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        get_bitacora_users_disabled(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_bitacora_users_disabled", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        get_bitacora_user_data(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_bitacora_user_data", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        update_bitacora_user(payload): Observable<any> {
            return this.httpClient.put(this.endpoint + "/update_bitacora_user", payload, { headers: this.get_headers(), responseType: 'json' });
        }

        enable_bitacora_user(payload): Observable<any> {
            return this.httpClient.put(this.endpoint + "/enable_bitacora_user", payload, { headers: this.get_headers(), responseType: 'json' });
        }

        unblock_bitacora_user(payload): Observable<any> {
            return this.httpClient.put(this.endpoint + "/unblock_bitacora_user", payload, { headers: this.get_headers(), responseType: 'json' });
        }

        insert_bitacora_user(payload):Observable<any>{
            return this.httpClient.post(this.endpoint + "/insert_bitacora_user", payload, { headers:this.get_headers(), responseType: 'json'});
        }

        get_bitacora_details(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_bitacora_details", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

    //USER ADMIN #############################################################
    //########################################################################

    //########################################################################
    //EXPORTS ################################################################

        get_sms_detail_csv(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_detail_csv", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        get_shipping_details_csv(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_shipping_details_csv", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        get_bitacora_details_csv(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_bitacora_details_csv", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        get_survey_answers_csv(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_survey_answers_csv", { params: params, headers: this.get_headers(), responseType: 'json' });
        }

        get_sms_inbox_csv(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_inbox_csv", { params: params, headers: this.get_headers(), responseType: 'json' });
        }

        get_sms_sent_shortcode_csv(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_sent_shortcode_csv", { params: params, headers: this.get_headers(), responseType: 'json' });
        }

        get_sms_sent_by_area_csv(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_sent_by_area_csv", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        get_sms_count_by_area_csv(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_count_by_area_csv", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        get_sms_count_year_csv(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_count_year_csv", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        get_sms_count_by_month_csv(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_count_by_month_csv", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        get_sms_count_by_day_csv(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_count_by_day_csv", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        get_sms_count_by_number_csv(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_count_by_number_csv", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        get_cost_explorer_csv(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_cost_explorer_csv", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

        get_array_csv(payload): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_array_csv", { params: payload, headers: this.get_headers(), responseType: 'json' });
        }

    //EXPORTS ################################################################
    //########################################################################

    //########################################################################
    //OPTIMIZED REPORTS ######################################################
    
        get_shipping_details(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_shipping_details", { params: params, headers: this.get_headers(), responseType: 'json' });
        }

        get_sms_inbox(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_inbox", { params: params, headers: this.get_headers(), responseType: 'json' });
        }

        get_sms_inbox_single(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_inbox_single", { params: params, headers: this.get_headers(), responseType: 'json' });
        }

        get_sms_outbox_single(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_outbox_single", { params: params, headers: this.get_headers(), responseType: 'json' });
        }

        get_sms_sent(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_sent", { params: params, headers: this.get_headers(), responseType: 'json' });
        }

        get_sms_sent_shortcode(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_sent_shortcode", { params: params, headers: this.get_headers(), responseType: 'json' });
        }

        get_sms_sent_smpp(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_sent_smpp", { params: params, headers: this.get_headers(), responseType: 'json' });
        }

        get_sms_sent_smpp_limited(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_sent_smpp_limited", { params: params, headers: this.get_headers(), responseType: 'json' });
        }

        get_sms_sent_smpp_24h(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_sent_smpp_24h", { params: params, headers: this.get_headers(), responseType: 'json' });
        }

        get_sms_sent_smpp_limited_24h(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_sent_smpp_limited_24h", { params: params, headers: this.get_headers(), responseType: 'json' });
        }

        get_sms_count_by_area(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_count_by_area", { params: params, headers: this.get_headers(), responseType: 'json' });
        }

        get_sms_count_by_year(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_count_by_year", { params: params, headers: this.get_headers(), responseType: 'json' });
        }

        get_sms_count_by_month(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_count_by_month", { params: params, headers: this.get_headers(), responseType: 'json' });
        }

        get_sms_count_by_day(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_count_by_day", { params: params, headers: this.get_headers(), responseType: 'json' });
        }

        get_sms_count_by_number(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_count_by_number", { params: params, headers: this.get_headers(), responseType: 'json' });
        }

        get_user_sms_data(): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_user_sms_data", { headers: this.get_headers(), responseType: 'json' });
        }

        get_dashboard(): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_dashboard", { headers: this.get_headers(), responseType: 'json' });
        }

        get_smpp_dashboard(): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_smpp_dashboard", { headers: this.get_headers(), responseType: 'json' });
        }

        get_smpp_dashboard_limited(): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_smpp_dashboard_limited", { headers: this.get_headers(), responseType: 'json' });
        }

        get_sms_sent_smpp_dashboard(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_sent_smpp_dashboard", { params: params, headers: this.get_headers(), responseType: 'json' });
        }

        get_sms_sent_campaign_dashboard(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_sms_sent_campaign_dashboard", { params: params, headers: this.get_headers(), responseType: 'json' });
        }

        get_cost_explorer(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_cost_explorer", { params: params, headers: this.get_headers(), responseType: 'json' });
        }

        validate_consolidated_balances(): Observable<any> {
            return this.httpClient.get(this.endpoint + "/validate_consolidated_balances", { headers: this.get_headers(), responseType: 'json' });
        }

        get_consolidated_balances_platform(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_consolidated_balances_platform", { params: params, headers: this.get_headers(), responseType: 'json' });
        }

        get_consolidated_balances_smpp(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_consolidated_balances_smpp", { params: params, headers: this.get_headers(), responseType: 'json' });
        }

    //OPTIMIZED REPORTS ######################################################
    //########################################################################

    //########################################################################
    //FILES ##################################################################

        get_file(params): Observable<any> {
            return this.httpClient.get(this.endpoint + "/get_file", { params: params, headers: this.get_headers(), responseType: 'blob' });
        }

    //FILES ##################################################################
    //########################################################################
      
    //########################################################################
    //ROLES ##################################################################

        get_satisfaction_routes(){
            var enabled_routes = [
                '/plataforma/menu_encuestas',
                '/plataforma/menu_encuestas/encuesta_satisfaccion',
                '/plataforma/menu_encuestas/resultados_satisfaccion',
                '/plataforma/menu_encuestas/crear_encuesta',
                '/plataforma/menu_encuestas/enviar_encuesta',
                '/plataforma/menu_encuestas/encuestas_personalizadas'
            ];
            return enabled_routes;
        }

        get_role_18_routes(){
            var enabled_routes = [
                '/plataforma/manual',
                '/plataforma/inicio',
                '/plataforma/menu_clientes',
                '/plataforma/menu_clientes/grupos',
                '/plataforma/menu_clientes/clientes',
                '/plataforma/menu_clientes/etiquetas',
                '/plataforma/menu_clientes/importar_exportar',
                '/plataforma/menu_clientes/borrar_clientes',
                '/plataforma/menu_sms',
                '/plataforma/menu_sms/plantillas_sms',
                '/plataforma/menu_sms/enviar_sms',
                '/plataforma/menu_sms/importar_enviar_sms',
                '/plataforma/menu_sms/calendario_sms',
                '/plataforma/menu_encuestas',
                '/plataforma/menu_reporteria',
                '/plataforma/detalle_envios',
                '/plataforma/menu_reporteria/bandeja_salida',
                '/plataforma/menu_reporteria/bandeja_entrada',
                '/plataforma/menu_reporteria/enviados_remitente',
                //'/plataforma/menu_reporteria/bandeja_salida_smpp',
                '/plataforma/menu_reporteria/historico_envio_area',
                '/plataforma/menu_reporteria/historico_envio_diario',
                '/plataforma/menu_reporteria/historico_envio_mensual',
                '/plataforma/menu_reporteria/historico_envio_anual',
                '/plataforma/menu_reporteria/sms_disponible',
                '/plataforma/menu_reporteria/reporte_numero',
                '/plataforma/menu_reporteria/explorador_costos',
                '/plataforma/menu_reporteria/balances_consolidados'
            ];
            return enabled_routes;
        }

        get_role_19_routes(){
            var enabled_routes = [
                //'/plataforma/inicio',
                '/plataforma/manual',
                '/plataforma/admin_usuarios',
                //'/plataforma/menu_clientes',
                //'/plataforma/menu_clientes/grupos',
                //'/plataforma/menu_clientes/clientes',
                //'/plataforma/menu_clientes/etiquetas',
                //'/plataforma/menu_clientes/importar_exportar',
                //'/plataforma/menu_clientes/borrar_clientes',
                //'/plataforma/menu_sms',
                //'/plataforma/menu_sms/plantillas_sms',
                //'/plataforma/menu_sms/enviar_sms',
                //'/plataforma/menu_sms/importar_enviar_sms',
                //'/plataforma/menu_sms/calendario_sms',
                //'/plataforma/menu_reporteria',
                //'/plataforma/detalle_envios',
                '/plataforma/detalle_bitacoras',
                //'/plataforma/menu_reporteria/bandeja_salida',
                //'/plataforma/menu_reporteria/bandeja_salida_smpp',
                //'/plataforma/menu_reporteria/historico_envio_area',
                //'/plataforma/menu_reporteria/historico_envio_diario',
                //'/plataforma/menu_reporteria/historico_envio_mensual',
                //'/plataforma/menu_reporteria/historico_envio_anual',
                //'/plataforma/menu_reporteria/sms_disponible',
                //'/plataforma/menu_reporteria/reporte_numero',
                //'/plataforma/menu_reporteria/explorador_costos'
            ];
            return enabled_routes;
        }

        get_role_7_routes(){
            var enabled_routes = [
                '/plataforma/inicio',
                '/plataforma/manual',
                //'/plataforma/menu_clientes',
                //'/plataforma/menu_clientes/grupos',
                //'/plataforma/menu_clientes/clientes',
                //'/plataforma/menu_clientes/etiquetas',
                //'/plataforma/menu_clientes/importar_exportar',
                //'/plataforma/menu_clientes/borrar_clientes',
                //'/plataforma/menu_sms',
                //'/plataforma/menu_sms/plantillas_sms',
                //'/plataforma/menu_sms/enviar_sms',
                //'/plataforma/menu_sms/importar_enviar_sms',
                //'/plataforma/menu_sms/calendario_sms',
                '/plataforma/menu_reporteria',
                '/plataforma/detalle_envios',
                '/plataforma/menu_reporteria/bandeja_salida',
                '/plataforma/menu_reporteria/bandeja_entrada',
                '/plataforma/menu_reporteria/enviados_remitente',
                //'/plataforma/menu_reporteria/bandeja_salida_smpp',
                '/plataforma/menu_reporteria/historico_envio_area',
                '/plataforma/menu_reporteria/historico_envio_diario',
                '/plataforma/menu_reporteria/historico_envio_mensual',
                '/plataforma/menu_reporteria/historico_envio_anual',
                '/plataforma/menu_reporteria/sms_disponible',
                '/plataforma/menu_reporteria/reporte_numero',
                '/plataforma/menu_reporteria/explorador_costos',
                '/plataforma/menu_reporteria/balances_consolidados'
            ];
            return enabled_routes;
        }

        get_role_3_routes(){
            var enabled_routes = [
                '/plataforma/inicio',
                '/plataforma/manual',
                //'/plataforma/menu_clientes',
                //'/plataforma/menu_clientes/grupos',
                //'/plataforma/menu_clientes/clientes',
                //'/plataforma/menu_clientes/etiquetas',
                //'/plataforma/menu_clientes/importar_exportar',
                //'/plataforma/menu_clientes/borrar_clientes',
                //'/plataforma/menu_sms',
                //'/plataforma/menu_sms/plantillas_sms',
                //'/plataforma/menu_sms/enviar_sms',
                //'/plataforma/menu_sms/importar_enviar_sms',
                //'/plataforma/menu_sms/calendario_sms',
                //'/plataforma/menu_reporteria',
                //'/plataforma/detalle_envios',
                //'/plataforma/menu_reporteria/bandeja_salida',
                //'/plataforma/menu_reporteria/bandeja_salida_smpp',
                //'/plataforma/menu_reporteria/historico_envio_area',
                //'/plataforma/menu_reporteria/historico_envio_diario',
                //'/plataforma/menu_reporteria/historico_envio_mensual',
                //'/plataforma/menu_reporteria/historico_envio_anual',
                //'/plataforma/menu_reporteria/sms_disponible',
                //'/plataforma/menu_reporteria/reporte_numero',
                //'/plataforma/menu_reporteria/explorador_costos'
            ];
            return enabled_routes;
        }

        get_role_22_routes(){
            var enabled_routes = [
                '/plataforma/inicio',
                '/plataforma/manual',
                //'/plataforma/menu_clientes',
                //'/plataforma/menu_clientes/grupos',
                //'/plataforma/menu_clientes/clientes',
                //'/plataforma/menu_clientes/etiquetas',
                //'/plataforma/menu_clientes/importar_exportar',
                //'/plataforma/menu_clientes/borrar_clientes',
                //'/plataforma/menu_sms',
                //'/plataforma/menu_sms/plantillas_sms',
                //'/plataforma/menu_sms/enviar_sms',
                //'/plataforma/menu_sms/importar_enviar_sms',
                //'/plataforma/menu_sms/calendario_sms',
                '/plataforma/menu_reporteria',
                //'/plataforma/detalle_envios',
                //'/plataforma/menu_reporteria/bandeja_salida',
                '/plataforma/menu_reporteria/bandeja_entrada',
                '/plataforma/menu_reporteria/bandeja_salida_smpp',
                '/plataforma/menu_reporteria/bandeja_salida_smpp_24h',
                //'/plataforma/menu_reporteria/historico_envio_area',
                //'/plataforma/menu_reporteria/historico_envio_diario',
                //'/plataforma/menu_reporteria/historico_envio_mensual',
                //'/plataforma/menu_reporteria/historico_envio_anual',
                //'/plataforma/menu_reporteria/sms_disponible',
                //'/plataforma/menu_reporteria/reporte_numero',
                //'/plataforma/menu_reporteria/explorador_costos'
            ];
            return enabled_routes;
        }

        get_role_23_routes(){
            var enabled_routes = [
                '/plataforma/inicio',
                '/plataforma/manual',
                //'/plataforma/menu_clientes',
                //'/plataforma/menu_clientes/grupos',
                //'/plataforma/menu_clientes/clientes',
                //'/plataforma/menu_clientes/etiquetas',
                //'/plataforma/menu_clientes/importar_exportar',
                //'/plataforma/menu_clientes/borrar_clientes',
                //'/plataforma/menu_sms',
                //'/plataforma/menu_sms/plantillas_sms',
                //'/plataforma/menu_sms/enviar_sms',
                //'/plataforma/menu_sms/importar_enviar_sms',
                //'/plataforma/menu_sms/calendario_sms',
                '/plataforma/menu_reporteria',
                //'/plataforma/detalle_envios',
                //'/plataforma/menu_reporteria/bandeja_salida',
                '/plataforma/menu_reporteria/bandeja_entrada',
                '/plataforma/menu_reporteria/bandeja_salida_smpp_acceso_limitado',
                '/plataforma/menu_reporteria/bandeja_salida_smpp_acceso_limitado_24h',
                //'/plataforma/menu_reporteria/bandeja_salida_smpp',
                //'/plataforma/menu_reporteria/historico_envio_area',
                //'/plataforma/menu_reporteria/historico_envio_diario',
                //'/plataforma/menu_reporteria/historico_envio_mensual',
                //'/plataforma/menu_reporteria/historico_envio_anual',
                //'/plataforma/menu_reporteria/sms_disponible',
                //'/plataforma/menu_reporteria/reporte_numero',
                //'/plataforma/menu_reporteria/explorador_costos'
            ];
            return enabled_routes;
        }

        get_role_17_routes(){
            var enabled_routes = [
                '/plataforma/inicio',
                '/plataforma/manual',
                //'/plataforma/menu_clientes',
                //'/plataforma/menu_clientes/grupos',
                //'/plataforma/menu_clientes/clientes',
                //'/plataforma/menu_clientes/etiquetas',
                //'/plataforma/menu_clientes/importar_exportar',
                //'/plataforma/menu_clientes/borrar_clientes',
                '/plataforma/menu_sms',
                //'/plataforma/menu_sms/plantillas_sms',
                '/plataforma/menu_sms/enviar_sms',
                //'/plataforma/menu_sms/importar_enviar_sms',
                //'/plataforma/menu_sms/calendario_sms',
                '/plataforma/detalle_envios',
                '/plataforma/menu_reporteria',
                '/plataforma/menu_reporteria/bandeja_salida',
                '/plataforma/menu_reporteria/bandeja_entrada',
                //'/plataforma/menu_reporteria/bandeja_salida_smpp',
                //'/plataforma/menu_reporteria/historico_envio_area',
                //'/plataforma/menu_reporteria/historico_envio_diario',
                //'/plataforma/menu_reporteria/historico_envio_mensual',
                //'/plataforma/menu_reporteria/historico_envio_anual',
                //'/plataforma/menu_reporteria/sms_disponible',
                //'/plataforma/menu_reporteria/reporte_numero',
                //'/plataforma/menu_reporteria/explorador_costos'
            ];
            return enabled_routes;
        }

        get_show_menu(url){
            if(this.get_session().role == 1){
                  return true;
            }
            // ROLE BASICO B CON REPORTES Y SATISFACTION
            else if(this.get_session().role == 18 && this.get_session().habilitar_satisfaccion && url != "/plataforma/menu_encuestas"){
                if(this.get_role_18_routes().includes(url) || this.get_satisfaction_routes().includes(url)){
                    return true;
                }else{
                      return false;
                }
            }
            // ROLE BASICO B CON REPORTES
            else if(this.get_session().role == 18){
                if(this.get_role_18_routes().includes(url)){
                    return true;
                }else{
                      return false;
                }
            }
            // ROLE ADMIN USUARIOS
            else if(this.get_session().role == 19){
                if(this.get_role_19_routes().includes(url)){
                    return true;
                }else{
                      return false;
                }
            }
            // ROLE GESTOR GATEWAY
            else if(this.get_session().role == 7){
                if(this.get_role_7_routes().includes(url)){
                    return true;
                }else{
                    return false;
                }
            }
            // ROLE ADMIN BITACORA
            else if(this.get_session().role == 3){
                if(this.get_role_3_routes().includes(url)){
                    return true;
                }else{
                    return false;
                }
            }
            // ROLE REPORTES SMPP
            else if(this.get_session().role == 22){
                if(this.get_role_22_routes().includes(url)){
                    return true;
                }else{
                    return false;
                }
            }
            // ROLE REPORTES SMPP ACCESO LIMITADO
            else if(this.get_session().role == 23){
                if(this.get_role_23_routes().includes(url)){
                    return true;
                }else{
                    return false;
                }
            }
            // ROLE GESTOR CONSULTAS INBOX
            else if(this.get_session().role == 17){
                if(this.get_role_17_routes().includes(url)){
                    return true;
                }else{
                    return false;
                }
            }
            // ROLE OTHER
            else{
                return false;
            }
        }

    //ROLES ##################################################################
    //########################################################################
}
