import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { AppService } from '../../../app.service';
import { AppEndpoints } from '../../../app.endpoints';
import { LayoutService } from '../../../layout/layout.service';
import { ModalDirective } from 'ngx-bootstrap';
import { default as swal } from 'sweetalert2';
import { FormRendererComponent } from '../../../components/form_renderer/form_renderer.component';
import { AlertService } from '../../../components/alert_service/alert.service';
import { ExcelService } from '../../../components/excel_service/excel.service';
import { conformToMask } from 'angular2-text-mask/dist/angular2TextMask';
import { ServiceDatatableNoCountComponent } from '../../../components/service_datatable_nocount/service-datatable-nocount.component';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
	selector: 'resultados_encuesta_satisfaccion',
	templateUrl: './resultados_encuesta_satisfaccion.component.html',
	styleUrls: [
		'../../../../vendor/libs/spinkit/spinkit.scss'
	]
})
export class ResultadosEncuestaSatisfaccionComponent implements OnInit {
	@ViewChild('survey_datatable_ref') survey_datatable_ref: ServiceDatatableNoCountComponent;
	@ViewChild('view_sms_modal') view_sms_modal: ModalDirective;
	@ViewChild("view_sms_ref") view_sms_ref;
	public load = {
		current_offset: null,
		sort_ascendent: null,
		sort_order: null,
		view_length: null,
		fecha_in: null,
		fecha_fin: null,
		area: null,
		contacto: null,
		empresa: null
	};
	public survey_view: number;
	public survey_modal_view: number;
	public survey_list: any[];
	public survey_loading: boolean;
	public survey_datatable: any;
	public survey_filters = {
		current_offset: 1,
		view_length: 10,
		sort_order: "",
		sort_ascendent: false,
		search_word:"",
		area: null,
		contacto: null,
		empresa: null
	};

	@ViewChild('survey_search_modal') survey_search_modal: ModalDirective;
	public survey_search_data = {
		fecha_in: '',
		fecha_fin: '',
		area: "",
		contacto: "",
		empresa: ""
	}
	public search_data = {
		dates:[],
		area: "",
		contacto: "",
		empresa: ""
	}

	public locale = 'es-HN';

	@ViewChild('download_csv_modal') download_csv_modal: ModalDirective;
	public download_loading:boolean;
	public download_view:number;
	public download_link:string;
	public download_data = {
		dates:[],
		area: "",
		contacto: "",
		empresa: ""
	}

	@ViewChild('download_survey_csv_modal') download_survey_csv_modal: ModalDirective;
	public download_survey_loading:boolean;
	public download_survey_view:number;
	public download_survey_link:string;
	public download_survey_data = {
		survey_id:"",
		name:""
	}
	
	public contacts = [];
	public companies = [];
	public areas = [];

	public view_data={
		id:"",
        mensaje_original:"",
        nom_camp:"",
        remitente:"",
        smsadmin_resellers_nombre:"",
        empresa_nombre:"",
        contacto_nombre:"",
        survey_fecha:"",
        text_intro:"",
        text_question:"",
        text_comments:"",
        text_close:"",
        total_excelente:"",
        total_bueno:"",
        total_neutral:"",
        total_malo:"",
        total_terrible:"",
        cantidad_numeros_envio:"",
        total_unanswered:0,
        excellent:false,
        good:false,
        neutral:false,
        bad:false,
        terrible:false,
        logo_data:null
	};

	public comments_example = "";
	
	@ViewChild('sms_vista_previa') sms_vista_previa: ModalDirective;
    public sms_preview_data = {
        text:"",
        url:"",
        remitente:"",
        hour:""
    }

    @ViewChild('edit_survey_modal') edit_survey_modal: ModalDirective;
    @ViewChild('edit_survey_form') edit_survey_form: FormRendererComponent;
    public edit_survey_view:number;
	public edit_survey_loading:boolean;
	public edit_survey_submitted:boolean;
	@ViewChild('logo_input') logo_input: any;

	constructor(private appService: AppService, public endpoint: AppEndpoints, private layoutService: LayoutService, private alertService: AlertService, private excelService: ExcelService, private localeService: BsLocaleService) {
		this.appService.pageTitle = 'Detalle de Envíos';
		this.localeService.use(this.locale);
		this.survey_view = 1;
		this.survey_modal_view = 1;
		this.survey_list = [];
		this.survey_loading = false;
		this.comments_example = "";
		this.download_loading = false;
		this.download_view = 1;
		this.download_link = "";
		this.download_data = {
			dates:[],
			area: "",
			contacto: "",
			empresa: ""
		}

		this.download_survey_loading = false;
		this.download_survey_view = 1;
		this.download_survey_link = "";
		this.download_survey_data = {
			survey_id:"",
			name:""
		}
		this.edit_survey_view = 1;
		this.edit_survey_submitted = false;
		this.edit_survey_loading = false;
	}

	ngOnInit() {
		this.set_datatable();
		this.get_areas();
		this.get_companies();
		this.get_contacts();
		this.survey_datatable_ref.set_show_length(10);
	}

	//########################################################################
	//CLIENTS ################################################################

		survey_datatable_events(event) {
			if(event.event == "Resultados"){
				this.view_data = {
					id:event.data.id,
			        mensaje_original:event.data.mensaje_original,
			        nom_camp:event.data.nom_camp,
			        remitente:event.data.remitente,
			        smsadmin_resellers_nombre:event.data.smsadmin_resellers_nombre,
			        empresa_nombre:event.data.empresa_nombre,
			        contacto_nombre:event.data.contacto_nombre,
			        survey_fecha:event.data.survey_fecha,
			        text_intro:event.data.text_intro,
			        text_question:event.data.text_question,
			        text_comments:event.data.text_comments,
			        text_close:event.data.text_close,
			        total_excelente:event.data.total_excelente,
			        total_bueno:event.data.total_bueno,
			        total_neutral:event.data.total_neutral,
			        total_malo:event.data.total_malo,
			        total_terrible:event.data.total_terrible,
			        cantidad_numeros_envio:event.data.cantidad_numeros_envio,
			        total_unanswered:event.data.cantidad_numeros_envio - (event.data.total_terrible + event.data.total_malo + event.data.total_neutral + event.data.total_bueno + event.data.total_excelente),
			        excellent:(event.data.excellent==1)?true:false,
			        good:(event.data.good==1)?true:false,
			        neutral:(event.data.neutral==1)?true:false,
			        bad:(event.data.bad==1)?true:false,
			        terrible:(event.data.terrible==1)?true:false,
			        logo_data:null
				}
	            this.view_sms_modal.show();
			}else if(event.event == "Previsualizar Encuesta"){
				window.open(event.data.URL_prueba, "_blank");
			}else if(event.event == "Exportar Detalle"){
				this.open_download_survey(event.data);
			}else if(event.event == "Vista Previa SMS"){
                this.sms_preview_data.remitente = event.data.remitente;
                this.sms_preview_data.text = event.data.mensaje_original;
                this.sms_preview_data.hour = (new Date()).toLocaleTimeString('es-hn',{ hour: '2-digit', minute: '2-digit', hour12: true });
                this.sms_vista_previa.show();
			}else if(event.event == "Editar Encuesta"){
				this.view_data = {
					id:event.data.id,
			        mensaje_original:event.data.mensaje_original,
			        nom_camp:event.data.nom_camp,
			        remitente:event.data.remitente,
			        smsadmin_resellers_nombre:event.data.smsadmin_resellers_nombre,
			        empresa_nombre:event.data.empresa_nombre,
			        contacto_nombre:event.data.contacto_nombre,
			        survey_fecha:event.data.survey_fecha,
			        text_intro:event.data.text_intro,
			        text_question:event.data.text_question,
			        text_comments:event.data.text_comments,
			        text_close:event.data.text_close,
			        total_excelente:event.data.total_excelente,
			        total_bueno:event.data.total_bueno,
			        total_neutral:event.data.total_neutral,
			        total_malo:event.data.total_malo,
			        total_terrible:event.data.total_terrible,
			        cantidad_numeros_envio:event.data.cantidad_numeros_envio,
			        total_unanswered:event.data.cantidad_numeros_envio - (event.data.total_terrible + event.data.total_malo + event.data.total_neutral + event.data.total_bueno + event.data.total_excelente),
			        excellent:(event.data.excellent==1)?true:false,
			        good:(event.data.good==1)?true:false,
			        neutral:(event.data.neutral==1)?true:false,
			        bad:(event.data.bad==1)?true:false,
			        terrible:(event.data.terrible==1)?true:false,
			        logo_data:null
				}
				this.edit_survey_view = 1;
				this.edit_survey_submitted = false;
				this.get_satisfaction_survey_logo();
				this.edit_survey_modal.show();
			}
		}

		get_satisfaction_survey_logo(){
            var response;
            this.edit_survey_loading = true;
            this.endpoint.get_satisfaction_survey_logo({survey_id: this.view_data.id}).subscribe(
                data => response = data,
                err => {
                    this.view_data.logo_data = null;
                    this.edit_survey_loading = false;
                },
                ()=> {
                    try{
                        if(response.logo){
                        	this.view_data.logo_data = response.logo;
                        }
                        this.edit_survey_loading = false;
                    }catch(error){
                        this.view_data.logo_data = null;
                        this.edit_survey_loading = false;
                    }
                }
            );
        }

        edit_survey(){
        	if(this.edit_survey_form.valid){
        		this.edit_survey_submitted = false;
        		this.alertService.option_alert("Editar Encuesta","¿Está seguro que desea editar la encuesta seleccionada?","Sí, Editar").then((result) => {
                    if(result.value){
                        this.edit_survey_loading = true;
                        var load = {
                            text_intro:this.view_data.text_intro,
                            text_question:this.view_data.text_question,
                            text_comments:this.view_data.text_comments,
                            text_close:this.view_data.text_close,
                            excellent:this.view_data.excellent,
                            good:this.view_data.good,
                            neutral:this.view_data.neutral,
                            bad:this.view_data.bad,
                            terrible:this.view_data.terrible,
                            logo_data:this.view_data.logo_data,
							survey_id:this.view_data.id
                        };
                        var response;
                        this.endpoint.edit_satisfaction_survey(load).subscribe(
                            data => response = data,
                            err => {
                                this.edit_survey_loading = false;
                                if(err.status && err.error){
                                    this.alertService.alert_message(err.status ,err.error);
                                }else{
                                    this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                                }
                            },
                            ()=> {
                                try{
                                    this.edit_survey_modal.hide();
                                    this.survey_datatable_get_results_refresh();
                                    this.edit_survey_loading = false;
                                }catch(error){
                                    this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                                    this.edit_survey_loading = false;
                                }
                            }
                        );
                    }
                }).catch(()=>{return false;});
        	}else{
        		this.edit_survey_submitted = true;
        	}
        }

		survey_datatable_get_results_offset_change(data) {
			this.survey_filters = {
				current_offset: data.current_offset,
				view_length: data.view_length,
				sort_order: data.sort_order,
				sort_ascendent: data.sort_ascendent,
				search_word:data.search_word,
				area: data.area,
				contacto: data.contacto,
				empresa: data.empresa
			}
			var response;
			this.load = {
				current_offset: this.survey_filters.current_offset,
				sort_ascendent: this.survey_filters.sort_ascendent,
				sort_order: this.survey_filters.sort_order,
				view_length: this.survey_filters.view_length,
				fecha_in: this.survey_search_data.fecha_in,
				fecha_fin: this.survey_search_data.fecha_fin,
				area: this.survey_search_data.area,
				contacto: this.survey_search_data.contacto,
				empresa: this.survey_search_data.empresa
			}
			this.endpoint.get_satisfaction_surveys(this.load).subscribe(
				data => {
					response = data;
				},
				err => {
					this.survey_datatable_ref.set_loading(false);
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
				},
				() => {
					try {
						for (var i = 0; i < response.list.length; i++) {
							if (response.list[i].survey_fecha) {
								response.list[i].survey_fecha_string = response.list[i].survey_fecha;
								response.list[i].survey_fecha = new Date(response.list[i].survey_fecha).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
							}
						}
						this.survey_datatable_ref.set_results_offset_change(response.list);
					} catch (error) {
						this.survey_datatable_ref.set_loading(false);
						this.alertService.alert_aplication_error("Error Interno del Aplicativo");
					}

				}
			);
		}

		survey_datatable_get_results_filter_change(data) {
			this.survey_filters = {
				current_offset: data.current_offset,
				view_length: data.view_length,
				sort_order: data.sort_order,
				sort_ascendent: data.sort_ascendent,
				search_word:data.search_word,
				area: data.area,
				contacto: data.contacto,
				empresa: data.empresa
			}
			var response;
			this.load = {
				current_offset: this.survey_filters.current_offset,
				sort_ascendent: this.survey_filters.sort_ascendent,
				sort_order: this.survey_filters.sort_order,
				view_length: this.survey_filters.view_length,
				fecha_in: this.survey_search_data.fecha_in,
				fecha_fin: this.survey_search_data.fecha_fin,
				area: this.survey_search_data.area,
				contacto: this.survey_search_data.contacto,
				empresa: this.survey_search_data.empresa
			}
			this.endpoint.get_satisfaction_surveys(this.load).subscribe(
				data => {
					response = data;
				},
				err => {
					this.survey_datatable_ref.set_loading(false);
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
				},
				() => {
					try {
						for (var i = 0; i < response.list.length; i++) {
							if (response.list[i].survey_fecha) {
								response.list[i].survey_fecha_string = response.list[i].survey_fecha;
								response.list[i].survey_fecha = new Date(response.list[i].survey_fecha).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
							}					
						}
						this.survey_datatable_ref.set_results_filter_change(response.list);
					} catch (error) {
						this.survey_datatable_ref.set_loading(false);
						this.alertService.alert_aplication_error("Error Interno del Aplicativo");
					}
				}
			);
		}

		survey_datatable_get_results_update_list(data) {
			this.survey_filters = {
				current_offset: data.current_offset,
				view_length: data.view_length,
				sort_order: data.sort_order,
				sort_ascendent: data.sort_ascendent,
				search_word:data.search_word,
				area: data.area,
				contacto: data.contacto,
				empresa: data.empresa
			}
			var response;
			this.load = {
				current_offset: this.survey_filters.current_offset,
				sort_ascendent: this.survey_filters.sort_ascendent,
				sort_order: this.survey_filters.sort_order,
				view_length: this.survey_filters.view_length,
				fecha_in: this.survey_search_data.fecha_in,
				fecha_fin: this.survey_search_data.fecha_fin,
				area: this.survey_search_data.area,
				contacto: this.survey_search_data.contacto,
				empresa: this.survey_search_data.empresa
			}
			this.endpoint.get_satisfaction_surveys(this.load).subscribe(
				data => {
					response = data;
				},
				err => {
					this.survey_datatable_ref.set_loading(false);
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
				},
				() => {
					try {
						for (var i = 0; i < response.list.length; i++) {
							if (response.list[i].survey_fecha) {
								response.list[i].survey_fecha_string = response.list[i].survey_fecha;
								response.list[i].survey_fecha = new Date(response.list[i].survey_fecha).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
							}
						}
						this.survey_datatable_ref.set_results_update_list(response.list);
					} catch (error) {
						this.survey_datatable_ref.set_loading(false);
						this.alertService.alert_aplication_error("Error Interno del Aplicativo");
					}
				}
			);
		}

		survey_datatable_get_results_refresh() {
			this.survey_datatable_ref.set_loading(true);
			var response;
			this.load = {
				current_offset: this.survey_filters.current_offset,
				sort_ascendent: this.survey_filters.sort_ascendent,
				sort_order: this.survey_filters.sort_order,
				view_length: this.survey_filters.view_length,
				fecha_in: this.survey_search_data.fecha_in,
				fecha_fin: this.survey_search_data.fecha_fin,
				area: this.survey_search_data.area,
				contacto: this.survey_search_data.contacto,
				empresa: this.survey_search_data.empresa
			}
			this.endpoint.get_satisfaction_surveys(this.load).subscribe(
				data => {
					response = data;
				},
				err => {
					this.survey_datatable_ref.set_loading(false);
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
				},
				() => {
					try {
						for (var i = 0; i < response.list.length; i++) {
							if (response.list[i].survey_fecha) {
								response.list[i].survey_fecha_string = response.list[i].survey_fecha;
								response.list[i].survey_fecha = new Date(response.list[i].survey_fecha).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
							}	
						}
						this.survey_datatable_ref.set_results_update_list(response.list);
					} catch (error) {
						this.survey_datatable_ref.set_loading(false);
						this.alertService.alert_aplication_error("Error Interno del Aplicativo");
					}
				}
			);
		}

		survey_open_search() {
			this.survey_search_modal.show();
		}

		search_shiping_details() {
			if(this.search_data.dates && this.search_data.dates.length == 2 && this.search_data.dates[0] instanceof Date && this.search_data.dates[1] instanceof Date){
				if(this.valid_date()){
					this.survey_search_data.fecha_in = (this.search_data.dates[0].getDate()<10 ? "0" + this.search_data.dates[0].getDate() : "" + this.search_data.dates[0].getDate()) + "/" + ((this.search_data.dates[0].getMonth() + 1)<10 ? "0" + (this.search_data.dates[0].getMonth() + 1) : "" + (this.search_data.dates[0].getMonth() + 1)) + "/"+this.search_data.dates[0].getFullYear();
					this.survey_search_data.fecha_fin = (this.search_data.dates[1].getDate()<10 ? "0" + this.search_data.dates[1].getDate() : "" + this.search_data.dates[1].getDate()) + "/" + ((this.search_data.dates[1].getMonth() + 1)<10 ? "0" + (this.search_data.dates[1].getMonth() + 1) : "" + (this.search_data.dates[1].getMonth() + 1)) + "/"+this.search_data.dates[1].getFullYear();
					this.survey_search_modal.hide();
					this.survey_datatable_ref.emit_get_results_filter_change();
				}
			}else{
				this.survey_search_data.fecha_in = "";
				this.survey_search_data.fecha_fin = "";
				this.survey_search_modal.hide();
				this.survey_datatable_ref.emit_get_results_filter_change();
			}	
		}

		valid_date(){
			if(this.search_data.dates && this.search_data.dates.length == 2 && this.search_data.dates[0] instanceof Date && this.search_data.dates[1] instanceof Date){
				var diff = this.search_data.dates[1].getTime() - this.search_data.dates[0].getTime();
				if(diff/(1000 * 3600 * 24) > 30){
					return false;
				}else{
					return true;
				}
			}else{
				return true;
			}
		}

		clean_search_shiping_detail() {
			this.survey_search_data = {
				fecha_in: "",
				fecha_fin: "",
				area: "",
				contacto: "",
				empresa: ""
			}
			this.search_data = {
				dates:[],
				area: "",
				contacto: "",
				empresa: ""
			}
			this.survey_search_modal.hide();
			this.survey_datatable_ref.clean_search();
			this.survey_datatable_ref.emit_get_results_filter_change();
		}

	//CLIENTS ################################################################
	//########################################################################


	//########################################################################
	//DOWNLOAD SMS ###########################################################

		open_download_survey(data){
			this.download_survey_loading = false;
			this.download_survey_view = 1;
			this.download_survey_link = "";
			this.download_survey_data = {
				survey_id:data.id,
				name:data.survey_fecha
			}
			this.download_survey_csv_modal.show();
		}

		download_survey(){
			this.download_survey_loading = true;
			var response;
			var load = {
				survey_id:this.download_survey_data.survey_id
			}
			this.endpoint.get_satisfaction_surveys_csv(load).subscribe(
				data => {
					response = data;
				},
				err => {
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
					this.download_survey_loading = false;
				},
				() => {
					let url = response.filename;
	        		window.open(this.endpoint.get_endpoint() + "/download/" + url);
	        		this.download_survey_link = this.endpoint.get_endpoint() + "/download/" + response.filename;
	        		this.download_survey_loading = false;
	        		this.download_survey_view = 2;
				}
			);
		}

		get_areas(){
			this.endpoint.get_areas({}).subscribe(
				data => this.areas = data,
				err => {
					this.areas = [];
				}
			);
		}

		get_contacts(){
			this.endpoint.get_contacts().subscribe(
				data => this.contacts = data,
				err => {
					this.contacts = [];
				}
			);
		}

		get_companies(){
			this.endpoint.get_companies().subscribe(
				data => this.companies = data,
				err => {
					this.companies = [];
				}
			);
		}

	//DOWNLOAD SMS ###########################################################
	//########################################################################

	//########################################################################
	//MISC ###################################################################

		remove_logo(){
            this.view_data.logo_data = null;
            this.logo_input.nativeElement.value = "";
        }

        upload_image_add(event){
            let fileList: FileList = event.target.files;
            if(fileList.length > 0) {
                let file: File = fileList[0];
                var input = event.target;

                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                reader.onload = (data) => {
                    try{
                        this.view_data.logo_data = reader.result;
                        this.logo_input.nativeElement.value = "";
                    }catch(err){
                        swal({
                            title: "Error al Cargar Archivo",
                            text: "Se produjo un error al intentar cargar el archivo, favor verificar que el formato del archivo sea el correcto",
                            type: "warning",
                            allowOutsideClick: false
                        }).catch(swal.noop)
                        this.logo_input.nativeElement.value = "";
                    }
                }
                reader.onerror = function () {
                    swal({
                        title: "Error al Cargar Archivo",
                        text: "Se produjo un error al intentar cargar el archivo, favor verificar que el formato del archivo sea el correcto",
                        type: "warning",
                        allowOutsideClick: false
                    }).catch(swal.noop)
                };
            }
        }

		set_datatable() {
			this.survey_datatable = {
				title: "Listado de Encuestas",
				show_search: true,
				search_placeholder: "- Buscar Encuestas -",
				icon: "gift",
				object_description: "Encuestas enviadas por área",
				empty_text: "No se encontraron registros",
				header_class: "custom-th-dark",
				columns: [
					{
						column: "survey_fecha",
						wrap_column: true,
						header: "Fecha Envío",
						wrap_header: true,
						type: "text",
						class: "custom-small-table-cell"
					},
					{
						column: "nom_camp",
						wrap_column: false,
						header: "Campaña",
						wrap_header: true,
						type: "text",
						class: "custom-small-table-cell"
					},
					{
						column: "smsadmin_resellers_nombre",
						wrap_column: false,
						header: "Área",
						wrap_header: true,
						type: "text",
						class: "custom-small-table-cell"
					},
					{
						column: "contacto_nombre",
						wrap_column: false,
						header: "Contacto",
						wrap_header: true,
						type: "text",
						class: "custom-small-table-cell"
					},
					{
						column: "cantidad_numeros_envio",
						wrap_column: true,
						header: "Clientes",
						wrap_header: true,
						type: "text",
						class: "custom-small-table-cell"
					}
				],
				events: [
					{
						name: "Resultados",
						style: "color:#39B7CB",
						hover_style: "cursor:pointer; color:#39B7CB; background-color:#BDF0FF !important;",
						icon: "gift"
					},
					{
						name: "Previsualizar Encuesta",
						style: "color:#323232",
						hover_style: "cursor:pointer; color:#323232; background-color:#D8D8D8 !important;",
						icon: "list-alt"
					},
					{
						name: "Vista Previa SMS",
						style: "color:#6E2EB8",
						hover_style: "cursor:pointer; color:#6E2EB8; background-color:#E2D3F4 !important;",
						icon: "envelope"
					},
					{
						name: "Exportar Detalle",
						style: "color:#57A473",
						hover_style:"cursor:pointer; color:#57A473; background-color:#C1FFD7 !important;",
	                    icon:"table"
					},
					{
						name: "Editar Encuesta",
						style: "color:#ffb300;",
						hover_style: "cursor:pointer; color:#ffb300; background-color:#FFF7C6 !important;",
						icon: "edit"
					},
				],
				events_header:"Opciones",
				navigation_offsets: [5, 10, 15, 20, 25, 50],
				show_search_field: true,
				table_icon: "caret-right"
			}
		}

		open_download_csv(){
			this.download_csv_modal.show();
			this.download_data = {
				dates:[],
				area: "",
				contacto: "",
				empresa: ""
			}
			this.download_loading = false;
			this.download_view = 1;
		}

		valid_download_date(){
			if(this.download_data.dates && this.download_data.dates.length == 2 && this.download_data.dates[0] instanceof Date && this.download_data.dates[1] instanceof Date){
				var diff = this.download_data.dates[1].getTime() - this.download_data.dates[0].getTime();
				if(diff/(1000 * 3600 * 24) > 30){
					return false;
				}else{
					return true;
				}
			}else{
				return false;
			}
		}

		download_csv() {
			this.download_loading = true;
			var load = {
				fecha_in:(this.download_data.dates[0].getDate()<10 ? "0" + this.download_data.dates[0].getDate() : "" + this.download_data.dates[0].getDate()) + "/" + ((this.download_data.dates[0].getMonth() + 1)<10 ? "0" + (this.download_data.dates[0].getMonth() + 1) : "" + (this.download_data.dates[0].getMonth() + 1)) + "/"+this.download_data.dates[0].getFullYear(),
				fecha_fin:(this.download_data.dates[1].getDate()<10 ? "0" + this.download_data.dates[1].getDate() : "" + this.download_data.dates[1].getDate()) + "/" + ((this.download_data.dates[1].getMonth() + 1)<10 ? "0" + (this.download_data.dates[1].getMonth() + 1) : "" + (this.download_data.dates[1].getMonth() + 1)) + "/"+this.download_data.dates[1].getFullYear(),
				area: this.download_data.area,
				contacto: this.download_data.contacto,
				empresa: this.download_data.empresa
			}
			var response;
			this.endpoint.get_satisfaction_campaigns_csv(load).subscribe(
				data => {
					response = data;
				},
				err => {
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
					this.download_loading = false;
				},
				() => {
					let url = response.filename;
	        		window.open(this.endpoint.get_endpoint() + "/download/" + url);
	        		this.download_link = this.endpoint.get_endpoint() + "/download/" + response.filename;
	        		this.download_loading = false;
	        		this.download_view = 2;
				}
			);
		}

	//MISC ###################################################################
	//########################################################################

	ngOnDestroy() {
		setTimeout(() => this.layoutService.off('resize.app-home'));
	}

}
