import { Component, OnInit, Input } from '@angular/core';
import { AppEndpoints } from '../../app.endpoints';
import { AppFunctions } from '../../app.functions';
import { AlertService } from '../../components/alert_service/alert.service';

@Component({
	selector: 'area_usage_chart',
	templateUrl: './area_usage_chart.component.html',
	styleUrls: [
		'../../../vendor/libs/spinkit/spinkit.scss'
	]
})

export class AreaUsageChart implements OnInit {
	@Input() valid:boolean;
	@Input() data:any[];
	@Input() loading:boolean;

	constructor(public endpoint: AppEndpoints, private alertService: AlertService, public functions: AppFunctions) {
	}

	ngOnInit() {
	}
}