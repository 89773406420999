import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {FormsModule,ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from '@angular/common';
import {UploadDatatableComponent} from "./upload_datatable.component";
@NgModule({
  declarations: [
    UploadDatatableComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    UploadDatatableComponent
  ],
})

export class UploadDatatableModule {}
