import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, OnInit  } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { AppService } from '../../../app.service';
import { AppEndpoints } from '../../../app.endpoints';
import { LayoutService } from '../../../layout/layout.service';
import { ModalDirective } from 'ngx-bootstrap';
import { default as swal } from 'sweetalert2';
import { FormRendererComponent } from '../../../components/form_renderer/form_renderer.component';
import { AlertService } from '../../../components/alert_service/alert.service';
import { ExcelService } from '../../../components/excel_service/excel.service';

@Component({
    selector: 'users',
    templateUrl: './users.component.html',
    styleUrls:[
        '../../../../vendor/libs/spinkit/spinkit.scss'
    ]
})
export class UsersComponent implements OnInit{
    @ViewChild('users_modal') users_modal: ModalDirective;
    @ViewChild('insert_user_modal') insert_user_modal: ModalDirective;
    @ViewChild('edit_user_form') edit_user_form: FormControlDirective;
    @ViewChild('insert_user_form') insert_user_form: FormControlDirective;
    public view:number;
    public areas:any[];
    public users:any[];
    public users_disabled:any[];
    public loading:boolean;
    public user_loading:boolean;
    public submitted:boolean;
    public search:string;
    public data = {
        area_filter:""
    }
    public label_data = {
        id:"",
        var_campo:""
    }
    public user_data = {
        res_nombre:"",
        auth_fullname:"",
        auth_username:"",
        auth_password:"",
        id:"",
        hidden:false,
        habilitado:true
    }

    public insert_user_data = {
        area_id:"",
        auth_fullname:"",
        auth_username:"",
        auth_password:""
    }

    constructor(private appService: AppService, public endpoint : AppEndpoints,  private layoutService: LayoutService, private alertService: AlertService, private excelService: ExcelService) {
        this.appService.pageTitle = 'Admin Usuarios';
        this.view = 1;
        this.search = "";
        this.areas = [];
        this.users = [];
        this.users_disabled = [];
        this.loading = false;
        this.user_loading = false;
        this.submitted = false;
    }

    ngOnInit(){
        //this.get_label_types();
        this.get_bitacora_areas();
    }

    //########################################################################
    //BITACORAS ##############################################################

        get_bitacora_areas(){
            this.loading = true;
            var response;
            this.endpoint.get_bitacora_areas().subscribe(
                data => response = data,
                err => {
                    this.areas = [];
                    this.loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.areas = [];
                        this.areas = response;
                        this.get_bitacora_users();
                    }catch(error){
                        this.loading = false;
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                    
                }
            );
        }

        get_bitacora_users(){
            this.loading = true;
            var response;
            var load = {
                id_area:this.data.area_filter
            }
            this.endpoint.get_bitacora_users(load).subscribe(
                data => response = data,
                err => {
                    this.areas = [];
                    this.loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        for(var i = 0;i<response.length;i++){
                            if(!response[i].res_nombre){
                                response[i].res_nombre = "";
                            }
                            if(!response[i].auth_fullname){
                                response[i].auth_fullname = "";
                            }
                            if(!response[i].auth_username){
                                response[i].auth_username = "";
                            }
                        }
                        this.users = response;
                        this.loading = false;
                    }catch(error){
                        this.loading = false;
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                    
                }
            );
        }

        get_bitacora_user_data(user){
            this.users_modal.show();
            this.user_data = {
                res_nombre:"",
                auth_fullname:"",
                auth_username:"",
                auth_password:"",
                id:"",
                hidden:false,
                habilitado:true
            }
            this.submitted = false;
            this.user_loading = true;
            var response;
            var load = {
                id:user.id
            }
            this.endpoint.get_bitacora_user_data(load).subscribe(
                data => response = data,
                err => {
                    this.user_loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.submitted = false;
                        this.user_data = {
                            res_nombre: response[0].res_nombre,
                            auth_fullname: response[0].auth_fullname,
                            auth_username: response[0].auth_username,
                            auth_password: response[0].auth_password,
                            id: response[0].id,
                            hidden:true,
                            habilitado:true
                        }
                        this.user_loading = false;
                    }catch(error){
                        this.user_loading = false;
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                    
                }
            );
        }

        get_bitacora_users_disabled(){
            this.loading = true;
            var response;
            var load = {
                id_area:this.data.area_filter
            }
            this.endpoint.get_bitacora_users_disabled(load).subscribe(
                data => response = data,
                err => {
                    this.areas = [];
                    this.loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        for(var i = 0;i<response.length;i++){
                            if(!response[i].res_nombre){
                                response[i].res_nombre = "";
                            }
                            if(!response[i].auth_fullname){
                                response[i].auth_fullname = "";
                            }
                            if(!response[i].auth_username){
                                response[i].auth_username = "";
                            }
                        }
                        this.users_disabled = response;
                        this.loading = false;
                    }catch(error){
                        this.loading = false;
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                    
                }
            );
        }

        update_bitacora_user(){
            if(this.edit_user_form.valid){
                this.submitted = false;
                this.alertService.option_alert("Editar Usuario","¿Está seguro que desea editar el usuario seleccionado?","Sí, Editar").then((result) => {
                    if(result.value){
                        this.user_loading = true;
                        var response;
                        this.endpoint.update_bitacora_user({auth_fullname:this.user_data.auth_fullname, auth_password:this.user_data.auth_password, id:this.user_data.id, habilitado:(this.user_data.habilitado)?1:0}).subscribe(
                            data => response = data,
                            err => {
                                this.user_loading = false;
                                if(err.status && err.error){
                                    this.alertService.alert_message(err.status ,err.error);
                                }else{
                                    this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                                }
                            },
                            ()=> {
                                try{
                                    this.users_modal.hide();
                                    this.go_to_active();
                                    this.alertService.alert_success(response.title, response.message);
                                    this.user_loading = false;
                                }catch(error){
                                    this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                                    this.user_loading = false;
                                }
                            }
                        );
                    }
                }).catch(()=>{return false;}); 
            }else{
                this.submitted = true;
            }
        }

        enable_bitacora_user(id){
            this.alertService.option_alert("Habilitar Usuario","¿Está seguro que desea habilitar el usuario seleccionado?","Sí, Habilitar").then((result) => {
                if(result.value){
                    this.loading = true;
                    var response;
                    this.endpoint.enable_bitacora_user({id:id}).subscribe(
                        data => response = data,
                        err => {
                            this.loading = false;
                            if(err.status && err.error){
                                this.alertService.alert_message(err.status ,err.error);
                            }else{
                                this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                            }
                        },
                        ()=> {
                            try{
                                this.users_modal.hide();
                                this.go_to_inactive();
                                this.alertService.alert_success(response.title, response.message);
                                this.loading = false;
                            }catch(error){
                                this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                                this.loading = false;
                            }
                        }
                    );
                }
            }).catch(()=>{return false;}); 
        }

        unblock_bitacora_user(auth_username){
            this.alertService.option_alert("Desbloquear Usuario","¿Está seguro que desea desbloquear el usuario seleccionado?","Sí, Desbloquear").then((result) => {
                if(result.value){
                    this.loading = true;
                    var response;
                    this.endpoint.unblock_bitacora_user({auth_username:auth_username}).subscribe(
                        data => response = data,
                        err => {
                            this.loading = false;
                            if(err.status && err.error){
                                this.alertService.alert_message(err.status ,err.error);
                            }else{
                                this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                            }
                        },
                        ()=> {
                            try{
                                this.alertService.alert_success(response.title, response.message);
                                this.loading = false;
                            }catch(error){
                                this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                                this.loading = false;
                            }
                        }
                    );
                }
            }).catch(()=>{return false;}); 
        }

        open_insert_user(){
            this.insert_user_modal.show();
            this.insert_user_data = {
                area_id:"",
                auth_fullname:"",
                auth_username:"",
                auth_password:""
            }
            this.submitted = false;
            this.user_loading = false;
        }

        insert_bitacora_user(){
            if(this.insert_user_form.valid){
                this.submitted = false;
                this.alertService.option_alert("Crear Usuario","¿Está seguro que desea crear el usuario?","Sí, Crear").then((result) => {
                    if(result.value){
                        this.user_loading = true;
                        var response;
                        this.endpoint.insert_bitacora_user(this.insert_user_data).subscribe(
                            data => response = data,
                            err => {
                                this.user_loading = false;
                                if(err.status && err.error){
                                    this.alertService.alert_message(err.status ,err.error);
                                }else{
                                    this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                                }
                            },
                            ()=> {
                                try{
                                    this.insert_user_modal.hide();
                                    this.go_to_active();
                                    this.alertService.alert_success(response.title, response.message);
                                    this.user_loading = false;
                                }catch(error){
                                    this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                                    this.user_loading = false;
                                }
                            }
                        );
                    }
                }).catch(()=>{return false;}); 
            }else{
                this.submitted = true;
            }
        }

        go_to_active(){
            this.view = 1;
            this.get_bitacora_users();
        }

        go_to_inactive(){
            this.view = 2;
            this.get_bitacora_users_disabled();
        }

    //BITACORAS ##############################################################
    //########################################################################

    ngOnDestroy() {
        setTimeout(() => this.layoutService.off('resize.app-home'));
    }

}
