import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { AppService } from '../../../app.service';
import { AppEndpoints } from '../../../app.endpoints';
import { LayoutService } from '../../../layout/layout.service';
import { ModalDirective } from 'ngx-bootstrap';
import { default as swal } from 'sweetalert2';
import { AlertService } from '../../../components/alert_service/alert.service';
import { AppFunctions } from '../../../app.functions';
import { ServiceDatatableComponent } from '../../../components/service_datatable/service-datatable.component';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
	selector: 'cost_explorer',
	templateUrl: './cost_explorer.component.html',
	styleUrls: [
		'../../../../vendor/libs/spinkit/spinkit.scss'
	]
})
export class CostExplorerComponent implements OnInit {
	@ViewChild('search_modal') search_modal: ModalDirective;
	public locale = 'es-HN';
	public datatable: any;
	public areas:any[];
	public loading:boolean;
	public search_data = {
		area:"",
		anio:"",
		date:null
	}
	public data:any[];
	public year:string;
	public area_string:string;
	public total:number;
	public total_string:string;
	public enable_cost_explorer:boolean;
	public labels = [];
	public datasets:any[];
	public options = {
	    scales: {
	      	xAxes: [
	        	{
	          		gridLines: {
	            		display: true
	          		},
	          		ticks: {
		            	fontColor: '#aaa',
		            	autoSkipPadding: 0,
	          		}
	        	}
	      	],
	      	yAxes: [
	        	{
	          		gridLines: {
	            		display: true
	          		},
	          		ticks: {
			            fontColor: '#aaa',
			            maxTicksLimit: 4,
			            beginAtZero: true,
			            callback: function(value, index, values) {
	                        return "$ " + value.toLocaleString('es-hn');
	                    }
	          		},
	        	}
	      	]
	    },
	    responsive: true,
	    maintainAspectRatio: false,
	    tooltips: {
	      	mode: 'index',
	      	callbacks: {
	        	label: function (tooltipItems, data) {
			        return "$ " + data.datasets[0].data[tooltipItems.index].toLocaleString('es-hn');
		        },
	      	}
	    },
	};
	public colors = [
	    {
	      backgroundColor: '#237E41',
	      borderColor: '#1C5F32'
	    }
	];

	@ViewChild('download_csv_modal') download_csv_modal: ModalDirective;
	public download_loading:boolean;
	public download_view:number;
	public download_link:string;
	public download_data = {
		area:"",
		anio:"",
		date:null
	}

	constructor(private appService: AppService, public endpoint: AppEndpoints, private layoutService: LayoutService, private alertService: AlertService, public functions: AppFunctions, private localeService: BsLocaleService){
		this.appService.pageTitle = 'Histórico de Envío Mensual';
		this.data = [];
		this.datasets = [];
		this.areas = [];
		this.localeService.use(this.locale);
		this.loading = false;
		this.year = "";
		this.area_string = "";
		this.total = 0;
		this.total_string = "0";
		this.enable_cost_explorer = true;
	}

	ngOnInit(){
		this.get_cost_explorer();
		this.get_areas();
	}

	ngOnDestroy() {
		setTimeout(() => this.layoutService.off('resize.app-home'));
	}


	//########################################################################
	//DATATABLE ##############################################################

		search(){
			if(this.search_data.date && this.search_data.date instanceof Date){
				this.search_data.anio = this.search_data.date.getFullYear() + "";
			}else{
				this.search_data.anio = "";
			}
			this.search_modal.hide();
			this.get_cost_explorer();
		}

		clean_search(){
			this.search_data = {
				area:"",
				anio:"",
				date:null
			}
			this.search_modal.hide();
			this.get_cost_explorer();
		}

	//DATATABLE ##############################################################
	//########################################################################

	//########################################################################
	//DOWNLOAD ###############################################################

		open_download_csv(){
			this.download_loading = false;
			this.download_view = 1;
			this.download_csv_modal.show();
			this.download_data.area = "";
		}

		download_csv() {
			this.download_loading = true;
			var load = {
				anio:"",
				area:this.download_data.area
			}
			if(this.download_data.date && this.download_data.date instanceof Date){
				load.anio = this.download_data.date.getFullYear() + "";
			}
			var response;
			this.endpoint.get_cost_explorer_csv(load).subscribe(
				data => {
					response = data;
				},
				err => {
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
					this.download_loading = false;
				},
				() => {
					let url = response.filename;
	        		window.open(this.endpoint.get_endpoint() + "/download/" + url);
	        		this.download_link = this.endpoint.get_endpoint() + "/download/" + response.filename;
	        		this.download_loading = false;
	        		this.download_view = 2;
				}
			);
		}

	//DOWNLOAD ###############################################################
	//########################################################################

	//########################################################################
	//CATALOGS ###############################################################

		get_cost_explorer(){
			this.loading = true;
			var response;
			this.endpoint.get_cost_explorer({anio:this.search_data.anio, area:this.search_data.area}).subscribe(
				data => {
					response = data;
				},
				err => {
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
					this.loading = false;
				},
				() => {
					try{
						var dataset = {
							label:["Periodo"],
							data: [],
					        borderWidth: 1,
					        fill: true
						}
						if(response.enable_cost_explorer){
							this.download_data.date = new Date(response.year,1,1);
							this.search_data.date = new Date(response.year,1,1);
							this.data = [];
							this.labels = [];
							this.total = 0;
							this.year = response.year + "";
							this.area_string = response.area + "";
							this.enable_cost_explorer = true;
							for(var i = 0;i<response.invoices.length;i++){
								var values = {
									year:response.invoices[i].year,
									label:this.functions.get_month_string(response.invoices[i].month - 1),
									total_sms:response.invoices[i].total_sms.toLocaleString('es-hn'),
									total_invoice:response.invoices[i].total_invoice.toLocaleString('es-hn', {maximumFractionDigits:2}),
									tarifa:response.invoices[i].tarifa
								}
								this.labels.push(this.functions.get_month_string(response.invoices[i].month - 1));
								dataset.data.push(response.invoices[i].total_invoice);
								this.total = this.total + response.invoices[i].total_invoice;
								this.data.push(values);
							}
							for(var i = dataset.data.length;i<12;i++){
								this.labels.push(this.functions.get_month_string(i));
								dataset.data.push(0);
							}
						}else{
							this.enable_cost_explorer = false;

						}
						this.total_string = this.total.toLocaleString('es-hn', {maximumFractionDigits:2});
						this.datasets = [];
						this.datasets.push(dataset);
						this.loading = false;
					}catch(err){
						this.alertService.alert_aplication_error("Error intero de aplicativo");
						this.loading = false;
					}
				}
			);
		}

		get_areas(){
			this.endpoint.get_areas({}).subscribe(
				data => this.areas = data,
				err => {
					this.areas = [];
				}
			);
		}

	//CATALOGS ###############################################################
	//########################################################################

	//########################################################################
	//MISC ###################################################################

		open_calendar(container) {
		 	container.yearSelectHandler = (event: any): void => {
		   		container._store.dispatch(container._actions.select(event.date));
		 	};     
		 	container.setViewMode('year');
		}

		format_status(status){
			return status == 'SENT' ? 'ENVIADO' : status == 'SENDING' ? 'ENVIANDO' : status == 'QUEUED' ? 'EN COLA' : status == 'PARSE_ERROR' ? 'ERROR' : '';
		}

	//MISC ###################################################################
	//########################################################################

}
