import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'monthly_trend_card',
	templateUrl: './monthly_trend_card.component.html',
	styleUrls: [
		'../../../vendor/libs/spinkit/spinkit.scss'
	]
})

export class MonthlyTrendCard implements OnInit {
	@Input() valid:boolean;
	@Input() set data(data){
		if(data.length == 0){
			this.trend.previous = "0";
			this.trend.current = "0";
			this.trend.percentage = "0%";
			this.trend.percentage_raw = 0;
		}else if(data.length == 1){
			this.trend.previous = "0";
			this.trend.current = data[0].total.toLocaleString('es-HN');
			this.trend.percentage = (data[0].total==0)?"0%":"100%";
			this.trend.percentage_raw = (data[0].total==0)?0:100;
		}else{
			var current = data[data.length -1].total;
			var previous = data[data.length -2].total;
			this.trend.current = current.toLocaleString('es-HN');
			this.trend.previous = previous.toLocaleString('es-HN');
			if(previous == 0 && current == 0){
				this.trend.percentage_raw = 0;
				this.trend.percentage = "0%"
			}else if(previous == 0){
				this.trend.percentage_raw = 100;
				this.trend.percentage = "100%"
			}else{
				this.trend.percentage_raw = (-1)*(1 - current/previous)*100;
				this.trend.percentage = (((1 - current/previous)*100)<0)?(((1 - current/previous)*-100).toLocaleString('es-HN', {maximumFractionDigits: 2})+"%"):(((1 - current/previous)*100).toLocaleString('es-HN', {maximumFractionDigits: 2})+"%");
			}
		}
	}
	@Input() loading:boolean;
	public trend = {
		previous:"0",
		current:"0",
		percentage:"0%",
		percentage_raw:0
	};

	constructor() {
	}

	ngOnInit() {
	}
}