import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { AppService } from '../../../app.service';
import { AppEndpoints } from '../../../app.endpoints';
import { LayoutService } from '../../../layout/layout.service';
import { ModalDirective } from 'ngx-bootstrap';
import { default as swal } from 'sweetalert2';
import { AlertService } from '../../../components/alert_service/alert.service';
import { ExcelService } from '../../../components/excel_service/excel.service';
import { conformToMask } from 'angular2-text-mask/dist/angular2TextMask';
import { ServiceDatatableNoCountComponent } from '../../../components/service_datatable_nocount/service-datatable-nocount.component';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { AppFunctions } from '../../../app.functions';

@Component({
	selector: 'sms_sent_number',
	templateUrl: './sms_sent_number.component.html',
	styleUrls: [
		'../../../../vendor/libs/spinkit/spinkit.scss'
	]
})
export class SmsSentNumberComponent implements OnInit {
	@ViewChild('form') form: FormControlDirective;
	@ViewChild('datatable_ref') datatable_ref: ServiceDatatableNoCountComponent;
	@ViewChild('search_modal') search_modal: ModalDirective;
	@ViewChild('view_sms_modal') view_sms_modal: ModalDirective;
	@ViewChild("view_sms_ref") view_sms_ref;
	public locale = 'es-HN';
	public loading:boolean;
	public number:string;
	public view:number;
	public submitted:boolean;
	public submitted_none:boolean;
	public searched_number:string;
	public area_string:string;
	public number_mask=[/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/];
	public months:any[];
	public areas = [];
	public datatable: any;
	
	public load = {
		current_offset: null,
		sort_ascendent: null,
		sort_order: null,
		view_length: null,
		area: null,
		numero:""
	};
	public filters = {
		current_offset: 1,
		view_length: 10,
		sort_order: "",
		sort_ascendent: false,
		search_word:""
	};
	public search_data = {
		area: ""
	}

	@ViewChild('download_csv_modal') download_csv_modal: ModalDirective;
	public download_loading:boolean;
	public download_view:number;
	public download_link:string;
	public download_data = {
		area:""
	}

	constructor(private appService: AppService, public endpoint: AppEndpoints, private layoutService: LayoutService, private alertService: AlertService, private excelService: ExcelService, private localeService: BsLocaleService, public functions: AppFunctions){
		this.appService.pageTitle = 'Reporte por Número';
		this.view = 1;
		this.loading = false;
		this.submitted = false;
		this.submitted_none = false;
		this.number = "";
		this.months = [];
		this.searched_number = "";
		this.area_string = "";
		this.localeService.use(this.locale);
		this.datatable = {
			show_search: false,
			search_placeholder: "- Buscar -",
			object_description: "mensajes enviados",
			empty_text: "No se encontraron mensajes",
			header_class: "custom-th-dark",
			columns: [
				{
					column: "empresa",
					wrap_column: false,
					header: "Empresa",
					wrap_header: true,
					type: "text",
					class: "custom-small-table-cell"
				},
				{
					column: "res_nombre",
					wrap_column: false,
					header: "Área",
					wrap_header: true,
					type: "text",
					class: "custom-small-table-cell"
				},
				{
					column: "contacto",
					wrap_column: false,
					header: "Contacto",
					wrap_header: true,
					type: "text",
					class: "custom-small-table-cell"
				},
				{
					column: "mt_enviado",
					wrap_column: false,
					header: "Fecha de Envío",
					wrap_header: true,
					type: "text",
					class: "custom-small-table-cell"
				},
				{
					column: "mt_text",
					wrap_column: false,
					header: "Texto",
					wrap_header: true,
					type: "text",
					class: "custom-small-table-cell w-50"
				},
				{
					column: "largo",
					wrap_column: false,
					header: "Largo",
					wrap_header: true,
					type: "text",
					class: "custom-small-table-cell"
				}
			],
			events: [
				{
                    name:"Reenviar SMS",
                    style:"color:#2F5BB4",
                    hover_style:"cursor:pointer; color:#2F5BB4; background-color:#D6E4FF !important;",
                    icon:"share"
                }
			],
			navigation_offsets: [5, 10, 15, 20, 25, 50],
			show_search_field: true,
			table_icon: "caret-right"
		}
	}

	ngOnInit(){
		this.get_areas();
	}

	ngOnDestroy() {
		setTimeout(() => this.layoutService.off('resize.app-home'));
	}

	//########################################################################
	//DOWNLOAD ###############################################################

		open_download_csv(){
			this.download_loading = false;
			this.download_view = 1;
			this.download_csv_modal.show();
			this.download_data.area = "";
		}

		download_csv() {
			this.download_loading = true;
			var load = {
				area:this.download_data.area,
				numero:this.load.numero
			}
			var response;
			this.endpoint.get_sms_count_by_number_csv(load).subscribe(
				data => {
					response = data;
				},
				err => {
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
					this.download_loading = false;
				},
				() => {
					let url = response.filename;
	        		window.open(this.endpoint.get_endpoint() + "/download/" + url);
	        		this.download_link = this.endpoint.get_endpoint() + "/download/" + response.filename;
	        		this.download_loading = false;
	        		this.download_view = 2;
				}
			);
		}

	//DOWNLOAD ###############################################################
	//########################################################################

	//########################################################################
	//SEARCH NUMBER ##########################################################

		consultar_reporte_numero(){
			if(this.form.valid){
				this.submitted = false;
				this.loading = true;
				this.datatable_ref.set_show_length(20);

			}else{
				this.submitted = true;
				this.loading = false;
			}
		}

		datatable_events(event){
            if(event.event == "Reenviar SMS"){
            	this.alertService.option_alert("Reenviar SMS","¿Está seguro que desea reenviar el SMS seleccionado?","Sí, Enviar").then((result) => {
            		if(result.value){
            			this.resend_sms(event.data.id);
            		}
                }).catch(()=>{return false;}); 
            }
        }

		resend_sms(id){
			this.datatable_ref.set_loading(true);
			var response;
			var load = {
				id:id
			}
			this.endpoint.resend_sms(load).subscribe(
				data => response = data,
				err => {
					this.datatable_ref.set_loading(false);
					if(err.status && err.error){
	                    this.alertService.alert_message(err.status ,err.error);
	                }else{
	                    this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
	                }
				},
				()=> {
					this.datatable_ref.set_loading(false);
					this.alertService.alert_success(response.title, response.message);
	            }
			);
		}

	//SEARCH NUMBER ##########################################################
	//########################################################################

	//########################################################################
	//DATATABLE ##############################################################

		get_results_offset_change(data){
			this.filters = {
				current_offset: data.current_offset,
				view_length: data.view_length,
				sort_order: data.sort_order,
				sort_ascendent: data.sort_ascendent,
				search_word: data.search_word
			}
			var response;
			this.load = {
				current_offset: this.filters.current_offset,
				sort_ascendent: this.filters.sort_ascendent,
				sort_order: this.filters.sort_order,
				view_length: this.filters.view_length,
				area: this.search_data.area,
				numero:this.number.replace(/-/g, '')
			}
			this.endpoint.get_sms_count_by_number(this.load).subscribe(
				data => response = data,
				err => {
					this.datatable_ref.set_loading(false);
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
				},
				() => {
					try {
						for (var i = 0; i < response.list.length; i++) {
							if (response.list[i].mt_enviado) {
								response.list[i].mt_enviado = new Date(response.list[i].mt_enviado).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
							}
						}
						this.datatable_ref.set_results_offset_change(response.list);
					} catch (error) {
						this.datatable_ref.set_loading(false);
						this.alertService.alert_aplication_error("Error Interno del Aplicativo");
					}

				}
			);
		}

		get_results_filter_change(data){
			this.filters = {
				current_offset: data.current_offset,
				view_length: data.view_length,
				sort_order: data.sort_order,
				sort_ascendent: data.sort_ascendent,
				search_word: data.search_word
			}
			var response;
			this.load = {
				current_offset: this.filters.current_offset,
				sort_ascendent: this.filters.sort_ascendent,
				sort_order: this.filters.sort_order,
				view_length: this.filters.view_length,
				area: this.search_data.area,
				numero:this.number.replace(/-/g, '')

			}
			this.endpoint.get_sms_count_by_number(this.load).subscribe(
				data => response = data,
				err => {
					this.datatable_ref.set_loading(false);
					this.loading = false;
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
				},
				() => {
					try {
						if(this.view == 1 && response.list.length == 0){
							this.datatable_ref.set_loading(false);
							this.submitted_none = true;
							this.searched_number = this.number;
							this.loading = false;
						}else{
							this.loading = false;
							this.submitted = false;
							this.submitted_none = false;
							this.searched_number = "";
							this.area_string = response.area + "";
							this.view = 2;
							for (var i = 0; i < response.list.length; i++) {
								if (response.list[i].mt_enviado) {
									response.list[i].mt_enviado = new Date(response.list[i].mt_enviado).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
								}
							}
							this.months = [];
							var current_month = {
								mes:response.months.current_month,
								total_envios:0,
								total_largo_1:0,
								total_largo_2:0,
								total_otros_largos:0,
								mes_string:this.functions.get_month_string(response.months.current_month - 1),
								total_envios_string:"0",
								total_largo_1_string:"0",
								total_largo_2_string:"0",
								total_otros_largos_string:"0"
							}
							var previous_month = {
								mes:response.months.previous_month,
								total_envios:0,
								total_largo_1:0,
								total_largo_2:0,
								total_otros_largos:0,
								mes_string:this.functions.get_month_string(response.months.previous_month - 1),
								total_envios_string:"0",
								total_largo_1_string:"0",
								total_largo_2_string:"0",
								total_otros_largos_string:"0"
							}
							for(var i = 0;i<response.totals.length;i++){
								if(response.totals[i].mes == previous_month.mes){
									previous_month = {
										mes:response.totals[i].mes,
										total_envios:response.totals[i].total_envios,
										total_largo_1:response.totals[i].total_largo_1,
										total_largo_2:response.totals[i].total_largo_2,
										total_otros_largos:response.totals[i].total_otros_largos,
										mes_string:this.functions.get_month_string(response.totals[i].mes - 1),
										total_envios_string:response.totals[i].total_envios.toLocaleString('es-hn'),
										total_largo_1_string:response.totals[i].total_largo_1.toLocaleString('es-hn'),
										total_largo_2_string:response.totals[i].total_largo_2.toLocaleString('es-hn'),
										total_otros_largos_string:response.totals[i].total_otros_largos.toLocaleString('es-hn')
									}
								}else if(response.totals[i].mes == current_month.mes){
									current_month = {
										mes:response.totals[i].mes,
										total_envios:response.totals[i].total_envios,
										total_largo_1:response.totals[i].total_largo_1,
										total_largo_2:response.totals[i].total_largo_2,
										total_otros_largos:response.totals[i].total_otros_largos,
										mes_string:this.functions.get_month_string(response.totals[i].mes - 1),
										total_envios_string:response.totals[i].total_envios.toLocaleString('es-hn'),
										total_largo_1_string:response.totals[i].total_largo_1.toLocaleString('es-hn'),
										total_largo_2_string:response.totals[i].total_largo_2.toLocaleString('es-hn'),
										total_otros_largos_string:response.totals[i].total_otros_largos.toLocaleString('es-hn')
									}
								}
							}
							this.months.push(previous_month);
							this.months.push(current_month);
							this.datatable_ref.set_results_filter_change(response.list);
						}
						
					} catch (error) {
						this.loading = false;
						this.datatable_ref.set_loading(false);
						this.alertService.alert_aplication_error("Error Interno del Aplicativo");
					}
				}
			);
		}

		get_results_update_list(data){
			this.filters = {
				current_offset: data.current_offset,
				view_length: data.view_length,
				sort_order: data.sort_order,
				sort_ascendent: data.sort_ascendent,
				search_word: this.filters.search_word
			}
			var response;
			this.load = {
				current_offset: this.filters.current_offset,
				sort_ascendent: this.filters.sort_ascendent,
				sort_order: this.filters.sort_order,
				view_length: this.filters.view_length,
				area: this.search_data.area,
				numero:this.number.replace(/-/g, '')
			}
			this.endpoint.get_sms_count_by_number(this.load).subscribe(
				data => response = data,
				err => {
					this.datatable_ref.set_loading(false);
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
				},
				() => {
					try {
						for (var i = 0; i < response.list.length; i++) {
							if (response.list[i].mt_enviado) {
								response.list[i].mt_enviado = new Date(response.list[i].mt_enviado).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
							}
						}
						this.datatable_ref.set_results_update_list(response.list);
					} catch (error) {
						this.datatable_ref.set_loading(false);
						this.alertService.alert_aplication_error("Error Interno del Aplicativo");
					}
				}
			);
		}

		get_results_refresh(){
			this.datatable_ref.set_loading(true);
			var response;
			this.load = {
				current_offset: this.filters.current_offset,
				sort_ascendent: this.filters.sort_ascendent,
				sort_order: this.filters.sort_order,
				view_length: this.filters.view_length,
				area: this.search_data.area,
				numero:this.number.replace(/-/g, '')
			}
			this.endpoint.get_sms_count_by_number(this.load).subscribe(
				data => response = data,
				err => {
					this.datatable_ref.set_loading(false);
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
				},
				() => {
					try {
						for (var i = 0; i < response.list.length; i++) {
							if (response.list[i].mt_enviado) {
								response.list[i].mt_enviado = new Date(response.list[i].mt_enviado).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
							}
						}
						this.datatable_ref.set_results_update_list(response.list);
					} catch (error) {
						this.datatable_ref.set_loading(false);
						this.alertService.alert_aplication_error("Error Interno del Aplicativo");
					}
				}
			);	
		}

		back_search(){
			this.load = {
				current_offset: null,
				sort_ascendent: null,
				sort_order: null,
				view_length: null,
				area: null,
				numero:""
			};
			this.filters = {
				current_offset: 1,
				view_length: 10,
				sort_order: "",
				sort_ascendent: false,
				search_word:""
			};
			this.search_data = {
				area: ""
			}
			this.loading = false;
			this.submitted = false;
			this.submitted_none = false;
			this.number = "";
			this.months = [];
			this.searched_number = "";
			this.area_string = "";
			this.view = 1;
		}

		search(){
			this.search_modal.hide();
			this.datatable_ref.emit_get_results_filter_change();
		}

		clean_search(){
			this.search_data = {
				area: ''
			}
			this.search_modal.hide();
			this.datatable_ref.clean_search();
			this.datatable_ref.emit_get_results_filter_change();
		}

	//DATATABLE ##############################################################
	//########################################################################

	//########################################################################
	//CATALOGS ###############################################################

		get_areas(){
			this.endpoint.get_areas({}).subscribe(
				data => this.areas = data,
				err => {
					this.areas = [];
				}
			);
		}

	//CATALOGS ###############################################################
	//########################################################################

	//########################################################################
	//MISC ###################################################################

		format_status(status){
			return status == 'SENT' ? 'ENVIADO' : status == 'SENDING' ? 'ENVIANDO' : status == 'QUEUED' ? 'EN COLA' : status == 'PARSE_ERROR' ? 'ERROR' : '';
		}

	//MISC ###################################################################
	//########################################################################

}
