import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, OnInit  } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { AppService } from '../../../app.service';
import { AppEndpoints } from '../../../app.endpoints';
import { LayoutService } from '../../../layout/layout.service';
import { ModalDirective } from 'ngx-bootstrap';
import { default as swal } from 'sweetalert2';
import { FormRendererComponent } from '../../../components/form_renderer/form_renderer.component';
import { AlertService } from '../../../components/alert_service/alert.service';
import { ExcelService } from '../../../components/excel_service/excel.service';

@Component({
    selector: 'labels',
    templateUrl: './labels.component.html',
    styleUrls:[
        '../../../../vendor/libs/spinkit/spinkit.scss'
    ]
})
export class LabelsComponent implements OnInit{
    @ViewChild('label_modal') label_modal: ModalDirective;
    @ViewChild('label_form') label_form: FormRendererComponent;
    public label_view:number;
    public label_modal_view:number;
    public label_list:any[];
    public label_loading:boolean;
    public label_main_view_loading:boolean;
    public label_inputs=[
        {
            class:"row",
            columns:[
                {
                    class:"col-md-6",
                    inputs:[
                        {
                             type:"select",
                            extra:"",
                            name:"tipo_etiqueta_id",
                            label:"Tipo de Campo",
                            icon:"",
                            class:"form-control",
                            placeholder:"- Seleccione -",
                            minlength:null,
                            maxlength:null,
                            pattern:null,
                            error_required:"Requerido",
                            error_pattern:"",
                            error_minlength:"",
                            list_data:{
                                value:"id",
                                text:"descripcion"
                            },
                            list:()=>{
                                return this.tipo_etiquetas_list;
                            },
                            textmask:()=>{
                                return false;
                            },
                            required:()=>{
                                return true;
                            },
                            disabled:()=>{
                                if(this.label_form.get_values().var_campo == "cli_identidad" ||
                                   this.label_form.get_values().var_campo == "cli_nombre" ||
                                   this.label_form.get_values().var_campo == "cli_sexo" ||
                                   this.label_form.get_values().var_campo == "cli_fecha_nac" ||
                                   this.label_form.get_values().var_campo == "cli_celular" ||
                                   this.label_form.get_values().var_campo == "cli_telefono" ||
                                   this.label_form.get_values().var_campo == "cli_direccion" ||
                                   this.label_form.get_values().var_campo == "cli_email" || 
                                   this.label_form.get_values().var_campo == "cli_tarjeta" ||
                                   this.label_form.get_values().var_campo == "cli_mensaje" ||
                                   this.label_form.get_values().var_campo == "cli_cif" ||
                                   this.label_form.get_values().var_campo == "cli_cm"){
                                    return true;
                                }else{
                                    return false;
                                }
                            },
                            change:(event)=>{
                            },
                            input:()=>{
                            }
                        }
                    ]
                },
                {
                    class:"col-md-6",
                    inputs:[
                        {
                            type:"text",
                            extra:"",
                            name:"var_alias",
                            label:"Etiqueta de Campo",
                            icon:"",
                            class:"form-control",
                            placeholder:"",
                            minlength:null,
                            maxlength:"70",
                            pattern:"[A-Za-z0-9]+",
                            error_required:"Requerido",
                            error_pattern:"Formato inválido",
                            error_minlength:"",
                            list_data:{
                                value:"",
                                text:""
                            },
                            list:()=>{
                                return []
                            },
                            textmask:()=>{
                                return false;
                            },
                            required:()=>{
                                return true;
                            },
                            disabled:()=>{
                                return false;
                            },
                            change:(event)=>{
                            },
                            input:()=>{
                            }
                        }
                    ]
                }
            ]
        }
    ];
    public label_data = {
        id:"",
        var_campo:""
    }

    public tipo_etiquetas_list:any[];

    constructor(private appService: AppService, public endpoint : AppEndpoints,  private layoutService: LayoutService, private alertService: AlertService, private excelService: ExcelService) {
        this.appService.pageTitle = 'Etiquetas de Campo';
        this.label_view = 1;
        this.label_list = [];
        this.label_loading = false;
        this.label_main_view_loading = false;
        this.tipo_etiquetas_list = [];
    }

    ngOnInit(){
        this.get_label_types();
        this.get_labels();
    }

    //########################################################################
    //LABELS #################################################################

        get_labels(){
            this.label_main_view_loading = true;
            var response;
            this.endpoint.get_labels().subscribe(
                data => response = data,
                err => {
                    this.label_list = [];
                    this.label_main_view_loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.label_list = [];
                        this.label_list = response;
                        this.label_main_view_loading = false;
                    }catch(error){
                        this.label_main_view_loading = false;
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                    
                }
            );
        }

        open_update_label(data){
            this.label_form.clean_form();
            this.label_form.set_values(data);
            this.label_loading = false;
            this.label_modal_view = 2;
            this.label_data.var_campo = data.var_campo;
            this.label_modal.show();
        }

        update_label(){
            if(this.label_form.valid()){
                this.alertService.option_alert("Editar Campo","¿Está seguro que desea editar el campo seleccionado?","Sí, Editar").then((result) => {
                    if(result.value){
                        this.label_loading = true;
                        var form_values = this.label_form.get_values();
                        var load = {
                            id:form_values.id,
                            var_alias:form_values.var_alias,
                            var_campo:form_values.var_campo,
                            tipo_etiqueta_id:form_values.tipo_etiqueta_id
                        };
                        var response;
                        this.endpoint.update_label(load).subscribe(
                            data => response = data,
                            err => {
                                this.label_loading = false;
                                if(err.status && err.error){
                                    this.alertService.alert_message(err.status ,err.error);
                                }else{
                                    this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                                }
                            },
                            ()=> {
                                try{
                                    this.label_modal.hide();
                                    this.get_labels();
                                    this.alertService.alert_success(response.title, response.message);
                                    this.label_loading = false;
                                }catch(error){
                                    this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                                    this.label_loading = false;
                                }
                            }
                        );
                    }
                }).catch(()=>{return false;}); 
            }
        }

        open_delete_label(data){
            this.alertService.option_alert("Restaurar Valor Predeterminado","¿Está seguro que desea restaurar a su valor predeterminado la etiqueta del campo seleccionado?<br><br><b>Campo:<br></b><b class='text-success'> "+data.var_campo+"</b>","Sí, Restaurar").then((result) => {
                if(result.value){
                    this.delete_label(data.id);
                }
            }).catch(()=>{return false;});
        }

        delete_label(id){
            this.label_main_view_loading = true;
            var load = {
                id:id
            };
            var response;
            this.endpoint.delete_label(load).subscribe(
                data => response = data,
                err => {
                    this.label_main_view_loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.get_labels();
                        this.alertService.alert_success(response.title, response.message);
                        this.label_main_view_loading = false;
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.label_main_view_loading = false;
                    }
                }
            );
        }

    //LABELS #################################################################
    //########################################################################

    //########################################################################
    //CATALOGS ###############################################################

        get_label_types(){
            this.tipo_etiquetas_list = [];
            var response;
            this.endpoint.get_label_types().subscribe(
                data => response = data,
                err => {
                    this.tipo_etiquetas_list = [];
                },
                ()=> {
                    this.tipo_etiquetas_list = response;
                }
            );
        }

    //CATALOGS ###############################################################
    //########################################################################

    ngOnDestroy() {
        setTimeout(() => this.layoutService.off('resize.app-home'));
    }

}
