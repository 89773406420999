import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, OnInit  } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { AppService } from '../../../app.service';
import { AppEndpoints } from '../../../app.endpoints';
import { LayoutService } from '../../../layout/layout.service';
import { ModalDirective } from 'ngx-bootstrap';
import { default as swal } from 'sweetalert2';
import { FormRendererComponent } from '../../../components/form_renderer/form_renderer.component';
import { AlertService } from '../../../components/alert_service/alert.service';
import { ExcelService } from '../../../components/excel_service/excel.service';
import { conformToMask } from 'angular2-text-mask/dist/angular2TextMask';
import { ServiceDatatableComponent } from '../../../components/service_datatable/service-datatable.component';
import { ServiceDatatableNoCountComponent } from '../../../components/service_datatable_nocount/service-datatable-nocount.component';
import { AppFunctions } from '../../../app.functions';

@Component({
    selector: 'clients',
    templateUrl: './clients.component.html',
    styleUrls:[
        '../../../../vendor/libs/spinkit/spinkit.scss'
    ]
})
export class ClientsComponent implements OnInit{
    @ViewChild('client_modal') client_modal: ModalDirective;
    @ViewChild('client_form') client_form: FormRendererComponent;
    @ViewChild('client_form_view') client_form_view: FormRendererComponent;
    @ViewChild('client_datatable_ref') client_datatable_ref: ServiceDatatableComponent;
    
    public client_view:number;
    public client_modal_view:number;
    public client_list:any[];
    public client_loading:boolean;
    public client_datatable:any;
    public client_datatable_loading:boolean;
    public client_inputs=[];
    public client_inputs_view=[];
    public client_data = {
        id:"",
        cli_nombre:"",
        cli_identidad:"",
        cli_celular:""
    };
    public client_filters = {
        current_offset:1,
        view_length:10,
        sort_order:"",
        sort_ascendent:false
    };

    @ViewChild('client_search_modal') client_search_modal: ModalDirective;
    @ViewChild('client_search_form') client_search_form: FormRendererComponent;
    public client_search_inputs=[]; 
    public client_search_data = {
        cli_identidad:"",
        cli_nombre:"",
        cli_sexo:"",
        cli_fecha_nac:"",
        cli_celular:"",
        cli_telefono:"",
        cli_email:"",
        cli_tarjeta:"",
        cli_direccion:""
    }

    @ViewChild('memberships_modal') memberships_modal: ModalDirective;
    @ViewChild('memberships_form') memberships_form: FormRendererComponent;
    public available_memberships_list:any[];
    public assigned_memberships_list:any[];
    public memberships_loading:boolean;
    public memberships_inputs=[]; 
    public label_list:any[];
    public cellphone_mask=[/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/];
    //public phone_mask=[/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/];
    //public id_mask=[/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/];

    @ViewChild('number_datatable_ref') number_datatable_ref: ServiceDatatableNoCountComponent;
    @ViewChild('sms_modal') sms_modal: ModalDirective;
    public number_datatable: any;
    public number:string;
    public area_string:string;
    public months:any[];
    public number_filters = {
        current_offset: 1,
        view_length: 10,
        sort_order: "",
        sort_ascendent: false,
        search_word:""
    };

    public number_load = {
        current_offset: null,
        sort_ascendent: null,
        sort_order: null,
        view_length: null,
        area: null,
        numero:""
    };
    constructor(private appService: AppService, public endpoint : AppEndpoints,  private layoutService: LayoutService, private alertService: AlertService, private excelService: ExcelService, public functions: AppFunctions) {
        this.appService.pageTitle = 'Clientes';
        this.client_view = 1;
        this.client_modal_view = 1;
        this.client_list = [];
        this.client_loading = false;
        this.client_datatable_loading = false;
        this.label_list = [];
        this.available_memberships_list = [];
        this.assigned_memberships_list = [];
        this.memberships_loading = false;
        this.number = "";
        this.months = [];
        this.area_string = "";
        this.number_datatable = {
            show_search: false,
            search_placeholder: "- Buscar -",
            object_description: "mensajes enviados",
            empty_text: "No se encontraron mensajes",
            header_class: "custom-th-dark",
            columns: [
                {
                    column: "empresa",
                    wrap_column: false,
                    header: "Empresa",
                    wrap_header: true,
                    type: "text",
                    class: "custom-small-table-cell"
                },
                {
                    column: "res_nombre",
                    wrap_column: false,
                    header: "Área",
                    wrap_header: true,
                    type: "text",
                    class: "custom-small-table-cell"
                },
                {
                    column: "contacto",
                    wrap_column: false,
                    header: "Contacto",
                    wrap_header: true,
                    type: "text",
                    class: "custom-small-table-cell"
                },
                {
                    column: "mt_enviado",
                    wrap_column: false,
                    header: "Fecha de Envío",
                    wrap_header: true,
                    type: "text",
                    class: "custom-small-table-cell"
                },
                {
                    column: "mt_text",
                    wrap_column: false,
                    header: "Texto",
                    wrap_header: true,
                    type: "text",
                    class: "custom-small-table-cell w-50"
                },
                {
                    column: "largo",
                    wrap_column: false,
                    header: "Largo",
                    wrap_header: true,
                    type: "text",
                    class: "custom-small-table-cell"
                }
            ],
            events: [
            ],
            navigation_offsets: [5, 10, 15, 20, 25, 50],
            show_search_field: true,
            table_icon: "caret-right"
        }
    }

    ngOnInit(){
        this.get_labels();
        //this.get_clients();
    }

    ngAfterViewInit(){
        this.client_datatable_ref.set_show_length(10);
    }

    //########################################################################
    //CLIENTS ################################################################

        client_datatable_events(event){
            if(event.event == "Datos del Cliente"){
                this.open_view_client(event.data);
            }else if(event.event == "Editar Cliente"){
                this.open_update_client(event.data);
            }else if(event.event == "Eliminar Cliente"){
                this.open_delete_client(event.data);
            }else if(event.event == "Membresías"){
                this.open_memberships(event.data);
            }else if(event.event =="Mensajes SMS"){
                this.number = event.data.cli_celular;
                this.months = [];
                this.area_string = "";
                this.number_filters = {
                    current_offset: 1,
                    view_length: 10,
                    sort_order: "",
                    sort_ascendent: false,
                    search_word:""
                };

                this.number_load = {
                    current_offset: null,
                    sort_ascendent: null,
                    sort_order: null,
                    view_length: null,
                    area: null,
                    numero:""
                };
                this.sms_modal.show();
                this.number_datatable_ref.set_show_length(10);
            }
        }

        client_datatable_get_results_offset_change(data){
            this.client_filters = {
                current_offset:data.current_offset,
                view_length:data.view_length,
                sort_order:data.sort_order,
                sort_ascendent:data.sort_ascendent
            }
            var response;
            var load = {
                current_offset:this.client_filters.current_offset,
                sort_ascendent:this.client_filters.sort_ascendent,
                sort_order:this.client_filters.sort_order,
                view_length:this.client_filters.view_length,
                cli_identidad:this.client_search_data.cli_identidad,
                //cli_identidad:this.client_search_data.cli_identidad.replace(/-/g, ''),
                cli_nombre:this.client_search_data.cli_nombre,
                cli_sexo:this.client_search_data.cli_sexo,
                cli_fecha_nac:this.client_search_data.cli_fecha_nac,
                cli_celular:this.client_search_data.cli_celular.replace(/-/g, ''),
               // cli_telefono:this.client_search_data.cli_telefono.replace(/-/g, ''),
                cli_telefono:this.client_search_data.cli_telefono,
                cli_email:this.client_search_data.cli_email,
                cli_tarjeta:this.client_search_data.cli_tarjeta,
                cli_direccion:this.client_search_data.cli_direccion,
                search_word:data.search_word
            }
            this.endpoint.get_clients(load).subscribe(
                data => response = data,
                err => {
                    this.client_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        for(var i = 0;i<response.list.length;i++){
                            if(response.list[i].cli_celular){
                                var masked_number = conformToMask(
                                    response.list[i].cli_celular,
                                    this.cellphone_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_celular = masked_number.conformedValue;
                            }
                            /*if(response.list[i].cli_telefono){
                                var masked_phone = conformToMask(
                                    response.list[i].cli_telefono,
                                    this.phone_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_telefono = masked_phone.conformedValue;
                            }
                            if(response.list[i].cli_identidad){
                                var masked_id = conformToMask(
                                    response.list[i].cli_identidad,
                                    this.id_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_identidad = masked_id.conformedValue;
                            }*/
                        }
                        this.client_datatable_ref.set_results_offset_change(response.list);
                    }catch(error){
                        this.client_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                    
                }
            );
        }

        client_datatable_get_results_filter_change(data){
            this.client_filters = {
                current_offset:data.current_offset,
                view_length:data.view_length,
                sort_order:data.sort_order,
                sort_ascendent:data.sort_ascendent
            }
            var response;
            var load = {
                current_offset:this.client_filters.current_offset,
                sort_ascendent:this.client_filters.sort_ascendent,
                sort_order:this.client_filters.sort_order,
                view_length:this.client_filters.view_length,
                //cli_identidad:this.client_search_data.cli_identidad.replace(/-/g, ''),
                cli_identidad:this.client_search_data.cli_identidad,
                cli_nombre:this.client_search_data.cli_nombre,
                cli_sexo:this.client_search_data.cli_sexo,
                cli_fecha_nac:this.client_search_data.cli_fecha_nac,
                cli_celular:this.client_search_data.cli_celular.replace(/-/g, ''),
                //cli_telefono:this.client_search_data.cli_telefono.replace(/-/g, ''),
                cli_telefono:this.client_search_data.cli_telefono,
                cli_email:this.client_search_data.cli_email,
                cli_tarjeta:this.client_search_data.cli_tarjeta,
                cli_direccion:this.client_search_data.cli_direccion,
                search_word:data.search_word
            }
            this.endpoint.get_clients(load).subscribe(
                data => response = data,
                err => {
                    this.client_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        for(var i = 0;i<response.list.length;i++){
                            if(response.list[i].cli_celular){
                                var masked_number = conformToMask(
                                    response.list[i].cli_celular,
                                    this.cellphone_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_celular = masked_number.conformedValue;
                            }
                            /*if(response.list[i].cli_telefono){
                                var masked_phone = conformToMask(
                                    response.list[i].cli_telefono,
                                    this.phone_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_telefono = masked_phone.conformedValue;
                            }
                            if(response.list[i].cli_identidad){
                                var masked_id = conformToMask(
                                    response.list[i].cli_identidad,
                                    this.id_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_identidad = masked_id.conformedValue;
                            }*/
                        }
                        this.client_datatable_ref.set_results_filter_change(response.list, response.count);
                    }catch(error){
                        this.client_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

        client_datatable_get_results_update_list(data){
            this.client_filters = {
                current_offset:data.current_offset,
                view_length:data.view_length,
                sort_order:data.sort_order,
                sort_ascendent:data.sort_ascendent
            }
            var response;
            var load = {
                current_offset:this.client_filters.current_offset,
                sort_ascendent:this.client_filters.sort_ascendent,
                sort_order:this.client_filters.sort_order,
                view_length:this.client_filters.view_length,
                //cli_identidad:this.client_search_data.cli_identidad.replace(/-/g, ''),
                cli_identidad:this.client_search_data.cli_identidad,
                cli_nombre:this.client_search_data.cli_nombre,
                cli_sexo:this.client_search_data.cli_sexo,
                cli_fecha_nac:this.client_search_data.cli_fecha_nac,
                cli_celular:this.client_search_data.cli_celular.replace(/-/g, ''),
                //cli_telefono:this.client_search_data.cli_telefono.replace(/-/g, ''),
                cli_telefono:this.client_search_data.cli_telefono,
                cli_email:this.client_search_data.cli_email,
                cli_tarjeta:this.client_search_data.cli_tarjeta,
                cli_direccion:this.client_search_data.cli_direccion,
                search_word:data.search_word
            }
            this.endpoint.get_clients(load).subscribe(
                data => response = data,
                err => {
                    this.client_datatable_ref.set_loading(false);
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        for(var i = 0;i<response.list.length;i++){
                            if(response.list[i].cli_celular){
                                var masked_number = conformToMask(
                                    response.list[i].cli_celular,
                                    this.cellphone_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_celular = masked_number.conformedValue;
                            }
                            /*if(response.list[i].cli_telefono){
                                var masked_phone = conformToMask(
                                    response.list[i].cli_telefono,
                                    this.phone_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_telefono = masked_phone.conformedValue;
                            }
                            if(response.list[i].cli_identidad){
                                var masked_id = conformToMask(
                                    response.list[i].cli_identidad,
                                    this.id_mask,
                                    {guide: false}
                                );
                                response.list[i].cli_identidad = masked_id.conformedValue;
                            }*/
                        }
                        this.client_datatable_ref.set_results_update_list(response.list, response.count);
                    }catch(error){
                        this.client_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

        open_insert_client(){
            this.client_loading = false;
            this.client_form.clean_form();
            this.client_modal_view = 1;
            this.client_modal.show();
        }

        insert_client(){
            if(this.client_form.valid()){
                this.client_loading = true;
                var form_values = this.client_form.get_values();
                var load = {
                    //cli_identidad:form_values.cli_identidad.replace(/-/g, ''),
                    cli_identidad:form_values.cli_identidad,
                    cli_nombre:form_values.cli_nombre,
                    cli_sexo:form_values.cli_sexo,
                    cli_fecha_nac:form_values.cli_fecha_nac,
                    cli_celular:form_values.cli_celular.replace(/-/g, ''),
                    //cli_telefono:form_values.cli_telefono.replace(/-/g, ''),
                    cli_telefono:form_values.cli_telefono,
                    cli_email:form_values.cli_email,
                    cli_tarjeta:form_values.cli_tarjeta,
                    cli_direccion:form_values.cli_direccion,
                    cli_campo1:form_values.cli_campo1,
                    cli_campo2:form_values.cli_campo2,
                    cli_campo3:form_values.cli_campo3,
                    cli_campo4:form_values.cli_campo4,
                    cli_campo5:form_values.cli_campo5,
                    cli_campo6:form_values.cli_campo6,
                    cli_campo7:form_values.cli_campo7
                };
                var response;
                this.endpoint.insert_client(load).subscribe(
                    data => response = data,
                    err => {
                        this.client_loading = false;
                        if(err.status && err.error){
                            this.alertService.alert_message(err.status ,err.error);
                        }else{
                            this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                        }
                    },
                    ()=> {
                        try{
                            this.client_modal.hide();
                            this.client_datatable_ref.emit_get_results_update_list();
                            this.alertService.alert_success(response.title, response.message);
                            this.client_loading = false;
                        }catch(error){
                            this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                            this.client_loading = false;
                        }
                    }
                );
            }
        }

        open_update_client(data){
            this.client_form.clean_form();
            this.client_form.set_values_with_date(data);
            this.client_loading = false;
            this.client_modal_view = 2;
            this.client_modal.show();
        }

        open_view_client(data){
            this.client_form_view.clean_form();
            this.client_form_view.set_values(data);
            this.client_loading = false;
            this.client_modal_view = 3;
            this.client_modal.show();
        }

        update_client(){
            if(this.client_form.valid()){
                this.alertService.option_alert("Editar Cliente","¿Está seguro que desea editar el cliente seleccionado?","Sí, Editar").then((result) => {
                    if(result.value){
                        this.client_loading = true;
                        var form_values = this.client_form.get_values();
                        var load = {
                            id:form_values.id,
                            //cli_identidad:form_values.cli_identidad.replace(/-/g, ''),
                            cli_identidad:form_values.cli_identidad,
                            cli_nombre:form_values.cli_nombre,
                            cli_sexo:form_values.cli_sexo,
                            cli_fecha_nac:form_values.cli_fecha_nac,
                            cli_celular:form_values.cli_celular.replace(/-/g, ''),
                            //cli_telefono:form_values.cli_telefono.replace(/-/g, ''),
                            cli_telefono:form_values.cli_telefono,
                            cli_email:form_values.cli_email,
                            cli_tarjeta:form_values.cli_tarjeta,
                            cli_direccion:form_values.cli_direccion,
                            cli_campo1:form_values.cli_campo1,
                            cli_campo2:form_values.cli_campo2,
                            cli_campo3:form_values.cli_campo3,
                            cli_campo4:form_values.cli_campo4,
                            cli_campo5:form_values.cli_campo5,
                            cli_campo6:form_values.cli_campo6,
                            cli_campo7:form_values.cli_campo7
                        };
                        var response;
                        this.endpoint.update_client(load).subscribe(
                            data => response = data,
                            err => {
                                this.client_loading = false;
                                if(err.status && err.error){
                                    this.alertService.alert_message(err.status ,err.error);
                                }else{
                                    this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                                }
                            },
                            ()=> {
                                try{
                                    this.client_modal.hide();
                                    this.client_datatable_ref.emit_get_results_update_list();
                                    this.alertService.alert_success(response.title, response.message);
                                    this.client_loading = false;
                                }catch(error){
                                    this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                                    this.client_loading = false;
                                }
                            }
                        );
                    }
                }).catch(()=>{return false;}); 
            }
        }

        open_delete_client(data){
            this.alertService.option_alert("Eliminar Cliente","¿Está seguro que desea eliminar el cliente seleccionado?<br><br><b>Cliente:<br></b><b class='text-success'> "+data.cli_celular+"</b>","Sí, Eliminar").then((result) => {
                if(result.value){
                    this.delete_client(data.id);
                }
            }).catch(()=>{return false;});
        }

        delete_client(id){
            this.client_datatable_loading = true;
            var load = {
                id:id
            };
            var response;
            this.endpoint.delete_client(load).subscribe(
                data => response = data,
                err => {
                    this.client_datatable_loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.client_datatable_ref.emit_get_results_update_list();
                        this.alertService.alert_success(response.title, response.message);
                        this.client_datatable_loading = false;
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.client_datatable_loading = false;
                    }
                }
            );
        }

        client_open_search(){
            this.client_search_form.set_values(this.client_search_data);
            this.client_search_modal.show();
        }

        search_clients(){
            if(this.client_search_form.valid()){
                if(this.client_search_form.get_values().cli_identidad){
                    this.client_search_data.cli_identidad = this.client_search_form.get_values().cli_identidad;
                }else{
                    this.client_search_data.cli_identidad = "";
                }
                if(this.client_search_form.get_values().cli_nombre){
                    this.client_search_data.cli_nombre = this.client_search_form.get_values().cli_nombre;
                }else{
                    this.client_search_data.cli_nombre = "";
                }
                if(this.client_search_form.get_values().cli_sexo){
                    this.client_search_data.cli_sexo = this.client_search_form.get_values().cli_sexo;
                }else{
                    this.client_search_data.cli_sexo = "";
                }
                if(this.client_search_form.get_values().cli_fecha_nac){
                    this.client_search_data.cli_fecha_nac = this.client_search_form.get_values().cli_fecha_nac;
                }else{
                    this.client_search_data.cli_fecha_nac = "";
                }
                if(this.client_search_form.get_values().cli_celular){
                    this.client_search_data.cli_celular = this.client_search_form.get_values().cli_celular;
                }else{
                    this.client_search_data.cli_celular = "";
                }
                if(this.client_search_form.get_values().cli_telefono){
                    this.client_search_data.cli_telefono = this.client_search_form.get_values().cli_telefono;
                }else{
                    this.client_search_data.cli_telefono = "";
                }
                if(this.client_search_form.get_values().cli_email){
                    this.client_search_data.cli_email = this.client_search_form.get_values().cli_email;
                }else{
                    this.client_search_data.cli_email = "";
                }
                if(this.client_search_form.get_values().cli_tarjeta){
                    this.client_search_data.cli_tarjeta = this.client_search_form.get_values().cli_tarjeta;
                }else{
                    this.client_search_data.cli_tarjeta = "";
                }
                if(this.client_search_form.get_values().cli_direccion){
                    this.client_search_data.cli_direccion = this.client_search_form.get_values().cli_direccion;
                }else{
                    this.client_search_data.cli_direccion = "";
                }
                this.client_search_modal.hide();
                this.client_datatable_ref.emit_get_results_filter_change();
            }
        }

        clean_search_clients(){
            this.client_search_data = {
                cli_identidad:"",
                cli_nombre:"",
                cli_sexo:"",
                cli_fecha_nac:"",
                cli_celular:"",
                cli_telefono:"",
                cli_email:"",
                cli_tarjeta:"",
                cli_direccion:""
            }
            this.client_search_modal.hide();
            this.client_datatable_ref.clean_search();
            this.client_datatable_ref.emit_get_results_filter_change();
        }

    //CLIENTS ################################################################
    //########################################################################

    //########################################################################
    //MEMBERSHIPS ############################################################

        open_memberships(data){
            this.client_data = {
                id:data.id,
                cli_nombre:data.cli_nombre,
                cli_identidad:data.cli_identidad,
                cli_celular:data.cli_celular
            }
            this.memberships_form.set_values(this.client_data);
            this.get_available_memberships(data.id);
            this.memberships_modal.show();
        }

        get_available_memberships(client_id){
            this.memberships_loading = true;
            var response;
            var load = {
                smsreseller_clientes_id:client_id
            };
            this.endpoint.get_available_memberships(load).subscribe(
                data => response = data,
                err => {
                    this.available_memberships_list = [];
                    this.memberships_loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.available_memberships_list = [];
                        for(var i = 0;i<response.length;i++){
                            var exists = false;
                            for(var j = 0;j<this.available_memberships_list.length;j++){
                                if(response[i].grp_id == this.available_memberships_list[j].grp_id){
                                    exists = true;
                                    this.available_memberships_list[j].subgroups.push(response[i]);
                                }
                            }
                            if(!exists){
                                this.available_memberships_list.push({
                                    grp_id:response[i].grp_id,
                                    grp_nombre:response[i].grp_nombre,
                                    subgroups:[response[i]]
                                })
                            }
                        }
                        this.get_assigned_memberships(client_id);
                    }catch(error){
                        this.memberships_loading = false;
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

        get_assigned_memberships(client_id){
            this.memberships_loading = true;
            var response;
            var load = {
                smsreseller_clientes_id:client_id
            };
            this.endpoint.get_assigned_memberships(load).subscribe(
                data => response = data,
                err => {
                    this.assigned_memberships_list = [];
                    this.memberships_loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    this.assigned_memberships_list = [];
                    for(var i = 0;i<response.length;i++){
                        var exists = false;
                        for(var j = 0;j<this.assigned_memberships_list.length;j++){
                            if(response[i].grp_id == this.assigned_memberships_list[j].grp_id){
                                exists = true;
                                this.assigned_memberships_list[j].subgroups.push(response[i]);
                            }
                        }
                        if(!exists){
                            this.assigned_memberships_list.push({
                                grp_id:response[i].grp_id,
                                grp_nombre:response[i].grp_nombre,
                                subgroups:[response[i]]
                            })
                        }
                    }
                    this.memberships_loading = false;
                }
            );
        }

        assign_membership(subgroup){
            this.memberships_loading = true;
            var load = {
                smsreseller_clientes_id:this.client_data.id,
                smsreseller_subgrupos_id:subgroup.id
            };
            var response;
            this.endpoint.assign_membership(load).subscribe(
                data => response = data,
                err => {
                    this.memberships_loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.get_available_memberships(this.client_data.id);
                        //this.alertService.alert_success(response.title, response.message);
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.memberships_loading = false;
                    }
                }
            );
        }

        assign_all_memberships(){
            this.memberships_loading = true;
            var load = {
                subgroups:[]
            };
            for(var i = 0;i<this.available_memberships_list.length;i++){
                for(var j = 0;j<this.available_memberships_list[i].subgroups.length;j++){
                    load.subgroups.push([this.client_data.id,this.available_memberships_list[i].subgroups[j].id]);
                }
            }
            var response;
            this.endpoint.assign_multiple_membership(load).subscribe(
                data => response = data,
                err => {
                    this.memberships_loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.get_available_memberships(this.client_data.id);
                        //this.alertService.alert_success(response.title, response.message);
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.memberships_loading = false;
                    }
                }
            );
        }

        unassign_membership(subgroup){
            this.memberships_loading = true;
            var load = {
                smsreseller_clientes_id:this.client_data.id,
                smsreseller_subgrupos_id:subgroup.id
            };
            var response;
            this.endpoint.unassign_membership(load).subscribe(
                data => response = data,
                err => {
                    this.memberships_loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.get_available_memberships(this.client_data.id);
                        //this.alertService.alert_success(response.title, response.message);
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.memberships_loading = false;
                    }
                }
            );
        }

        unassign_all_memberships(){
            this.memberships_loading = true;
            var load = {
                smsreseller_clientes_id:this.client_data.id,
            };
            var response;
            this.endpoint.unassign_multiple_membership(load).subscribe(
                data => response = data,
                err => {
                    this.memberships_loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    try{
                        this.get_available_memberships(this.client_data.id);
                        //this.alertService.alert_success(response.title, response.message);
                    }catch(error){
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                        this.memberships_loading = false;
                    }
                }
            );
        }

    //MEMBERSHIPS ############################################################
    //########################################################################

    //########################################################################
    //SMS ####################################################################

        get_number_results_offset_change(data){
            this.number_filters = {
                current_offset: data.current_offset,
                view_length: data.view_length,
                sort_order: data.sort_order,
                sort_ascendent: data.sort_ascendent,
                search_word: data.search_word
            }
            var response;
            this.number_load = {
                current_offset: this.number_filters.current_offset,
                sort_ascendent: this.number_filters.sort_ascendent,
                sort_order: this.number_filters.sort_order,
                view_length: this.number_filters.view_length,
                area: "",
                numero:this.number.replace(/-/g, '')
            }
            this.endpoint.get_sms_count_by_number(this.number_load).subscribe(
                data => response = data,
                err => {
                    this.number_datatable_ref.set_loading(false);
                    if (err.status && err.error) {
                        this.alertService.alert_message(err.status, err.error);
                    } else {
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                () => {
                    try {
                        for (var i = 0; i < response.list.length; i++) {
                            if (response.list[i].mt_enviado) {
                                response.list[i].mt_enviado = new Date(response.list[i].mt_enviado).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
                            }
                        }
                        this.number_datatable_ref.set_results_offset_change(response.list);
                    } catch (error) {
                        this.number_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }

                }
            );
        }

        get_number_results_filter_change(data){
            this.number_filters = {
                current_offset: data.current_offset,
                view_length: data.view_length,
                sort_order: data.sort_order,
                sort_ascendent: data.sort_ascendent,
                search_word: data.search_word
            }
            var response;
            this.number_load = {
                current_offset: this.number_filters.current_offset,
                sort_ascendent: this.number_filters.sort_ascendent,
                sort_order: this.number_filters.sort_order,
                view_length: this.number_filters.view_length,
                area: "",
                numero:this.number.replace(/-/g, '')

            }
            this.endpoint.get_sms_count_by_number(this.number_load).subscribe(
                data => response = data,
                err => {
                    this.number_datatable_ref.set_loading(false);
                    if (err.status && err.error) {
                        this.alertService.alert_message(err.status, err.error);
                    } else {
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                () => {
                    try {
                        this.area_string = response.area + "";
                        for (var i = 0; i < response.list.length; i++) {
                            if (response.list[i].mt_enviado) {
                                response.list[i].mt_enviado = new Date(response.list[i].mt_enviado).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
                            }
                        }
                        this.months = [];
                        var current_month = {
                            mes:response.months.current_month,
                            total_envios:0,
                            total_largo_1:0,
                            total_largo_2:0,
                            total_otros_largos:0,
                            mes_string:this.functions.get_month_string(response.months.current_month - 1),
                            total_envios_string:"0",
                            total_largo_1_string:"0",
                            total_largo_2_string:"0",
                            total_otros_largos_string:"0"
                        }
                        var previous_month = {
                            mes:response.months.previous_month,
                            total_envios:0,
                            total_largo_1:0,
                            total_largo_2:0,
                            total_otros_largos:0,
                            mes_string:this.functions.get_month_string(response.months.previous_month - 1),
                            total_envios_string:"0",
                            total_largo_1_string:"0",
                            total_largo_2_string:"0",
                            total_otros_largos_string:"0"
                        }
                        for(var i = 0;i<response.totals.length;i++){
                            if(response.totals[i].mes == previous_month.mes){
                                previous_month = {
                                    mes:response.totals[i].mes,
                                    total_envios:response.totals[i].total_envios,
                                    total_largo_1:response.totals[i].total_largo_1,
                                    total_largo_2:response.totals[i].total_largo_2,
                                    total_otros_largos:response.totals[i].total_otros_largos,
                                    mes_string:this.functions.get_month_string(response.totals[i].mes - 1),
                                    total_envios_string:response.totals[i].total_envios.toLocaleString('es-hn'),
                                    total_largo_1_string:response.totals[i].total_largo_1.toLocaleString('es-hn'),
                                    total_largo_2_string:response.totals[i].total_largo_2.toLocaleString('es-hn'),
                                    total_otros_largos_string:response.totals[i].total_otros_largos.toLocaleString('es-hn')
                                }
                            }else if(response.totals[i].mes == current_month.mes){
                                current_month = {
                                    mes:response.totals[i].mes,
                                    total_envios:response.totals[i].total_envios,
                                    total_largo_1:response.totals[i].total_largo_1,
                                    total_largo_2:response.totals[i].total_largo_2,
                                    total_otros_largos:response.totals[i].total_otros_largos,
                                    mes_string:this.functions.get_month_string(response.totals[i].mes - 1),
                                    total_envios_string:response.totals[i].total_envios.toLocaleString('es-hn'),
                                    total_largo_1_string:response.totals[i].total_largo_1.toLocaleString('es-hn'),
                                    total_largo_2_string:response.totals[i].total_largo_2.toLocaleString('es-hn'),
                                    total_otros_largos_string:response.totals[i].total_otros_largos.toLocaleString('es-hn')
                                }
                            }
                        }
                        this.months.push(previous_month);
                        this.months.push(current_month);
                        this.number_datatable_ref.set_results_filter_change(response.list);
                    } catch (error) {
                        this.number_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

        get_number_results_update_list(data){
            this.number_filters = {
                current_offset: data.current_offset,
                view_length: data.view_length,
                sort_order: data.sort_order,
                sort_ascendent: data.sort_ascendent,
                search_word: this.number_filters.search_word
            }
            var response;
            this.number_load = {
                current_offset: this.number_filters.current_offset,
                sort_ascendent: this.number_filters.sort_ascendent,
                sort_order: this.number_filters.sort_order,
                view_length: this.number_filters.view_length,
                area: "",
                numero:this.number.replace(/-/g, '')
            }
            this.endpoint.get_sms_count_by_number(this.number_load).subscribe(
                data => response = data,
                err => {
                    this.number_datatable_ref.set_loading(false);
                    if (err.status && err.error) {
                        this.alertService.alert_message(err.status, err.error);
                    } else {
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                () => {
                    try {
                        for (var i = 0; i < response.list.length; i++) {
                            if (response.list[i].mt_enviado) {
                                response.list[i].mt_enviado = new Date(response.list[i].mt_enviado).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
                            }
                        }
                        this.number_datatable_ref.set_results_update_list(response.list);
                    } catch (error) {
                        this.number_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );
        }

        get_number_results_refresh(){
            this.number_datatable_ref.set_loading(true);
            var response;
            this.number_load = {
                current_offset: this.number_filters.current_offset,
                sort_ascendent: this.number_filters.sort_ascendent,
                sort_order: this.number_filters.sort_order,
                view_length: this.number_filters.view_length,
                area: "",
                numero:this.number.replace(/-/g, '')
            }
            this.endpoint.get_sms_count_by_number(this.number_load).subscribe(
                data => response = data,
                err => {
                    this.number_datatable_ref.set_loading(false);
                    if (err.status && err.error) {
                        this.alertService.alert_message(err.status, err.error);
                    } else {
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                () => {
                    try {
                        for (var i = 0; i < response.list.length; i++) {
                            if (response.list[i].mt_enviado) {
                                response.list[i].mt_enviado = new Date(response.list[i].mt_enviado).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
                            }
                        }
                        this.number_datatable_ref.set_results_update_list(response.list);
                    } catch (error) {
                        this.number_datatable_ref.set_loading(false);
                        this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                    }
                }
            );    
        }

        number_datatable_events(event){

        }

    //SMS ####################################################################
    //########################################################################

    //########################################################################
    //CATALOGS ###############################################################

        get_labels(){
            this.client_datatable_loading = true;
            var response;
            this.endpoint.get_labels().subscribe(
                data => response = data,
                err => {
                    this.label_list = [];
                    this.client_datatable_loading = false;
                    if(err.status && err.error){
                        this.alertService.alert_message(err.status ,err.error);
                    }else{
                        this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                    }
                },
                ()=> {
                    this.label_list = [];
                    this.label_list = response;
                    var response_area_code;
                    this.endpoint.get_area_code().subscribe(
                        data => response_area_code = data,
                        err => {
                            this.label_list = [];
                            this.client_datatable_loading = false;
                            if(err.status && err.error){
                                this.alertService.alert_message(err.status ,err.error);
                            }else{
                                this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
                            }
                        },
                        ()=> {
                            try{
                                this.set_datatable(this.label_list);
                                this.set_inputs(this.label_list, response_area_code);
                                this.client_datatable_loading = false;
                            }catch(error){
                                this.client_datatable_loading = false;
                                this.alertService.alert_aplication_error("Error Interno del Aplicativo");
                            }
                            
                        }
                    );
                }
            );
        }

    //CATALOGS ###############################################################
    //########################################################################

    //########################################################################
    //MISC ###################################################################

        set_datatable(labels:any[]){
            this.client_datatable = {
                title:"Listado de Clientes",
                show_search:true,
                search_placeholder:"- Buscar -",
                icon:"users",
                object_description:"clientes",
                empty_text:"No se encontraron clientes",
                header_class: "custom-th-dark",
                columns:[
                    {   
                        column:"cli_nombre",
                        wrap_column:false,
                        header:"Nombre del Cliente",
                        wrap_header:true,
                        type:"text",
                        class: "custom-md-table-cell"
                    },
                    {   
                        column:"cli_celular",
                        wrap_column:true,
                        header:"Número de Celular",
                        wrap_header:true,
                        type:"text",
                        class: "custom-md-table-cell"
                    },
                    {   
                        column:"cli_email",
                        wrap_column:false,
                        header:"Correo Electrónico",
                        wrap_header:true,
                        type:"text",
                        class: "custom-md-table-cell"
                    },
                    {   
                        column:"cli_sexo",
                        wrap_column:true,
                        header:"Sexo",
                        wrap_header:true,
                        type:"text",
                        class: "custom-md-table-cell"
                    }
                ],
                events:[
                    {
                        name:"Mensajes SMS",
                        style:"color:#2F5BB4",
                        hover_style:"cursor:pointer; color:#2F5BB4; background-color:#D6E4FF !important;",
                        icon:"comments"
                    },
                    {
                        name:"Membresías",
                        style:"color:#57A473",
                        hover_style:"cursor:pointer; color:#57A473; background-color:#C1FFD7 !important;",
                        icon:"users"
                    },
                    {
                        name:"Datos del Cliente",
                        style:"color:#39B7CB",
                        hover_style:"cursor:pointer; color:#39B7CB; background-color:#BDF0FF !important;",
                        icon:"search"
                    },
                    {
                        name:"Editar Cliente",
                        style:"color:#ffb300",
                        hover_style:"cursor:pointer; color:#ffb300; background-color:#FFF7C6 !important;",
                        icon:"edit"
                    },
                    {
                        name:"Eliminar Cliente",
                        style:"color:#FB5D5D",
                        hover_style:"cursor:pointer; color:#FB5D5D; background-color:#FEDCDC !important;",
                        icon:"trash-alt"
                    }
                ],
                navigation_offsets:[5,10,15,20,25,50],
                show_search_field:true,
                table_icon:"caret-right"
            }
            for(var i = 0;i<labels.length;i++){
                for(var j = 0;j<this.client_datatable.columns.length;j++){
                    if(labels[i].var_campo==this.client_datatable.columns[j].column){
                        this.client_datatable.columns[j].header = labels[i].var_alias;
                    }
                }
            }
        }

        set_inputs(labels:any[], area_code:string){
            this.memberships_inputs = [
                {
                    class:"row",
                    columns:[
                        {
                            class:"col-md-3",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_celular",
                                    label:"Número de Celular",
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                        {
                            class:"col-md-3",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_identidad",
                                    label:"Identidad",
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                        {
                            class:"col-md-6",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_nombre",
                                    label:"Nombre",
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
            this.client_inputs=[
                {
                    class:"row",
                    columns:[
                        {
                            class:"col-md-3",
                            inputs:[
                                {
                                    type:"cellphone",
                                    extra:"",
                                    name:"cli_celular",
                                    label:"Número de Celular",
                                    icon:"",
                                    class:"form-control",
                                    placeholder:area_code + "-####-####",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:"^" + area_code + "-[0-9]{4}-[0-9]{4}$",
                                    error_required:"Requerido",
                                    error_pattern:"Formato Inválido",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return [/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/];
                                    },
                                    required:()=>{
                                        return true;
                                    },
                                    disabled:()=>{
                                        return false;
                                    },
                                    change:(event)=>{
                                        return false;
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                        {
                            class:"col-md-3",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_identidad",
                                    label:"No. de Identidad",
                                    icon:"",
                                    class:"form-control",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:"50",
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"Formato Inválido",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return false;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                        {
                            class:"col-md-6",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_nombre",
                                    label:"Nombre del Cliente",
                                    icon:"",
                                    class:"form-control",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:"50",
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return false;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    class:"row",
                    columns:[
                        
                        {
                            class:"col-md-3",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_fecha_nac",
                                    label:"Nacimiento",
                                    icon:"",
                                    class:"form-control",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:"50",
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return false;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                        {
                            class:"col-md-3",
                            inputs:[
                                {
                                    type:"select",
                                    extra:"",
                                    name:"cli_sexo",
                                    label:"Sexo",
                                    icon:"",
                                    class:"form-control",
                                    placeholder:"- Seleccione -",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"value",
                                        text:"text"
                                    },
                                    list:()=>{
                                        return [
                                            {
                                                value:"Femenino",
                                                text:"Femenino"
                                            },
                                            {
                                                value:"Masculino",
                                                text:"Masculino"
                                            }  
                                        ]
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return false;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                        {
                            class:"col-md-6",
                            inputs:[
                                {
                                    type:"email",
                                    extra:"",
                                    name:"cli_email",
                                    label:"Correo Electrónico",
                                    icon:"",
                                    class:"form-control",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:"50",
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return false;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                    ]
                },
                {
                    class:"row",
                    columns:[
                        {
                            class:"col-md-3",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_telefono",
                                    label:"Teléfono Fijo",
                                    icon:"",
                                    class:"form-control",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:"50",
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"Formato Inválido",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return false;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                        {
                            class:"col-md-9",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_direccion",
                                    label:"Dirección",
                                    icon:"",
                                    class:"form-control",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:"100",
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return false;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                    ]
                },
                {
                    class:"row",
                    columns:[]
                }
            ];
            this.client_search_inputs=[
                {
                    class:"row",
                    columns:[
                        {
                            class:"col-md-3",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_celular",
                                    label:"Número de Celular",
                                    icon:"",
                                    class:"form-control",
                                    placeholder:area_code + "-####-####",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:"^" + area_code + "-[0-9]{4}-[0-9]{4}$",
                                    error_required:"Requerido",
                                    error_pattern:"Formato Inválido",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return [/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/];
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return false;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                        {
                            class:"col-md-3",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_identidad",
                                    label:"No. de Identidad",
                                    icon:"",
                                    class:"form-control",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:"50",
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"Formato Inválido",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return false;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                        {
                            class:"col-md-6",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_nombre",
                                    label:"Nombre del Cliente",
                                    icon:"",
                                    class:"form-control",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:"50",
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return false;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    class:"row",
                    columns:[
                        
                        {
                            class:"col-md-3",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_fecha_nac",
                                    label:"Nacimiento",
                                    icon:"",
                                    class:"form-control",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:"50",
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return false;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                        {
                            class:"col-md-3",
                            inputs:[
                                {
                                    type:"select",
                                    extra:"",
                                    name:"cli_sexo",
                                    label:"Sexo",
                                    icon:"",
                                    class:"form-control",
                                    placeholder:"- Seleccione -",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"value",
                                        text:"text"
                                    },
                                    list:()=>{
                                        return [
                                            {
                                                value:"Femenino",
                                                text:"Femenino"
                                            },
                                            {
                                                value:"Masculino",
                                                text:"Masculino"
                                            }  
                                        ]
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return false;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                        {
                            class:"col-md-6",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_email",
                                    label:"Correo Electrónico",
                                    icon:"",
                                    class:"form-control",
                                    placeholder:"correo@ejemplo.com",
                                    minlength:null,
                                    maxlength:"50",
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return false;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                    ]
                },
                {
                    class:"row",
                    columns:[
                        {
                            class:"col-md-3",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_telefono",
                                    label:"Teléfono Fijo",
                                    icon:"",
                                    class:"form-control",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:"50",
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"Formato Inválido",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return false;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                        {
                            class:"col-md-9",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_direccion",
                                    label:"Dirección",
                                    icon:"",
                                    class:"form-control",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:"100",
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return false;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                    ]
                },
                {
                    class:"row",
                    columns:[]
                }
            ];
            this.client_inputs_view=[
                {
                    class:"row",
                    columns:[
                        {
                            class:"col-md-3",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_celular",
                                    label:"Número de Celular",
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:area_code + "-####-####",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"Formato Inválido",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                        {
                            class:"col-md-3",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_identidad",
                                    label:"No. de Identidad",
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"Formato Inválido",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                        {
                            class:"col-md-6",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_nombre",
                                    label:"Nombre del Cliente",
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    class:"row",
                    columns:[                      
                        {
                            class:"col-md-3",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_fecha_nac",
                                    label:"Nacimiento",
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:"50",
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                        {
                            class:"col-md-3",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_sexo",
                                    label:"Sexo",
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"value",
                                        text:"text"
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                        {
                            class:"col-md-6",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_email",
                                    label:"Correo Electrónico",
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                    ]
                },
                {
                    class:"row",
                    columns:[
                        {
                            class:"col-md-3",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_telefono",
                                    label:"Teléfono Fijo",
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"Formato Inválido",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                        {
                            class:"col-md-9",
                            inputs:[
                                {
                                    type:"text",
                                    extra:"",
                                    name:"cli_direccion",
                                    label:"Dirección",
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"Requerido",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                    ]
                },
                {
                    class:"row",
                    columns:[]
                }
            ];
            for(var i = 0;i<labels.length;i++){
                if(labels[i].var_alias && labels[i].id && (labels[i].var_campo=="cli_campo1" ||
                   labels[i].var_campo=="cli_campo2" ||
                   labels[i].var_campo=="cli_campo3" ||
                   labels[i].var_campo=="cli_campo4" ||
                   labels[i].var_campo=="cli_campo5" ||
                   labels[i].var_campo=="cli_campo6" ||
                   labels[i].var_campo=="cli_campo7")){

                    var type = "";
                    var maxlength = "";
                    var pattern = "";
                    var mask;
                    var error_pattern = "";
                    var extra = "";

                    if(labels[i].tipo_etiqueta_id==1){
                        type = "text";
                        maxlength = "70";
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==2){
                        type = "integer";
                        maxlength = null;
                        pattern = "";
                        mask = false;
                        error_pattern = "Formato Inválido";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==3){
                        type = "decimal";
                        maxlength = null;
                        pattern = "";
                        mask = false;
                        error_pattern = "Formato Inválido";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==4){
                        type = "date";
                        maxlength = null;
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==5){
                        type = "time";
                        maxlength = null;
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==6){
                        type = "calendar";
                        maxlength = null;
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "popup";
                    }
                    this.client_inputs[3].columns.push(
                        {
                            class:"col-md-6",
                            inputs:[
                                {
                                    type:type,
                                    extra:extra,
                                    name:labels[i].var_campo,
                                    label:labels[i].var_alias,
                                    icon:"",
                                    class:"form-control",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:maxlength,
                                    pattern:pattern,
                                    error_required:"Requerido",
                                    error_pattern:error_pattern,
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return mask;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return false;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                    );
                    this.client_inputs_view[3].columns.push(
                        {
                            class:"col-md-6",
                            inputs:[
                                {
                                    type:type,
                                    extra:extra,
                                    name:labels[i].var_campo,
                                    label:labels[i].var_alias,
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                    );
                }else if(labels[i].var_alias && labels[i].id && (labels[i].var_campo=="cli_cif")){
                    if(labels[i].tipo_etiqueta_id==1){
                        type = "text";
                        maxlength = "50";
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==2){
                        type = "integer";
                        maxlength = null;
                        pattern = "";
                        mask = false;
                        error_pattern = "Formato Inválido";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==3){
                        type = "decimal";
                        maxlength = null;
                        pattern = "";
                        mask = false;
                        error_pattern = "Formato Inválido";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==4){
                        type = "date";
                        maxlength = null;
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==5){
                        type = "time";
                        maxlength = null;
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==6){
                        type = "calendar";
                        maxlength = null;
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "popup";
                    }
                    this.client_inputs[3].columns.push(
                        {
                            class:"col-md-6",
                            inputs:[
                                {
                                    type:type,
                                    extra:extra,
                                    name:labels[i].var_campo,
                                    label:labels[i].var_alias,
                                    icon:"",
                                    class:"form-control",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:maxlength,
                                    pattern:pattern,
                                    error_required:"Requerido",
                                    error_pattern:error_pattern,
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return mask;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return false;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                    );
                    this.client_inputs_view[3].columns.push(
                        {
                            class:"col-md-6",
                            inputs:[
                                {
                                    type:type,
                                    extra:extra,
                                    name:labels[i].var_campo,
                                    label:labels[i].var_alias,
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                    );
                }else if(labels[i].var_alias && labels[i].id && (labels[i].var_campo=="cli_cm")){
                    if(labels[i].tipo_etiqueta_id==1){
                        type = "text";
                        maxlength = "30";
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==2){
                        type = "integer";
                        maxlength = null;
                        pattern = "";
                        mask = false;
                        error_pattern = "Formato Inválido";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==3){
                        type = "decimal";
                        maxlength = null;
                        pattern = "";
                        mask = false;
                        error_pattern = "Formato Inválido";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==4){
                        type = "date";
                        maxlength = null;
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==5){
                        type = "time";
                        maxlength = null;
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==6){
                        type = "calendar";
                        maxlength = null;
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "popup";
                    }
                    this.client_inputs[3].columns.push(
                        {
                            class:"col-md-6",
                            inputs:[
                                {
                                    type:type,
                                    extra:extra,
                                    name:labels[i].var_campo,
                                    label:labels[i].var_alias,
                                    icon:"",
                                    class:"form-control",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:maxlength,
                                    pattern:pattern,
                                    error_required:"Requerido",
                                    error_pattern:error_pattern,
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return mask;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return false;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                    );
                    this.client_inputs_view[3].columns.push(
                        {
                            class:"col-md-6",
                            inputs:[
                                {
                                    type:type,
                                    extra:extra,
                                    name:labels[i].var_campo,
                                    label:labels[i].var_alias,
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                    );
                }else if(labels[i].var_alias && labels[i].id && (labels[i].var_campo=="cli_tarjeta")){
                    if(labels[i].tipo_etiqueta_id==1){
                        type = "text";
                        maxlength = "50";
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==2){
                        type = "integer";
                        maxlength = null;
                        pattern = "";
                        mask = false;
                        error_pattern = "Formato Inválido";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==3){
                        type = "decimal";
                        maxlength = null;
                        pattern = "";
                        mask = false;
                        error_pattern = "Formato Inválido";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==4){
                        type = "date";
                        maxlength = null;
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==5){
                        type = "time";
                        maxlength = null;
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "";
                    }else if(labels[i].tipo_etiqueta_id==6){
                        type = "calendar";
                        maxlength = null;
                        pattern = null;
                        mask = false;
                        error_pattern = "";
                        extra = "popup";
                    }
                    this.client_inputs[3].columns.push(
                        {
                            class:"col-md-6",
                            inputs:[
                                {
                                    type:type,
                                    extra:extra,
                                    name:labels[i].var_campo,
                                    label:labels[i].var_alias,
                                    icon:"",
                                    class:"form-control",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:maxlength,
                                    pattern:pattern,
                                    error_required:"Requerido",
                                    error_pattern:error_pattern,
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return mask;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return false;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                    );
                    this.client_inputs_view[3].columns.push(
                        {
                            class:"col-md-6",
                            inputs:[
                                {
                                    type:type,
                                    extra:extra,
                                    name:labels[i].var_campo,
                                    label:labels[i].var_alias,
                                    icon:"",
                                    class:"form-control bg-white",
                                    placeholder:"",
                                    minlength:null,
                                    maxlength:null,
                                    pattern:null,
                                    error_required:"",
                                    error_pattern:"",
                                    error_minlength:"",
                                    list_data:{
                                        value:"",
                                        text:""
                                    },
                                    list:()=>{
                                        return []
                                    },
                                    textmask:()=>{
                                        return false;
                                    },
                                    required:()=>{
                                        return false;
                                    },
                                    disabled:()=>{
                                        return true;
                                    },
                                    change:(event)=>{
                                    },
                                    input:()=>{
                                    }
                                }
                            ]
                        },
                    );
                }
            }
            for(var i = 0;i<labels.length;i++){
                for(var j = 0;j<this.client_inputs.length;j++){
                    for(var m = 0;m<this.client_inputs[j].columns.length;m++){
                        for(var n = 0;n<this.client_inputs[j].columns[m].inputs.length;n++){
                            if(this.client_inputs[j].columns[m].inputs[n].name == labels[i].var_campo){
                                this.client_inputs[j].columns[m].inputs[n].label = labels[i].var_alias;
                            }
                        }
                    }
                }
            }
            for(var i = 0;i<labels.length;i++){
                for(var j = 0;j<this.memberships_inputs.length;j++){
                    for(var m = 0;m<this.memberships_inputs[j].columns.length;m++){
                        for(var n = 0;n<this.memberships_inputs[j].columns[m].inputs.length;n++){
                            if(this.memberships_inputs[j].columns[m].inputs[n].name == labels[i].var_campo){
                                this.memberships_inputs[j].columns[m].inputs[n].label = labels[i].var_alias;
                            }
                        }
                    }
                }
            }
            for(var i = 0;i<labels.length;i++){
                for(var j = 0;j<this.client_search_inputs.length;j++){
                    for(var m = 0;m<this.client_search_inputs[j].columns.length;m++){
                        for(var n = 0;n<this.client_search_inputs[j].columns[m].inputs.length;n++){
                            if(this.client_search_inputs[j].columns[m].inputs[n].name == labels[i].var_campo){
                                this.client_search_inputs[j].columns[m].inputs[n].label = labels[i].var_alias;
                            }
                        }
                    }
                }
            }
            for(var i = 0;i<labels.length;i++){
                for(var j = 0;j<this.client_inputs_view.length;j++){
                    for(var m = 0;m<this.client_inputs_view[j].columns.length;m++){
                        for(var n = 0;n<this.client_inputs_view[j].columns[m].inputs.length;n++){
                            if(this.client_inputs_view[j].columns[m].inputs[n].name == labels[i].var_campo){
                                this.client_inputs_view[j].columns[m].inputs[n].label = labels[i].var_alias;
                            }
                        }
                    }
                }
            }
        }

    //MISC ###################################################################
    //########################################################################

    ngOnDestroy() {
        setTimeout(() => this.layoutService.off('resize.app-home'));
    }

}
