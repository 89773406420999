import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { AppService } from '../../../app.service';
import { AppEndpoints } from '../../../app.endpoints';
import { LayoutService } from '../../../layout/layout.service';
import { ModalDirective } from 'ngx-bootstrap';
import { default as swal } from 'sweetalert2';
import { FormRendererComponent } from '../../../components/form_renderer/form_renderer.component';
import { AlertService } from '../../../components/alert_service/alert.service';
import { ExcelService } from '../../../components/excel_service/excel.service';
import { conformToMask } from 'angular2-text-mask/dist/angular2TextMask';
import { AppFunctions } from '../../../app.functions';
import { ServiceDatatableComponent } from '../../../components/service_datatable/service-datatable.component';
import * as FileSaver from 'file-saver';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
	selector: 'daily_shiping_history',
	templateUrl: './daily_shiping_history.component.html',
	styleUrls: [
		'../../../../vendor/libs/spinkit/spinkit.scss'
	]
})
export class DailyShipingHistoryComponent implements OnInit {
	@ViewChild('search_modal') search_modal: ModalDirective;
	public locale = 'es-HN';
	public datatable: any;
	public areas:any[];
	public loading:boolean;
	public search_data = {
		area:"",
		anio:"",
		mes:"",
		date:null
	}
	public data:any[];
	public year:string;
	public month:string;
	public total:number;
	public total_string:string;
	public efective_avg:number;
	public efective_avg_string:string;
	public area_string:string;
	public labels = [];
	public datasets:any[];
	public options = {
	    scales: {
	      	xAxes: [
	        	{
	          		gridLines: {
	            		display: true
	          		},
	          		ticks: {
		            	fontColor: '#aaa',
		            	autoSkipPadding: 0,
	          		}
	        	}
	      	],
	      	yAxes: [
	        	{
	          		gridLines: {
	            		display: true
	          		},
	          		ticks: {
			            fontColor: '#aaa',
			            maxTicksLimit: 4,
			            beginAtZero: true,
			            callback: function(value, index, values) {
	                        return value.toLocaleString('es-hn');
	                    }
	          		},
	        	}
	      	]
	    },
	    responsive: true,
	    maintainAspectRatio: false,
	    tooltips: {
	      	mode: 'index',
	      	callbacks: {
	        	label: function (tooltipItems, data) {
			        return data.datasets[0].data[tooltipItems.index].toLocaleString('es-hn');
		        },
		        title: (tooltipItems, data)=> {
			        return this.month + " - " + this.labels[tooltipItems[0].index];
		        },
	      	}
	    },
	};
	public colors = [
	    {
	      backgroundColor: 'rgb(84, 153, 199,0.8)',
	      borderColor: '#2471A3'
	    }
	];

	@ViewChild('download_csv_modal') download_csv_modal: ModalDirective;
	public download_loading:boolean;
	public download_view:number;
	public download_link:string;
	public download_data = {
		area:"",
		anio:"",
		mes:"",
		date:null
	}

	constructor(private appService: AppService, public endpoint: AppEndpoints, private layoutService: LayoutService, private alertService: AlertService, private excelService: ExcelService, public functions: AppFunctions, private localeService: BsLocaleService){
		this.appService.pageTitle = 'Histórico de Envío Diario';
		this.data = [];
		this.datasets = [];
		this.areas = [];
		this.localeService.use(this.locale);
		this.loading = false;
		this.year = "";
		this.month = "";
		this.area_string = "";
		this.total = 0;
		this.total_string = "0";
		this.efective_avg = 0;
		this.efective_avg_string = "0";
	}

	ngOnInit(){
		this.get_sms_count_by_day();
		this.get_areas();
	}

	ngOnDestroy() {
		setTimeout(() => this.layoutService.off('resize.app-home'));
	}


	//########################################################################
	//DOWNLOAD ###############################################################

		open_download_csv(){
			this.download_loading = false;
			this.download_view = 1;
			this.download_csv_modal.show();
			this.download_data.area = "";
		}

		download_csv() {
			this.download_loading = true;
			var load = {
				anio:"",
				mes:"",
				area:this.download_data.area
			}
			if(this.download_data.date && this.download_data.date instanceof Date){
				load.anio = this.download_data.date.getFullYear() + "";
				load.mes = (this.download_data.date.getMonth() + 1) + "";
			}
			var response;
			this.endpoint.get_sms_count_by_day_csv(load).subscribe(
				data => {
					response = data;
				},
				err => {
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
					this.download_loading = false;
				},
				() => {
					let url = response.filename;
	        		window.open(this.endpoint.get_endpoint() + "/download/" + url);
	        		this.download_link = this.endpoint.get_endpoint() + "/download/" + response.filename;
	        		this.download_loading = false;
	        		this.download_view = 2;
				}
			);
		}

	//DOWNLOAD ###############################################################
	//########################################################################

	//########################################################################
	//DATATABLE ##############################################################

		search(){
			if(this.search_data.date && this.search_data.date instanceof Date){
				this.search_data.anio = this.search_data.date.getFullYear() + "";
				this.search_data.mes = (this.search_data.date.getMonth() + 1) + "";
			}else{
				this.search_data.anio = "";
				this.search_data.mes = "";
			}
			this.search_modal.hide();
			this.get_sms_count_by_day();
		}

		clean_search(){
			this.search_data = {
				area:"",
				anio:"",
				mes:"",
				date:null
			}
			this.search_modal.hide();
			this.get_sms_count_by_day();
		}

	//DATATABLE ##############################################################
	//########################################################################

	//########################################################################
	//CATALOGS ###############################################################

		get_sms_count_by_day(){
			this.loading = true;
			var response;
			this.endpoint.get_sms_count_by_day({anio:this.search_data.anio, mes:this.search_data.mes, area:this.search_data.area}).subscribe(
				data => {
					response = data;
				},
				err => {
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
					this.loading = false;
				},
				() => {
					try{
						this.download_data.date = new Date(response.year,response.month - 1,1);
						this.search_data.date = new Date(response.year,response.month - 1,1);
						this.year = response.year + "";
						this.area_string = response.area + "";
						this.month = this.functions.get_month_string(response.month - 1);
						this.efective_avg_string = response.efectividad.toLocaleString('es-hn', {maximumFractionDigits:2}) + "%";
						this.efective_avg = response.efectividad;
						this.total = 0;
						this.data = [];
						this.labels = [];
						var dataset = {
							label:["Día"],
							data: [],
					        borderWidth: 1,
					        fill: true
						}
						for(var i = 0;i<response.list.length;i++){
							this.total = this.total + response.list[i].total;
							var values = {
								label:new Date(response.list[i].fecha).toLocaleString('es-hn', {day:"numeric", month:"long", year:"numeric"}),
								count:response.list[i].total.toLocaleString('es-hn')
							}
							this.labels.push(response.list[i].dia);
							dataset.data.push(response.list[i].total);
							this.data.push(values);
						}
						this.datasets = [];
						this.datasets.push(dataset);
						this.total_string = this.total.toLocaleString('es-hn');
						this.loading = false;
					}catch(err){
						this.alertService.alert_aplication_error("Error intero de aplicativo");
						this.loading = false;
					}
				}
			);
		}

		get_areas(){
			this.endpoint.get_areas({}).subscribe(
				data => this.areas = data,
				err => {
					this.areas = [];
				}
			);
		}

	//CATALOGS ###############################################################
	//########################################################################

	//########################################################################
	//MISC ###################################################################

		open_calendar(container) {
		 	container.monthSelectHandler = (event: any): void => {
		   		container._store.dispatch(container._actions.select(event.date));
		 	};     
		 	container.setViewMode('month');
		}

		format_status(status){
			return status == 'SENT' ? 'ENVIADO' : status == 'SENDING' ? 'ENVIANDO' : status == 'QUEUED' ? 'EN COLA' : status == 'PARSE_ERROR' ? 'ERROR' : '';
		}

	//MISC ###################################################################
	//########################################################################



}
