import { Component, OnInit, Input } from '@angular/core';
import { AppEndpoints } from '../../app.endpoints';
import { AlertService } from '../../components/alert_service/alert.service';

@Component({
	selector: 'recent_smpp_shipments_chart',
	templateUrl: './recent_smpp_shipments_chart.component.html',
	styleUrls:[
	'../../../vendor/libs/spinkit/spinkit.scss'
	]
})

export class RecentSmppShipmentsChart implements OnInit {
  @Input() valid:boolean;
  @Input() data:any[];
  @Input() loading:boolean;
	public current_offset:number;

  	constructor(public endpoint: AppEndpoints, private alertService: AlertService) {
  		this.current_offset = 0;
  		this.data = [];
  	}

  	ngOnInit() {
  	}

    get_data(){
	   	this.loading = true;
	   	this.endpoint.get_sms_sent_smpp_dashboard({current_offset:(this.current_offset*10)}).subscribe(
	   		data => {
	   			for(var i = 0;i<data.length;i++){
  					data[i].fecha_string = new Date(data[i].fecha).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit', hour12: true });
  				}
	   			this.data = data;
	   			this.loading = false;
	   		},
	   		err => {
	      		this.loading = false;
	    });
    }

    set_offset(val){
    	if(val>this.current_offset){
    		this.current_offset = val;
    		this.get_data();
    	}else if(val<this.current_offset){
    		if(val<0){
    			this.current_offset = 0;
    			this.get_data();
    		}else{
    			this.current_offset = val;
    			this.get_data();
    		}
    	}
    }

    set_data(data){
   		this.data = data;
    }
}