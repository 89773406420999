import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { AppService } from '../../../app.service';
import { AppEndpoints } from '../../../app.endpoints';
import { LayoutService } from '../../../layout/layout.service';
import { ModalDirective } from 'ngx-bootstrap';
import { default as swal } from 'sweetalert2';
import { AlertService } from '../../../components/alert_service/alert.service';
import { AppFunctions } from '../../../app.functions';
import { ServiceDatatableComponent } from '../../../components/service_datatable/service-datatable.component';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
	selector: 'yearly_shiping_history',
	templateUrl: './yearly_shiping_history.component.html',
	styleUrls: [
		'../../../../vendor/libs/spinkit/spinkit.scss'
	]
})
export class YearlyShipingHistoryComponent implements OnInit {
	@ViewChild('search_modal') search_modal: ModalDirective;
	public locale = 'es-HN';
	public datatable: any;
	public areas:any[];
	public loading:boolean;
	public search_data = {
		area:"",
		date:null
	}
	public data:any[];
	public area_string:string;
	public total:number;
	public total_string:string;
	public efective_avg:number;
	public efective_avg_string:string;

	public labels = [];
	public datasets:any[];
	public options = {
	    scales: {
	      	xAxes: [
	        	{
	          		gridLines: {
	            		display: true
	          		},
	          		ticks: {
		            	fontColor: '#aaa',
		            	autoSkipPadding: 0,
	          		}
	        	}
	      	],
	      	yAxes: [
	        	{
	          		gridLines: {
	            		display: true
	          		},
	          		ticks: {
			            fontColor: '#aaa',
			            maxTicksLimit: 4,
			            beginAtZero: true,
			            callback: function(value, index, values) {
	                        return value.toLocaleString('es-hn');
	                    }
	          		},
	        	}
	      	]
	    },
	    responsive: true,
	    maintainAspectRatio: false,
	    tooltips: {
	      	mode: 'index',
	      	callbacks: {
	        	label: function (tooltipItems, data) {
			        return data.datasets[0].data[tooltipItems.index].toLocaleString('es-hn');
		        },
		        title: (tooltipItems, data)=> {
			        return  "Año " + this.labels[tooltipItems[0].index];
		        },
	      	}
	    },
	};
	public colors = [
	    {
	      backgroundColor: 'rgb(84, 153, 199,0.8)',
	      borderColor: '#2471A3'
	    }
	];

	@ViewChild('download_csv_modal') download_csv_modal: ModalDirective;
	public download_loading:boolean;
	public download_view:number;
	public download_link:string;
	public download_data = {
		area:"",
		date:null
	}

	constructor(private appService: AppService, public endpoint: AppEndpoints, private layoutService: LayoutService, private alertService: AlertService, public functions: AppFunctions, private localeService: BsLocaleService){
		this.appService.pageTitle = 'Histórico de Envío Anual';
		this.data = [];
		this.datasets = [];
		this.areas = [];
		this.localeService.use(this.locale);
		this.loading = false;
		this.area_string = "";
		this.total = 0;
		this.total_string = "0";
		this.efective_avg = 0;
		this.efective_avg_string = "0";
	}

	ngOnInit(){
		this.get_sms_count_by_year();
		this.get_areas();
	}

	ngOnDestroy() {
		setTimeout(() => this.layoutService.off('resize.app-home'));
	}


	//########################################################################
	//DOWNLOAD ###############################################################

		open_download_csv(){
			this.download_loading = false;
			this.download_view = 1;
			this.download_csv_modal.show();
			this.download_data.area = "";
		}

		download_csv() {
			this.download_loading = true;
			var load = {
				area:this.download_data.area
			}
			var response;
			this.endpoint.get_sms_count_year_csv(load).subscribe(
				data => {
					response = data;
				},
				err => {
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
					this.download_loading = false;
				},
				() => {
					let url = response.filename;
	        		window.open(this.endpoint.get_endpoint() + "/download/" + url);
	        		this.download_link = this.endpoint.get_endpoint() + "/download/" + response.filename;
	        		this.download_loading = false;
	        		this.download_view = 2;
				}
			);
		}

	//DOWNLOAD ###############################################################
	//########################################################################

	//########################################################################
	//DATATABLE ##############################################################

		search(){
			this.search_modal.hide();
			this.get_sms_count_by_year();
		}

		clean_search(){
			this.search_data = {
				area:"",
				date:null
			}
			this.search_modal.hide();
			this.get_sms_count_by_year();
		}

	//DATATABLE ##############################################################
	//########################################################################

	//########################################################################
	//CATALOGS ###############################################################

		get_sms_count_by_year(){
			this.loading = true;
			var response;
			this.endpoint.get_sms_count_by_year({area:this.search_data.area}).subscribe(
				data => {
					response = data;
				},
				err => {
					if (err.status && err.error) {
						this.alertService.alert_message(err.status, err.error);
					} else {
						this.alertService.alert_internal_server_error("Error interno del servidor", "Revise su conexión de internet o inténtelo más tarde");
					}
					this.loading = false;
				},
				() => {
					try{
						this.data = [];
						this.labels = [];
						this.total = 0;
						var efective_acum = 0;
						var efective_total = 0;
						this.efective_avg = 0;
						this.area_string = response.area + "";
						var dataset = {
							label:["Periodo"],
							data: [],
					        borderWidth: 1,
					        fill: true
						}
						this.labels.push("Inicio");
						dataset.data.push(0);
						for(var i = 0;i<response.list.length;i++){
							if(response.list[i].total && response.list[i].efectividad && response.list[i].efectividad>0 && response.list[i].total > 0 && ((response.list[i].efectividad/response.list[i].total)*100)>10){
								if(response.list[i].efectividad/response.list[i].total>1){
									var percent = (100).toLocaleString('es-hn', {maximumFractionDigits:2}) + "%";
									efective_acum = efective_acum + 1;
									efective_total++;
								}else{
									var percent = ((response.list[i].efectividad/response.list[i].total)*100).toLocaleString('es-hn', {maximumFractionDigits:2}) + "%";
									efective_acum = efective_acum + (response.list[i].efectividad/response.list[i].total);
									efective_total++;
								}
							}else{
								var percent = "N/A";
							}
							var values = {
								label:response.list[i].anio,
								count:response.list[i].total.toLocaleString('es-hn'),
								efective:response.list[i].efectividad.toLocaleString('es-hn'),
								efective_percent:percent
							}
							this.labels.push(response.list[i].anio);
							dataset.data.push(response.list[i].total);
							this.total = this.total + response.list[i].total;
							this.data.push(values);
						}
						this.datasets = [];
						this.datasets.push(dataset);
						this.total_string = this.total.toLocaleString('es-hn');
						if(efective_total > 0){
							if(efective_acum/efective_total>1){
								this.efective_avg = 100;
							}else{
								this.efective_avg = efective_acum/efective_total * 100;
							}
							this.efective_avg_string = this.efective_avg.toLocaleString('es-hn', {maximumFractionDigits:2}) + "%";
						}else{
							this.efective_avg = 0;
							this.efective_avg_string = "0%";
						}
						this.loading = false;
					}catch(err){
						this.alertService.alert_aplication_error("Error intero de aplicativo");
						this.loading = false;
					}
				}
			);
		}

		get_areas(){
			this.endpoint.get_areas({}).subscribe(
				data => this.areas = data,
				err => {
					this.areas = [];
				}
			);
		}

	//CATALOGS ###############################################################
	//########################################################################

	//########################################################################
	//MISC ###################################################################

		open_calendar(container) {
		 	container.yearSelectHandler = (event: any): void => {
		   		container._store.dispatch(container._actions.select(event.date));
		 	};     
		 	container.setViewMode('year');
		}

		format_status(status){
			return status == 'SENT' ? 'ENVIADO' : status == 'SENDING' ? 'ENVIANDO' : status == 'QUEUED' ? 'EN COLA' : status == 'PARSE_ERROR' ? 'ERROR' : '';
		}

	//MISC ###################################################################
	//########################################################################

}
