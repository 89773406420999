import { Injectable, KeyValueDiffers } from '@angular/core';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as fs from 'file-saver';
import { callbackify } from 'util';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {

    constructor() { }

    upload_format_to_excel(headers: any[], excelFileName: string): void {
        let workbook = new Excel.Workbook();
        let worksheet = workbook.addWorksheet('Clientes');
        var header_row = [];
        for (var i = 0; i < headers.length; i++) {
            if (headers[i].label && headers[i].field) {
                header_row.push("$" + headers[i].field + "\r\n" + headers[i].label);
            }
        }
        let headerRow = worksheet.addRow(header_row);
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '00BEF3FE' }
            };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
            cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            cell.font = {
                name: 'Segoe UI',
                size: 10,
                color: { argb: '00424242' }
            };
        });
        worksheet.columns.forEach((x => {
            x.width = 25;
        }));
        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: EXCEL_TYPE });
            fs.saveAs(blob, excelFileName + '.' + EXCEL_EXTENSION);
            blob = null;
        });
    }

    json_list_to_excel(headers: any[], excelFileName: string, array: any[]): void {
        let workbook = new Excel.Workbook();
        let worksheet = workbook.addWorksheet('Clientes');
        var header_row = [];
        for (var i = 0; i < headers.length; i++) {
            if (headers[i].label && headers[i].field) {
                header_row.push("$" + headers[i].field + "\r\n" + headers[i].label);
            }
        }
        let headerRow = worksheet.addRow(header_row);
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '00BEF3FE' }
            };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
            cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            cell.font = {
                name: 'Segoe UI',
                size: 10,
                color: { argb: '00424242' }
            };
        });
        worksheet.columns.forEach(((x, i) => {
            if (headers[i] && headers[i].field == "cli_errores") {
                x.width = 100;
            } else {
                x.width = 25;
            }
        }));

        for (var i = 0; i < array.length; i++) {
            let fieldRow = worksheet.addRow(array[i]);
            fieldRow.eachCell((cell, number) => {
                cell.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
            });
        }

        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: EXCEL_TYPE });
            fs.saveAs(blob, excelFileName + '.' + EXCEL_EXTENSION);
            blob = null;
        });
    }

    survey_answers_to_excel(excelFileName: string, array: any, callback): void {

        let workbook = new Excel.Workbook();

        // Pages
        let summary_ws = workbook.addWorksheet('Resumen');

        // _____________________________________ Summary ____________________________________________

        var headers = ["Nombre Encuesta", "Código de Encuesta", "Fecha Creación", "Mensaje"];

        let headerRow = summary_ws.addRow(headers);
        headerRow.eachCell(cell => this.format_basic_header(cell));

        let fieldRow = summary_ws.addRow([
            array.summary.name,
            array.summary.survey_code,
            array.summary.creation_date,
            array.summary.sms_mensaje_original
        ]);
        fieldRow.eachCell(cell => {
            cell.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
        });

        var answer_data_format = [];
        array.answer_data.forEach((section, s_index) => {
            var current_id = '';
            if(!(section instanceof Array)){
                current_id = section.id;
            }else{
                current_id = section[0].id;
            }
            var sections_array = [];
            for(var i = 0;i<array.answer_data.length;i++){
                if(!(array.answer_data[i] instanceof Array)){
                    if(array.answer_data[i].id == current_id){
                       sections_array.push(array.answer_data[i]);
                    }
                }else{
                    for(var j = 0;j<array.answer_data[i].length;j++){
                        if(array.answer_data[i][j].id == current_id){
                           sections_array.push(array.answer_data[i][j]);
                        }
                    }
                }   
            }
            if(sections_array.length > 0){
                var exists = false;
                for(var i = 0;i<answer_data_format.length;i++){
                    if(answer_data_format[i][0].id == sections_array[0].id){
                        exists = true;
                    }
                }
                if(!exists){
                    answer_data_format.push(sections_array)
                }
            }
        });
        array.answer_data = answer_data_format;
        //TRABAJAR ESTO
        /*
        array.answer_data.forEach((section, s_index) => {
            for(var j = 0;j<array.answer_data.length;j++){
                if(answer_data_format.length > 0){
                    var exists = false;
                    for(var i = 0;i<answer_data_format.length;i++){
                        if(section.id == answer_data_format[i][0].id){
                            answer_data_format[i].push
                            exists = true;
                        }
                    }
                    if(!exists){

                    }
                }else{
                    if(!(section instanceof Array)){
                        answer_data_format.push([section]);
                    }else{
                        answer_data_format.push(section);
                    }
                }
                
            }
        });

        array.answer_data.forEach((section, s_index) => {
            if(!(section instanceof Array)){
                section = [section];
            }
            if(answer_data_format.length > 0){
                for(var i = 0;i<answer_data_format.length;i++){
                    if(section.id == answer_data_format[i].id){
                        answer_data_format[i].push(section);
                    }else{
                        answer_data_format.push(section);
                    }
                }
            }else{
                answer_data_format.push(section);
            }
        });*/

        array.answer_data.forEach((section, s_index) => {

            let ws = workbook.addWorksheet('Sección ' + (s_index + 1));
            if(!(section instanceof Array)){
                section = [section];
            }
            section.forEach((value, index) => {
                if (value.section_type_id == 1) {
                    // _____________________________________ Image ____________________________________________
                    if(index == 0){
                        headerRow = ws.addRow(["Tipo de Sección: Imagen"]);
                        headers = ["Identidad Cliente", "Nombre Cliente", "Sexo Cliente", "Fecha Nacimiento Cliente", "Celular Cliente", "Teléfono Cliente", "Correo Cliente", "Dirección Cliente", "Tarjeta Cliente", "Visto", "Fecha"];

                        headerRow = ws.addRow(headers);
                        headerRow.eachCell(cell => this.format_basic_header(cell));
                    }
                    

                    fieldRow = ws.addRow([
                        value.cli_identidad,
                        value.cli_nombre,
                        value.cli_sexo,
                        value.cli_fecha_nac,
                        value.cli_celular,
                        value.cli_telefono,
                        value.cli_email,
                        value.cli_direccion,
                        value.cli_tarjeta,
                        value.viewed,
                        value.created_at
                    ]);
                    fieldRow.eachCell(cell => {
                        cell.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
                    });

                }
                else if (value.section_type_id == 3) {
                    if(index == 0){
                        headerRow = ws.addRow(["Tipo de Sección: Formulario"]);
                        // _____________________________________ Form ____________________________________________

                        headers = ["Identidad Cliente", "Nombre Cliente", "Sexo Cliente", "Fecha Nacimiento Cliente", "Celular Cliente", "Teléfono Cliente", "Correo Cliente", "Dirección Cliente", "Tarjeta Cliente", "Fecha", 'Campo 1', 'Campo 2', 'Campo 3', 'Campo 4', 'Campo 5', 'Campo 6', 'Campo 7', 'Campo 8', 'Campo 9', 'Campo 10', 'Campo 11', 'Campo 12', 'Campo 13', 'Campo 14', 'Campo 15', 'Campo 16', 'Campo 17', 'Campo 18', 'Campo 19', 'Campo 20', 'Campo 21', 'Campo 22', 'Campo 23', 'Campo 24', 'Campo 25', 'Campo 26', 'Campo 27', 'Campo 28', 'Campo 29', 'Campo 30', 'Campo 31', 'Campo 32', 'Campo 33', 'Campo 34', 'Campo 35', 'Campo 36', 'Campo 37', 'Campo 38', 'Campo 39', 'Campo 40', 'Campo 41', 'Campo 42', 'Campo 43', 'Campo 44', 'Campo 45', 'Campo 46', 'Campo 47', 'Campo 48', 'Campo 49', 'Campo 50', 'Campo 51', 'Campo 52', 'Campo 53', 'Campo 54', 'Campo 55', 'Campo 56', 'Campo 57', 'Campo 58', 'Campo 59', 'Campo 60', 'Campo 61', 'Campo 62', 'Campo 63', 'Campo 64', 'Campo 65', 'Campo 66', 'Campo 67', 'Campo 68', 'Campo 69', 'Campo 70', 'Campo 71', 'Campo 72', 'Campo 73', 'Campo 74', 'Campo 75', 'Campo 76', 'Campo 77', 'Campo 78', 'Campo 79', 'Campo 80', 'Campo 81', 'Campo 82', 'Campo 83', 'Campo 84', 'Campo 85', 'Campo 86', 'Campo 87', 'Campo 88', 'Campo 89', 'Campo 90', 'Campo 91', 'Campo 92', 'Campo 93', 'Campo 94', 'Campo 95', 'Campo 96', 'Campo 97', 'Campo 98', 'Campo 99', 'Campo 100'];

                        headerRow = ws.addRow(headers);
                        headerRow.eachCell(cell => this.format_basic_header(cell));
                    }
                    
                    var section_data = JSON.parse(value.data);

                    section_data.form_survey.sections.forEach((field, f_index) => {
                        if (field.type == 4 || field.type == 5 || field.type == 6) {
                            var field_object = field.options.find(option => option.value == value["field_" + (f_index + 1)]);
                            value["field_" + (f_index + 1)] = field_object ? field_object.text : "";
                        }
                    });

                    fieldRow = ws.addRow([
                        value.cli_identidad,
                        value.cli_nombre,
                        value.cli_sexo,
                        value.cli_fecha_nac,
                        value.cli_celular,
                        value.cli_telefono,
                        value.cli_email,
                        value.cli_direccion,
                        value.cli_tarjeta,
                        value.created_at,
                        value.field_1,
                        value.field_2,
                        value.field_3,
                        value.field_4,
                        value.field_5,
                        value.field_6,
                        value.field_7,
                        value.field_8,
                        value.field_9,
                        value.field_10,
                        value.field_11,
                        value.field_12,
                        value.field_13,
                        value.field_14,
                        value.field_15,
                        value.field_16,
                        value.field_17,
                        value.field_18,
                        value.field_19,
                        value.field_20,
                        value.field_21,
                        value.field_22,
                        value.field_23,
                        value.field_24,
                        value.field_25,
                        value.field_26,
                        value.field_27,
                        value.field_28,
                        value.field_29,
                        value.field_30,
                        value.field_31,
                        value.field_32,
                        value.field_33,
                        value.field_34,
                        value.field_35,
                        value.field_36,
                        value.field_37,
                        value.field_38,
                        value.field_39,
                        value.field_40,
                        value.field_41,
                        value.field_42,
                        value.field_43,
                        value.field_44,
                        value.field_45,
                        value.field_46,
                        value.field_47,
                        value.field_48,
                        value.field_49,
                        value.field_50,
                        value.field_51,
                        value.field_52,
                        value.field_53,
                        value.field_54,
                        value.field_55,
                        value.field_56,
                        value.field_57,
                        value.field_58,
                        value.field_59,
                        value.field_60,
                        value.field_61,
                        value.field_62,
                        value.field_63,
                        value.field_64,
                        value.field_65,
                        value.field_66,
                        value.field_67,
                        value.field_68,
                        value.field_69,
                        value.field_70,
                        value.field_71,
                        value.field_72,
                        value.field_73,
                        value.field_74,
                        value.field_75,
                        value.field_76,
                        value.field_77,
                        value.field_78,
                        value.field_79,
                        value.field_80,
                        value.field_81,
                        value.field_82,
                        value.field_83,
                        value.field_84,
                        value.field_85,
                        value.field_86,
                        value.field_87,
                        value.field_88,
                        value.field_89,
                        value.field_90,
                        value.field_91,
                        value.field_92,
                        value.field_93,
                        value.field_94,
                        value.field_95,
                        value.field_96,
                        value.field_97,
                        value.field_98,
                        value.field_99,
                        value.field_100
                    ]);
                    fieldRow.eachCell(cell => {
                        cell.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
                    });

                }
                else if (value.section_type_id == 2) {
                    if(index == 0){
                        headerRow = ws.addRow(["Tipo de Sección: Satisfacción"]);
                        // _____________________________________ Satisfaction ____________________________________________

                        headers = ["Identidad Cliente", "Nombre Cliente", "Sexo Cliente", "Fecha Nacimiento Cliente", "Celular Cliente", "Teléfono Cliente", "Correo Cliente", "Dirección Cliente", "Tarjeta Cliente", "Excelente", "Bueno", "Neutral", "Malo", "Terrible", "Fecha"];

                        headerRow = ws.addRow(headers);
                        headerRow.eachCell(cell => this.format_basic_header(cell));
                    }
                    

                    fieldRow = ws.addRow([
                        value.cli_identidad,
                        value.cli_nombre,
                        value.cli_sexo,
                        value.cli_fecha_nac,
                        value.cli_celular,
                        value.cli_telefono,
                        value.cli_email,
                        value.cli_direccion,
                        value.cli_tarjeta,
                        value.excellent,
                        value.good,
                        value.neutral,
                        value.bad,
                        value.terrible,
                        value.created_at
                    ]);
                    fieldRow.eachCell(cell => {
                        cell.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
                    });

                }
                else if (value.section_type_id == 4) {
                    if(index == 0){
                        headerRow = ws.addRow(["Tipo de Sección: Texto"]);
                        // _____________________________________ Texto ____________________________________________

                        headers = ["Identidad Cliente", "Nombre Cliente", "Sexo Cliente", "Fecha Nacimiento Cliente", "Celular Cliente", "Teléfono Cliente", "Correo Cliente", "Dirección Cliente", "Tarjeta Cliente", "Visto", "Fecha"];

                        headerRow = ws.addRow(headers);
                        headerRow.eachCell(cell => this.format_basic_header(cell));
                    }
                    

                    fieldRow = ws.addRow([
                        value.cli_identidad,
                        value.cli_nombre,
                        value.cli_sexo,
                        value.cli_fecha_nac,
                        value.cli_celular,
                        value.cli_telefono,
                        value.cli_email,
                        value.cli_direccion,
                        value.cli_tarjeta,
                        value.viewed,
                        value.created_at
                    ]);
                    fieldRow.eachCell(cell => {
                        cell.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
                    });
                }
            });
        });

        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: EXCEL_TYPE });
            fs.saveAs(blob, excelFileName + '.' + EXCEL_EXTENSION);
            blob = null;
            callback();
        });
    }

    format_basic_header(cell) {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00BEF3FE' }
        };
        cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        cell.font = {
            name: 'Segoe UI',
            size: 10,
            color: { argb: '00424242' }
        };
        return cell;
    }
}